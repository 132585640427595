import { InputBase } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import appStyle from "../styles/AppStyle";

const InputWithTitle = (props) => {
    const {
        title,
        data,
        placeHolder,
        style,
        showBefore,
        titleBefore,
        showComment,
        onChange,
        comment,
        hideComment,
        require,
        type,
        hideTitle,
    } = props;
    const appStyles = appStyle();
    const [value, setValue] = useState("");
    const invalidChars = ["-", "+", "e"];

    useEffect(() => {
        setValue(data ?? "");

        // document.getElementById("inputBox").addEventListener("input", function () {
        //     this.value = this.value.replace(/[e\+\-]/gi, "");
        // });
    }, [data]);

    return (
        <div>
            <div style={{ width: "100%", marginTop: 10, ...style }} className="display-col">
                {!hideTitle && (
                    <div style={{ height: 14 }}>
                        <div
                            style={{
                                fontSize: 12,
                                lineHeight: "14px",
                                color: "white",
                                fontFamily: "SFProTextSemiBold",
                                display: "flex",
                            }}
                        >
                            {title}
                            {require && <div style={{ color: "red", whiteSpace: "pre-wrap" }}> *</div>}
                        </div>
                    </div>
                )}
                <div className="display-center-row" style={{ marginTop: 4 }}>
                    {showBefore && (
                        <div
                            style={{ width: 130, height: 27, background: "rgba(0, 0, 0, 0.25)", padding: "1px 0.28px" }}
                        >
                            <div
                                className="display-center"
                                style={{
                                    background: "rgba(0, 0, 0, 0.15)",
                                    width: "100%",
                                    height: "100%",
                                    fontSize: 14,
                                    color: "white",
                                    fontFamily: "SFProDisplay",
                                }}
                            >
                                {titleBefore}
                            </div>
                        </div>
                    )}
                    <div
                        className="display-center-row"
                        style={{
                            flex: 1,
                            border: "1px solid #858C94",
                            borderRadius: 8,
                            height: 31,
                            cursor: "pointer",
                            padding: "3px 14px 4px",
                        }}
                    >
                        <InputBase
                            placeholder={placeHolder}
                            type={type}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                // lineHeight: "24px",
                                fontFamily: "SFProDisplay",
                                height: "100%",
                            }}
                            onChange={(e) => {
                                setValue(e.currentTarget.value);
                                onChange(e.currentTarget.value);
                            }}
                            value={value}
                            onKeyPress={(e) => {
                                if (type === "number" && invalidChars.includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        ></InputBase>
                    </div>
                </div>
            </div>
            {!hideComment && (
                <div
                    style={{ marginTop: 2, fontSize: 10, lineHeight: "20px", height: 20, fontFamily: "SourceSansPro" }}
                >
                    {showComment ? `${comment}` : ""}
                </div>
            )}
        </div>
    );
};

InputWithTitle.defaultProps = {
    type: "text",
    title: "",
    placeHolder: "",
    style: {},
    hideTitle: false,
    showBefore: false,
    showComment: true,
    hideComment: false,
    comment: "Keep it short and pithy. It’s a quick way to tell consumers about your feed item. Max 255 char.",
    onChange: () => {},
};

export default InputWithTitle;
