import clsx from "clsx";
import React from "react";
import createFormStyle from "../styles/CreateFormStyle";

function ButtonBottom(props) {
    const { showDraft, showDelete, txtOk, onClickOk, onClickCancel, onClickDelete, txtCancel } = props;
    const classes = createFormStyle();

    return (
        <div className={clsx("display-center-row", classes.containerBottom)}>
            <div style={{ flex: 1 }}>
                {showDelete && (
                    <div
                        className={clsx(classes.containerBtn, "display-center")}
                        style={{ background: "#FF6961" }}
                        onClick={onClickDelete}
                    >
                        Delete
                    </div>
                )}
            </div>
            <div className="display-row" style={{ width: 150 }}>
                <div style={{ marginRight: 12, width: 69 }}>
                    {showDraft && (
                        <div
                            className={clsx(classes.containerBtn, "display-center")}
                            style={{ background: "#585758" }}
                            onClick={onClickCancel}
                        >
                            {txtCancel}
                        </div>
                    )}
                </div>
                <div
                    className={clsx(classes.containerBtn, "display-center")}
                    style={{ background: "#8CB2F8" }}
                    onClick={onClickOk}
                >
                    {txtOk}
                </div>
            </div>
        </div>
    );
}

ButtonBottom.defaultProps = {
    showDraft: true,
    showDelete: false,
    txtOk: "SUBMIT",
    txtCancel: "DRAFT",
    onClickOk: () => {},
    onClickCancel: () => {},
    onClickDelete: () => {},
};

export default ButtonBottom;
