import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put, select } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { openMessageDialog } from "../reducers/AppReducer";
import { sortCategory } from "../../utils/Utils";
import {
    createCategory,
    deleteCategory,
    deleteCategoryFail,
    getCategory,
    getCategoryFail,
    getCategorySuccess,
    updateCategory,
    updateCategoryFail,
} from "../reducers/CategoryReducer";

function* categorySagaWatcher() {
    yield takeLatest(getCategory, withCallback(getListCat));
    yield takeLatest(createCategory, withCallback(createCat));
    yield takeLatest(updateCategory, withCallback(updateCat));
    yield takeLatest(deleteCategory, withCallback(deleteCat));
}

function* getListCat(action) {
    try {
        const response = yield call(handleApi, "dashboard/category");
        sortCategory(response.data)
        yield put(getCategorySuccess(response.data));
        return response.data;
    } catch (error) {
        yield put(getCategoryFail({ isError: true, messageError: error.message }));
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return [];
    }
}

function* createCat(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/category/${encodeURIComponent(action.payload.id)}`,
            "POST",
            action.payload.data
        );
        yield put(getCategorySuccess(state.category.data.concat(response.data)));
        return true;
    } catch (error) {
        yield put(getCategoryFail({ isError: true, messageError: error.message }));
        return false;
    }
}

function* updateCat(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/category/${encodeURIComponent(action.payload.id)}`,
            "POST",
            action.payload.data
        );
        var result = state.category.data.map((item) => {
            if (item.uid === action.payload.id) {
                item = response.data;
            }
            return item;
        });
        yield put(getCategorySuccess(result));
        return true;
    } catch (error) {
        yield put(updateCategoryFail({ isError: true, messageError: error.message }));
        return false;
    }
}

function* deleteCat(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/category/${encodeURIComponent(action.payload)}`, "DELETE");
        yield put(getCategorySuccess(state.category.data.filter((el) => el.uid !== action.payload)));
        return true;
    } catch (error) {
        yield put(deleteCategoryFail({ isError: true, messageError: error.message }));
        return false;
    }
}

export default categorySagaWatcher;
