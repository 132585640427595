import { createSlice } from "@reduxjs/toolkit";

const eventReducer = createSlice({
    name: "events",
    initialState: {
        isLoading: false,
        data: { events: [], items: [] },
        event: undefined,
        isError: false,
        messageError: "",
        isDeleteEventUnassign: false,
        isDeleteEvent: false,
    },
    reducers: {
        createEvent: (state, action) => {
            state.isLoading = true;
            state.isError = false;
        },
        createEventSuccess: (state, action) => {
            state.isLoading = false;
            state.event = action.payload;
        },
        createEventFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        deleteEvent: (state, action) => {
            state.isLoading = true;
            state.isError = false;
            state.isDeleteEvent = false;
        },
        deleteEventSuccess: (state, action) => {
            state.isLoading = false;
            state.isDeleteEvent = true;
        },
        deleteEventFail: (state, action) => {
            state.isLoading = false;
            state.isDeleteEvent = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        getEventsById: (state, action) => {
            state.isLoading = true;
            // state.data = undefined;
        },
        getEventsByIdSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getEventsByIdFail: (state, action) => {
            state.isLoading = false;
        },
        getEventsUnassign: (state, action) => {
            state.isLoading = true;
        },
        getEventsUnassignSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getEventsUnassignFail: (state, action) => {
            state.isLoading = false;
        },
        createEventUnassign: (state, action) => {
            state.isLoading = true;
            state.isError = false;
        },
        createEventUnassignSuccess: (state, action) => {
            state.isLoading = false;
            state.event = action.payload;
        },
        createEventUnassignFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        deleteEventUnassign: (state, action) => {
            state.isLoading = true;
            state.isError = false;
            state.isDeleteEventUnassign = false;
        },
        deleteEventUnassignSuccess: (state, action) => {
            state.isLoading = false;
            state.isDeleteEventUnassign = true;
        },
        deleteEventUnassignFail: (state, action) => {
            state.isLoading = false;
            state.isDeleteEventUnassign = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        resetListEvent: (state, action) => {
            state.data = { events: [], items: [] };
        },
        updateEventUnassign: (state, action) => {
            state.isLoading = true;
            state.isError = false;
        },
        updateEventUnassignFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        updateEvent: (state, action) => {
            state.isLoading = true;
            state.isError = false;
        },
        updateEventFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        getEventUnassignById: (state, action) => {
            state.isLoading = true;
        },
        getEventUnassignByIdFail: (state, action) => {
            state.isLoading = false;
        },
        getEventById: (state, action) => {
            state.isLoading = true;
        },
        getEventByIdFail: (state, action) => {
            state.isLoading = false;
        },
        getEventRsvpLink: (state, action) => {
            // empty
        }
    },
});

const { reducer, actions } = eventReducer;
export const {
    createEvent,
    createEventSuccess,
    createEventFail,
    getEventsById,
    getEventsByIdSuccess,
    getEventsByIdFail,
    getEventsUnassign,
    getEventsUnassignSuccess,
    getEventsUnassignFail,
    createEventUnassign,
    createEventUnassignSuccess,
    createEventUnassignFail,
    resetListEvent,
    deleteEventUnassign,
    deleteEventUnassignSuccess,
    deleteEventUnassignFail,
    deleteEvent,
    deleteEventFail,
    deleteEventSuccess,
    updateEventUnassign,
    updateEventUnassignFail,
    updateEvent,
    updateEventFail,
    getEventById,
    getEventByIdFail,
    getEventUnassignById,
    getEventUnassignByIdFail,
    getEventRsvpLink
} = actions;
export default reducer;
