import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put, select } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { openMessageDialog } from "../reducers/AppReducer";
import {
    createPersona,
    createPersonaFail,
    deletePersona,
    deletePersonaFail,
    getPersonas,
    getPersonasFail,
    getPersonasSuccess,
    updatePersona,
    updatePersonaFail,
} from "../reducers/PersonaReducer";
import {sortCalendarJockeyPersona} from "../../utils/Utils"

function* personaWatcher() {
    yield takeLatest(getPersonas, getListPersonaSaga);
    yield takeLatest(createPersona, withCallback(createPersonaSaga));
    yield takeLatest(updatePersona, withCallback(updatePersonaSaga));
    yield takeLatest(deletePersona, withCallback(deletePersonaSaga));
}

function* getListPersonaSaga(action) {
    try {
        const response = yield call(handleApi, "dashboard/persona");
        sortCalendarJockeyPersona(response.data);
        yield put(getPersonasSuccess(response.data));
    } catch (error) {
        yield put(getPersonasFail({ isError: true, message: error.message }));
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

function* createPersonaSaga(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/persona/${encodeURIComponent(action.payload.id)}`,
            "POST",
            action.payload.data
        );
        yield put(getPersonasSuccess(state.persona.data.concat(response.data)));
        return true;
    } catch (error) {
        yield put(createPersonaFail({ isError: true, message: error.message }));
        return false;
    }
}

function* updatePersonaSaga(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/persona/${encodeURIComponent(action.payload.id)}`,
            "POST",
            action.payload.data
        );
        var result = state.persona.data.map((item) => {
            if (item.uid === action.payload.id) {
                item = response.data;
            }
            return item;
        });
        yield put(getPersonasSuccess(result));
        return true;
    } catch (error) {
        yield put(updatePersonaFail({ isError: true, message: error.message }));
        return false;
    }
}

function* deletePersonaSaga(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/persona/${encodeURIComponent(action.payload)}`, "DELETE");
        yield put(getPersonasSuccess(state.persona.data.filter((el) => el.uid !== action.payload)));
        return true;
    } catch (error) {
        yield put(deletePersonaFail({ isError: true, message: error.message }));
        return false;
    }
}

export default personaWatcher;
