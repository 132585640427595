import clsx from "clsx";
import React, { useEffect, useState } from "react";
import sortStyle from "../styles/SortStyle";
import { faSortAmountDownAlt, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@material-ui/core";
import { isEmpty } from "lodash";
import PopupMenu from "./PopupMenu";

function SortComponent(props) {
    const { isEvent, isAssignEvent, isAscending, onClickSort, sortBy, tag} = props;
    const classes = sortStyle();
    const [anchorEl, setAnchorEl] = useState();
    const [listMenu, setListMenu] = useState();
    const [title, setTitle] = useState();
    const [ascending, setAscending] = useState(false);

    useEffect(() => {
        if(tag===true) {
            setListMenu(["By Title", "By Event Start Time", "By Created Time", "By Event End Time"]);
        } else if (isAssignEvent) {
            setListMenu(["By Title", "By Event Start Time", "By Created Time"]);
        } else {
            setListMenu([
                "By Title",
                isEvent ? "By Event Start Time" : "By Upcoming Event Start Time",
                "By Publish Start Time",
                "By Created Time",
            ]);
        }
    }, [isAssignEvent, isEvent, tag]);

    useEffect(() => {
        if (sortBy?.includes("title")) {
            setTitle("By Title");
        } else if (sortBy?.includes("upcoming_event_time") || sortBy === "start_time" || sortBy === "-start_time") {
            setTitle((isEvent || tag === true) ? "By Event Start Time" : "By Upcoming Event Start Time");
        } else if (sortBy?.includes("publish_start_time")) {
            setTitle("By Publish Start Time");
        } else if (sortBy?.includes("created")) {
            setTitle("By Created Time");
        } else if (sortBy?.includes("end_time")) {
            setTitle("By Event End Time");
        } else {
            setTitle("By Created Time");
        }
    }, [sortBy, isEvent]);

    useEffect(() => {
        setAscending(isAscending);
    }, [isAscending]);

    return (
        <div>
            <div
                className={clsx("display-center-row")}
                style={{ cursor: "pointer" }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <div
                    className={clsx(classes.conIconSort, "display-center")}
                    onClick={(e) => {
                        e.stopPropagation();
                        var tempSort;
                        switch (title) {
                            case "By Title":
                                tempSort = ascending ? "-title" : "title";
                                break;
                            case "By Upcoming Event Start Time":
                            case "By Event Start Time":
                                if (isAssignEvent || tag === true) {
                                    tempSort = ascending ? "-start_time" : "start_time";
                                } else {
                                    tempSort = ascending ? "-upcoming_event_time" : "upcoming_event_time";
                                }
                                break;
                            case "By Publish Start Time":
                                tempSort = ascending ? "-publish_start_time" : "publish_start_time";
                                break;
                            case "By Event End Time":
                                tempSort = ascending ? "-end_time" : "end_time";
                                break;
                            default:
                                tempSort = ascending ? "-created" : "created";
                                break;
                        }
                        onClickSort(tempSort, true);
                        setAscending(!ascending);
                    }}
                >
                    <FontAwesomeIcon icon={ascending ? faSortAmountDownAlt : faSortAmountUp} />
                </div>
                <div className={classes.txtSort}>{title}</div>
            </div>
            <Popover
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <PopupMenu
                    listMenu={listMenu}
                    onClick={(value) => {
                        setAnchorEl();
                        setTitle(value);
                        var tempSort;
                        switch (value) {
                            case "By Title":
                                tempSort = "-title";
                                break;
                            case "By Upcoming Event Start Time":
                            case "By Event Start Time":
                                tempSort = tag===true ? "-start_time" : isAssignEvent ? "-start_time" : "-upcoming_event_time";
                                break;
                            case "By Publish Start Time":
                                tempSort = "-publish_start_time";
                                break;
                            case "By Event End Time":
                                tempSort = "-end_time";
                                break;
                            default:
                                tempSort = "-created";
                                break;
                        }
                        onClickSort(tempSort, false);
                        setAscending(false);
                    }}
                />
            </Popover>
        </div>
    );
}

SortComponent.defaultProps = {
    isAssignEvent: false,
};

export default SortComponent;
