import { isEmpty } from "lodash";
import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put, select } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { getItemFeedByIdSuccess, openMessageDialog } from "../reducers/AppReducer";
import {
    createCalendar,
    createCalendarSuccess,
    createCalendarFail,
    getCalendars,
    getCalendarsSuccess,
    getCalendarsFail,
    deleteCalendar,
    deleteCalendarFail,
    deleteCalendarSuccess,
    updateCalendar,
    updateCalendarFail,
    updateCalendarSuccess,
    getCalendarById,
    getCalendarByIdFail,
} from "../reducers/CalendarReducer";
import { getAllFeedByPreFilterSuccess, getFeedsByPreFilterSuccess } from "../reducers/PreFilterReducer";
import { getStatistics } from "../reducers/StatisticsReducer";

function* calendarSagaWatcher() {
    yield takeLatest(createCalendar, withCallback(create));
    yield takeLatest(getCalendars, getListCalendar);
    yield takeLatest(deleteCalendar, withCallback(deleteItem));
    yield takeLatest(updateCalendar, withCallback(updateItem));
    yield takeLatest(getCalendarById, withCallback(getItemById));
}

function* create(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, "dashboard/collections", "POST", action.payload.data);
        yield put(createCalendarSuccess(response.data));
        yield put(getStatistics());
        if (state.calendar.data.count === state.calendar.data.items.length) {
            yield put(
                getCalendarsSuccess({
                    count: state.calendar.data.count + 1,
                    items: state.calendar.data.items.concat(response.data),
                    nextPageToken: null,
                })
            );
        }
        return true;
    } catch (error) {
        yield put(createCalendarFail({ isError: true, message: error.message }));
        return false;
    }
}

function* deleteItem(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/collections/${action.payload.uid}`, "DELETE");
        yield put(deleteCalendarSuccess());
        yield put(getStatistics());
        yield put(
            getFeedsByPreFilterSuccess({
                ...state.preFilter.data,
                items: state?.preFilter?.data?.items?.filter((el) => {
                    if (el.event) {
                        return true;
                    } else {
                        return encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2];
                    }
                }),
            })
        );
        yield put(
            getAllFeedByPreFilterSuccess({
                calendar: {
                    ...state.preFilter.calendar,
                    items: state?.preFilter?.calendar?.items?.filter(
                        (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                    ),
                },
                collection: { ...state.preFilter.collection },
                event: { ...state.preFilter.event },
            })
        );
        yield put(
            getCalendarsSuccess({
                ...state.calendar.data,
                count: state.calendar.data.count - 1,
                items: state?.calendar?.data?.items?.filter(
                    (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                ),
            })
        );
        return true;
    } catch (error) {
        yield put(deleteCalendarFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* getListCalendar(action) {
    try {
        const state = yield select();
        var url = `dashboard/all/calendars?limit=20&sort=title${
            !isEmpty(action.payload) ? `&page_token=${encodeURIComponent(action.payload)}` : ""
        }`;
        const response = yield call(handleApi, url);
        yield put(
            getCalendarsSuccess({ ...response.data, items: state.calendar.data.items.concat(response.data.items) })
        );
    } catch (error) {
        yield put(getCalendarsFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

function* updateItem(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/collections/${action.payload.uid}`,
            "PUT",
            action.payload.data
        );
        yield put(updateCalendarSuccess(response.data));
        yield put(getStatistics());
        yield put(getItemFeedByIdSuccess(response.data));
        yield put(
            getFeedsByPreFilterSuccess({
                ...state.preFilter.data,
                items: state?.preFilter?.data?.items?.filter(
                    (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                ),
            })
        );
        yield put(
            getAllFeedByPreFilterSuccess({
                calendar: {
                    ...state.preFilter.calendar,
                    items: state?.preFilter?.calendar?.items?.filter(
                        (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                    ),
                },
                collection: { ...state.preFilter.collection },
                event: { ...state.preFilter.event },
            })
        );
        // yield put(
        //     getCalendarsSuccess({
        //         ...state.calendar.data,
        //         count: state.calendar.data.count - 1,
        //         items: state.calendar.data.items.filter((el) => el.uid !== action.payload.uid.split(":")[2]),
        //     })
        // );
        return true;
    } catch (error) {
        yield put(updateCalendarFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* getItemById(action) {
    try {
        var url = `/dashboard/collections/${action.payload}`;
        const response = yield call(handleApi, url);
        return response.data;
    } catch (error) {
        yield put(getCalendarByIdFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return {};
    }
}

export default calendarSagaWatcher;
