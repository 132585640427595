import { takeLatest, call, put, select, all } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import {
    getEventsByTag,
    getEventsByTagSuccess,
    getEventsByTagFail
} from "../reducers/TagEventReducer";
import { isEmpty } from "lodash";
import { withCallback } from "redux-saga-callback";
import { openMessageDialog } from "../reducers/AppReducer";
import { event } from "jquery";

function* eventTagWatcher() {
    yield takeLatest(getEventsByTag, withCallback(getFeedsByTag));
}

function* getFeedsByTag(action) {
    try {
        const state = yield select();
        const limit = `limit=${action.payload.limit}`;
        const sort = !isEmpty(action.payload.sortBy) ? `&sort=${action.payload.sortBy}` : "";
        const query = !isEmpty(action.payload.query) ? `&query=${action.payload.query}` : "";
        const page_token = !isEmpty(action.payload.page_token) ? `&page_token=${encodeURIComponent(action.payload.page_token)}` : "";

        const response = yield call(
            handleApi,
            `dashboard/tagged_events/${action.payload.tag_id}?${limit}${sort}${query}${page_token}`
        );

        const events = !page_token ? response.data.events : state.eventTag.data.events.concat(response.data.events)

        yield put(getEventsByTagSuccess({
            ...response.data,
            events,
            items: [],
        }));
        return response.data;
    } catch (error) {
        yield put(getEventsByTagFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return { items: [] };
    }
}

export default eventTagWatcher;
