import { makeStyles } from "@material-ui/core";

const carouselStyle = makeStyles((theme) => ({
    conEmpty: {
        height: "100%",
        width: "100%",
    },
    txtCreateNew: {
        fontSize: 14,
        fontFamily: "Roboto",
        color: "#8CB2F8",
        marginLeft: 20,
    },
    conDivider: {
        border: "1px solid #282828",
        marginTop: 13,
    },
    conCarousel: {
        height: 437,
    },
    txtTitle: {
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: "21px",
        flex: 1,
        color: "white",
    },
    conNav: {
        width: 46,
        justifyContent: "space-between",
    },
    conAdd: {
        marginTop: 20,
        cursor: "pointer",
    },
}));

export default carouselStyle;
