import { Box, InputBase, Popper, ClickAwayListener } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import appStyle from "../styles/AppStyle";
import CheckIcon from "@material-ui/icons/Check";
import { isEmpty } from "lodash";
import { usePopper } from "react-popper";

function SearchSelectTitle(props) {
    const {
        title,
        list,
        data,
        placeHolder,
        style,
        showComment,
        onItemChange,
        onItemInputChange,
        require,
    } = props;
    // const [anchorEl, setAnchorEl] = useState();
    const appStyles = appStyle();
    const parentRef = useRef();
    const [value, setValue] = useState("");
    const [currentValue, setCurrentValue] = useState("");
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement);
    const [isOpen, setOpen] = useState();

    useEffect(() => {
        if (!isEmpty(data) && !isEmpty(list)) {
            setValue(list.find((el) => el.value === data)?.title ?? "");
            setCurrentValue(list.find((el) => el.value === data)?.value ?? "");
        } else {
            setValue("");
            setCurrentValue();
        }
    }, [data, list]);

    return (
        <div style={{ width: "100%", marginTop: 10, ...style }} className="display-col">
            <div
                style={{
                    fontSize: 12,
                    lineHeight: "14px",
                    color: "white",
                    fontFamily: "SFProTextSemiBold",
                    display: "flex",
                }}
            >
                {title}
                {require && <div style={{ color: "red", whiteSpace: "pre-wrap" }}> *</div>}
            </div>
            <div
                ref={parentRef}
                className="display-center-row"
                style={{
                    flex: 1,
                    marginTop: 4,
                    border: "1px solid #858C94",
                    borderRadius: 8,
                    height: 31,
                    cursor: "pointer",
                    padding: "3px 14px 4px",
                }}
            >
                <InputBase
                    ref={setReferenceElement}
                    placeholder={placeHolder}
                    style={{
                        flex: 1,
                        fontSize: 14,
                        // lineHeight: "24px",
                        fontFamily: "SFProDisplay",
                        height: "100%",
                    }}
                    onChange={(e) => {
                        setValue(e.currentTarget.value);
                        onItemInputChange(e.currentTarget.value);
                        // setAnchorEl(parentRef.current);
                    }}
                    onClick={(e) => {
                        setOpen(true);
                        // setAnchorEl(parentRef.current);
                    }}
                    onFocus={(e) => {
                        // setAnchorEl(parentRef.current);
                    }}
                    onBlur={(e) => {}}
                    value={value}
                />
            </div>
            <div style={{ marginTop: 2, fontSize: 10, lineHeight: "20px", height: 20, fontFamily: "SourceSansPro" }}>
                {showComment
                    ? "Keep it short and pithy. It’s a quick way to tell consumers about your feed item. Max 255 char."
                    : ""}
            </div>
            {/* <Popper
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom"
                disablePortal={false}
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                    },
                }}
            > */}
            {isOpen && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 99, top: 5 }} {...attributes.popper}>
                        <Box
                            boxShadow={3}
                            style={{
                                width: parentRef?.current?.offsetWidth * 0.95,
                                maxHeight: 200,
                                overflowY: "auto",
                                borderRadius: 8,
                            }}
                            className={appStyles.scrollbar}
                        >
                            {list
                                .filter((el) => el.title.toLowerCase().includes(value.toLowerCase()))
                                .map((item, index) => (
                                    <div
                                        key={index}
                                        className="display-center-row"
                                        onClick={(e) => {
                                            // setAnchorEl();
                                            setOpen(false);
                                            setValue(item.title);
                                            setCurrentValue(item.value);
                                            onItemChange(item.value);
                                        }}
                                        style={{
                                            height: 30,
                                            background: "#181818",
                                            color: "#ADAFB2",
                                            cursor: "pointer",
                                            zIndex: 100,
                                            pointerEvents: "auto",
                                        }}
                                    >
                                        <div style={{ width: 30 }} className="display-center">
                                            {currentValue === item.value && (
                                                <CheckIcon style={{ width: 24, heigh: 24, cursor: "pointer" }} />
                                            )}
                                        </div>
                                        <span style={{ flex: 1, paddingRight: 30 }} className="text-ellipsis">
                                            {item.title}
                                        </span>
                                    </div>
                                ))}
                        </Box>
                    </div>
                </ClickAwayListener>
            )}
            {/* </Popper> */}
        </div>
    );
}

SearchSelectTitle.defaultProps = {
    list: [],
    placeHolder: "",
    style: {},
    showComment: true,
    onItemChange: () => {},
    onItemInputChange: () => {},
    isAlignParent: false,
    disable: false,
};

export default SearchSelectTitle;
