import { createSlice } from "@reduxjs/toolkit";

const categoryReducer = createSlice({
    name: "category",
    initialState: {
        isLoading: false,
        data: [],
        isError: false,
        messageError: "",
    },
    reducers: {
        getCategory: (state, action) => {
            state.isLoading = true;
        },
        getCategorySuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
        createCategory: (state, action) => {
            state.isLoading = true;
        },
        createCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
        updateCategory: (state, action) => {
            state.isLoading = true;
        },
        updateCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
        deleteCategory: (state, action) => {
            state.isLoading = true;
        },
        deleteCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
    },
});

const { reducer, actions } = categoryReducer;
export const {
    getCategory,
    getCategorySuccess,
    getCategoryFail,
    createCategory,
    createCategoryFail,
    updateCategory,
    updateCategoryFail,
    deleteCategory,
    deleteCategoryFail,
} = actions;
export default reducer;
