import React, { Component, Fragment } from 'react';
import { SchemaElementType, schemaStyles, SchemaComponent } from './Schema';
import SchemaKeyValue from './SchemaKeyValue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faPlay } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SchemaArray from './SchemaArray';

export default class SchemaArrayDynamicLength extends Component {
  openCharacter = '[';
  closeCharacter = ']';

  constructor(props) {
    super(props);
    this.state = {
      schema: props.schema,
      values: props.initialValue || {},
      expanded: true,
      dropdownOpen: false,
      possibleTypes: props.schema.array.map((schema) => schema.visibleName),
      selectedType: props.schema.array[0].visibleName,
      required: props.required,
      elements:
        props.initialValue
          ? this.compileSchemaFromInitialValue(
            props.initialValue,
            props.schema.array,
            props.schema.dynamicLength.isUnion,
          )
          : {},
      nextIndex:
        props.initialValue
          ? Object.keys(props.initialValue).length
          : 0,
    };
    // console.log('STATE: ', this.state)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValue, schema } = this.props
    if (
      JSON.stringify(this.props.schema) !== JSON.stringify(prevState.schema)
      || JSON.stringify(this.props.initialValue) !== JSON.stringify(prevProps.initialValue)
    ) {
      const newPossibleTypes = schema.array.map((schema) => schema.visibleName);
      const newSelectedType = schema.array[0].visibleName;
      const newElements = initialValue ?
        this.compileSchemaFromInitialValue(initialValue, schema.array, this.state.schema.dynamicLength.isUnion)
        : {}
      const newNextIndex = initialValue
        ? Object.keys(initialValue).length
        : 0;
      this.setState({
        schema,
        possibleTypes: newPossibleTypes,
        selectedType: newSelectedType,
        elements: newElements,
        nextIndex: newNextIndex,
        values: initialValue
      })
    }
    this.printSchema()
  }

  onDeleteElement = (key) => () => {
    const { schema } = this.state;
    let newValues = { ...this.state.values };
    // console.log('Pre deleted values: ', newValues)
    delete newValues[key];
    // console.log('Post deleted values: ', newValues)
    const newElements = this.compileSchemaFromInitialValue(newValues, schema.array, schema.dynamicLength.isUnion);
    this.setState({
      values: newValues,
      elements: newElements,
    })
  }

  onAddElement = async () => {
    await this.setState((state) => {
      const elements = { ...state.elements };
      const nextIndex = state.nextIndex;
      const selectedType = state.selectedType;
      elements[nextIndex] = this.compileElement(
        nextIndex,
        state.schema.array.find(element => element.visibleName === selectedType),
      );
      const newValue = elements[nextIndex].props.children[1].props.children.props.initialValue
      const values = { ...state.values, [nextIndex]: newValue }
      // console.log('added values: ', values)
      // console.log('added elements', elements)
      return { ...state, elements, values, nextIndex: nextIndex + 1 };
    });
  }

  onSchemaValueChange = (key, type) => (value) => {
    // console.log('newValue: ', value)
    // console.log('pre values change state: ', this.state.values)
    const newValues = { ...this.state.values, [key]: this.state.schema.dynamicLength.isUnion ? { __type: type, value } : value }
    this.setState({ values: newValues })
  }

  printSchema = () => {
    const { values, required } = this.state
    const arr = [];
    if (values) {
      Object.keys(values).forEach(key => {
        arr.push(values[key])
      });
    }
    // this.props.onValueChange(arr.length === 0 ? (required ? [] : undefined) : arr);
    this.props.onValueChange(arr);
  }


  compileSchemaFromInitialValue = (initialValue, possibleSchemaTypes, isUnion) => {
    const elements = {};
    Object.keys(initialValue).forEach(key => {
      const value = initialValue[key];
      const schemaToUse = isUnion
        ? possibleSchemaTypes.find(schema => schema.visibleName === value.__type)
        : possibleSchemaTypes[0];
      elements[key] = this.compileElement(key, schemaToUse, isUnion ? value.value : value);
    });
    return elements;
  }

  compileSchema = (schema, initialValue) => {
    const elements = {};
    schema.forEach((value, i) => {
      const initVal = initialValue[i];
      elements[i] = this.compileElement(i, value, initVal);
    });
    return elements;
  }

  compileElement = (key, schemaEntry, initialValue) => {
    const {
      type,
      keyvalue,
      object,
      array,
      required,
      visibleName
    } = schemaEntry;
    const deleteIcon =
      <Col style={{ paddingTop: '24px' }} sm={{ size: 'auto' }}>
        <FontAwesomeIcon
          style={{ cursor: 'pointer', fontSize: '1.4em' }}
          icon={faMinusCircle}
          size="1x"
          onClick={this.onDeleteElement(key)}
        />
      </Col>;
    if (type === SchemaElementType.KEY_VALUE) {
      return <Row>
        {deleteIcon}
        <Col>
          <SchemaKeyValue
            valueType={keyvalue.valueType}
            enumEntries={keyvalue.enumEntries}
            initialValue={initialValue}
            required={required}
            onValueChange={this.onSchemaValueChange(key, visibleName)}
          />
        </Col>
      </Row>
    }
    if (type === SchemaElementType.OBJECT) {
      return <Row>
        {deleteIcon}
        <Col>
          <SchemaComponent
            schemaComponent="SchemaObject"
            schema={object}
            initialValue={initialValue}
            onValueChange={this.onSchemaValueChange(
              key,
              visibleName
            )}
          />
        </Col>
      </Row>
    }
    if (type === SchemaElementType.ARRAY) {
      return <Row>
        {deleteIcon}
        <Col>
          <SchemaComponent
            schemaComponent="SchemaArray"
            schema={array}
            initialValue={initialValue}
            onValueChange={this.onSchemaValueChange(
              key,
              visibleName
            )}
          />
        </Col>
      </Row>
    }
    if (type === SchemaElementType.DYNAMIC_LENGTH) {
      return <div>
        {deleteIcon}
        <Col>
          <SchemaArrayDynamicLength
            schema={schemaEntry}
            required={required}
            initialValue={initialValue}
            onValueChange={this.onSchemaValueChange(
              key,
              visibleName
            )}
          />
        </Col>
      </div>
    }
    if (type === SchemaElementType.UNION) {
      return <div>
        {deleteIcon}
        <Col>
          <SchemaComponent
            schemaComponent="SchemaUnion"
            schema={schemaEntry}
            initialValue={initialValue}
            onValueChange={this.onSchemaValueChange(
              key,
              visibleName,
              )}
          />
        </Col>
      </div>
    }
  }

  changeSelectedType = (type) => {
    this.setState({ selectedType: type });
  }

  elementsToRender = () => {
    const elements = [];
    Object.keys(this.state.elements).forEach(key => {
      elements.push(this.state.elements[key]);
    });
    elements.push(
      <Row style={schemaStyles.topMargin}>
        <Col sm={{ size: 'auto' }} style={{ paddingTop: '7px' }}>
          <FontAwesomeIcon
            style={{ cursor: 'pointer', fontSize: '1.4em' }}
            icon={faPlusCircle}
            size="1x"
            onClick={this.onAddElement}
          />
        </Col>
        {this.state.schema.dynamicLength.isUnion &&
          <Col sm={{ size: 'auto' }}>
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={() => { this.setState(state => ({ dropdownOpen: !state.dropdownOpen })) }}
            >
              <DropdownToggle caret>
                {this.state.selectedType}
              </DropdownToggle>
              <DropdownMenu color="primary" className="w-100 p-1">
                {this.state.possibleTypes.map(
                  entry => <DropdownItem
                    onClick={() => this.changeSelectedType(entry)}>
                    {entry}
                  </DropdownItem>)}
              </DropdownMenu>
            </Dropdown>
          </Col>
        }
      </Row>
    )
    return elements;
  }

  openLine = () => {
    const {
      visibleName,
    } = this.props;
    const bracket = this.state.expanded
      ? `${this.openCharacter}`
      : `${this.openCharacter} ... ${this.closeCharacter}`;
    if (visibleName) {
      return `${visibleName}: ${bracket}`;
    }
    return bracket;
  }

  onClickCaret = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  }

  render() {
    return (
      <Fragment>
        <div style={{ ...schemaStyles.topMargin }}>
          <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faPlay} size="xs" rotation={this.state.expanded ? 270 : 90} onClick={this.onClickCaret} />
          <span style={{ ...schemaStyles.schemaLine, paddingLeft: '1em' }}>{this.openLine()}</span>
        </div>
        <div
          style={{ paddingLeft: "20px", display: this.state.expanded ? 'block' : 'none' }}
        >
          {this.elementsToRender()}
        </div>
        <p
          style={{ ...schemaStyles.schemaLine, display: this.state.expanded ? 'block' : 'none' }}
        >{this.closeCharacter}</p>

      </Fragment>
    );
  }
}