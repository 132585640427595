import { takeLatest, call, put, select, all } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import {
    getAllFeedByPreFilter,
    getAllFeedByPreFilterFail,
    getAllFeedByPreFilterSuccess,
    getFeedsByPreFilter,
    getFeedsByPreFilterFail,
    getFeedsByPreFilterSuccess
} from "../reducers/PreFilterReducer";
import { isEmpty } from "lodash";
import { withCallback } from "redux-saga-callback";
import { openMessageDialog } from "../reducers/AppReducer";

function* preFilterWatcher() {
    yield takeLatest(getFeedsByPreFilter, withCallback(getFeeds));
    yield takeLatest(getAllFeedByPreFilter, getAllFeed);
}

function* getFeeds(action) {
    try {
        const state = yield select();
        const status = action.payload.status;
        const type = action.payload.type;
        const limit = action.payload.limit;
        const isUpcoming = action.payload.isUpcoming === true ? `&upcoming_only=true` : "";
        const sortBy = !isEmpty(action.payload.sortBy) ? `&sort=${action.payload.sortBy}` : "";
        const query = !isEmpty(action.payload.query) ? `&query=${action.payload.query}` : "";
        const pageToken = !isEmpty(action.payload.pageToken) ? `&page_token=${encodeURIComponent(action.payload.pageToken)}` : "";

        const response = yield call(
            handleApi,
            `dashboard/${status}/${type}?limit=${limit}${isUpcoming}${sortBy}${query}${pageToken}`
        );

        const tempItem = !pageToken ? response.data.items : state.preFilter.data.items.concat(response.data.items)

        yield put(
            getFeedsByPreFilterSuccess({
                ...response.data,
                items: tempItem,
            })
        );
        // yield put(
        //     getAllFeedByPreFilterSuccess({
        //         calendar: { count: 0, items: [], nextPageToken: null },
        //         collection: { count: 0, items: [], nextPageToken: null },
        //         event: { count: 0, items: [], nextPageToken: null },
        //     })
        // );
        return response.data;
    } catch (error) {
        yield put(getFeedsByPreFilterFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return { items: [] };
    }
}

function* getAllFeed(action) {
    try {
        const state = yield select();
        const [calendarResponse, collectionResponse, eventResponse] = yield all([
            call(handleApi, `dashboard/${action.payload}/calendars?limit=10&upcoming_only=true`),
            call(handleApi, `dashboard/${action.payload}/collections?limit=10&upcoming_only=true`),
            call(handleApi, `dashboard/${action.payload}/single-events?limit=10`),
        ]);
        yield put(
            getAllFeedByPreFilterSuccess({
                calendar: calendarResponse.data,
                collection: collectionResponse.data,
                event: eventResponse.data,
            })
        );
        yield put(getFeedsByPreFilterSuccess({ count: 0, items: [], nextPageToken: null }));
    } catch (error) {
        yield put(getAllFeedByPreFilterFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

export default preFilterWatcher;
