import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import createFormStyle from "../styles/CreateFormStyle";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import SelectWithTitle from "../components/SelectWithTitle";
import InputWithTitle from "../components/InputWithTitle";
import TagWithTitle from "../components/TagWithTitle";
import SummerNote from "../components/SummerNote";
import SelectDate from "../components/SelectDate";
import { useSelector, useDispatch } from "react-redux";
import { getSchema, getSchemaByName } from "../redux/reducers/SchemaReducer";
import { createCalendarFail, deleteCalendarFail } from "../redux/reducers/CalendarReducer";
import { getCategory } from "../redux/reducers/CategoryReducer";
import { getSubCategory } from "../redux/reducers/SubCategoryReducer";
import gqlASTParser, {
    AWS_SCALARS,
    compileSchemaComponentsFromParsedAST,
    MEDIA_RESOURCE_TYPE,
} from "../utils/gqlASTParser";
import SchemaObject from "../components/Feed/schema/SchemaObject";
import { isEmpty } from "lodash";
import SchemaUnion from "../components/Feed/schema/SchemaUnion";
import ButtonBottom from "../components/ButtonBottom";
import SectionHeader from "../components/SectionHeader";
import { getPersonas } from "../redux/reducers/PersonaReducer";
import JsonComponent from "../components/JsonComponent";
import * as Yup from "yup";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import {
    createItemFeedCalendar,
    createItemFeedCollection,
    deleteItemCalendar,
    deleteItemFeedCollection,
    getItemCalendarById,
    getItemCollectionById,
    updateItemFeedCalendar,
    updateItemFeedCollection,
} from "../redux/actions";
import { createCollectionFail, deleteCollectionFail } from "../redux/reducers/CollectionReducer";
import ImagePicker from "../components/ImagePicker";
import { disableBodyScroll } from "body-scroll-lock";
import JsonEditorComponent from "../components/JsonEditorComponent";

const FeedView = () => {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const {
        listCat,
        listSubCat,
        listSchema,
        schema,
        isErrorCreateCalendar,
        messageErrorCalendar,
        isErrorCreateCollection,
        messageErrorCollection,
        listPersona,
        onClickDelete,
        isDeleteCalendar,
        isDeleteCollection,
    } = useSelector((state) => ({
        listCat: state.category.data,
        listSubCat: state.subCategory.data,
        listSchema: state.schema.data,
        schema: state.schema.schema,
        isErrorCreateCalendar: state.calendar.isError,
        messageErrorCalendar: state.calendar.messageError,
        isErrorCreateCollection: state.collection.isError,
        messageErrorCollection: state.collection.messageError,
        listPersona: state.persona.data,
        onClickDelete: state.app.onClickDelete,
        isDeleteCalendar: state.calendar.isDelete,
        isDeleteCollection: state.collection.isDelete,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [itemFeed, setItemFeed] = useState(history?.location?.state?.data);
    const typeAction = history.location.pathname.split("/")[1];
    const isCalendar = history.location.pathname.includes("calendar");
    const idFeed = useParams().id;

    const [title, setTitle] = useState(null);
    const [feedSchema, setFeedSchema] = useState(null);
    const [schemaData, setSchemaData] = useState(null);
    const [description, setDescription] = useState(null);
    const [creatorId, setCreatorId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [listSubCatId, setListSubCatId] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [showError, setShowError] = useState(false);
    const [imageRes, setImageRes] = useState(null);
    const [subCatShow, setSubCatShow] = useState([]);
    const [schemaValue, setSchemaValue] = useState(undefined);
    const [atDefault, setAtDefault] = useState(true);

    const [initialJson, setInitialJson] = useState(null);
    const [errorMessSchema, setErrorMessSchema] = useState("");
    const jsonValueRef = useRef();

    const [listReminder, setListReminder] = useState();
    const remindersData = [
        { title: "5 minutes before", value: 5 },
        { title: "10 minutes before", value: 10 },
        { title: "15 minutes before", value: 15 },
        { title: "30 minutes before", value: 30 },
        { title: "1 hour before", value: 60 },
        { title: "2 hours before", value: 120 },
    ];

    const objSchema = Yup.object()
        .shape({
            title: Yup.string().nullable(),
            description: Yup.string().nullable(),
            categoryId: Yup.string().nullable(),
            subCategoriesId: Yup.array().nullable(),
            creatorId: Yup.string().nullable(),
            publishStartTime: Yup.string().nullable(),
            publishEndTime: Yup.string().nullable(),
            feed_schema: Yup.string().nullable(),
            feed_data: Yup.object().nullable(),
            image: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }).noUnknown(),
            reminders: Yup.array().nullable(),
        })
        .noUnknown()
        .strict(true);

    const scrollRef = useRef();
    useEffect(() => {
        disableBodyScroll(scrollRef.current);
    }, [scrollRef.current]);

    useEffect(() => {
        dispatch(getPersonas());
        dispatch(getCategory());
        dispatch(getSubCategory());
        dispatch(getSchema(isCalendar ? "calendar" : "collection"));
    }, []);

    useEffect(() => {
        setItemFeed({});
        setSchemaValue();
        if (!isEmpty(idFeed)) {
            if (isCalendar) {
                dispatch(
                    getItemCalendarById({
                        payload: idFeed,
                        onComplete: ({ error, cancelled, data }) => {
                            if (error || cancelled) return;
                            setItemFeed(data);
                        },
                    })
                );
            } else {
                dispatch(
                    getItemCollectionById({
                        payload: idFeed,
                        onComplete: ({ error, cancelled, data }) => {
                            if (error || cancelled) return;
                            setItemFeed(data);
                        },
                    })
                );
            }
        }
    }, [idFeed, isCalendar, typeAction]);

    useEffect(() => {
        if (!isEmpty(schema)) {
            const parsedAST = gqlASTParser(
                schema.code.replace(/\\n/gm, "\n").replace(/\\t/gm, "\t") + AWS_SCALARS + MEDIA_RESOURCE_TYPE
            );
            const schemaCompile = compileSchemaComponentsFromParsedAST(schema.name, parsedAST);
            setSchemaValue(schemaCompile);
        }
    }, [schema]);

    useEffect(() => {
        setShowError(false);
        setTitle(itemFeed?.title ?? null);
        setCreatorId(itemFeed?.creatorId ?? null);
        setDescription(itemFeed?.description ?? null);
        setCategoryId(itemFeed?.categoryId ?? null);
        setListSubCatId(itemFeed?.subCategoriesId ?? null);
        setStartTime(itemFeed?.publishStartTime ?? null);
        setEndTime(itemFeed?.publishEndTime ?? null);
        setSchemaData(itemFeed?.feed_data ?? null);
        setFeedSchema(itemFeed?.feed_schema ?? null);
        if (!isEmpty(itemFeed?.image)) {
            setImageRes({
                path: !isEmpty(itemFeed.image.thumbnail) ? itemFeed.image.thumbnail : itemFeed.image.path,
                mediaType: itemFeed.image.mediaType,
            });
        } else {
            setImageRes({ path: null, mediaType: null });
        }
        setListReminder(itemFeed?.reminders ?? null);
    }, [itemFeed]);

    useEffect(() => {
        if (!isEmpty(listSchema) && !isEmpty(feedSchema) && listSchema.some((el) => el === feedSchema)) {
            dispatch(getSchemaByName({ type: isCalendar ? "calendar" : "collection", name: feedSchema }));
        }
    }, [listSchema, feedSchema]);

    useEffect(() => {
        if (!isEmpty(listSubCat) && !isEmpty(categoryId)) {
            setSubCatShow(listSubCat.filter((el) => el.categoryId === categoryId));
        }
    }, [listSubCat, categoryId]);

    useEffect(() => {
        if (isErrorCreateCalendar || isErrorCreateCollection) {
            setShowError(true);
            if (isCalendar) {
                dispatch(createCalendarFail({ isError: false, message: messageErrorCalendar }));
            } else {
                dispatch(createCollectionFail({ isError: false, message: messageErrorCollection }));
            }
        }
    }, [isErrorCreateCalendar, isErrorCreateCollection]);

    useEffect(() => {
        if (onClickDelete) {
            if (isCalendar) {
                dispatch(
                    deleteItemCalendar({
                        uid: idFeed,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.goBack();
                            }
                        },
                    })
                );
            } else {
                dispatch(
                    deleteItemFeedCollection({
                        uid: idFeed,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.goBack();
                            }
                        },
                    })
                );
            }
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    useEffect(() => {
        if (isDeleteCalendar || isDeleteCollection) {
            dispatch(deleteCollectionFail({ isError: false, message: "" }));
            dispatch(deleteCalendarFail({ isError: false, message: "" }));
        }
    }, [isDeleteCalendar, isDeleteCollection]);

    function createFeedItem(draft) {
        var data = {
            type: isCalendar ? "calendar" : undefined,
            title: title,
            description: description,
            category: categoryId,
            subcategories: listSubCatId,
            creator: creatorId,
            publish_start_time: startTime,
            publish_end_time: endTime,
            feed_schema: feedSchema,
            feed_data: schemaData,
            is_calendar: isCalendar ? true : undefined,
            status: draft ? "draft" : undefined,
            image: isEmpty(imageRes.path) ? null : imageRes,
            reminders: listReminder,
        };
        if (typeAction === "edit") {
            if (isCalendar) {
                dispatch(
                    updateItemFeedCalendar({
                        uid: idFeed,
                        data,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                if (draft) {
                                    history.push("/draft");
                                } else {
                                    history.goBack();
                                }
                            }
                        },
                    })
                );
            } else {
                dispatch(
                    updateItemFeedCollection({
                        uid: idFeed,
                        data,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                if (draft) {
                                    history.push("/draft");
                                } else {
                                    history.goBack();
                                }
                            }
                        },
                    })
                );
            }
        } else {
            if (isCalendar) {
                dispatch(
                    createItemFeedCalendar({
                        data,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                if (draft) {
                                    history.push("/draft");
                                } else {
                                    history.goBack();
                                }
                            }
                        },
                    })
                );
            } else {
                dispatch(
                    createItemFeedCollection({
                        data,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                if (draft) {
                                    history.push("/draft");
                                } else {
                                    history.goBack();
                                }
                            }
                        },
                    })
                );
            }
        }
    }

    const getJsonValue = () => {
        return objSchema.cast({
            title: title,
            description: description,
            categoryId: categoryId,
            subCategoriesId: listSubCatId,
            creatorId: creatorId,
            publishStartTime: startTime,
            publishEndTime: endTime,
            feed_schema: feedSchema,
            feed_data: schemaData,
            image: {
                path: imageRes?.path,
                mediaType: imageRes?.mediaType,
            },
            reminders: listReminder,
        })
    };

    const validateJson = useCallback((jsonData) => {
        return objSchema
        .validate(jsonData)
        .then(data => {
            return {
                data,
                errors: null
            };
        })
        .catch((e) => {
            let path = e.path;
            if (path !== undefined) {
                path = path.split('.');
            } else {
                path = [];
            }
            if (e.type === 'noUnknown') {
                path.push(e.params.unknown);
            }
            return {
                data: null,
                errors: [{
                    path: path,
                    message: e.message
                }]
            }
        });
    }, []);

    return (
        <div className="display-col">
            <div className={classes.titleHeader}>{`${typeAction === "edit" ? "Edit" : "Create"} > ${
                isCalendar ? "Calendar" : "Collection"
            }`}</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        title={`${
                            typeAction === "edit" ? `${title ?? ""}` : `NEW ${isCalendar ? "CALENDAR" : "COLLECTION"}`
                        }`}
                        onClickClose={() => history.goBack()}
                        onClickJson={() => {
                            setInitialJson(getJsonValue());
                            setAtDefault(false);
                        }}
                        isDefault={atDefault}
                        onClickDefault={() => {
                            if (isEmpty(errorMessSchema)) {
                                setAtDefault(true);
                            }
                        }}
                        type={`${isCalendar ? "calendar" : "collection"}`}
                    />
                    <div className={classes.containerDivider} />
                    {atDefault ? (
                        <div ref={scrollRef} className={clsx(classes.containerForm, appStyles.scrollbar)}>
                            <InputWithTitle
                                title="Title"
                                require
                                data={title}
                                placeHolder={`Name your ${isCalendar ? "calendar" : "collection"}`}
                                onChange={(value) => {
                                    setTitle(value);
                                }}
                            />
                            <SelectWithTitle
                                data={creatorId}
                                list={listPersona.map((item) => ({ title: item.name, value: item.uid }))}
                                title="Calendar Jockey Persona"
                                placeHolder="Choose your creator persona"
                                onItemChange={(value) => setCreatorId(value)}
                            />
                            <SelectWithTitle
                                data={categoryId}
                                list={listCat.map((item) => ({ title: item.name, value: item.uid }))}
                                title="Category"
                                require
                                placeHolder="Select a category"
                                onItemChange={(value) => {
                                    setCategoryId(value);
                                    setListSubCatId([]);
                                    setSubCatShow(listSubCat.filter((el) => el.categoryId === value));
                                }}
                            />
                            <TagWithTitle
                                title="Subcategories"
                                require
                                placeHolder="Add subcategory"
                                data={listSubCatId}
                                list={subCatShow.map((item) => ({ title: item.name, value: item.uid }))}
                                onItemChange={(value) => setListSubCatId(value)}
                            />
                            <SummerNote
                                data={description}
                                title="Description"
                                placeholder={`${isCalendar ? "Calendar" : "Collection"} description`}
                                onChange={(content) => setDescription(content)}
                            />
                            <SelectDate
                                style={{ marginTop: 32 }}
                                initStartDate={startTime}
                                initEndDate={endTime}
                                onStartDateChange={(value) => setStartTime(value)}
                                onEndDateChange={(value) => setEndTime(value)}
                                isoFormat={true}
                            />
                            <TagWithTitle
                                title="Reminders"
                                placeHolder="Add reminder"
                                data={listReminder}
                                list={remindersData}
                                showComment={false}
                                style={{ marginTop: 32 }}
                                onItemChange={(value) => setListReminder(value)}
                            />
                            <ImagePicker
                                data={imageRes}
                                title="Image"
                                placeHolder="Image URL"
                                imageSource={(value) => setImageRes(value)}
                            />
                            <SelectWithTitle
                                data={feedSchema}
                                list={listSchema.map((item) => ({ title: item, value: item }))}
                                showComment={false}
                                title="Metadata"
                                placeHolder="Select a schema"
                                style={{ width: "calc(50% - 10px)" }}
                                onItemChange={(value) => {
                                    setFeedSchema(value);
                                    dispatch(
                                        getSchemaByName({
                                            type: `${isCalendar ? "calendar" : "collection"}`,
                                            name: value,
                                        })
                                    );
                                }}
                            />
                            {!isEmpty(schema) && !isEmpty(schemaValue) && schemaValue.object && (
                                <SchemaObject
                                    onValueChange={(value) => setSchemaData(value)}
                                    schema={schemaValue.object}
                                    initialValue={itemFeed?.feed_data}
                                />
                            )}
                            {!isEmpty(schema) && !isEmpty(schemaValue) && schemaValue.union && (
                                <SchemaUnion
                                    visibleName={schema.name}
                                    onValueChange={(value) => setSchemaData(value)}
                                    schema={schemaValue}
                                    initialValue={itemFeed?.feed_data}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={classes.containerJsonForm}>
                            <div className={classes.containerJsonError}>
                                {!isEmpty(errorMessSchema) && (
                                    <span className={classes.textError}>{errorMessSchema}</span>
                                )}
                            </div>
                            <JsonEditorComponent
                                data={initialJson}
                                validate={validateJson}
                                onChange={setItemFeed}
                                onError={setErrorMessSchema}
                            />
                        </div>
                    )}
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {showError && (
                            <span className={classes.textError}>
                                {isCalendar ? messageErrorCalendar : messageErrorCollection}
                            </span>
                        )}
                    </div>
                    <ButtonBottom
                        showDelete={typeAction === "edit"}
                        onClickDelete={() => {
                            dispatch(
                                openMessageDialog({
                                    open: true,
                                    message: `Are you sure you want to delete this ${
                                        isCalendar ? "calendar" : "collection"
                                    }?`,
                                    title: `DELETE ${isCalendar ? "CALENDAR" : "COLLECTION"}`,
                                })
                            );
                        }}
                        onClickOk={() => {
                            if (isEmpty(errorMessSchema)) {
                                createFeedItem(false);
                            }
                        }}
                        onClickCancel={() => {
                            if (isEmpty(errorMessSchema)) {
                                createFeedItem(true);
                            }
                        }}
                    />
                </div>
                <div className="display-center-col designPreview" style={{ flex: 1, padding: "0 20px" }}>
                    <div style={{ width: 300, height: 500, background: "#282828" }}></div>
                </div>
            </div>
        </div>
    );
};

export default FeedView;
