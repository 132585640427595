import { takeLatest, call, put } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { openMessageDialog } from "../reducers/AppReducer";
import { getStatistics, getStatisticsFail, getStatisticsSuccess } from "../reducers/StatisticsReducer";

function* statisticsWatcher() {
    yield takeLatest(getStatistics, getListStatistic);
}

function* getListStatistic(action) {
    try {
        const response = yield call(handleApi, "dashboard/statistics");
        yield put(getStatisticsSuccess(response.data));
    } catch (error) {
        yield put(getStatisticsFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

export default statisticsWatcher;
