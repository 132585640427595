import { createSlice } from "@reduxjs/toolkit";

const schemaReducer = createSlice({
    name: "schema",
    initialState: {
        isLoading: false,
        data: [],
        schema: undefined,
        isError: false,
        messageError: "",
    },
    reducers: {
        getSchema: (state, action) => {
            state.isLoading = true;
            state.schema = undefined;
        },
        getSchemaSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getSchemaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        getSchemaByName: (state, action) => {
            state.isLoading = true;
            state.schema = undefined;
        },
        getSchemaByNameSuccess: (state, action) => {
            state.isLoading = false;
            state.schema = action.payload;
        },
        getSchemaByNameFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        createSchema: (state, action) => {
            state.isLoading = true;
        },
        createSchemaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        updateSchema: (state, action) => {
            state.isLoading = true;
        },
        updateSchemaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        deleteSchema: (state, action) => {
            state.isLoading = true;
        },
        deleteSchemaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
    },
});

const { reducer, actions } = schemaReducer;
export const {
    getSchema,
    getSchemaSuccess,
    getSchemaFail,
    getSchemaByName,
    getSchemaByNameSuccess,
    getSchemaByNameFail,
    createSchema,
    createSchemaFail,
    updateSchema,
    updateSchemaFail,
    deleteSchema,
    deleteSchemaFail,
} = actions;
export default reducer;
