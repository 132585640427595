import React, { useEffect, useRef, useState } from "react";
import IconDown from "../assets/icons/icon-dropDown.svg";
import { Box, Popover } from "@material-ui/core";
import _, { isEmpty } from "lodash";
import appStyle from "../styles/AppStyle";
import CheckIcon from "@material-ui/icons/Check";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";

import StatusBadge from "./StatusBadge";

const TagWithTitle = (props) => {
    const { data, style, title, showComment, list, onItemChange, placeHolder, disable, require, loading } = props;
    const parentRef = useRef();
    const selectRef = useRef();
    const [anchorEl, setAnchorEl] = useState();
    const appStyles = appStyle();
    const [dataTag, setDataTag] = useState([]);

    useEffect(() => {
        if (!isEmpty(data) && !isEmpty(list)) {
            var temp = [];
            data.map((item) => {
                if (list.some((el) => el.value === item)) {
                    temp.push(list.find((el) => el.value === item));
                }
                return item;
            });
            setDataTag(temp);
        } else {
            setDataTag([]);
        }
    }, [data, list]);

    return (
        <div ref={parentRef} style={{ width: "100%", marginTop: 10, ...style }} className="display-col">
            <div
                style={{
                    fontSize: 12,
                    lineHeight: "14px",
                    color: disable ? "rgba(255, 255, 255, 0.6)" : "white",
                    fontFamily: "SFProTextSemiBold",
                    display: "flex",
                }}
            >
                {title}
                <StatusBadge required={require} disabled={disable} loading={loading} />
            </div>
            <div
                ref={selectRef}
                className="display-center-row"
                style={{
                    marginTop: 4,
                    border: "1px solid #858C94",
                    borderRadius: 8,
                    minHeight: 31,
                    cursor: disable ? "default" : "pointer",
                }}
                onClick={(e) => {
                    if (!(disable || isEmpty(list))) setAnchorEl(selectRef.current);
                }}
            >
                {isEmpty(dataTag) && !isEmpty(placeHolder) ? (
                    <div
                        style={{
                            flex: 1,
                            margin: "3px 14px 4px",
                            fontFamily: "SFProDisplay",
                            fontSize: 14,
                            // lineHeight: "24px",
                            color: "white",
                            opacity: 0.6,
                        }}
                    >
                        {placeHolder}
                    </div>
                ) : (
                    <div className="display-row" style={{ width: "100%", flexWrap: "wrap", padding: "3px 14px 4px" }}>
                        {dataTag.map((item, index) => (
                            <div
                                key={index}
                                className="display-center-row"
                                style={{ background: "#444444", margin: "0 2px", padding: "0 5px", borderRadius: 4 }}
                            >
                                <span
                                    style={{ maxWidth: 200, color: disable ? "rgba(255, 255, 255, 0.6)" : "white" }}
                                    className="text-ellipsis"
                                >
                                    {item?.title ?? ""}
                                </span>
                                {!disable && (
                                    <HighlightOffOutlinedIcon
                                        style={{
                                            width: 14,
                                            height: 14,
                                            marginLeft: 5,
                                            cursor: disable ? "default" : "pointer",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!disable) {
                                                setDataTag(dataTag.filter((el) => el.value !== item.value));
                                                onItemChange(
                                                    dataTag
                                                        .filter((el) => el.value !== item.value)
                                                        .map((el) => el.value)
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {!(disable || isEmpty(list)) && (
                    <img src={IconDown} style={{ margin: "5px 9px 2px 0px" }} width={16} height={16} alt="" />
                )}
            </div>
            <div style={{ marginTop: 2, fontSize: 10, lineHeight: "20px", height: 20, fontFamily: "SourceSansPro" }}>
                {showComment
                    ? "Keep it short and pithy. It’s a quick way to tell consumers about your feed item. Max 255 char."
                    : ""}
            </div>
            <Popover
                open={!_.isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl();
                    onItemChange(dataTag.map((el) => el?.value));
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box
                    boxShadow={3}
                    style={{ width: parentRef?.current?.offsetWidth * 0.95, maxHeight: 200, overflowY: "auto" }}
                    className={appStyles.scrollbar}
                >
                    {list.map((item, index) => (
                        <div
                            key={index}
                            className="display-center-row"
                            onClick={() => {
                                if (!dataTag.some((el) => el.value === item.value)) {
                                    setDataTag(dataTag.concat({ title: item.title, value: item.value }));
                                } else {
                                    setDataTag(dataTag.filter((el) => el.value !== item.value));
                                }
                            }}
                            style={{
                                height: 30,
                                background: "#181818",
                                color: "#ADAFB2",
                                cursor: "pointer",
                            }}
                        >
                            <div style={{ width: 30 }} className="display-center">
                                {dataTag.some((el) => el?.value === item?.value) && (
                                    <CheckIcon style={{ width: 24, heigh: 24, cursor: "pointer" }} />
                                )}
                            </div>
                            <span style={{ flex: 1, paddingRight: 30 }} className="text-ellipsis">
                                {item.title}
                            </span>
                        </div>
                    ))}
                </Box>
            </Popover>
        </div>
    );
};

TagWithTitle.defaultProps = {
    title: "",
    style: {},
    list: [],
    data: [],
    showComment: true,
    onItemChange: () => {},
    disable: false,
};

export default TagWithTitle;
