import { createSlice } from "@reduxjs/toolkit";

const personaReducer = createSlice({
    name: "persona",
    initialState: {
        isLoading: false,
        data: [],
        isError: false,
        messageError: "",
    },
    reducers: {
        getPersonas: (state, action) => {
            state.isLoading = true;
        },
        getPersonasSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getPersonasFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        createPersona: (state, action) => {
            state.isLoading = true;
        },
        createPersonaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        updatePersona: (state, action) => {
            state.isLoading = true;
        },
        updatePersonaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        deletePersona: (state, action) => {
            state.isLoading = true;
        },
        deletePersonaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
    },
});

const { reducer, actions } = personaReducer;
export const {
    getPersonas,
    getPersonasSuccess,
    getPersonasFail,
    createPersona,
    createPersonaFail,
    updatePersona,
    updatePersonaFail,
    deletePersona,
    deletePersonaFail,
} = actions;
export default reducer;
