import { createSlice } from "@reduxjs/toolkit";

const appReducer = createSlice({
    name: "app",
    initialState: {
        token: undefined,
        message: undefined,
        isSuccess: false,
        isError: false,
        errMess: undefined,
        isLoading: false,
        itemFeed: {},
        listFeed: [],
        openConfirmDialog: false,
        onClickDelete: false,
        messageDialog: "",
        titleDialog: "",
        isOpenSidebar: true,
        process: ""
    },
    reducers: {
        saveToken: (state, action) => {
            state.token = action.payload;
        },
        showSuccess: (state, action) => {
            state.isSuccess = action.payload.isSuccess;
            state.message = action.payload.message;
        },
        showError: (state, action) => {
            state.isError = action.payload.isError;
            state.errMess = action.payload.errMess;
        },
        getItemFeedById: (state, action) => {
            state.isLoading = true;
            state.itemFeed = {};
        },
        getItemFeedByIdSuccess: (state, action) => {
            state.isLoading = false;
            state.itemFeed = action.payload;
        },
        getItemFeedByIdFail: (state, action) => {
            state.isLoading = false;
        },
        getFeedsByCreator: (state, action) => {
            state.isLoading = true;
        },
        getFeedsByCreatorSuccess: (state, action) => {
            state.isLoading = false;
            state.listFeed = action.payload;
        },
        getFeedsByCreatorFail: (state, action) => {
            state.isLoading = false;
        },
        openMessageDialog: (state, action) => {
            state.openConfirmDialog = action.payload.open;
            state.messageDialog = action.payload.message;
            state.titleDialog = action.payload.title;
        },
        onClickConfirmDialog: (state, action) => {
            state.onClickDelete = action.payload;
        },
        onChangeOpenSidebar: (state, action) => {
            state.isOpenSidebar = action.payload;
        },
        setHistory: (state, action) => {
            state.process = action.payload.process
        }
    },
});

const { reducer, actions } = appReducer;
export const {
    saveToken,
    getItemFeedById,
    getItemFeedByIdSuccess,
    getItemFeedByIdFail,
    getFeedsByCreator,
    getFeedsByCreatorSuccess,
    getFeedsByCreatorFail,
    openMessageDialog,
    onClickConfirmDialog,
    onChangeOpenSidebar,
    setHistory
} = actions;
export default reducer;
