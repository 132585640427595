import { createSlice } from "@reduxjs/toolkit";

const tagReducer = createSlice({
    name: "tag",
    initialState: {
        isLoading: false,
        data: [],
        isError: false,
        messageError: "",
    },
    reducers: {
        getTags: (state, action) => {
            state.isLoading = true;
        },
        getTagsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getTagsFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        createTag: (state, action) => {
            state.isLoading = true;
        },
        createTagFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        updateTag: (state, action) => {
            state.isLoading = true;
        },
        updateTagFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        deleteTag: (state, action) => {
            state.isLoading = true;
        },
        deleteTagFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
    },
});

const { reducer, actions } = tagReducer;
export const {
    getTags,
    getTagsSuccess,
    getTagsFail,
    createTag,
    createTagFail,
    updateTag,
    updateTagFail,
    deleteTag,
    deleteTagFail,
} = actions;
export default reducer;
