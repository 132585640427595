import React, { useEffect, useRef, useState, useCallback } from "react";
import createFormStyle from "../styles/CreateFormStyle";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import InputWithTitle from "../components/InputWithTitle";
import SummerNote from "../components/SummerNote";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ButtonBottom from "../components/ButtonBottom";
import SelectWithTitle from "../components/SelectWithTitle";
import { getCategory } from "../redux/reducers/CategoryReducer";
import SectionHeader from "../components/SectionHeader";
import ImagePicker from "../components/ImagePicker";
import JsonComponent from "../components/JsonComponent";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { createItemSubCategory, deleteItemSubCategory, updateItemSubCategory } from "../redux/actions";
import { getSubCategory } from "../redux/reducers/SubCategoryReducer";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import SearchSelectTitle from "../components/SearchSelectTitle";

function CreateSubCategoryView() {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const history = useHistory();
    const { listCat, isError, messageError, listSubCat, onClickDelete } = useSelector((state) => ({
        listCat: state.category.data,
        isError: state.subCategory.isError,
        messageError: state.subCategory.messageError,
        listSubCat: state.subCategory.data,
        onClickDelete: state.app.onClickDelete,
    }));
    const dispatch = useDispatch();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [catId, setCatId] = useState(null);
    const [id, setId] = useState(null);
    const [imageRes, setImageRes] = useState(null);
    const [iconRes, setIconRes] = useState(null);
    const [itemSubCat, setItemSubCat] = useState();
    const [actionType, setActionType] = useState("create");
    const [atDefault, setAtDefault] = useState(true);
    const [errorMessSchema, setErrorMessSchema] = useState("");
    const schemaRef = useRef();
    const [missingId, setMissingId] = useState(false);

    const subCatSchema = Yup.object()
        .shape({
            uid: Yup.string().nullable(),
            name: Yup.string().nullable(),
            longFormText: Yup.string().nullable(),
            icon: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            image: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
        })
        .noUnknown()
        .strict(true);

    const editSubCatSchema = Yup.object()
        .shape({
            name: Yup.string().nullable(),
            longFormText: Yup.string().nullable(),
            icon: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            image: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
        })
        .noUnknown()
        .strict(true);

    useEffect(() => {
        dispatch(getCategory());
    }, []);

    useEffect(() => {
        setTitle(itemSubCat?.name ?? null);
        setId(itemSubCat?.uid ?? null);
        setCatId(itemSubCat?.categoryId ?? null);
        setDescription(itemSubCat?.longFormText ?? null);
        setImageRes(itemSubCat?.image ?? null);
        setIconRes(itemSubCat?.icon ?? null);
    }, [itemSubCat]);

    function createSubCat() {
        var data = { name: title, longFormText: description, icon: iconRes, image: imageRes };

        if (actionType === "create") {
            if (id === null) {
                setMissingId(true);
            } else {
                dispatch(
                    createItemSubCategory({
                        catId,
                        id,
                        data,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.goBack();
                            }
                        },
                    })
                );
            }
        } else {
            dispatch(
                updateItemSubCategory({
                    catId,
                    id,
                    data,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
        }
    }

    useEffect(() => {
        if (onClickDelete) {
            dispatch(
                deleteItemSubCategory({
                    catId: itemSubCat.categoryId,
                    id: itemSubCat.uid,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    const handleSchema = useCallback(
        (itemSubCat) => {
            var tempSchema = actionType === "create" ? subCatSchema : editSubCatSchema;
            tempSchema
                .validate(schemaRef.current)
                .then(function (value) {
                    // console.log(value);
                    if (!isEmpty(value)) {
                        setErrorMessSchema();
                        setItemSubCat({ ...itemSubCat, ...value });
                    }
                })
                .catch((e) => {
                    // console.log(e);
                    setAtDefault(false);
                    setErrorMessSchema(e.message);
                });
        },
        [schemaRef.current]
    );

    return (
        <div className="display-col">
            <div className={classes.titleHeader}>{`${actionType === "create" ? "Create" : "Edit"} > Subcategory`}</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        title={`${actionType === "create" ? "NEW SUBCATEGORY" : "EDIT SUBCATEGORY"}`}
                        txtAction={`${
                            actionType === "create" ? "Edit an existing Subcategory" : "Create New Subcategory"
                        }`}
                        onClickClose={() => history.goBack()}
                        onClickJson={() => setAtDefault(false)}
                        showTextEdit={true}
                        onClickEdit={() => {
                            if (actionType === "create") {
                                setActionType("edit");
                                if (isEmpty(listSubCat)) {
                                    dispatch(getSubCategory());
                                }
                            } else {
                                setItemSubCat();
                                setActionType("create");
                            }
                        }}
                        isDefault={atDefault}
                        onClickDefault={() => {
                            if (isEmpty(errorMessSchema)) {
                                schemaRef.current = undefined;
                                setAtDefault(true);
                            }
                        }}
                        type="subcategory"
                    />
                    <div className={classes.containerDivider} />
                    {atDefault ? (
                        <div className={clsx(classes.containerForm, appStyles.scrollbar)}>
                            {actionType === "create" && (
                                <div>
                                    <InputWithTitle
                                        data={id}
                                        title="Unique ID"
                                        require
                                        placeHolder="Custom Subcategory ID"
                                        showComment={false}
                                        onChange={(value) => {
                                            setId(value);
                                        }}
                                    />
                                    <InputWithTitle
                                        data={title}
                                        title="Name"
                                        require
                                        placeHolder="Subcategory name"
                                        onChange={(value) => {
                                            setTitle(value);
                                        }}
                                    />
                                    <SelectWithTitle
                                        data={catId}
                                        list={listCat.map((item) => ({ title: item.name, value: item.uid }))}
                                        title="Select Category"
                                        require
                                        placeHolder="Select a category"
                                        onItemChange={(value) => {
                                            setCatId(value);
                                        }}
                                    />
                                </div>
                            )}

                            {actionType === "edit" && (
                                <SearchSelectTitle
                                    data={id}
                                    list={listSubCat
                                        // .filter((el) => el.categoryId === catId)
                                        .map((item) => ({ title: item.name, value: item.uid }))}
                                    title="Name"
                                    require
                                    placeHolder="Select a Subcategory"
                                    onItemChange={(value) => {
                                        setItemSubCat(listSubCat.find((el) => el.uid === value));
                                    }}
                                />
                            )}

                            <ImagePicker
                                data={iconRes}
                                title="Icon"
                                placeHolder="Icon URL"
                                imageSource={(value) => setIconRes(value)}
                            />
                            <ImagePicker
                                data={imageRes}
                                title="Image"
                                placeHolder="Image URL"
                                imageSource={(value) => setImageRes(value)}
                            />
                            <SummerNote
                                data={description}
                                title="Description"
                                placeholder="Subcategory description"
                                onChange={(content) => setDescription(content)}
                            />
                        </div>
                    ) : (
                        <div className={classes.containerJsonForm}>
                            <div className={classes.containerJsonError}>
                                {!isEmpty(errorMessSchema) && (
                                    <span className={classes.textError}>{errorMessSchema}</span>
                                )}
                            </div>
                            <JsonComponent
                                data={subCatSchema.cast({
                                    uid: actionType === "create" ? id : undefined,
                                    name: title,
                                    longFormText: description,
                                    icon: {
                                        path: iconRes?.path ?? null,
                                        mediaType: iconRes?.mediaType ?? null,
                                    },
                                    image: {
                                        path: imageRes?.path ?? null,
                                        mediaType: imageRes?.mediaType ?? null,
                                    },
                                })}
                                onChange={(value) => {
                                    schemaRef.current = value;
                                    handleSchema(itemSubCat);
                                    // setItemSubCat({ ...value, uid: itemSubCat.uid });
                                }}
                            />
                        </div>
                    )}
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {isError && <span className={classes.textError}>{messageError}</span>}
                    </div>
                    <div className={classes.containerError}>
                        {missingId && <span className={classes.textError}>Missing ID for Subcategory</span>}
                    </div>
                    <ButtonBottom
                        showDelete={actionType === "edit" && !isEmpty(itemSubCat)}
                        onClickDelete={() =>
                            dispatch(
                                openMessageDialog({
                                    open: true,
                                    message: "Are you sure you want to delete this subcategory?",
                                    title: "DELETE SUBCATEGORY",
                                })
                            )
                        }
                        onClickCancel={() => setActionType("create")}
                        txtCancel="Duplicate"
                        showDraft={actionType === "edit" && !isEmpty(itemSubCat)}
                        txtOk="SAVE"
                        onClickOk={() => {
                            if (isEmpty(errorMessSchema)) {
                                createSubCat();
                            }
                        }}
                    />
                </div>
                <div className="display-center-col designPreview" style={{ flex: 1, padding: "0 20px" }}>
                    <div style={{ width: 300, height: 500, background: "#282828" }}></div>
                </div>
            </div>
        </div>
    );
}

export default CreateSubCategoryView;
