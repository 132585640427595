import config from './config.json';

const env = process.env.REACT_APP_STAGE || 'dev';

console.log('env:', env);

const actualConfig = config[env];

export default {
  rest_uri: actualConfig.rest_uri,
  rest_api_key: actualConfig.rest_api_key,
  auth0_domain: actualConfig.auth0_domain,
  auth0_clientId: actualConfig.auth0_clientId
}
