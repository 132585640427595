export const getListCat = ({ count, onComplete }) => {
    return {
        type: "category/getCategory",
        payload: { count },
        onComplete,
    };
};

export const getListFeedByPreFilter = ({ status, type, limit, pageToken, isUpcoming, onComplete }) => {
    return {
        type: "preFilter/getFeedsByPreFilter",
        payload: { status, type, limit, pageToken, isUpcoming },
        onComplete,
    };
};

export const getListEventByTag = ({tag_id, limit, sortBy, query, page_token, onComplete}) => {
    return {
        type: "tagEvent/getEventsByTag",
        payload: { tag_id, limit, sortBy, query, page_token},
        onComplete,
    };
};

export const createItemFeedCalendar = ({ data, onComplete }) => {
    return {
        type: "calendars/createCalendar",
        payload: { data },
        onComplete,
    };
};

export const updateItemFeedCalendar = ({ uid, data, isShowErrorDialog, onComplete }) => {
    return {
        type: "calendars/updateCalendar",
        payload: { uid, data, isShowErrorDialog },
        onComplete,
    };
};

export const createItemFeedCollection = ({ data, onComplete }) => {
    return {
        type: "collections/createCollection",
        payload: { data },
        onComplete,
    };
};

export const updateItemFeedCollection = ({ uid, data, isShowErrorDialog, onComplete }) => {
    return {
        type: "collections/updateCollection",
        payload: { uid, data, isShowErrorDialog },
        onComplete,
    };
};

export const deleteItemFeedCollection = ({ uid, isShowErrorDialog, onComplete }) => {
    return {
        type: "collections/deleteCollection",
        payload: { uid, isShowErrorDialog },
        onComplete,
    };
};

export const createItemFeedEvent = ({ payload, onComplete }) => {
    return {
        type: "events/createEvent",
        payload,
        onComplete,
    };
};

export const updateItemFeedEvent = ({ uid, data, isShowErrorDialog, onComplete }) => {
    return {
        type: "events/updateEvent",
        payload: { uid, data, isShowErrorDialog },
        onComplete,
    };
};

export const deleteItemFeedEvent = ({ uid, isShowErrorDialog, onComplete }) => {
    return {
        type: "events/deleteEvent",
        payload: { uid, isShowErrorDialog },
        onComplete,
    };
};

export const createItemEventUnassign = ({ data, onComplete }) => {
    return {
        type: "events/createEventUnassign",
        payload: { data },
        onComplete,
    };
};

export const updateItemEventUnassign = ({ uid, data, isShowErrorDialog, onComplete }) => {
    return {
        type: "events/updateEventUnassign",
        payload: { uid, data, isShowErrorDialog },
        onComplete,
    };
};

export const deleteItemEventUnassign = ({ uid, isShowErrorDialog, onComplete }) => {
    return {
        type: "events/deleteEventUnassign",
        payload: { uid, isShowErrorDialog },
        onComplete,
    };
};

export const getFeedsByPreFilter = ({ status, type, limit, sortBy, pageToken, onComplete, query}) => {
    return {
        type: "preFilter/getFeedsByPreFilter",
        payload: { status, type, limit, sortBy, pageToken, query },
        onComplete,
    };
};



export const getEventsById = ({ id, limit, sortBy, pageToken,query, onComplete }) => {
    return {
        type: "events/getEventsById",
        payload: { id, limit, sortBy, pageToken, query },
        onComplete,
    };
};

export const getListEventUnassign = ({ status, limit, sortBy, pageToken, query, onComplete }) => {
    return {
        type: "events/getEventsUnassign",
        payload: { status, limit, sortBy, pageToken, query },
        onComplete,
    };
};

export const getItemEventById = ({ id, eventId, onComplete }) => {
    return {
        type: "events/getEventById",
        payload: { id, eventId },
        onComplete,
    };
};

export const getItemCollectionById = ({ payload, onComplete }) => {
    return {
        type: "collections/getCollectionById",
        payload,
        onComplete,
    };
};

export const deleteItemCalendar = ({ uid, isShowErrorDialog, onComplete }) => {
    return {
        type: "calendars/deleteCalendar",
        payload: { uid, isShowErrorDialog },
        onComplete,
    };
};

export const getItemEventUnassignById = ({ payload, onComplete }) => {
    return {
        type: "events/getEventUnassignById",
        payload,
        onComplete,
    };
};

export const getItemCalendarById = ({ payload, onComplete }) => {
    return {
        type: "calendars/getCalendarById",
        payload,
        onComplete,
    };
};

export const createItemTag = ({ id, data, onComplete }) => {
    return {
        type: "tag/createTag",
        payload: { id, data },
        onComplete,
    };
};

export const createItemYoutube = ({ type, id, settings, onComplete }) => {
    return {
        type: "youtube/createYoutube",
        payload: { type, id, settings },
        onComplete,
    };
};

export const createItemStanza = ({id, dataST, onComplete }) => {
    return {
        type: "stanza/createStanza",
        payload: {id, dataST},
        onComplete,
    };
};

export const resetItemStanza = () => {
    return {
        type: "stanza/resetStanza",
    };
};

export const createItemPersona = ({ id, data, onComplete }) => {
    return {
        type: "persona/createPersona",
        payload: { id, data },
        onComplete,
    };
};

export const createItemSchema = ({ type, name, data, onComplete }) => {
    return {
        type: "schema/createSchema",
        payload: { type, name, data },
        onComplete,
    };
};

export const createItemSubCategory = ({ catId, id, data, onComplete }) => {
    return {
        type: "subCategory/createSubCategory",
        payload: { catId, id, data },
        onComplete,
    };
};

export const deleteItemSchema = ({ type, name, onComplete }) => {
    return {
        type: "schema/deleteSchema",
        payload: { type, name },
        onComplete,
    };
};

export const createItemCategory = ({ id, data, onComplete }) => {
    return {
        type: "category/createCategory",
        payload: { id, data },
        onComplete,
    };
};

export const updateItemPersona = ({ id, data, onComplete }) => {
    return {
        type: "persona/updatePersona",
        payload: { id, data },
        onComplete,
    };
};

export const updateItemTag = ({ id, db, data, onComplete }) => {
    return {
        type: "tag/updateTag",
        payload: { id, db, data },
        onComplete,
    };
};

export const updateItemSubCategory = ({ catId, id, data, onComplete }) => {
    return {
        type: "subCategory/updateSubCategory",
        payload: { catId, id, data },
        onComplete,
    };
};

export const updateItemCategory = ({ id, data, onComplete }) => {
    return {
        type: "category/updateCategory",
        payload: { id, data },
        onComplete,
    };
};

export const deleteItemTag = ({ payload, onComplete }) => {
    return {
        type: "tag/deleteTag",
        payload,
        onComplete,
    };
};

export const deleteItemPersona = ({ payload, onComplete }) => {
    return {
        type: "persona/deletePersona",
        payload,
        onComplete,
    };
};

export const deleteItemSubCategory = ({ catId, id, onComplete }) => {
    return {
        type: "subCategory/deleteSubCategory",
        payload: { catId, id },
        onComplete,
    };
};

export const deleteItemCategory = ({ payload, onComplete }) => {
    return {
        type: "category/deleteCategory",
        payload,
        onComplete,
    };
};

export const getFeedById = ({ payload, onComplete }) => {
    return {
        type: "app/getItemFeedById",
        payload,
        onComplete,
    };
};
