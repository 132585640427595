import React, { useState, useEffect } from "react";
import headerPreFilterStyle from "../styles/HeaderPreFilterStyle";
import clsx from "clsx";
import { getParameterByName } from "../utils/Utils";

function TabsComponent(props) {
    const { onChangeTab, status } = props;
    const classes = headerPreFilterStyle();
    const [isActive, setActive] = useState();

    useEffect(() => {
        switch (getParameterByName("type")) {
            case "calendars":
                setActive(2);
                break;
            case "collections":
                setActive(3);
                break;
            case "single-events":
                setActive(4);
                break;
            case "tags":
                setActive(5);
                break;
            default:
                setActive(1);
                break;
        }
    }, [status]);

    return (
        <div style={{ marginTop: 11, height: 30 }} className={clsx("display-row", classes.StickyFilterMenu)}>
            <div className={classes.containerTab1}>
                <div
                    style={{ cursor: "pointer", width: 25 }}
                    onClick={() => {
                        setActive(1);
                        onChangeTab("all");
                    }}
                >
                    <div className={classes.txtTab} style={{ color: isActive === 1 ? "white" : "#ADAFB2" }}>
                        ALL
                    </div>
                    <div
                        style={{
                            height: 2,
                            background: isActive === 1 ? "#8CB2F8" : "transparent",
                        }}
                    />
                </div>
            </div>
            <div className={classes.containerTab2}>
                <div
                    style={{ cursor: "pointer", width: 75 }}
                    onClick={() => {
                        setActive(2);
                        onChangeTab("calendars");
                    }}
                >
                    <div className={classes.txtTab} style={{ color: isActive === 2 ? "white" : "#ADAFB2" }}>
                        CALENDAR
                    </div>
                    <div
                        style={{
                            height: 2,
                            background: isActive === 2 ? "#8CB2F8" : "transparent",
                        }}
                    />
                </div>
            </div>
            <div className={classes.containerTab3}>
                <div
                    style={{ cursor: "pointer", width: 90 }}
                    onClick={() => {
                        setActive(3);
                        onChangeTab("collections");
                    }}
                >
                    <div className={classes.txtTab} style={{ color: isActive === 3 ? "white" : "#ADAFB2" }}>
                        COLLECTION
                    </div>
                    <div
                        style={{
                            height: 2,
                            width: 90,
                            background: isActive === 3 ? "#8CB2F8" : "transparent",
                        }}
                    />
                </div>
            </div>
            <div className={classes.containerTab4}>
                <div
                    style={{ cursor: "pointer", width: 44}}
                    onClick={() => {
                        setActive(4);
                        onChangeTab("single-events");
                    }}
                >
                    <div className={classes.txtTab} style={{ color: isActive === 4 ? "white" : "#ADAFB2" }}>
                        EVENT
                    </div>
                    <div
                        style={{
                            height: 2,
                            background: isActive === 4 ? "#8CB2F8" : "transparent",
                        }}
                    />
                </div>
            </div>
            <div className={classes.containerTab5}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setActive(5);
                        onChangeTab("tags", 'pseudo');
                    }}
                >
                    <div className={classes.txtTab} style={{ color: isActive === 5 ? "white" : "#ADAFB2" }}>
                        EVENTS by TAG
                    </div>
                    <div
                        style={{
                            height: 2,
                            background: isActive === 5 ? "#8CB2F8" : "transparent",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default TabsComponent;
