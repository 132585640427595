import { takeLatest, call, put, select } from "redux-saga/effects";
import { sortFeedByCreator } from '../../utils/Utils'
import {
    getFeedsByCreator,
    getFeedsByCreatorFail,
    getFeedsByCreatorSuccess,
    getItemFeedById,
    getItemFeedByIdFail,
    getItemFeedByIdSuccess,
    openMessageDialog,
} from "../reducers/AppReducer";
import handleApi from "../../utils/HandleApi";
import { withCallback } from "redux-saga-callback";

function* appSagaWatcher() {
    yield takeLatest(getItemFeedById, withCallback(getItemFeed));
    yield takeLatest(getFeedsByCreator, getListFeedByCreator);
}

function* getItemFeed(action) {
    try {
        // console.log('App saga', action.payload);
        const response = yield call(handleApi, `dashboard/collections/${action.payload}`);
        yield put(getItemFeedByIdSuccess(response.data));
        return response.data;
    } catch (error) {
        yield put(getItemFeedByIdFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return {};
    }
}

function* getListFeedByCreator(action) {
    try {
        const response = yield call(handleApi, `dashboard/collections`);
        sortFeedByCreator(response.data)
        yield put(getFeedsByCreatorSuccess(response.data));
        
    } catch (error) {
        yield put(getFeedsByCreatorFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

export default appSagaWatcher;
