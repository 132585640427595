import React, { useEffect, useState } from "react";
import "../globals";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import "bootstrap/js/modal";
import "bootstrap/js/dropdown";
import "bootstrap/js/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/summer_note_overrides.css";
import dataSource from "../assets/dataSource";
import { isEmpty } from "lodash";

const SummerNote = (props) => {
    const { style, title, data, onChange, placeholder } = props;
    const emojis = Object.keys(dataSource);
    const emojiUrls = dataSource;
    const [value, setValue] = useState();

    useEffect(() => {
        setValue(isEmpty(data) ? "" : data);
    }, [data]);

    return (
        <div style={{ width: "100%", marginTop: 10, ...style }} className="display-col">
            <div style={{ fontSize: 12, lineHeight: "14px", color: "white", fontFamily: "SFProTextSemiBold" }}>{title}</div>
            <div
                className="display-center-row"
                style={{ marginTop: 4, border: "1px solid #858C94", borderRadius: 8, padding: 10 }}
            >
                <ReactSummernote
                    className="container-summernote"
                    value={value}
                    options={{
                        minHeight: 61,
                        dialogsInBody: false,
                        placeholder: placeholder,
                        toolbar: [
                            ["style", ["style"]],
                            ["font", ["bold", "underline", "clear"]],
                            ["color"],
                            // ["fontname", ["fontname"]],
                            ["para", ["ul", "ol", "paragraph"]],
                            ["table", ["table"]],
                            ["insert", ["link", "picture", "video"]],
                            // ["view", ["fullscreen", "codeview"]],
                        ],
                        hint: {
                            match: /:([\-+\w]+)$/,
                            search: function (keyword, callback) {
                                callback(
                                    window.$.grep(emojis, function (item) {
                                        return item.indexOf(keyword) === 0;
                                    })
                                );
                            },
                            template: function (item) {
                                var content = emojiUrls[item];
                                return '<img src="' + content + '" width="20" /> :' + item + ":";
                            },
                            content: function (item) {
                                var url = emojiUrls[item];
                                if (url) {
                                    return window.$("<img />").attr("src", url).css("width", 20)[0];
                                }
                                return "";
                            },
                        },
                    }}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

SummerNote.defaultProps = {
    title: "",
    style: {},
    // data: "",
    // placeholder: "",
    onChange: () => {},
};

export default SummerNote;
