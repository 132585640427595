import { all } from "redux-saga/effects";
import appSagaWatcher from "./AppSaga";
import calendarSagaWatcher from "./CalendarSaga";
import categorySagaWatcher from "./CategorySaga";
import collectionSagaWatcher from "./CollectionSaga";
import eventSagaWatcher from "./EventSaga";
import schemaSagaWatcher from "./SchemaSaga";
import statisticsWatcher from "./StatisticsSaga";
import subCategorySagaWatcher from "./SubCategorySaga";
import tagSagaWatcher from "./TagSaga";
import preFilterWatcher from "./PreFilterSaga";
import personaWatcher from "./PersonaSaga";
import youTubeSagaWatcher from "./YouTubeSaga";
import stanzaSagaWatcher from "./StanzaSaga";
import eventTagWatcher from "./TagEventSaga";

export default function* IndexSaga() {
    yield all([
        appSagaWatcher(),
        collectionSagaWatcher(),
        categorySagaWatcher(),
        subCategorySagaWatcher(),
        schemaSagaWatcher(),
        calendarSagaWatcher(),
        tagSagaWatcher(),
        eventSagaWatcher(),
        statisticsWatcher(),
        preFilterWatcher(),
        personaWatcher(),
        youTubeSagaWatcher(),
        eventTagWatcher(),
        stanzaSagaWatcher(),
    ]);
}
