import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import IconLeftActive from "../assets/icons/arrow-left-active.svg";
import IconRightActive from "../assets/icons/arrow-right-active.svg";
import IconLeft from "../assets/icons/arrow-left.svg";
import IconRight from "../assets/icons/arrow-right.svg";
import UserIcon from "../assets/icons/user_icon.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { Popover } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { setHistory } from "../redux/reducers/AppReducer";

const Header = (props) => {
    const { user, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = useState();
    const history = useHistory();
    const location = useLocation();
    const previousKeyRef = useRef();
    const originLocationRef = useRef();
    const lastLocationRef = useRef();
    const listKeyRef = useRef([]);
    const dispatch = useDispatch();

    history.listen((location, action) => {
        const { key } = location;

        // If there is no key, it was a goBack.
        if (key === undefined) {
            dispatch(setHistory({process: 'goBack'}))
            return;
        }

        // If it's an entirely new key, it was a goForward.
        // If it was neither of the above, you can compare the index
        // of `key` to the previous key in your keys array.
        if (!listKeyRef.current.includes(key)) {
            listKeyRef.current = listKeyRef.current.concat(key);
            dispatch(setHistory({process: 'goForward'}))
            lastLocationRef.current = location;
        } else if (listKeyRef.current.indexOf(key) < listKeyRef.current.indexOf(previousKeyRef.current)) {
            dispatch(setHistory({process: 'goBack'}))
        }

        previousKeyRef.current = key;
    });

    useEffect(() => {
        originLocationRef.current = location;
        lastLocationRef.current = location;
    }, []);

    return (
        <div className={clsx("container-header", "display-row")}>
            <div
                style={{
                    opacity: "0.7",
                    letterSpacing: "0.7px",
                    fontSize: 24,
                    lineHeight: "29px",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontFamily: "NewYorkExtraLarge",
                    margin: "13px 30px 0 12px",
                }}
            >
                Timeless
            </div>
            <div
                style={{ justifyContent: "space-between", width: 45.7, marginTop: 20, height: 14.59 }}
                className="display-center-row"
            >
                <div
                    className={clsx("display-center", "container-icon-navigation")}
                    onClick={() => {
                        if (location.key !== originLocationRef.current.key) {
                            history.goBack();
                        }
                    }}
                >
                    <img
                        className="icon-navigation"
                        src={location.key === originLocationRef.current?.key ? IconLeft : IconLeftActive}
                        alt=""
                    />
                </div>
                <div
                    className={clsx("display-center", "container-icon-navigation")}
                    onClick={() => {
                        if (location.key !== lastLocationRef.current?.key) {
                            history.goForward();
                        }
                    }}
                >
                    <img
                        className="icon-navigation"
                        src={location.key === lastLocationRef.current?.key ? IconRight : IconRightActive}
                        alt=""
                    />
                </div>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                <div
                    style={{ width: 180, marginTop: 11, cursor: "pointer", justifyContent: "center" }}
                    className="display-row"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    <img
                        style={{ width: 28.5, height: 28.5, marginRight: 4.5, borderRadius: "50%" }}
                        src={isEmpty(user.picture) ? UserIcon : user.picture}
                        alt=""
                    />
                    <div style={{ marginTop: 5, letterSpacing: -0.1, color: "#ADAFB2", lineHeight: "17px" }}>
                        {user.name}
                    </div>
                </div>
            </div>
            <Popover
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <div
                    style={{
                        fontSize: 15,
                        lineHeight: "19px",
                        cursor: "pointer",
                        fontWeight: 600,
                        color: "white",
                        fontFamily: "Mulish",
                        margin: "23px 47px 22px 30px",
                    }}
                    onClick={() => {
                        logout({ returnTo: window.location.origin });
                    }}
                >
                    Log out
                </div>
            </Popover>
        </div>
    );
};

export default Header;
