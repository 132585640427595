import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "../src/assets/fonts/SFProDisplay-Regular.ttf";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import config from "./config";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

ReactDOM.render(
    <Provider store={store}>
        <Auth0Provider
            domain={config.auth0_domain}
            clientId={config.auth0_clientId}
            redirectUri={window.location.origin}
        >
            <DndProvider backend={HTML5Backend}>
                <Router>
                    <Switch>
                        <Route path="/" component={App} />
                    </Switch>
                </Router>
            </DndProvider>
        </Auth0Provider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
