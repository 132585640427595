import store from "../redux/store";
import _ from "lodash";
import config from "../config";

const handleApi = (endpoint, method, requestData) => {
    const url = `${config.rest_uri}${endpoint}`;

    const headers = {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-Api-Key": config.rest_api_key,
    };
    const request = {
        method: _.isEmpty(method) ? "GET" : method,
        headers: headers,
        body: _.isEmpty(requestData) ? undefined : JSON.stringify(requestData),
    };

    return fetch(url, request).then((response) => {
        if (response.status === 401) {
            throw new Error("Unauthorized");
            //store.dispatch(actionShowError)
        }
        if (response.status === 500) {
            throw new Error("Critical");
        }
        if (response.status === 400) {
            return response.json().then(body => {
                throw body.error;
            })
        }
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw new Error(`Unknown Error, StatusCode:${response.status}`);
    });
};
export default handleApi;
