import React, { useEffect, useState, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import ScreenLoading from "react-fullpage-custom-loader";
import Logo from "../assets/icons/logo.svg";
import CarouselComponent from "../components/CarouselComponent";
import appStyle from "../styles/AppStyle";
import clsx from "clsx";
import { getAllFeedByPreFilter } from "../redux/reducers/PreFilterReducer";
import { isEmpty } from "lodash";
import { getListFeedByPreFilter } from "../redux/actions";
import { tr } from "date-fns/locale";
import HeaderHomeSticky from "../components/HeaderHomeSticky";
import HeaderPreFilterSticky from "../components/HeaderPreFilterSticky";

const HomeView = (props) => {
    const appStyles = appStyle();
    const dispatch = useDispatch();
    const {responsive, setResponsive} = props.carouselInfo
    const { 
        calendar,
        collection, 
        event, 
        isOpenSidebar, 
        isLoadingCategory, 
        isLoadingStatistic,  
        isLoadingPrefilter, 
        isLoadingCollection,
        isLoadingCalendar } = useSelector((state) => ({
        calendar: state.preFilter.calendar,
        collection: state.preFilter.collection,
        event: state.preFilter.event,
        isOpenSidebar: state.app.isOpenSidebar,
        isLoadingCategory: state.category.isLoading,
        isLoadingStatistic: state.statistics.isLoading,
        isLoadingPrefilter: state.preFilter.isLoading,
        isLoadingCollection: state.collection.isLoading,
        isLoadingCalendar: state.calendar.isLoading
    }));

    const isLoading = isLoadingCategory || isLoadingStatistic || isLoadingPrefilter || isLoadingCollection || isLoadingCalendar ;
    const [hello, setHello] = useState("");
    var today = new Date();
    var curHr = today.getHours();
    const [calendarShow, setCalendarShow] = useState();
    const [collectionShow, setCollectionShow] = useState();
    const [eventShow, setEventShow] = useState();
    const [isLoadCollection, setLoadingCollection] = useState(false);
    const [isLoadCalendar, setLoadingCalendar] = useState(false);
    const [isLoadEvent, setLoadingEvent] = useState(false);
    const [openSidebar, setOpenSidebar] = useState();
    const carouselRef = useRef(null);

    

    useEffect(() => {
        // Morning - 4 am - 11:59 am
        // Afternoon - noon - 5 pm
        // Evening - 5:01 pm - 9 pm
        // Night - 9:01 pm - 4 am
        if (curHr > 4 && curHr < 12) {
            setHello("Good morning");
        } else if (curHr >= 12 && curHr < 17) {
            setHello("Good afternoon");
        } else if (curHr >= 17 && curHr < 21) {
            setHello("Good evening");
        } else {
            setHello("Good night");
        }
    }, [curHr]);

    useEffect(() => {
        dispatch(getAllFeedByPreFilter("live"));
    }, []);

    useEffect(() => {
        setCalendarShow(calendar);
        setCollectionShow(collection);
        setEventShow(event);
    }, [calendar, collection, event]);

    useEffect(() => {
        setOpenSidebar(isOpenSidebar);
    }, [isOpenSidebar]);

    return (
        <div>
            {
                isLoading ? (
                    <ScreenLoading loaderType={"line-scale"} sentences={[]} />
                ) : (
                    <>
                        <div className="containerHomeHeader">
                            <div className="display-row" style={{ height: 100, alignItems: "center" }}>
                                <div
                                    className={clsx(
                                        "txtAnimation txt-hello",
                                        openSidebar ? "txtsz55" : false,
                                        appStyles.textEllipsis
                                    )}
                                    style={{ fontWeight: "bold", letterSpacing: "0.7px", color: "white", flex: 1 }}
                                >
                                    {hello}
                                </div>
                                <img src={Logo} alt="" style={{ width: 70, height: 70 }} />
                            </div>
                        </div>

                        {/* </div> */}
                        <HeaderHomeSticky title={hello} logo={Logo} />
                        <div
                            className={clsx("display-col", appStyles.scrollbar)}
                            style={{
                                padding: "0px 20px 0px 20px",
                                // height: "100%",
                                // overflowY: "auto",
                                background: "#181818",
                            }}
                        >
                            {/* <div className="header-sticky-top" style={{borderBottom: "1px solid #3b3b3c"}}> */}

                            <CarouselComponent
                                responsive={responsive}
                                setResponsive={setResponsive}
                                style={{ marginTop: 20 }}
                                title="Calendar"
                                data={calendarShow?.items}
                                total={calendarShow?.count}
                                isLoading={isLoadCalendar}
                                onNextPage={() => {
                                    setLoadingCalendar(true);
                                    dispatch(
                                        getListFeedByPreFilter({
                                            status: "live",
                                            type: "calendars",
                                            limit: 20,
                                            pageToken: calendarShow.nextPageToken,
                                            isUpcoming: true,
                                            onComplete: ({ error, cancelled, data }) => {
                                                if (error || cancelled) return;
                                                setLoadingCalendar(false);
                                                setCalendarShow({ ...data, items: calendarShow.items.concat(data.items) });
                                            },
                                        })
                                    );
                                }}
                            />
                            <CarouselComponent
                                responsive={responsive}
                                setResponsive={setResponsive}
                                style={{ marginTop: 20 }}
                                title="Collection"
                                data={collectionShow?.items}
                                total={collectionShow?.count}
                                isLoading={isLoadCollection}
                                onNextPage={() => {
                                    setLoadingCollection(true);
                                    dispatch(
                                        getListFeedByPreFilter({
                                            status: "live",
                                            type: "collections",
                                            limit: 20,
                                            pageToken: collectionShow.nextPageToken,
                                            isUpcoming: true,
                                            onComplete: ({ error, cancelled, data }) => {
                                                if (error || cancelled) return;
                                                setLoadingCollection(false);
                                                setCollectionShow({ ...data, items: collectionShow.items.concat(data.items) });
                                            },
                                        })
                                    );
                                }}
                            />
                            <CarouselComponent
                                responsive={responsive}
                                setResponsive={setResponsive}
                                style={{ marginTop: 20 }}
                                title="Event"
                                data={eventShow?.items}
                                total={eventShow?.count}
                                isLoading={isLoadEvent}
                                onNextPage={() => {
                                    setLoadingEvent(true);
                                    dispatch(
                                        getListFeedByPreFilter({
                                            status: "live",
                                            type: "single-events",
                                            limit: 20,
                                            pageToken: eventShow.nextPageToken,
                                            isUpcoming: true,
                                            onComplete: ({ error, cancelled, data }) => {
                                                if (error || cancelled) return;
                                                setLoadingEvent(false);
                                                setEventShow({ ...data, items: eventShow.items.concat(data.items) });
                                            },
                                        })
                                    );
                                }}
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default HomeView;
