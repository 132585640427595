import React, { useEffect, useState } from "react";
import Logo from "../assets/icons/logo.svg";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import HeaderPreFilterSticky from "./HeaderPreFilterSticky";
import headerPreFilterStyle from "../styles/HeaderPreFilterStyle";

function HeaderPreFilter(props) {
    const { title } = props;
    const classes = headerPreFilterStyle();

    const { isOpenSidebar } = useSelector((state) => ({
        isOpenSidebar: state.app.isOpenSidebar,
    }));
    const [openSidebar, setOpenSidebar] = useState();

    useEffect(() => {
        setOpenSidebar(isOpenSidebar);
    }, [isOpenSidebar]);

    return (
        <div>
            <div id="headerPrefilter" className={clsx(classes.container)}>
                <div className="display-between" style={{ height: 100, alignItems: "center" }}>
                    <div className={clsx("txtAnimation", classes.txtPreFilter, openSidebar ? "txtsz55" : false)}>
                        {title}
                    </div>
                    <img src={Logo} alt="" width={70} height={70} />
                </div>
            </div>
        </div>
    );
}
HeaderPreFilter.defaultProps = {};

export default HeaderPreFilter;
