import React, { useEffect, useState } from "react";
import DragIcon from "../assets/icons/reorder_rounded.svg";
import detailViewStyle from "../styles/DetailViewStyle";
import MoreIcon from "../assets/icons/more.svg";
import clsx from "clsx";
import { Popover } from "@material-ui/core";
import { isEmpty, unescape } from "lodash";
import moment from "moment-timezone";
import RadioLive from "../assets/icons/radio-live.svg";
import RadioDraft from "../assets/icons/radio-draft.svg";
import RadioPending from "../assets/icons/radio-pending.svg";
import RadioArchived from "../assets/icons/radio-archived.svg";
import { useHistory, useLocation } from "react-router-dom";
import PopupMenu from "./PopupMenu";
import { useDispatch } from "react-redux";
import copy from "copy-to-clipboard";
import { updateItemEventUnassign } from "../redux/actions";
import { formatTime, copyFeedEventItem, getImageUrl } from "../utils/Utils";
import { getEventRsvpLink } from "../redux/reducers/EventReducer";
import IconDraft from "../assets/icons/paste.svg";
import { useDrag, DragPreviewImage } from "react-dnd";

function ItemEventDetail(props) {
    const { item, onClickDeleteFeed } = props;
    const classes = detailViewStyle();
    const [isOver, setOver] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [listMenu, setListMenu] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [{ isDragging }, dragSourceRef, preview] = useDrag({
        item: { type: "item-unassign", data: props.item },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                // setDropped(true);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: (item, monitor) => {
            return true;
        },
    });

    useEffect(() => {
        switch (item.status) {
            case "live":
                setListMenu(["Pause", "Delete", "Duplicate", "Archive", "Preview", "Copy JSON", "Create RSVP Link"]);
                break;
            case "draft":
                setListMenu(["Submit", "Delete", "Duplicate", "Archive", "Preview", "Copy JSON"]);
                break;
            case "pending":
                setListMenu(["Approve - Live", "Pause", "Delete", "Duplicate", "Archive", "Preview", "Copy JSON", "Create RSVP Link"]);
                break;
            case "archived":
                setListMenu(["Unarchive", "Delete", "Duplicate", "Copy JSON"]);
                break;
            default:
                break;
        }
    }, [item.status]);

    return (
        <div
            ref={preview}
            style={{
                height: 92,
                position: "relative",
                background: `${isOver ? "rgba(0, 0, 0, 0.3)" : ""}`,
                borderRadius: `${isOver ? "10px" : 0}`,
                cursor: "pointer",
                borderBottom: "1px solid rgba(223, 224, 235, 0.1)",
            }}
            className="display-row container-table-detail"
            onMouseEnter={(e) => {
                setOver(true);
            }}
            onMouseLeave={(e) => {
                setOver(false);
            }}
            onClick={() => {
                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                    history.replace(`/edit/event/${encodeURIComponent(item.event.uid)}`);
                } else {
                    history.push(`/edit/event/${encodeURIComponent(item.event.uid)}`);
                }
            }}
        >
            {isOver && (
                <div className={clsx("display-row", classes.containerOver)}>
                    <div
                        ref={dragSourceRef}
                        style={{ width: 24, height: 24, margin: "34px 0px 33px 12px", cursor: "move" }}
                    >
                        <img alt="" src={DragIcon} />
                    </div>
                    <div style={{ flex: 1 }} />
                    <div
                        style={{
                            width: 30,
                            height: 30,
                            margin: "34px 33px 31px 0",
                            marginRight: item.isSubscribable === undefined && 19,
                            cursor: "pointer",
                        }}
                        className="display-center"
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        <img src={MoreIcon} alt="" />
                    </div>
                </div>
            )}
            <div className="display-row col-detail">
                <img alt="" src={getImageUrl(item.event.flair)} className={clsx(classes.imageItem, "imageItemHid")} />
                <div style={{ marginTop: 16, width: "calc(100% - 170px)" }} className={"data-col-detail"}>
                    <div className={clsx(classes.txtDetails, "text-ellipsis")}>{unescape(item.event.title)}</div>
                    <div style={{ margin: "4px 0" }} className={classes.txtSubDetails}>
                        {moment.duration(moment(item.event.created).diff(moment())).humanize(true)}
                    </div>
                    <div className={clsx(classes.txtSubDetails, "text-ellipsis")}>{`uid: ${item.event.uid}`}</div>
                </div>
            </div>
            <div className="col-location">
                <div className={clsx(classes.txtItemLocation, "col-txt-location")} style={{ maxWidth: 300 }}>
                    {item.event.location}
                </div>
                {/* <div className={classes.txtSubItemLocation}>{item.event.location}</div> */}
            </div>
            <div className="display-row col-time">
                <div>
                    <div className={clsx(classes.txtItemLocation, "col-txt-location")}>
                        {formatTime(item.event.allDay, item.event.time.start, item.event.time.timeZone, "MMM DD, YYYY")}
                    </div>
                    {!item.event.allDay && (
                        <div className={clsx(classes.txtSubItemLocation)}>
                            {`${formatTime(item.event.allDay, item.event.time.start, item.event.time.timeZone, "h:mm A z")} ${
                                !isEmpty(item.event.time.timeZone)
                                    ? `(GMT${moment.tz(item.event.time.timeZone).format("Z")})`
                                    : "GMT"
                            }`}
                        </div>
                    )}
                </div>
            </div>
            <div className="col-status">
                <div
                    className="display-center-row icon-status"
                    style={{
                        height: 36,
                        borderRadius: 8,
                        marginTop: 16,
                        justifyContent: "center",
                        background:
                            item.status === "live"
                                ? "rgba(140, 178, 248, 0.1)"
                                : item.status === "draft"
                                ? "rgba(255, 171, 21, 0.1)"
                                : "rgba(255, 105, 97,0.1)",
                    }}
                >
                    <img
                        alt=""
                        src={item.status === "live" ? RadioLive : item.status === "draft" ? RadioDraft : RadioPending}
                    />
                    <div
                        style={{
                            fontFamily: "MulishBold",
                            fontSize: 14,
                            lineHeight: "18px",
                            marginLeft: 7,
                            color: item.status === "live" ? "#8CB2F8" : item.status === "draft" ? "#FFAB15" : "#FF6961",
                        }}
                        className="col-txt-status"
                    >
                        {item.status === "live" ? "Live" : item.status === "draft" ? "Draft" : "Pending"}
                    </div>
                </div>
            </div>
            <div className="col-dots">
                <Popover
                    open={!isEmpty(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setAnchorEl();
                        setOver(false);
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                >
                    <PopupMenu
                        listMenu={listMenu}
                        onClick={(itemMenu) => {
                            setAnchorEl();
                            switch (itemMenu) {
                                case "Pause":
                                    dispatch(
                                        updateItemEventUnassign({
                                            uid: item.event.uid,
                                            data: { status: "draft" },
                                        })
                                    );
                                    break;
                                case "Delete":
                                    onClickDeleteFeed(item);
                                    break;
                                case "Duplicate":
                                    if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                        history.replace(`/duplicate/${encodeURIComponent(item.event.uid)}`);
                                    } else {
                                        history.push(`/duplicate/${encodeURIComponent(item.event.uid)}`);
                                    }
                                    break;
                                case "Archive":
                                    dispatch(
                                        updateItemEventUnassign({
                                            uid: item.event.uid,
                                            data: { status: "archived" },
                                        })
                                    );
                                    break;
                                case "Preview":
                                    if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                        history.replace(`/edit/event/${encodeURIComponent(item.event.uid)}`);
                                    } else {
                                        history.push(`/edit/event/${encodeURIComponent(item.event.uid)}`);
                                    }
                                    break;
                                case "Unarchive":
                                case "Submit":
                                    dispatch(
                                        updateItemEventUnassign({
                                            uid: item.event.uid,
                                            data: { status: "pending" },
                                        })
                                    );
                                    break;
                                case "Approve - Live":
                                    dispatch(
                                        updateItemEventUnassign({
                                            uid: item.event.uid,
                                            data: { status: "live" },
                                        })
                                    );
                                    break;
                                case "Copy JSON":
                                    copy(`${JSON.stringify(copyFeedEventItem(item))}`);
                                    break;
                                case "Create RSVP Link":
                                    dispatch(
                                        getEventRsvpLink({
                                            feedId: `${item.provider}:${item.type}:${item.uid}`,
                                            eventId: item.event.uid
                                        })
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                </Popover>
            </div>
        </div>
    );
}

export default ItemEventDetail;
