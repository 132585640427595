import { takeLatest, call, put, select, all } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import {
    createEvent,
    createEventFail,
    createEventSuccess,
    getEventsById,
    getEventsByIdFail,
    getEventsByIdSuccess,
    getEventsUnassign,
    getEventsUnassignSuccess,
    getEventsUnassignFail,
    createEventUnassign,
    createEventUnassignSuccess,
    createEventUnassignFail,
    deleteEventUnassign,
    deleteEventUnassignFail,
    deleteEventUnassignSuccess,
    deleteEvent,
    deleteEventFail,
    deleteEventSuccess,
    updateEventUnassignFail,
    updateEventUnassign,
    updateEvent,
    updateEventFail,
    getEventById,
    getEventUnassignById,
    getEventByIdFail,
    getEventUnassignByIdFail,
    getEventRsvpLink
} from "../reducers/EventReducer";
import { isEmpty } from "lodash";
import { getAllFeedByPreFilterSuccess, getFeedsByPreFilterSuccess } from "../reducers/PreFilterReducer";
import { getStatistics } from "../reducers/StatisticsReducer";
import { withCallback } from "redux-saga-callback";
import { openMessageDialog } from "../reducers/AppReducer";

function* eventSagaWatcher() {
    yield takeLatest(createEvent, withCallback(createItemEvent));
    yield takeLatest(updateEvent, withCallback(updateItemEvent));
    // yield takeLatest(createEventUnassign, withCallback(createItemEventUnassign));
    yield takeLatest(getEventsById, withCallback(getListEventById));
    yield takeLatest(getEventsUnassign, withCallback(getListEventUnassign));
    yield takeLatest(deleteEventUnassign, withCallback(deleteItemUnassign));
    yield takeLatest(deleteEvent, withCallback(deleteItem));
    yield takeLatest(updateEventUnassign, withCallback(updateItemUnassign));
    yield takeLatest(getEventById, withCallback(getItemById));
    yield takeLatest(getEventUnassignById, withCallback(getItemUnassignById));
    yield takeLatest(getEventRsvpLink, withCallback(getEventRsvpLinkHandler));
}

function* createItemEvent(action) {
    try {
        const response = yield call(handleApi, `dashboard/events`, "POST", action.payload);
        yield put(createEventSuccess(response.data));
        return true;
    } catch (error) {
        yield put(createEventFail({ isError: true, message: error.message }));
        return false;
    }
}

function* getListEventById(action) {
    try {
        const state = yield select();
        const query = !isEmpty(action.payload.query) ? `&query=${action.payload.query}` : "";
        const pageToken = !isEmpty(action.payload.pageToken) ? `&page_token=${encodeURIComponent(action.payload.pageToken)}` : "";
        const response = yield call(
            handleApi,
            `dashboard/collections/${action.payload.id}/events?limit=${action.payload.limit}${
                !isEmpty(action.payload.sortBy) ? `&sort=${action.payload.sortBy}` : ""
            }${query}${pageToken}`
        );
        const tempEvent = !pageToken ? response.data.events : state.event.data.events.concat(response.data.events)
        yield put(
            getEventsByIdSuccess({
                ...response.data,
                events: tempEvent,
                items: [],
            })
        );
        return response.data;
    } catch (error) {
        yield put(getEventsByIdFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return {};
    }
}

function* getListEventUnassign(action) {
    try {
        const state = yield select();
        const query = !isEmpty(action.payload.query) ? `&query=${action.payload.query}` : ""

        const response = yield call(
            handleApi,
            `dashboard/${isEmpty(action.payload.status) ? "all" : `${action.payload.status}`}/single-events?limit=${
                action.payload.limit
            }${!isEmpty(action.payload.sortBy) ? `&sort=${action.payload.sortBy}` : ""}${query}${
                !isEmpty(action.payload.pageToken) ? `&page_token=${encodeURIComponent(action.payload.pageToken)}` : ""
            }`
        );

        yield put(
            getEventsUnassignSuccess({
                ...response.data,
                items: state.event.data.items.concat(response.data.items),
                events: [],
            })
        );
        return response.data;
    } catch (error) {
        yield put(getEventsUnassignFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return {};
    }
}

// function* createItemEventUnassign(action) {
//     try {
//         const response = yield call(handleApi, "dashboard/unassigned_events", "POST", action.payload.data);
//         yield put(createEventUnassignSuccess(response.data));
//         return true;
//     } catch (error) {
//         yield put(createEventUnassignFail({ isError: true, message: error.message }));
//         return false;
//     }
// }

function* deleteItemUnassign(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/events/${action.payload.uid}`, "DELETE");
        yield put(deleteEventUnassignSuccess());
        yield put(getStatistics());
        yield put(
            getFeedsByPreFilterSuccess({
                ...state.preFilter.data,
                items: state.preFilter.data.items.filter((el) => {
                    if (el.event) {
                        return encodeURIComponent(el.event.uid) !== action.payload.uid;
                    } else {
                        return true;
                    }
                }),
            })
        );
        yield put(
            getAllFeedByPreFilterSuccess({
                calendar: { ...state.preFilter.calendar },
                collection: { ...state.preFilter.collection },
                event: {
                    ...state.preFilter.event,
                    items: state?.preFilter?.event?.items?.filter(
                        (el) => encodeURIComponent(el.event.uid) !== action.payload.uid
                    ),
                },
            })
        );
        yield put(
            getEventsUnassignSuccess({
                ...state.event.data,
                items: state?.event?.data?.items?.filter((el) => encodeURIComponent(el.event.uid) !== action.payload.uid),
                events: [],
            })
        );
        return true;
    } catch (error) {
        yield put(deleteEventUnassignFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* deleteItem(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/events/${action.payload.uid}`, "DELETE");
        yield put(deleteEventSuccess());
        yield put(
            getEventsByIdSuccess({
                ...state.event.data,
                events: state.event.data.events.filter((el) => encodeURIComponent(el.uid) !== action.payload.uid),
                items: [],
            })
        );
        return true;
    } catch (error) {
        yield put(deleteEventFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* updateItemUnassign(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/events/${action.payload.uid}`, "PUT", action.payload.data);
        yield put(getStatistics());
        yield put(
            getFeedsByPreFilterSuccess({
                ...state.preFilter.data,
                items: state.preFilter.data.items.filter((el) => {
                    if (el.event) {
                        return encodeURIComponent(el.event.uid) !== action.payload.uid;
                    } else {
                        return true;
                    }
                }),
            })
        );
        yield put(
            getAllFeedByPreFilterSuccess({
                calendar: { ...state.preFilter.calendar },
                collection: { ...state.preFilter.collection },
                event: {
                    ...state.preFilter.event,
                    items: state?.preFilter?.event?.items?.filter(
                        (el) => encodeURIComponent(el.event.uid) !== action.payload.uid
                    ),
                },
            })
        );
        yield put(
            getEventsUnassignSuccess({
                ...state.event.data,
                items: state?.event?.data?.items?.filter(
                    (el) => encodeURIComponent(el.event.uid) !== action.payload.uid
                ),
                events: [],
            })
        );
        return true;
    } catch (error) {
        yield put(updateEventUnassignFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* updateItemEvent(action) {
    try {
        const response = yield call(handleApi, `dashboard/events/${action.payload.uid}`, "PUT", action.payload.data);
        // yield put(createEventSuccess(response.data));
        return true;
    } catch (error) {
        yield put(updateEventFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* getItemById(action) {
    try {
        const response = yield call(handleApi, `dashboard/collections/${action.payload.id}/events/${action.payload.eventId}`);
        return response.data;
    } catch (error) {
        yield put(getEventUnassignByIdFail({ isError: true, message: error.message }));
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return {};
    }
}

function* getItemUnassignById(action) {
    try {
        const response = yield call(handleApi, `dashboard/unassigned_events/${action.payload}`);
        return response.data;
    } catch (error) {
        yield put(getEventByIdFail({ isError: true, message: error.message }));
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return {};
    }
}

function* getEventRsvpLinkHandler(action) {
    try {
        const { feedId, eventId } = action.payload;
        const params = `feed_id=${encodeURIComponent(feedId)}&event_id=${encodeURIComponent(eventId)}`
        const response = yield call(handleApi, `dashboard/rsvp_link?${params}`);
        yield put(
            openMessageDialog({
                open: true,
                message: `Event RSVP link: ${response.data.rsvp_link}`,
                title: "INFO",
            })
        );
        return true;
    } catch (error) {
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return false;
    }
}

export default eventSagaWatcher;
