import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getImageUrl } from "../utils/Utils";
import { useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import { updateItemEventUnassign, updateItemFeedEvent } from "../redux/actions";

const ItemSubSidebar = (props) => {
    const { item, active, onItemClick } = props;
    const [isHover, setHover] = useState(false);
    const history = useHistory();
    const { listCat, isOpenSidebar } = useSelector((state) => ({
        listCat: state.category.data,
        isOpenSidebar: state.app.isOpenSidebar,
    }));
    const { innerWidth: width, innerHeight: height } = window;
    const dispatch = useDispatch();

    const [{ isOverItem }, itemCalendarDropRef] = useDrop({
        accept: ["item-unassign", "item-assign"],
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOverItem: monitor.isOver(),
        }),
        drop: (item, monitor) => {
            // console.log(item);
            // console.log(props.item);
            if (item.type === "item-assign" && item.data.collectionId.split(":")[2] === props.item.uid) {
                return;
            }
            if (item.type === "item-unassign") {
                dispatch(
                    updateItemEventUnassign({
                        uid: encodeURIComponent(item.data.event.uid),
                        data: {
                            collection_id: `${props.item.provider}:${props.item.type}:${props.item.uid}`,
                        },
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.push(
                                    `/${props.item.isSubscribable ? "calendar" : "collection"}/${props.item.provider}:${
                                        props.item.type
                                    }:${encodeURIComponent(props.item.uid)}`
                                );
                            }
                        },
                    })
                );
            } else {
                dispatch(
                    updateItemFeedEvent({
                        uid: encodeURIComponent(item.data.uid),
                        data: {
                            collection_id: `${props.item.provider}:${props.item.type}:${props.item.uid}`,
                        },
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.push(
                                    `/${props.item.isSubscribable ? "calendar" : "collection"}/${props.item.provider}:${
                                        props.item.type
                                    }:${encodeURIComponent(props.item.uid)}`
                                );
                            }
                        },
                    })
                );
            }
        },
    });

    return (
        <div
            ref={itemCalendarDropRef}
            style={{ height: 32, background: isOverItem ? "rgba(255, 255, 255, 0.2)" : "initial" }}
            className="display-center-row"
            onMouseOver={(e) => setHover(true)}
            onMouseEnter={(e) => setHover(true)}
            onMouseLeave={(e) => setHover(false)}
            onClick={() =>
                history.push(
                    `/${item.isSubscribable ? "calendar" : "collection"}/${item.provider}:${
                        item.type
                    }:${encodeURIComponent(item.uid)}`
                )
            }
        >
            <div
                style={{
                    width: 4,
                    height: 32,
                    background: active || isHover ? "#8CB2F8" : "transparent",
                    marginLeft: 1,
                }}
            ></div>
            <div className="display-center-row" style={{ cursor: "pointer", flex: 1 }} onClick={onItemClick}>
                <img
                    style={{ margin: "0 15px 0 42px", objectFit: "cover" }}
                    width={17}
                    height={17}
                    src={getImageUrl(listCat.find((el) => el.uid === item.categoryId)?.icon)}
                    alt=""
                />
                <div
                    className="text-ellipsis"
                    style={{ color: active || isHover ? "#8CB2F8" : "#ADAFB2", width: 120, flex: 1, userSelect: "none", marginRight: "8px"}}
                >
                    {isOpenSidebar && item.title}
                </div>
            </div>
        </div>
    );
};

export default ItemSubSidebar;
