import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const StatusBadge = ({ required, loading, disabled }) => {
    return <div style={{display: 'flex'}}>
        {required && <div style={{ color: "red", whiteSpace: "pre-wrap" }}> *</div>}
        {disabled && <div
            style={{
                fontSize: 9,
                lineHeight: "14px",
                color: "rgba(255, 255, 255, 0.6)",
                fontFamily: "SFProText",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                border: "1px solid rgba(255, 255, 255, 0.4)",
                borderRadius: "4px",
                padding: "0 4px",
                marginLeft: 10,
            }}
        >DISABLED</div>}
        {loading && <div
            style={{
                fontSize: 9,
                lineHeight: "14px",
                color: "rgba(255, 255, 255, 0.6)",
                marginLeft: 10,
            }}
        >
            <FontAwesomeIcon icon={faSpinner} spin />
        </div>}
    </div>
};

export default StatusBadge;
