import { createSlice } from "@reduxjs/toolkit";

const preFilterReducer = createSlice({
    name: "preFilter",
    initialState: {
        isLoading: false,
        data: { items: [] },
        calendar: undefined,
        collection: undefined,
        event: undefined,
    },
    reducers: {
        getFeedsByPreFilter: (state, action) => {
            state.isLoading = true;
        },
        getFeedsByPreFilterSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getFeedsByPreFilterFail: (state, action) => {
            state.isLoading = false;
        },
        changeTab: (state, action) => {
            state.data = { items: [] };
        },
        getAllFeedByPreFilter: (state, action) => {
            state.isLoading = true;
        },
        getAllFeedByPreFilterSuccess: (state, action) => {
            state.isLoading = false;
            state.calendar = action.payload.calendar;
            state.collection = action.payload.collection;
            state.event = action.payload.event;
        },
        getAllFeedByPreFilterFail: (state, action) => {
            state.isLoading = false;
        },
        sortPreFilterBy: (state, action) => {
            state.isLoading = true;
        }
    },
});

const { reducer, actions } = preFilterReducer;
export const {
    getFeedsByPreFilter,
    getFeedsByPreFilterSuccess,
    getFeedsByPreFilterFail,
    changeTab,
    getAllFeedByPreFilter,
    getAllFeedByPreFilterSuccess,
    getAllFeedByPreFilterFail,
} = actions;
export default reducer;
