import { createSlice } from "@reduxjs/toolkit";

const statisticsReducer = createSlice({
    name: "statistics",
    initialState: {
        isLoading: false,
        data: undefined,
    },
    reducers: {
        getStatistics: (state, action) => {
            state.isLoading = true;
        },
        getStatisticsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getStatisticsFail: (state, action) => {
            state.isLoading = true;
        },
    },
});

const { reducer, actions } = statisticsReducer;
export const { getStatistics, getStatisticsSuccess, getStatisticsFail } = actions;
export default reducer;
