import { isEmpty } from "lodash";
import Logo from "../assets/icons/logo.svg";
import CardImg from "../assets/icons/card_image_placeholder.svg";
import CardList from "../assets/icons/card_list_image.svg";
import moment from "moment-timezone";
import React from 'react'
import clsx from 'clsx'

export const getImageUrl = (image) => {
    return isEmpty(image) || (isEmpty(image.thumbnail) && isEmpty(image.path))
        ? Logo
        : validateUrl(image.thumbnail)
        ? image.thumbnail
        : validateUrl(image.path)
        ? image.path
        : Logo;
};

export const getImageUrlSmall = (image) => {
    return isEmpty(image) || (isEmpty(image.thumbnail) && isEmpty(image.path))
        ? CardList
        : validateUrl(image.thumbnail)
        ? image.thumbnail
        : validateUrl(image.path)
        ? image.path
        : CardList;
};
export const getImageUrlBig = (image) => {
    return isEmpty(image) || (isEmpty(image.thumbnail) && isEmpty(image.path))
        ? CardImg
        : validateUrl(image.thumbnail)
        ? image.thumbnail
        : validateUrl(image.path)
        ? image.path
        : CardImg;
};

function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
    );
}

export const getListTimezone = () => {
    // var timeZone = moment.tz.guess();
    // const zoneName = moment.tz(timeZone).zoneName();
    // return moment.tz(timeZoneName).format('zz');
    var timeZones = moment.tz.names();
    var offsetTmz = [];

    for (var i in timeZones) {
        offsetTmz.push({
            title: timeZones[i] + " (GMT" + moment.tz(timeZones[i]).format("Z") + ")",
            value: timeZones[i],
        });
    }
    return offsetTmz;
};

export const sortByUpcomingEventTime = (data) => {
    return data.sort((a, b) => {
        var date1 = new Date(a.upcomingEventTime);
        var date2 = new Date(b.upcomingEventTime);
        if (date1 < date2) return 1;
        else if (date1 > date2) return -1;
        else return 0;
    });
};

export const sortCalendarJockeyPersona = (data) => {
    let collator = new Intl.Collator('en');
    return data.sort((a, b) => {
        return collator.compare(a.name, b.name);
    });
};

export const sortFeedByCreator = (data) => {
    let collator = new Intl.Collator('en');
    return data.sort((a, b) => {
        return collator.compare(a.title, b.title);
    });
};

export const sortCategory = (data) => {
    let collator = new Intl.Collator('en');
    return data.sort((a, b) => {
        return collator.compare(a.name, b.name);
    });
};

export const sortSubCategory = (data) => {
    let collator = new Intl.Collator('en');
    return data.sort((a, b) => {
        return collator.compare(a.name, b.name);
    });
};

export const sortTag = (data) => {
    let collator = new Intl.Collator('en');
    return data.sort((a, b) => {
        return collator.compare(a.name, b.name);
    });
};


export const upperCaseFirstLetter = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatTime = (allDay, time, timeZone, fmt) => {
    // console.log(time, timeZone, fmt);
    time = moment(time);
    if (!isEmpty(timeZone) && allDay === false) {
        time.tz(timeZone);
    }
    return time.format(fmt);
};

export const formatNaiveTime = (time, tz) => {
    if (!time) return null;
    if (tz) {
        time = moment.tz(time, tz);
    } else {
        time = moment(time);
    }
    return time.format('YYYY-MM-DDTHH:mm:ss.sss');
}

const _formatEventTimePretty = (dateStr, timeStr, classes) => {
    let dateSeparator = <div style={{ width: 1, height: 24, background: "#E8EAEE", margin: "0 12px" }} />;
    return (
      <div className="display-row" style={{ height: 24 }}>
          <div className={clsx(classes.txtDate, "txtDateSmall")}>
              {dateStr}
          </div>
          {timeStr && dateSeparator}
          {timeStr && (
            <div className={clsx(classes.txtTime, "txtDateSmall")}>
                {timeStr}
            </div>
          )}
      </div>
    )
}

export const formatEventTime = (event, prettyPrint, classes) => {
    // Turn on prettyPrint will:
    //      - Use long format for date
    //         (eg., Wednesday, December 2, 2020 instead of Wed, Dec 02)
    //      - Return a <div> element instead of a string
    if (!event || !event.time) return '';

    let withTz = !event.allDay ? event.time.timeZone : null;
    let start = moment.tz(event.time.start, withTz);
    let end = moment.tz(event.time.end, withTz);

    let dateFmt = prettyPrint ? 'dddd, MMM D, YYYY' : 'ddd, MMM DD';
    let hrFmt = prettyPrint ? 'h:mmA' : 'h:mm a';

    let dateStr, timeStr;

    // if end time is 00:00 the next day, subtract it by 1 day first
    if (end.hour() === 0 && end.minute() === 0) {
        end = end.subtract(1, 'day');
    }
    let startDate = start.format(dateFmt);
    let endDate = end.format(dateFmt);

    if (startDate !== endDate) {
        // Multi days event
        dateStr = `${startDate} - ${endDate}`;
    } else {
        // Single day event, checking whether we should show time component or not
        dateStr = startDate;
        if (!event.allDay && !(
          start.hour() === 0 && start.minute() === 0 && end.hour() === 0 && end.minute() === 0
        )) {
            timeStr = `${start.format(hrFmt)} - ${end.format(hrFmt)}`;
        }
    }

    if (prettyPrint) return _formatEventTimePretty(dateStr, timeStr, classes);
    else {
        let parts = [];
        if (dateStr) parts.push(dateStr);
        if (timeStr) parts.push(timeStr);
        return parts.join(' ');
    }
};

export const copyFeedItem = (item) => {
    return {
        title: item.title,
        description: item.description,
        categoryId: item?.categoryId ?? null,
        subCategoriesId: item?.subCategoriesId ?? null,
        creatorId: item?.creatorId ?? null,
        publishStartTime: item?.publishStartTime ?? null,
        publishEndTime: item?.publishEndTime ?? null,
        feed_schema: item?.feed_schema ?? null,
        feed_data: item?.feed_data ?? null,
        image: {
            path: item?.image?.path,
            mediaType: item?.image?.mediaType,
        },
    };
};

export const copyFeedEventItem = (item) => {
    return {
        categoryId: item?.categoryId ?? null,
        subCategoriesId: item?.subCategoriesId ?? null,
        creatorId: item?.creatorId ?? null,
        event: {
            collectionId: item?.event?.collectionId ?? null,
            notes: item?.event?.notes ?? null,
            title: item?.event?.title ?? null,
            feed_schema: item?.event?.feed_schema ?? null,
            feed_data: item?.event?.feed_data ?? null,
            location: item?.event?.location ?? null,
            allDay: item?.event?.allDay ?? false,
            time: {
                start: item?.event?.time?.start ?? null,
                end: item?.event?.time?.end ?? null,
                timeZone: item?.event?.time?.timeZone ?? null,
            },
            flair: {
                path: item?.event?.flair?.path,
                mediaType: item?.event?.flair?.mediaType,
            },
            tagIds: item?.event?.tagIds ?? null,
        },
        publishStartTime: item?.publishStartTime ?? null,
        publishEndTime: item?.publishEndTime ?? null,
    };
};

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};
