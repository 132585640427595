import { Popover } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import MoreIcon from "../assets/icons/more.svg";
import itemPreFilterStyle from "../styles/ItemPreFilterStyle";
import { formatEventTime, getImageUrlSmall, copyFeedEventItem } from "../utils/Utils";
import { useHistory, useLocation } from "react-router-dom";
import PopupMenu from "./PopupMenu";
import { useDispatch } from "react-redux";
import {
    deleteEventUnassign,
    getEventRsvpLink,
} from '../redux/reducers/EventReducer'
import copy from "copy-to-clipboard";
import { updateItemEventUnassign } from "../redux/actions";
import { setTagType } from "../redux/reducers/TagEventReducer";

function ItemEventPreFilter(props) {
    const { item, itemParent, status, onClickDeleteFeed, tag, tagType } = props;
    const classes = itemPreFilterStyle();
    const [isOver, setOver] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [listMenu, setListMenu] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        switch (status) {
            case "live":
                setListMenu(["Pause", "Delete", "Duplicate", "Archive", "Preview", "Copy JSON", "Create RSVP Link"]);
                break;
            case "draft":
                setListMenu(["Submit", "Delete", "Duplicate", "Archive", "Preview", "Copy JSON"]);
                break;
            case "pending":
                setListMenu([
                    "Approve - Live",
                    "Go To Draft",
                    "Delete",
                    "Duplicate",
                    "Archive",
                    "Preview",
                    "Copy JSON",
                    "Create RSVP Link",
                ]);
                break;
            case "archived":
                setListMenu(["Unarchive", "Delete", "Duplicate", "Copy JSON"]);
                break;
            default:
                break;
        }
    }, [status, item]);

    return (
        <div
            className={clsx("display-row", classes.containerItem, "container-item-pre-filter")}
            style={{
                background: `${isOver ? "rgba(0, 0, 0, 0.3)" : ""}`,
                borderRadius: `${isOver ? "10px" : 0}`,
                cursor: "pointer",
                // pointerEvents: `${tag === true && 'none'}`

            }}
            // onMouseOver={(e) => {
            //     setOver(true);
            // }}
            onMouseEnter={(e) => {
                setOver(true);
            }}
            onMouseLeave={(e) => {
                setAnchorEl();
                setOver(false);
            }}
            onClick={() => {
                if(tag === true){
                    dispatch(setTagType({tagType}))
                    const linkRedirect = !item.collectionId ? `/edit/event/${encodeURIComponent(item.uid)}` : (`/feed/${item.collectionId}/edit/${encodeURIComponent(item.uid)}`) 
                    history.push(linkRedirect);
                } else if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                    history.replace(`/edit/event/${encodeURIComponent(item.uid)}`);
                } else {
                    history.push(`/edit/event/${encodeURIComponent(item.uid)}`);
                }
            }}
        >
            {isOver && (
                <div className={classes.containerOver}>
                    <div
                        className={clsx("display-center", classes.iconMore)}
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        <img src={MoreIcon} alt="" />
                    </div>
                </div>
            )}
            <div className={classes.containerImage}>
                <img
                    alt=""
                    src={getImageUrlSmall(item.flair)}
                    style={{ width: 117, height: 117, objectFit: "cover", borderRadius: 8 }}
                />
            </div>
            <div style={{ height: 117, flex: 1, width: "calc(100% - 157px)" }}>
                <div style={{ height: 35 }}></div>
                <div className={clsx(classes.txtNextEvent, "text-ellipsis ")}>{`${item?.title ?? ""}`}</div>
                {formatEventTime(item, true, classes)}
            </div>
            <Popover
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl();
                    setOver(false);
                }}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
            >
                <PopupMenu
                    listMenu={listMenu}
                    onClick={(itemMenu) => {
                        setAnchorEl();
                        switch (itemMenu) {
                            case "Approve - Live":
                                dispatch(
                                    updateItemEventUnassign({
                                        uid: encodeURIComponent(item.uid),
                                        data: { status: "live" },
                                    })
                                );
                                break;
                            case "Unarchive":
                            case "Submit":
                                dispatch(
                                    updateItemEventUnassign({
                                        uid: encodeURIComponent(item.uid),
                                        data: { status: "pending" },
                                    })
                                );
                                break;
                            case "Go To Draft":
                            case "Pause":
                                dispatch(
                                    updateItemEventUnassign({
                                        uid: encodeURIComponent(item.uid),
                                        data: { status: "draft" },
                                    })
                                );
                                break;
                            case "Delete":
                                onClickDeleteFeed(item);
                                break;
                            case "Duplicate":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(`/duplicate/${encodeURIComponent(item.uid)}`);
                                } else {
                                    history.push(`/duplicate/${encodeURIComponent(item.uid)}`);
                                }
                                break;
                            case "Archive":
                                dispatch(
                                    updateItemEventUnassign({
                                        uid: encodeURIComponent(item.uid),
                                        data: { status: "archived" },
                                    })
                                );
                                break;
                            case "Preview":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(`/edit/event/${encodeURIComponent(item.uid)}`);
                                } else {
                                    history.push(`/edit/event/${encodeURIComponent(item.uid)}`);
                                }
                                break;
                            case "Copy JSON":
                                copy(`${JSON.stringify(copyFeedEventItem(itemParent))}`);
                                break;
                            case "Create RSVP Link":
                                dispatch(
                                    getEventRsvpLink({
                                        feedId: `${itemParent.provider}:${itemParent.type}:${itemParent.uid}`,
                                        eventId: item.uid
                                    })
                                );
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </Popover>
        </div>
    );
}

export default ItemEventPreFilter;
