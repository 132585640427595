import React, { useState } from "react";
import appStyle from "../styles/AppStyle";

function PopupMenu(props) {
    const { listMenu, onClick } = props;
    const appStyles = appStyle();
    const [isHoverItem, setHoverItem] = useState(false);
    const [indexItem, setIndexItem] = useState();

    return (
        <div className={appStyles.containerPopup}>
            {listMenu.map((itemMenu, index) => (
                <div
                    onMouseOver={(e) => {
                        setHoverItem(true);
                        setIndexItem(index);
                    }}
                    onMouseLeave={(e) => {
                        setHoverItem(false);
                        setIndexItem();
                    }}
                    key={index}
                    className={appStyles.txtPopup}
                    style={{
                        background: `${isHoverItem && indexItem === index ? "rgba(255, 255, 255, 0.2)" : ""}`,
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick(itemMenu);
                    }}
                >
                    {itemMenu}
                </div>
            ))}
        </div>
    );
}

export default PopupMenu;
