import clsx from "clsx";
import React, { useState, useEffect } from "react";
import detailViewStyle from "../styles/DetailViewStyle";
import Logo from "../assets/icons/logo.svg";
import EditIcon from "../assets/icons/edit.svg";
import MoreIcon from "../assets/icons/more.svg";
import { Popover } from "@material-ui/core";
import { isEmpty } from "lodash";
import appStyle from "../styles/AppStyle";
import { getImageUrl, upperCaseFirstLetter, getImageUrlBig, copyFeedItem } from "../utils/Utils";
import parseHtml from "html-react-parser";
import { useHistory, useLocation } from "react-router-dom";
import PopupMenu from "./PopupMenu";
import RadioLive from "../assets/icons/radio-live.svg";
import RadioDraft from "../assets/icons/radio-draft.svg";
import RadioPending from "../assets/icons/radio-pending.svg";
import RadioArchived from "../assets/icons/radio-archived.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteCalendar, deleteCalendarFail } from "../redux/reducers/CalendarReducer";
import { deleteCollection, deleteCollectionFail } from "../redux/reducers/CollectionReducer";
import { getPersonas } from "../redux/reducers/PersonaReducer";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import { updateItemFeedCalendar, updateItemFeedCollection } from "../redux/actions";
import copy from "copy-to-clipboard";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function HeaderDetail(props) {
    const { data, isEvent } = props;
    const classes = detailViewStyle();
    const appStyles = appStyle();
    const [anchorEl, setAnchorEl] = useState();
    const [listMenu, setListMenu] = useState([]);
    const [isOver, setOver] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [imageUrl, setImageUrl] = useState();
    const [isDelete, setDelete] = useState();
    const [openSidebar, setOpenSidebar] = useState();

    const { isDeleteCalendar, isDeleteCollection, listPersona, onClickDelete, isOpenSidebar } = useSelector((state) => ({
        isDeleteCalendar: state.calendar.isDelete,
        isDeleteCollection: state.collection.isDelete,
        listPersona: state.persona.data,
        onClickDelete: state.app.onClickDelete,
        isOpenSidebar: state.app.isOpenSidebar,
    }));

    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(769));

    useEffect(() => {
        setOpenSidebar(isOpenSidebar);
    }, [isOpenSidebar]);

    useEffect(() => {
        if (!isEmpty(listPersona) && !isEmpty(data.creatorId)) {
            setImageUrl(listPersona.find((el) => el.uid === data.creatorId)?.image);
        } else {
            setImageUrl();
        }
    }, [listPersona, data]);

    useEffect(() => {
        if (isEmpty(listPersona)) {
            dispatch(getPersonas());
        }
        switch (data.status) {
            case "live":
                if (isEmpty(data.nextEvent)) {
                    setListMenu(["Pause", "Delete", "Duplicate", "Archive", "Preview", "Add Event", "Copy JSON"]);
                } else {
                    setListMenu([
                        "Pause",
                        "Delete",
                        "Duplicate",
                        "Edit Upcoming Event",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                }
                break;
            case "draft":
                if (isEmpty(data.nextEvent)) {
                    setListMenu(["Submit", "Delete", "Duplicate", "Archive", "Preview", "Add Event", "Copy JSON"]);
                } else {
                    setListMenu([
                        "Submit",
                        "Delete",
                        "Duplicate",
                        "Edit Upcoming Event",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                }

                break;
            case "pending":
                if (isEmpty(data.nextEvent)) {
                    setListMenu([
                        "Approve - Live",
                        "Go To Draft",
                        "Delete",
                        "Duplicate",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                } else {
                    setListMenu([
                        "Approve - Live",
                        "Go To Draft",
                        "Delete",
                        "Duplicate",
                        "Edit Upcoming Event",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                }

                break;
            case "archived":
                setListMenu(["Unarchive", "Delete", "Duplicate", "Copy JSON"]);
                break;
            default:
                break;
        }
    }, [data]);

    useEffect(() => {
        if (isDeleteCalendar || isDeleteCollection) {
            dispatch(deleteCollectionFail({ isError: false, message: "" }));
            dispatch(deleteCalendarFail({ isError: false, message: "" }));
            history.goBack();
        }
    }, [isDeleteCalendar, isDeleteCollection]);

    useEffect(() => {
        if (onClickDelete && isDelete) {
            setDelete(false);
            if (data.isSubscribable) {
                dispatch(deleteCalendar({ uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}` }));
            } else {
                dispatch(deleteCollection({ uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}` }));
            }
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    return (
        <div
            className={clsx(!isEvent ? classes.containerHead : [classes.containerEvents], "display-row")}
            onMouseOver={(e) => {
                setOver(true);
            }}
            onMouseEnter={(e) => {
                setOver(true);
            }}
            onMouseLeave={(e) => {
                setAnchorEl();
                setOver(false);
            }}
        >
            {!isEvent ? (
                <div style={{ width: "calc(100% - 70px)" }}>
                    <div className="display-row">
                        <img
                            className={openSidebar ? classes.squareImage : classes.squareImageSidebarOpen}
                            alt=""
                            src={getImageUrlBig(data.image)}
                        />
                        <div style={{ width: `calc(100% - ${!isTablet ? 201 : !isOpenSidebar ? 96 : 131}px)` }}>
                            <div style={{ height: 36 }}>
                                {!isEmpty(data) && (
                                    <div
                                        className={clsx("display-center-row", {
                                            [classes.typeAndStatusGroupSidebarOpen]: !openSidebar,
                                            [classes.typeAndStatusGroup]: openSidebar,
                                        })}
                                    >
                                        <div
                                            className={clsx("display-center", {
                                                [classes.containerIconHeaderSidebarOpen]: !openSidebar,
                                                [classes.containerIconHeader]: openSidebar,
                                                [classes.txtMulishSidebarOpen]: !openSidebar,
                                                [classes.txtMulish]: openSidebar,
                                            })}
                                        >
                                            {data.isSubscribable ? "CALENDAR" : "COLLECTION"}
                                        </div>
                                        <div
                                            style={{
                                                justifyContent: "center",
                                                marginLeft: 8,
                                                background:
                                                    data.status === "pending"
                                                        ? "rgba(255, 105, 97,0.1)"
                                                        : data.status === "draft"
                                                        ? "rgba(255, 171, 21, 0.1)"
                                                        : "rgba(140, 178, 248,0.1)",
                                            }}
                                            className={clsx("display-center-row", {
                                                [classes.containerIconHeaderSidebarOpen]: !openSidebar,
                                                [classes.containerIconHeader]: openSidebar,
                                            })}
                                        >
                                            <img
                                                src={
                                                    data.status === "live"
                                                        ? RadioLive
                                                        : data.status === "pending"
                                                        ? RadioPending
                                                        : data.status === "archived"
                                                        ? RadioArchived
                                                        : RadioDraft
                                                }
                                                alt=""
                                                style={{ marginRight: 6 }}
                                            />
                                            <div
                                                className={classes.txtMulish}
                                                style={{
                                                    color:
                                                        data.status === "live" || data.status === "archived"
                                                            ? "#8CB2F8"
                                                            : data.status === "draft"
                                                            ? "#FFAB15"
                                                            : "#FF6961",
                                                }}
                                            >
                                                {upperCaseFirstLetter(data.status)}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className={clsx("display-center-row", {
                                    [classes.txtInHeaderSidebarOpen]: !openSidebar,
                                    [classes.txtInHeader]: openSidebar,
                                })}
                            >
                                {data?.eventsCount && <div>{`${data.eventsCount} EVENTS`}</div>}
                                {data.isSubscribable && (
                                    <div style={{ marginLeft: 30 }}>{`${data.subscribersCount} SUBSCRIBERS`}</div>
                                )}
                            </div>
                            <div
                                className={clsx("display-center-row", {
                                    [classes.titleGroupSidebarOpen]: !openSidebar,
                                    [classes.titleGroup]: openSidebar,
                                })}
                            >
                                <img
                                    alt=""
                                    src={getImageUrl(imageUrl)}
                                    className={clsx({
                                        [classes.circleImageSidebarOpen]: !openSidebar,
                                        [classes.circleImage]: openSidebar,
                                    })}
                                />
                                <div
                                    className={clsx(
                                        {
                                            [classes.txtTitleSidebarOpen]: !openSidebar,
                                            [classes.txtTitle]: openSidebar,
                                        },
                                        appStyles.textEllipsis
                                    )}
                                >
                                    {data.title}
                                </div>
                                <div style={{ width: 82 }}>
                                    {isOver && (
                                        <div className="display-center-row" style={{ display: "inline-flex" }}>
                                            <div
                                                className="display-center"
                                                style={{
                                                    width: 16,
                                                    height: 16,
                                                    cursor: "pointer",
                                                    margin: "0 25px 0 25px",
                                                }}
                                                onClick={(e) => {
                                                    if (
                                                        location.pathname.includes("create") ||
                                                        location.pathname.includes("edit")
                                                    ) {
                                                        history.replace(
                                                            `/edit/${data.isSubscribable ? "calendar" : "collection"}/${
                                                                data.provider
                                                            }:${data.type}:${encodeURIComponent(data.uid)}`
                                                        );
                                                    } else {
                                                        history.push(
                                                            `/edit/${data.isSubscribable ? "calendar" : "collection"}/${
                                                                data.provider
                                                            }:${data.type}:${encodeURIComponent(data.uid)}`
                                                        );
                                                    }
                                                }}
                                            >
                                                <img src={EditIcon} alt="" />
                                            </div>
                                            <div
                                                className="display-center"
                                                style={{ width: 16, height: 16, cursor: "pointer" }}
                                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                            >
                                                <img src={MoreIcon} alt="" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={clsx({
                                    [classes.txtBottomHeaderSidebarOpen]: !openSidebar,
                                    [classes.txtBottomHeader]: openSidebar,
                                })}
                            >
                                {parseHtml(data?.description ?? "")}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                data === "" && (
                    <div id="ctn-header-sticky">
                        <div className={clsx(classes.txtEvents, "txtAnimation", !openSidebar ? "txtsz55" : false)}>
                            Events
                        </div>
                    </div>
                )
            )}

            <img src={Logo} alt="" width={70} height={70} style={{ marginTop: 15 }} />
            <Popover
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl();
                    setOver(false);
                }}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <PopupMenu
                    listMenu={listMenu}
                    onClick={(itemMenu) => {
                        setAnchorEl();
                        switch (itemMenu) {
                            case "Approve - Live":
                                if (data.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "live" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "live" },
                                        })
                                    );
                                }
                                break;
                            case "Delete":
                                setDelete(true);
                                dispatch(
                                    openMessageDialog({
                                        open: true,
                                        message: `Are you sure you want to delete this ${
                                            data.isSubscribable ? "calendar" : "collection"
                                        }?`,
                                        title: `DELETE ${data.isSubscribable ? "CALENDAR" : "COLLECTION"}`,
                                    })
                                );
                                break;
                            case "Duplicate":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/duplicate/${data.isSubscribable ? "calendar" : "collection"}/${
                                            data.provider
                                        }:${data.type}:${encodeURIComponent(data.uid)}`
                                    );
                                } else {
                                    history.push(
                                        `/duplicate/${data.isSubscribable ? "calendar" : "collection"}/${
                                            data.provider
                                        }:${data.type}:${encodeURIComponent(data.uid)}`
                                    );
                                }
                                break;
                            case "Edit Upcoming Event":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/feed/${data.provider}:${data.type}:${encodeURIComponent(
                                            data.uid
                                        )}/edit/${encodeURIComponent(data.nextEvent.uid)}`
                                    );
                                } else {
                                    history.push(
                                        `/feed/${data.provider}:${data.type}:${encodeURIComponent(
                                            data.uid
                                        )}/edit/${encodeURIComponent(data.nextEvent.uid)}`
                                    );
                                }
                                break;
                            case "Archive":
                                if (data.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "archived" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "archived" },
                                        })
                                    );
                                }
                                break;
                            case "Preview":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/edit/${data.isSubscribable ? "calendar" : "collection"}/${data.provider}:${
                                            data.type
                                        }:${encodeURIComponent(data.uid)}`
                                    );
                                } else {
                                    history.push(
                                        `/edit/${data.isSubscribable ? "calendar" : "collection"}/${data.provider}:${
                                            data.type
                                        }:${encodeURIComponent(data.uid)}`
                                    );
                                }
                                break;
                            case "Add Event":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/feed/${data.provider}:${data.type}:${encodeURIComponent(data.uid)}/create`
                                    );
                                } else {
                                    history.push(
                                        `/feed/${data.provider}:${data.type}:${encodeURIComponent(data.uid)}/create`
                                    );
                                }
                                break;
                            case "Copy JSON":
                                copy(`${JSON.stringify(copyFeedItem(data))}`);
                                break;
                            case "Pause":
                            case "Go To Draft":
                                if (data.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "draft" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "draft" },
                                        })
                                    );
                                }
                                break;
                            case "Unarchive":
                            case "Submit":
                                if (data.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "pending" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${data.provider}:${data.type}:${encodeURIComponent(data.uid)}`,
                                            data: { status: "pending" },
                                        })
                                    );
                                }
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </Popover>
        </div>
    );
}

export default HeaderDetail;
