import { Box, Popover } from "@material-ui/core";
import _, { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";

import IconDown from "../assets/icons/icon-dropDown.svg";
import appStyle from "../styles/AppStyle";
import StatusBadge from "./StatusBadge";

const SelectWithTitle = (props) => {
    const { title, list, data, placeHolder, style, showComment, onItemChange, isAlignParent, disable, require, loading, tag, onChangeTab } = props;
    const [anchorEl, setAnchorEl] = useState();
    const appStyles = appStyle();
    const parentRef = useRef();
    const [value, setValue] = useState("");
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        if (!isEmpty(data) && !isEmpty(list)) {
            setValue(list.find((el) => el.value === data)?.title ?? "");
            setCurrentValue(list.find((el) => el.value === data)?.value ?? "");
        } else {
            setValue(placeHolder);
            setCurrentValue();
        }
    }, [data, list]);

    return (
        <div ref={parentRef} style={{ width: `${tag === true ? '30%' : '100%'}`, marginTop: `${tag === true ? '20px' : '10px'}`, overflow: "hidden", ...style }} className="display-col">
            <div
                style={{
                    fontSize: 12,
                    lineHeight: "14px",
                    color: disable ? "rgba(255, 255, 255, 0.6)" : "white",
                    fontFamily: "SFProTextSemiBold",
                    display: `${tag ? 'none' : 'flex'}`,
                }}
            >
                {title}
                <StatusBadge required={require} disabled={disable} loading={loading} />
            </div>
            <div
                className="display-center-row"
                style={{
                    marginTop: 4,
                    border: "1px solid #858C94",
                    borderRadius: 8,
                    height: 31,
                    cursor: disable || isEmpty(list) ? "default" : "pointer",
                    overflow: "hidden",
                }}
                onClick={(e) => {
                    if (!(disable || isEmpty(list))) setAnchorEl(e.currentTarget);
                }}
            >
                <div className="display-center-row" style={{ flex: 1, padding: "3px 14px 4px", overflow: "hidden" }}>
                    <div
                        style={{
                            fontSize: 14,
                            lineHeight: "24px",
                            fontFamily: "SFProDisplay",
                            opacity: `${isEmpty(data) ? 0.6 : 1}`,
                            color: disable ? "rgba(255, 255, 255, 0.6)" : "white",
                        }}
                        className="text-ellipsis"
                    >
                        {value}
                    </div>
                </div>
                {!(disable || isEmpty(list)) && (
                    <img src={IconDown} width={16} height={16} style={{ margin: "5px 9px 2px 0px" }} alt="" />
                )}
            </div>
            <div style={{ marginTop: 2, fontSize: 10, lineHeight: "20px", height: 20, fontFamily: "SourceSansPro", display: `${tag ? 'none' : 'flex'}`}}>
                {showComment
                    ? "Keep it short and pithy. It’s a quick way to tell consumers about your feed item. Max 255 char."
                    : ""}
            </div>
            <Popover
                open={!_.isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box
                    boxShadow={3}
                    style={{ width: parentRef?.current?.offsetWidth * 0.95, maxHeight: 200, overflowY: "auto" }}
                    className={appStyles.scrollbar}
                >
                    {list.map((item, index) => (
                        <div
                            key={index}
                            className="display-center-row"
                            onClick={() => {
                                setAnchorEl();
                                setValue(item.title);
                                setCurrentValue(item.value);
                                if(tag === true) {
                                    onItemChange(item)
                                } else {
                                    onItemChange(item.value);
                                }
                            }}
                            style={{
                                height: 30,
                                background: "#181818",
                                color: "#ADAFB2",
                                cursor: "pointer",
                            }}
                        >
                            <div style={{ width: 30 }} className="display-center">
                                {currentValue === item.value && (
                                    <CheckIcon style={{ width: 24, heigh: 24, cursor: "pointer" }} />
                                )}
                            </div>
                            <span style={{ flex: 1, paddingRight: 30 }} className="text-ellipsis">
                                {item.title}
                            </span>
                        </div>
                    ))}
                </Box>
            </Popover>
        </div>
    );
};

SelectWithTitle.defaultProps = {
    list: [],
    placeHolder: "",
    style: {},
    showComment: true,
    onItemChange: () => {},
    isAlignParent: false,
    disable: false,
};

export default SelectWithTitle;
