import React, { useState } from "react";
import DragIcon from "../assets/icons/reorder_rounded.svg";
import detailViewStyle from "../styles/DetailViewStyle";
import MoreIcon from "../assets/icons/more.svg";
import clsx from "clsx";
import { Popover } from "@material-ui/core";
import { isEmpty, unescape } from "lodash";
import moment from "moment-timezone";
import { useHistory, useLocation } from "react-router-dom";
import PopupMenu from "./PopupMenu";
import { useDispatch } from "react-redux";
import { formatTime, copyFeedEventItem, getImageUrl } from "../utils/Utils";
import { getEventRsvpLink } from "../redux/reducers/EventReducer";
import copy from "copy-to-clipboard";
import { useDrag, DragPreviewImage } from "react-dnd";

function ItemDetail(props) {
    const { item, itemFeed, onClickDeleteFeed } = props;
    const classes = detailViewStyle();
    const [isOver, setOver] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const listMenu = ["Delete", "Duplicate", "Preview", "Copy JSON", "Create RSVP Link"];
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [{ isDragging }, dragSourceRef, preview] = useDrag({
        item: { type: "item-assign", data: props.item },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                // setDropped(true);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: (item, monitor) => {
            return true;
        },
    });

    return (
        <div
            ref={preview}
            style={{
                height: 92,
                position: "relative",
                background: `${isOver ? "rgba(0, 0, 0, 0.3)" : ""}`,
                borderRadius: `${isOver ? "10px" : 0}`,
                cursor: "pointer",
                borderBottom: "1px solid rgba(223, 224, 235, 0.1)",
            }}
            className="display-row container-table-detail"
            onMouseEnter={(e) => {
                setOver(true);
            }}
            onMouseLeave={(e) => {
                setAnchorEl();
                setOver(false);
            }}
            onClick={() => {
                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                    history.replace(
                        `/feed/${itemFeed.provider}:${itemFeed.type}:${encodeURIComponent(
                            itemFeed.uid
                        )}/edit/${encodeURIComponent(item.uid)}`
                    );
                } else {
                    history.push(
                        `/feed/${itemFeed.provider}:${itemFeed.type}:${encodeURIComponent(
                            itemFeed.uid
                        )}/edit/${encodeURIComponent(item.uid)}`
                    );
                }
            }}
        >
            {isOver && (
                <div className={clsx("display-row", classes.containerOver)}>
                    <div
                        ref={dragSourceRef}
                        style={{ width: 24, height: 24, margin: "34px 0px 33px 12px", cursor: "move" }}
                    >
                        <img alt="" src={DragIcon} />
                    </div>
                    <div style={{ flex: 1 }} />
                    <div
                        style={{
                            width: 30,
                            height: 30,
                            margin: "34px 33px 31px 0",
                            marginRight: item.isSubscribable === undefined && 19,
                            cursor: "pointer",
                        }}
                        className="display-center"
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        <img src={MoreIcon} alt="" />
                    </div>
                </div>
            )}
            <div className="display-row col-detail">
                <img alt="" src={getImageUrl(item.flair)} className={clsx(classes.imageItem, "imageItemHid")} />
                <div style={{ marginTop: 16, width: "calc(100% - 170px)" }} className={"data-col-detail"}>
                    <div className={clsx(classes.txtDetails, "text-ellipsis")}>{unescape(item.title)}</div>
                    <div style={{ margin: "4px 0" }} className={classes.txtSubDetails}>
                        {moment.duration(moment(item.created).diff(moment())).humanize(true)}
                    </div>
                    <div className={clsx(classes.txtSubDetails, "text-ellipsis")}>{`uid: ${item.uid}`}</div>
                </div>
            </div>
            <div className="col-location">
                <div className={clsx(classes.txtItemLocation, "col-txt-location")} style={{ maxWidth: 300 }}>
                    {item.location}
                </div>
                {/* <div className={classes.txtSubItemLocation}>{item.location}</div> */}
            </div>
            <div className="display-row col-time">
                <div>
                    <div className={clsx(classes.txtItemLocation, "col-txt-location")}>
                        {formatTime(item.allDay, item.time.start, item.time.timeZone, "MMM DD, YYYY")}
                    </div>
                    {!item.allDay &&
                        <div className={classes.txtSubItemLocation}>
                            {`${formatTime(item.allDay, item.time.start, item.time.timeZone, "h:mm A z")} ${
                                !isEmpty(item.time.timeZone) ? `(GMT${moment.tz(item.time.timeZone).format("Z")})` : "GMT"
                            }`}
                        </div>
                    }
                </div>
            </div>
            <div className="col-dots">
                <Popover
                    open={!isEmpty(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={(e) => {
                        e.stopPropagation();
                        setAnchorEl();
                        setOver(false);
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                >
                    <PopupMenu
                        listMenu={listMenu}
                        onClick={(itemMenu) => {
                            setAnchorEl();
                            switch (itemMenu) {
                                case "Delete":
                                    onClickDeleteFeed(item);
                                    break;
                                case "Duplicate":
                                    if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                        history.replace(
                                            `/feed/${itemFeed.provider}:${itemFeed.type}:${encodeURIComponent(
                                                itemFeed.uid
                                            )}/duplicate/${encodeURIComponent(item.uid)}`
                                        );
                                    } else {
                                        history.push(
                                            `/feed/${itemFeed.provider}:${itemFeed.type}:${encodeURIComponent(
                                                itemFeed.uid
                                            )}/duplicate/${encodeURIComponent(item.uid)}`
                                        );
                                    }
                                    break;
                                case "Preview":
                                    if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                        history.replace(
                                            `/feed/${itemFeed.provider}:${itemFeed.type}:${encodeURIComponent(
                                                itemFeed.uid
                                            )}/edit/${encodeURIComponent(item.uid)}`
                                        );
                                    } else {
                                        history.push(
                                            `/feed/${itemFeed.provider}:${itemFeed.type}:${encodeURIComponent(
                                                itemFeed.uid
                                            )}/edit/${encodeURIComponent(item.uid)}`
                                        );
                                    }
                                    break;
                                case "Copy JSON":
                                    copy(`${JSON.stringify(copyFeedEventItem({ ...itemFeed, event: item }))}`);
                                    break;
                                case "Create RSVP Link":
                                    dispatch(
                                        getEventRsvpLink({
                                            feedId: `${itemFeed.provider}:${itemFeed.type}:${itemFeed.uid}`,
                                            eventId: item.uid
                                        })
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                </Popover>
            </div>
        </div>
    );
}

export default ItemDetail;
