import React, { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import "../styles/json_input_overrides.css";

function JsonComponent(props) {
    const { data, onChange, isAssignEvent } = props;
    const [dataShow, setDataShow] = useState();

    useEffect(() => {
        setDataShow(data);
    }, [data]);

    return (
        <JSONInput
            id="a_unique_id"
            placeholder={dataShow}
            locale={locale}
            colors={{
                string: "#DAA520",
            }}
            confirmGood={false}
            onChange={(value) => {
                // console.log(value.json);
                if (value.jsObject !== undefined) {
                    if (isAssignEvent) {
                        setDataShow({
                            event: JSON.parse(value.json).event,
                            publishEndTime: JSON.parse(value.json).publishEndTime,
                            publishStartTime: JSON.parse(value.json).publishStartTime,
                        });
                        onChange({
                            event: JSON.parse(value.json).event,
                            publishEndTime: JSON.parse(value.json).publishEndTime,
                            publishStartTime: JSON.parse(value.json).publishStartTime,
                        });
                    } else {
                        onChange(JSON.parse(value.json));
                    }
                }
            }}
            // onBlur={(value) => {
            //     if (value.jsObject !== undefined) {
            //         onChange(JSON.parse(value.json));
            //     }
            // }}
        />
    );
}

export default JsonComponent;
