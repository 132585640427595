import React, { Component, Fragment } from "react";
import { SchemaElementType, schemaStyles, SchemaComponent } from "./Schema";
import SchemaKeyValue from "./SchemaKeyValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

export default class SchemaObject extends Component {
    openCharacter = "{";
    closeCharacter = "}";

    constructor(props) {
        super(props);
        let collapsable = props.collapsable ?? true;
        this.state = {
            schema: props.schema,
            elements: this.compileSchema(props.schema, props.initialValue || {}),
            values: props.initialValue || {},
            expanded: true,
            collapsable: collapsable,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            JSON.stringify(this.props.schema) !== JSON.stringify(prevState.schema) ||
            JSON.stringify(this.props.initialValue) !== JSON.stringify(prevProps.initialValue)
        ) {
            this.setState({
                schema: this.props.schema,
                // values: (JSON.stringify(this.props.initialValue) !== JSON.stringify(prevProps.initialValue))
                //   ? this.props.initialValue
                //   : {},
                elements: this.compileSchema(this.props.schema, this.props.initialValue || {}),
            });
        }
        this.printSchema();
    }

    printSchema = () => {
        const { values } = this.state;
        this.props.onValueChange(values);
    };

    onSchemaValueChange = (key) => async (value) => {
        // console.log('newSchemaObjetValue: ', value)
        this.setState((state) => {
            const newValues = { ...state.values, [key]: value };
            return { values: { ...newValues } };
        });
        // const newValues = { ...this.state.values, [key]: value };
        // await this.setState({ values: {...newValues} });
    };

    compileSchema = (schema, initialValue) => {
        const elements = {};
        Object.keys(schema).forEach((key) => {
            const value = schema[key];
            const initVal = initialValue[key];
            elements[key] = this.compileElement(key, value, initVal);
        });
        this.setState((state) => {
            const { values } = state;
            const newValues = {};
            Object.keys(schema).forEach((key) => {
                newValues[key] = values[key];
            });
            return { values: newValues };
        });
        return elements;
    };

    compileElement = (key, schemaEntry, initialValue) => {
        const { type, visibleName, keyvalue, object, array, required } = schemaEntry;
        if (type === SchemaElementType.KEY_VALUE) {
            return (
                <SchemaKeyValue
                    key={visibleName}
                    visibleName={visibleName}
                    valueType={keyvalue.valueType}
                    enumEntries={keyvalue.enumEntries}
                    initialValue={initialValue}
                    required={required}
                    onValueChange={this.onSchemaValueChange(key)}
                />
            );
        }
        if (type === SchemaElementType.OBJECT) {
            return (
                <SchemaObject
                    key={visibleName}
                    visibleName={visibleName}
                    schema={object}
                    initialValue={initialValue}
                    onValueChange={this.onSchemaValueChange(key)}
                />
            );
        }
        if (type === SchemaElementType.ARRAY) {
            return (
                <SchemaComponent
                    key={visibleName}
                    schemaComponent="SchemaArray"
                    visibleName={visibleName}
                    schema={array}
                    initialValue={initialValue}
                    onValueChange={this.onSchemaValueChange(key)}
                />
            );
        }
        if (type === SchemaElementType.DYNAMIC_LENGTH) {
            return (
                <SchemaComponent
                    key={visibleName}
                    schemaComponent="SchemaArrayDynamicLength"
                    visibleName={visibleName}
                    schema={schemaEntry}
                    initialValue={initialValue}
                    required={required}
                    onValueChange={this.onSchemaValueChange(key)}
                />
            );
        }
        if (type === SchemaElementType.UNION) {
            return (
                <SchemaComponent
                    key={visibleName}
                    schemaComponent="SchemaUnion"
                    visibleName={visibleName}
                    schema={schemaEntry}
                    initialValue={initialValue}
                    onValueChange={this.onSchemaValueChange(key)}
                />
            );
        }
    };

    openLine = () => {
        const { visibleName } = this.props;
        const bracket = this.state.expanded
            ? `${this.openCharacter}`
            : `${this.openCharacter} ... ${this.closeCharacter}`;
        if (visibleName) {
            return `${visibleName}: ${bracket}`;
        }
        return bracket;
    };

    onClickCaret = () => {
        this.setState((state) => ({ expanded: !state.expanded }));
    };

    elementsToRender = () => {
        const elements = [];
        Object.keys(this.state.elements).forEach((key) => {
            elements.push(this.state.elements[key]);
        });
        return elements;
    };

    render() {
        return (
            <Fragment>
                <div style={{ ...schemaStyles.topMargin }}>
                    {this.state.collapsable &&
                    <div>
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faPlay}
                            size="xs"
                            rotation={this.state.expanded ? 270 : 90}
                            onClick={this.onClickCaret}
                        />
                        <span
                          style={{ ...schemaStyles.schemaLine, paddingLeft: this.state.collapsable ? "1em" : "" }}
                        >{this.openLine()}</span>
                    </div>
                    }
                </div>
                <div id="schema-obj" style={{ paddingLeft: this.state.collapsable ? "20px" : "", display: this.state.expanded ? "block" : "none" }}>
                    {this.elementsToRender()}
                </div>
                {this.state.collapsable &&
                <p style={{ ...schemaStyles.schemaLine, display: this.state.expanded ? "block" : "none" }}>
                    {this.closeCharacter}
                </p>
                }
            </Fragment>
        );
    }
}
