import SchemaObject from "./SchemaObject";

export default class SchemaArray extends SchemaObject {
  openCharacter = '[';
  closeCharacter = ']';

  onSchemaValueChange = (key, value) => {
    this.setState((state) => {
      const newState = { values: { ...state.values, [key]: value } };
      const arr = [];
      Object.keys(newState.values).forEach(key => {
        arr.push(newState.values[key])
      });
      this.props.onValueChange(arr);
      return newState;
    });
  }

  compileSchema = (schema, initialValue) => {
    const elements = [];
    schema.forEach((value, i) => {
      elements.push(this.compileElement(i, value, initialValue[i]));
    });
    return elements;
  }
}