import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import createFormStyle from "../styles/CreateFormStyle";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import InputWithTitle from "../components/InputWithTitle";
import SummerNote from "../components/SummerNote";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ButtonBottom from "../components/ButtonBottom";
import SectionHeader from "../components/SectionHeader";
import ImagePicker from "../components/ImagePicker";
import JsonComponent from "../components/JsonComponent";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { getTags } from "../redux/reducers/TagReducer";
import { createItemTag, deleteItemTag, updateItemTag } from "../redux/actions";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import SearchSelectTitle from "../components/SearchSelectTitle";
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from "@material-ui/core";

function CreateTagView() {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const { isError, messageError, listTag, onClickDelete } = useSelector((state) => ({
        isError: state.tag.isError,
        messageError: state.tag.messageError,
        listTag: state.tag.data,
        onClickDelete: state.app.onClickDelete,
    }));
    const [id, setId] = useState(null);
    const [name, setName] = useState(null);
    const [subText, setSubText] = useState(null);
    const [imageRes, setImageRes] = useState(null);
    const [iconRes, setIconRes] = useState(null);
    const [description, setDescription] = useState(null);
    const [itemTag, setItemTag] = useState();
    const [actionType, setActionType] = useState("create");
    const [atDefault, setAtDefault] = useState(true);
    const [errorMessSchema, setErrorMessSchema] = useState("");
    const schemaRef = useRef();

    const tagSchema = Yup.object()
        .shape({
            uid: Yup.string().nullable(),
            name: Yup.string().nullable(),
            longFormText: Yup.string().nullable(),
            icon: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            image: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            subtext: Yup.string().nullable(),
        })
        .noUnknown()
        .strict(true);

    const editTagSchema = Yup.object()
        .shape({
            name: Yup.string().nullable(),
            longFormText: Yup.string().nullable(),
            icon: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            image: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            subtext: Yup.string().nullable(),
        })
        .noUnknown()
        .strict(true);

    useEffect(() => {
        setName(itemTag?.name ?? null);
        setId(itemTag?.uid ?? null);
        setDescription(itemTag?.longFormText ?? null);
        setIconRes(itemTag?.icon ?? null);
        setImageRes(itemTag?.image ?? null);
        setSubText(itemTag?.subtext ?? null);
    }, [itemTag]);

    function createTag() {
        var data = { name: name, longFormText: description, icon: iconRes, image: imageRes, subtext: subText };
       
        if (actionType === "create") {
            dispatch(
                createItemTag({
                    id: uuidv4(),
                    data,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
        } else {
            dispatch(
                updateItemTag({
                    id,
                    db: itemTag.dataSource,
                    data,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
        }
    }

    useEffect(() => {
        if (onClickDelete) {
            dispatch(
                deleteItemTag({
                    payload: itemTag,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    const renderSearchTitle = useMemo(()=>{
        return (
            <SearchSelectTitle
                data={id}
                list={listTag.map((item) => ({ title: item.name, value: item.uid }))}
                title="Name"
                require
                placeHolder="Select a Tag"
                onItemChange={(value) => {
                    setItemTag(listTag.find((el) => el.uid === value));
                    setName(value)
                }}
            />
        )
    }, [id, listTag])

    const handleSchema = useCallback(
        (itemTag) => {
            var tempSchema = actionType === "create" ? tagSchema : editTagSchema;
            tempSchema
                .validate(schemaRef.current)
                .then(function (value) {
                    // console.log(value);
                    if (!isEmpty(value)) {
                        setErrorMessSchema();
                        setItemTag({ ...itemTag, ...value });
                    }
                })
                .catch((e) => {
                    // console.log(e);
                    setAtDefault(false);
                    setErrorMessSchema(e.message);
                });
        },
        [schemaRef.current]
    );

    return (
        <div className="display-col">
            <div className={classes.titleHeader}>{`${actionType === "create" ? "Create" : "Edit"} > Tag`}</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        title={`${actionType === "create" ? "NEW TAG" : "EDIT TAG"}`}
                        txtAction={`${actionType === "create" ? "Edit an existing Tag" : "Create New Tag"}`}
                        onClickClose={() => history.goBack()}
                        onClickJson={() => setAtDefault(false)}
                        showTextEdit={true}
                        onClickEdit={() => {
                            if (actionType === "create") {
                                setActionType("edit");
                                if (isEmpty(listTag)) {
                                    dispatch(getTags());
                                }
                            } else {
                                setItemTag();
                                setActionType("create");
                            }
                        }}
                        isDefault={atDefault}
                        onClickDefault={() => {
                            if (isEmpty(errorMessSchema)) {
                                schemaRef.current = undefined;
                                setAtDefault(true);
                            }
                        }}
                        type="tag"
                    />
                    <div className={classes.containerDivider} />
                    {atDefault ? (
                        <div className={clsx(classes.containerForm, appStyles.scrollbar)}>
                            {actionType === "create" && (
                                <div>
                                    {/* <InputWithTitle
                                        data={id}
                                        title="Unique ID"
                                        require
                                        placeHolder="Custom Tag ID"
                                        style={{ marginTop: 25 }}
                                        onChange={(value) => {
                                            setId(value);
                                        }}
                                    /> */}
                                    <InputWithTitle
                                        data={name}
                                        title="Name"
                                        require
                                        placeHolder="Tag name"
                                        onChange={(value) => {
                                            setName(value);
                                        }}
                                    />
                                </div>
                            )}

                            {actionType === "edit" && (
                                renderSearchTitle
                            )}

                            <InputWithTitle
                                data={subText}
                                title="Subtext"
                                placeHolder="Subtext"
                                onChange={(value) => {
                                    setSubText(value);
                                }}
                            />

                            <ImagePicker
                                data={iconRes}
                                title="Icon"
                                placeHolder="Icon URL"
                                imageSource={(value) => setIconRes(value)}
                            />

                            <ImagePicker
                                data={imageRes}
                                title="Image"
                                placeHolder="Image URL"
                                imageSource={(value) => setImageRes(value)}
                            />

                            <SummerNote
                                data={description}
                                title="Description"
                                placeholder="Tag description"
                                onChange={(content) => setDescription(content)}
                            />
                        </div>
                    ) : (
                        <div className={classes.containerJsonForm}>
                            <div className={classes.containerJsonError}>
                                {!isEmpty(errorMessSchema) && (
                                    <span className={classes.textError}>{errorMessSchema}</span>
                                )}
                            </div>
                            <JsonComponent
                                data={tagSchema.cast({
                                    uid: actionType === "create" ? id : undefined,
                                    name: name,
                                    longFormText: description,
                                    icon: {
                                        path: iconRes?.path ?? null,
                                        mediaType: iconRes?.mediaType ?? null,
                                    },
                                    image: {
                                        path: imageRes?.path ?? null,
                                        mediaType: imageRes?.mediaType ?? null,
                                    },
                                    subtext: subText ?? null,
                                })}
                                onChange={(value) => {
                                    schemaRef.current = value;
                                    handleSchema(itemTag);
                                    // setItemTag({ ...value, uid: itemTag.uid });
                                }}
                            />
                        </div>
                    )}
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {isError && <span className={classes.textError}>{messageError}</span>}
                    </div>
                    <ButtonBottom
                        showDelete={actionType === "edit" && !isEmpty(itemTag)}
                        onClickDelete={() =>
                            dispatch(
                                openMessageDialog({
                                    open: true,
                                    message: "Are you sure you want to delete this tag?",
                                    title: "DELETE TAG",
                                })
                            )
                        }
                        onClickCancel={() => setActionType("create")}
                        txtCancel="Duplicate"
                        showDraft={actionType === "edit" && !isEmpty(itemTag)}
                        txtOk="SAVE"
                        onClickOk={() => {
                            if (isEmpty(errorMessSchema)) {
                                createTag();
                            }
                        }}
                    />
                </div>
                <div className="display-center-col designPreview" style={{ flex: 1, padding: "0 20px" }}>
                    <div style={{ width: 300, height: 500, background: "#282828" }}></div>
                </div>
            </div>
        </div>
    );
}

export default CreateTagView;
