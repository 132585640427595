import { InputBase } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import CautionIcon from "../assets/icons/caution.svg";
import CheckIcon from "../assets/icons/check_icon_green.svg";
import DeleteIcon from "../assets/icons/delete_icon_red.svg";
import DeleteImage from "../assets/icons/delete_image.svg";
import imagePickerStyle from "../styles/ImagePickerStyles";
import SelectWithTitle from './SelectWithTitle'

function ImagePicker(props) {
    const {
        title,
        data,
        placeHolder,
        style,
        showComment,
        onChange,
        comment,
        imageSource,
        aspectRatio,
        customProxy,
        checkRatio,
        formatList,
    } = props;
    const [imageURL, setImageURL] = useState("");
    const [mediaType, setMediaType] = useState("");
    const [imageShow, setImageShow] = useState();
    const classes = imagePickerStyle();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        // if (!isEmpty(data?.path)) {
        //     setImageShow(data);
        // } else {
        //     setImageShow();
        // }
        if (data) {
            setImageURL(data.path);
            setMediaType(data.mediaType);
        } else {
            setImageURL("");
            setMediaType("png");
        }
    }, [data]);

    function gcd(u, v) {
        if (u === v) return u;
        if (u === 0) return v;
        if (v === 0) return u;

        if (~u & 1)
            if (v & 1) return gcd(u >> 1, v);
            else return gcd(u >> 1, v >> 1) << 1;

        if (~v & 1) return gcd(u, v >> 1);

        if (u > v) return gcd((u - v) >> 1, v);

        return gcd((v - u) >> 1, u);
    }

    function ratio(w, h) {
        var d = gcd(w, h);
        return `${w / d}:${h / d}`;
    }

    function getImageBlob(imageUrl) {
        fetch(`${customProxy}${imageUrl}`, {
            credentials: "omit",
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (formatList.includes(blob.type.split("/")[1])) {
                    setImageShow({ path: imageURL, mediaType: blob.type.split("/")[1] });
                    imageSource({ path: imageURL, mediaType: blob.type.split("/")[1] });
                    setErrorMessage("");
                    setImageURL("");
                } else {
                    setErrorMessage("Image file types .png, .svg, .heic, .jpeg and .jpg are supported. Please retry");
                }
            })
            .catch((err) => {
                setErrorMessage("Could not resolve URL");
            });
    }

    function getBase64FromImageUrl(url) {
        // if (!validateUrl(url)) {
        //     setIsValidRatio(false);
        //     return;
        // }

        var img = new Image();

        img.onload = function () {
            if (checkRatio) {
                const currentRatio = ratio(this.width, this.height);
                const isValidRatio = validateImageRatio(currentRatio, aspectRatio);
                if (isValidRatio) {
                    getImageBlob(imageURL);
                } else {
                    setErrorMessage("Image aspect ratio should be: 1x1. 3x2. 4x3. 5x9 or 16x9. Please retry");
                }
            } else {
                getImageBlob(imageURL);
            }
        };

        img.src = url;
    }

    function isImage(imageUrl) {
        return new Promise((resolve, reject) => {
            if (!validateUrl(imageUrl)) {
                resolve(false);
            }

            let img = document.createElement("img");

            img.onload = function () {
                if (this.naturalHeight > 0 || this.naturalWidth > 0) resolve(true);
                else resolve(false);
            };

            img.onerror = function (e) {
                resolve(false);
            };

            img.src = imageUrl;
        });
    }

    function validateUrl(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            value
        );
    }

    function validateImageRatio(ratio, aspectRatio) {
        return aspectRatio.includes(ratio);
    }

    function validateImageUrl() {
        if (!validateUrl(imageURL)) {
            setErrorMessage("Invalid URL");
        } else {
            imageSource({ path: imageURL, mediaType: mediaType });
            setErrorMessage("");
        }
        // isImage(imageURL).then((value) => {
        //     if (value) {
        //         getBase64FromImageUrl(imageURL);
        //     } else {
        //         setErrorMessage("Invalid URL");
        //     }
        // });
    }

    return (
        <div>
            <div className="display-row" style={{ width: "100%", ...style }}>
                <div style={{ width: "80%", marginTop: 10 }} className="display-col">
                    <div className={classes.txtTitle}>{title}</div>
                    <div
                        className="display-center-row"
                        style={{
                            flex: 1,
                            marginTop: 4,
                            border: "1px solid #858C94",
                            borderRadius: 8,
                            height: 31,
                            cursor: "pointer",
                        }}
                    >
                        <div style={{ padding: "3px 14px 4px", flex: 1 }}>
                            <InputBase
                                name="image"
                                placeholder={placeHolder}
                                disabled={!isEmpty(imageShow)}
                                style={{
                                    width: "100%",
                                    fontSize: 14,
                                    // lineHeight: "24px",
                                    fontFamily: "SFProDisplay",
                                    height: "100%",
                                }}
                                onChange={(e) => {
                                    setErrorMessage();
                                    setImageURL(e.currentTarget.value);
                                }}
                                onBlur={validateImageUrl}
                                onKeyPress={(ev) => {
                                    if (ev.key === "Enter") {
                                        validateImageUrl();
                                    }
                                }}
                                value={imageURL}
                            />
                        </div>
                        {!isEmpty(imageURL) && (
                            <div className="display-center-row">
                                {isEmpty(errorMessage) && (
                                    <img
                                        src={CheckIcon}
                                        alt=""
                                        style={{ marginRight: 10, cursor: "pointer" }}
                                        onClick={validateImageUrl}
                                    />
                                )}
                                <img
                                    src={DeleteIcon}
                                    alt=""
                                    style={{ marginRight: 13, cursor: "pointer" }}
                                    onClick={() => {
                                        setImageURL("");
                                        setErrorMessage("");
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="display-center-row" style={{ marginTop: 2 }}>
                        {!isEmpty(errorMessage) && <img src={CautionIcon} alt="" style={{ marginRight: 3 }} />}
                        <div
                            style={{
                                color: !isEmpty(errorMessage) && "#FF6961",
                                fontSize: 10,
                                lineHeight: "20px",
                                height: "20px",
                                fontFamily: "SourceSansPro",
                            }}
                        >
                            {!isEmpty(errorMessage) ? `${errorMessage}` : comment}
                        </div>
                    </div>
                </div>
                <div style={{ width: "20%" }} className="display-col">
                    <SelectWithTitle
                        list={["PNG", "JPG", "GIF", "WEBP", "MP4", "Youtube"].map((item) => ({
                            title: item,
                            value: item.toLowerCase(),
                        }))}
                        onItemChange={value => {
                            setMediaType(value);
                            imageSource({path: imageURL, mediaType: value});
                        }}
                        showComment={false}
                        data={mediaType}
                        title="Media type"
                    />
                </div>
            </div>
            {!isEmpty(imageShow) && (
                <div
                    style={{
                        width: 53,
                        height: 62,
                        position: "relative",
                        display: "flex",
                        alignItems: "flex-end",
                        marginRight: 13,
                    }}
                >
                    <img
                        src={DeleteImage}
                        alt=""
                        style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}
                        onClick={() => {
                            setImageShow();
                            imageSource({ path: null, mediaType: null });
                        }}
                    />
                    <img src={imageShow.path} alt="" style={{ width: 44, height: 54, objectFit: "cover" }} />
                </div>
            )}
        </div>
    );
}

ImagePicker.defaultProps = {
    // title: "Image",
    placeHolder: "Image URL",
    style: {},
    showComment: true,
    comment: "Keep it short and pithy. It’s a quick way to tell consumers about your feed item. Max 255 char.",
    onChange: () => {},
    imageSource: () => {},
    customProxy: "https://cors-anywhere.herokuapp.com/",
    checkRatio: true,
    aspectRatio: ["16:9", "4:3", "1:1", "3:2", "5:9"],
    formatList: ["png", "jpg", "jpeg", "heic", "svg"],
};

export default ImagePicker;
