import React from "react";
import SearchSelectTitle from "./SearchSelectTitle";
import { getListTimezone } from "../utils/Utils";

const TimeZonePicker = ({ timeZone, onTimeZoneChange }) => {
    return (
      <SearchSelectTitle
        data={timeZone}
        list={getListTimezone()}
        title="Time Zone"
        showComment={false}
        placeHolder="Select a Time zone"
        style={{ marginTop: 32 }}
        onItemChange={onTimeZoneChange}
      />
    );
};

TimeZonePicker.defaultProps = {
    onTimeZoneChange: () => {}
};

export default TimeZonePicker;
