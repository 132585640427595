import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ScreenLoading from "react-fullpage-custom-loader";
import moment from "moment-timezone";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import clsx from "clsx";
import en from "react-json-editor-ajrm/locale/en";
import { disableBodyScroll } from "body-scroll-lock";

import createFormStyle from "../styles/CreateFormStyle";
import appStyle from "../styles/AppStyle";
import SelectWithTitle from "../components/SelectWithTitle";
import InputWithTitle from "../components/InputWithTitle";
import TagWithTitle from "../components/TagWithTitle";
import SummerNote from "../components/SummerNote";
import SelectDate from "../components/SelectDate";
import TimeZonePicker from "../components/TimeZonePicker";
import CustomSelectTimeZone from "../components/CustomSelectTimezone";
import { getSchema, getSchemaByName } from "../redux/reducers/SchemaReducer";
import { createEventFail, createEventUnassignFail } from "../redux/reducers/EventReducer";
import { getCategory } from "../redux/reducers/CategoryReducer";
import { getSubCategory } from "../redux/reducers/SubCategoryReducer";
import { getTags } from "../redux/reducers/TagReducer";
import gqlASTParser, {
    AWS_SCALARS,
    compileSchemaComponentsFromParsedAST,
    MEDIA_RESOURCE_TYPE,
} from "../utils/gqlASTParser";
import SchemaObject from "../components/Feed/schema/SchemaObject";
import SchemaUnion from "../components/Feed/schema/SchemaUnion";
import ButtonBottom from "../components/ButtonBottom";
import SectionHeader from "../components/SectionHeader";
import { getPersonas } from "../redux/reducers/PersonaReducer";
import { getFeedsByCreator } from "../redux/reducers/AppReducer";
import { formatNaiveTime } from "../utils/Utils";
import {
    createItemFeedEvent,
    updateItemFeedEvent,
    deleteItemFeedEvent,
    getItemEventById,
    createItemEventUnassign,
    getItemEventUnassignById,
    deleteItemEventUnassign,
    updateItemEventUnassign,
    getFeedById,
} from "../redux/actions";
import JsonComponent from "../components/JsonComponent";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import ImagePicker from "../components/ImagePicker";

const UNASSIGNED_EVENT_COLLECTION_ID = '--UNASSIGNED-EVENT--'

function formatPublishStartTime(publishStartTime, startTime, tz) {
    let time = null;
    if (publishStartTime) {
        time = formatNaiveTime(publishStartTime, tz);
    } else if (startTime) {
        time = formatNaiveTime(moment(startTime, tz).subtract(7, "day"))
    }
    return time;
}

function formatPublishEndTime(publishEndTime, endTime, tz) {
    return formatNaiveTime(publishEndTime || endTime, tz);
}

function EventView() {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const {
        listCat,
        listSubCat,
        listTag,
        listSchema,
        schema,
        isError,
        messageError,
        listPersona,
        listAssign,
        onClickDelete,
    } = useSelector((state) => ({
        listCat: state.category.data,
        listSubCat: state.subCategory.data,
        listTag: state.tag.data,
        listSchema: state.schema.data,
        schema: state.schema.schema,
        isError: state.event.isError,
        messageError: state.event.messageError,
        listPersona: state.persona.data,
        listAssign: state.app.listFeed,
        onClickDelete: state.app.onClickDelete,
    }));
    const history = useHistory();
    const dispatch = useDispatch();
    const [itemFeed, setItemFeed] = useState(null);
    const typeAction = history.location.pathname.includes("create")
        ? "create"
        : history.location.pathname.includes("edit")
        ? "edit"
        : "duplicate";
    const params = useParams();
    const idFeed = params.id ? decodeURIComponent(params.id) : null;
    const idEvent = params.eventId;
    const fixedCollectionMode = !isEmpty(idFeed) && typeAction === "create";

    const [title, setTitle] = useState(null);
    const [creatorId, setCreatorId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);

    const [assignTo, setAssignTo] = useState(idFeed);
    const [loadingCollectionInfo, setLoadingCollectionInfo] = useState(!isEmpty(idFeed));
    const [initialLoadingDone, setInitialLoadingDone] = useState(false);

    const [description, setDescription] = useState(null);
    const [feedSchema, setFeedSchema] = useState(null);
    const [schemaData, setSchemaData] = useState(null);
    const [listSubCatId, setListSubCatId] = useState(null);
    const [listTagData, setListTagData] = useState(null);
    const [location, setLocation] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [publishStartTime, setPublishStartTime] = useState(null);
    const [publishEndTime, setPublishEndTime] = useState(null);
    const [timeZone, setTimeZone] = useState(null);
    const [isAllDay, setAllDay] = useState(false);
    const [showError, setShowError] = useState(false);
    const [imageRes, setImageRes] = useState(null);
    const [subCatShow, setSubCatShow] = useState([]);
    const [schemaValue, setSchemaValue] = useState(undefined);
    const [isChoosePublishStartDate, setChoosePublishStartDate] = useState();
    const [isChoosePublishEndDate, setChoosePublishEndDate] = useState();
    const [atDefault, setAtDefault] = useState(true);
    const [errorMessSchema, setErrorMessSchema] = useState("");
    const schemaRef = useRef();

    const eventSchema = Yup.object()
        .shape({
            categoryId: Yup.string().nullable(),
            subCategoriesId: Yup.array().nullable(),
            creatorId: Yup.string().nullable(),
            event: Yup.object({
                collectionId: Yup.string().nullable(),
                title: Yup.string().nullable(),
                notes: Yup.string().nullable(),
                feed_schema: Yup.string().nullable(),
                feed_data: Yup.object().nullable(),
                time: Yup.object({
                    timeZone: Yup.string().nullable(),
                    start: Yup.string().nullable(),
                    end: Yup.string().nullable(),
                }),
                allDay: Yup.bool().default(false),
                location: Yup.string().nullable(),
                flair: Yup.object({
                    path: Yup.string().nullable(),
                    mediaType: Yup.string().nullable(),
                }),
                tagIds: Yup.array().nullable(),
            }),
            publishStartTime: Yup.string().nullable(),
            publishEndTime: Yup.string().nullable(),
        })
        .noUnknown()
        .strict(true);

    const eventAssignSchema = Yup.object()
        .shape({
            event: Yup.object({
                collectionId: Yup.string().nullable(),
                title: Yup.string().nullable(),
                notes: Yup.string().nullable(),
                feed_schema: Yup.string().nullable(),
                feed_data: Yup.object().nullable(),
                time: Yup.object({
                    timeZone: Yup.string().nullable(),
                    start: Yup.string().nullable(),
                    end: Yup.string().nullable(),
                }),
                allDay: Yup.bool().default(false),
                location: Yup.string().nullable(),
                flair: Yup.object({
                    path: Yup.string().nullable(),
                    mediaType: Yup.string().nullable(),
                }),
                tagIds: Yup.array().nullable(),
            }),
            publishStartTime: Yup.string().nullable(),
            publishEndTime: Yup.string().nullable(),
        })
        .noUnknown()
        .strict(true);

    const scrollRef = useRef();
    useEffect(() => {
        disableBodyScroll(scrollRef.current);
    }, [scrollRef.current]);

    useEffect(() => {
        dispatch(getCategory());
        dispatch(getSubCategory());
        dispatch(getTags());
        dispatch(getSchema("event"));
        dispatch(getPersonas());
        dispatch(getFeedsByCreator());
    }, []);

    useEffect(() => {
        let localTz = moment.tz.guess();
        if (!isEmpty(idEvent)) {
            if (!isEmpty(idFeed)) {
                dispatch(
                    getItemEventById({
                        id: encodeURIComponent(idFeed),
                        eventId: idEvent,
                        onComplete: ({ error, cancelled, data }) => {
                            if (error || cancelled) return;
                            setItemFeed({event: data});
                        },
                    })
                );
            } else {
                    dispatch(
                    getItemEventUnassignById({
                        payload: idEvent,
                        onComplete: ({ error, cancelled, data }) => {
                            if (error || cancelled) return;
                            setItemFeed(data);
                        },
                    })
                );
            }
        } else {
            setItemFeed({
                event: {
                    collectionId: idFeed,
                    time: {
                        timeZone: localTz
                    }
                }
            });
        }
    }, [idFeed, idEvent]);

    useEffect(() => {
        let feedId = itemFeed?.event?.collectionId ?? null;

        setAssignTo(prevAssignTo => {
            if (!feedId) {
                setCreatorId(itemFeed?.creatorId ?? null);
                setCategoryId(itemFeed?.categoryId ?? null);
                setListSubCatId(itemFeed?.subCategoriesId ?? null);
            } else if (feedId !== prevAssignTo) {
                setCreatorId(null);
                setCategoryId(null);
                setListSubCatId(null);
            }
            return feedId;
        });
        setTitle(itemFeed?.event?.title ?? null);
        setDescription(itemFeed?.event?.notes ?? null);
        setSchemaData(itemFeed?.event?.feed_data ?? null);
        setFeedSchema(itemFeed?.event?.feed_schema ?? null);
        setLocation(itemFeed?.event?.location ?? null);
        setListTagData(itemFeed?.event?.tagIds);

        let tz = itemFeed?.event?.time?.timeZone || 'GMT';
        let _allDay = itemFeed?.event?.allDay ?? false;
        let _endTime = itemFeed?.event?.time?.end;
        if (_endTime) {
            if (tz) {
                _endTime = moment.tz(_endTime, tz);
            } else {
                _endTime = moment(_endTime);
            }
            if (_allDay) {
                // When displaying, subtract all-day event's end date by 1
                _endTime = _endTime.subtract(1, 'day');
            }
        }
        setTimeZone(tz);
        setStartTime(formatNaiveTime(itemFeed?.event?.time?.start, tz));
        setEndTime(formatNaiveTime(_endTime));
        if (typeAction !== "edit") {
            setPublishStartTime(
                formatPublishStartTime(
                    itemFeed?.publishStartTime,
                    itemFeed?.event?.time?.start,
                    tz
                )
            );
            setPublishEndTime(
                formatPublishEndTime(
                    itemFeed?.publishEndTime,
                    itemFeed?.event?.time?.end,
                    tz
                )
            );
        } else {
            setPublishStartTime(formatNaiveTime(itemFeed?.publishStartTime, tz));
            setPublishEndTime(formatNaiveTime(itemFeed?.publishEndTime , tz));
        }
        if (!isEmpty(itemFeed?.event?.collectionId)) {
            setPublishStartTime(null);
            setPublishEndTime(null);
        }
        setAllDay(_allDay);
        if (!isEmpty(itemFeed?.event?.flair)) {
            setImageRes({
                path: !isEmpty(itemFeed.event.flair.thumbnail)
                    ? itemFeed.event.flair.thumbnail
                    : itemFeed.event.flair.path,
                mediaType: itemFeed.event.flair.mediaType,
            });
        } else {
            setImageRes({ path: null, mediaType: null });
        }
    }, [itemFeed]);

    useEffect(() => {
        if (initialLoadingDone) return;
        if (!isEmpty(listPersona) && !isEmpty(listAssign) &&
          !isEmpty(listCat) && !isEmpty(listSubCat) &&
          !isEmpty(listTag) && !isEmpty(listSchema) &&
          !isEmpty(itemFeed) && !loadingCollectionInfo) {
            setInitialLoadingDone(true);
        }
    }, [initialLoadingDone, listPersona, listAssign, listCat, listSubCat,
        listTag, listSchema, itemFeed, loadingCollectionInfo])

    useEffect(() => {
        if (!isEmpty(schema)) {
            const parsedAST = gqlASTParser(
                schema.code.replace(/\\n/gm, "\n").replace(/\\t/gm, "\t") + AWS_SCALARS + MEDIA_RESOURCE_TYPE
            );
            const schemaCompile = compileSchemaComponentsFromParsedAST(schema.name, parsedAST);
            setSchemaValue(schemaCompile);
        }
    }, [schema]);

    useEffect(() => {
        if (!isEmpty(listSchema) && !isEmpty(feedSchema) && listSchema.some((el) => el === feedSchema)) {
            dispatch(getSchemaByName({ type: "event", name: feedSchema }));
        }
    }, [listSchema, feedSchema]);

    useEffect(() => {
        if (!isEmpty(listSubCat) && !isEmpty(categoryId)) {
            setSubCatShow(listSubCat.filter((el) => el.categoryId === categoryId));
        }
    }, [listSubCat, categoryId]);

    useEffect(() => {
        if (isError) {
            setShowError(true);
            dispatch(createEventFail({ isError: false, message: messageError }));
            dispatch(createEventUnassignFail({ isError: false, message: messageError }));
        }
    }, [isError]);

    useEffect(() => {
        if (onClickDelete) {
            if (!isEmpty(itemFeed.event.collectionId)) {
                dispatch(
                    deleteItemFeedEvent({
                        uid: idEvent,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.goBack();
                            }
                        },
                    })
                );
            } else {
                dispatch(
                    deleteItemEventUnassign({
                        uid: idEvent,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.goBack();
                            }
                        },
                    })
                );
            }
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    useEffect(() => {
        const fetchCollectionInfo = async () => {
            if (assignTo) {
                setLoadingCollectionInfo(true);
                dispatch(getFeedById({
                    // TODO: Move encoding to handleAPI layer
                    payload: encodeURIComponent(assignTo),
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        setCreatorId(data.creatorId);
                        setCategoryId(data.categoryId);
                        setListSubCatId(data.subCategoriesId);
                        setLoadingCollectionInfo(false);
                    },
                }))
            } else {
                setLoadingCollectionInfo(false);
            }
        };
        fetchCollectionInfo();
    }, [assignTo]);

    function createEventItem(draft) {
        let _start = startTime ? moment.tz(startTime, timeZone) : null;
        let _end = endTime ? moment.tz(endTime, timeZone) : null;
        let _publishStart = publishStartTime ? moment.tz(publishStartTime, timeZone) : null;
        let _publishEnd = publishEndTime ? moment.tz(publishEndTime, timeZone) : null;
        var data = {
            title: title,
            notes: description,
            creator: creatorId,
            collection_id: isEmpty(assignTo) ? null : assignTo,
            feed_schema: feedSchema,
            feed_data: schemaData,
            time_zone: timeZone,
            start_time: isEmpty(_start)
                ? null
                : isAllDay
                ? _start.format("YYYY-MM-DD")
                : _start.toISOString(),
            end_time: isEmpty(_end)
                ? null
                : isAllDay
                ? _end.add(1, 'day').format("YYYY-MM-DD")
                : _end.toISOString(),
            all_day: isAllDay,
            location: location,
            publish_start_time: _publishStart && _publishStart.toISOString(),
            publish_end_time: _publishEnd && _publishEnd.toISOString(),
            category: categoryId,
            subcategories: listSubCatId,
            tags: listTagData,
            status: draft ? "draft" : undefined,
            flair: isEmpty(imageRes.path) ? null : imageRes,
        };

        if (typeAction === "edit") {
            if (!isEmpty(assignTo)) {
                dispatch(
                    updateItemFeedEvent({
                        uid: idEvent,
                        data,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                history.goBack();
                            }
                        },
                    })
                );
            } else {
                dispatch(
                    updateItemEventUnassign({
                        uid: idEvent,
                        data,
                        isShowErrorDialog: false,
                        onComplete: ({ error, cancelled, data }) => {
                            if (data) {
                                if (draft) {
                                    history.push("/draft");
                                } else {
                                    history.goBack();
                                }
                            }
                        },
                    })
                );
            }
        } else {
            dispatch(
                createItemFeedEvent({
                    payload: data,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            if (draft) {
                                history.push("/draft");
                            } else {
                                history.goBack();
                            }
                        }
                    },
                })
            );
        }
    }

    function getItemSchema() {
        let _start = startTime ? moment.tz(startTime, timeZone) : null;
        let _end = endTime ? moment.tz(endTime, timeZone) : null;
        let _publishStart = publishStartTime ? moment.tz(publishStartTime, timeZone) : null;
        let _publishEnd = publishEndTime ? moment.tz(publishEndTime, timeZone) : null;

        if (isAllDay && _end) {
            // Add back 1 day to all-day event's end date when viewing in json
            //  and when submitting to server
            _end = _end.add(1, 'day');
        }

        return {
            categoryId: !isEmpty(assignTo) ? undefined : categoryId,
            subCategoriesId: !isEmpty(assignTo) ? undefined : listSubCatId,
            creatorId: !isEmpty(assignTo) ? undefined : creatorId,
            publishStartTime: !isEmpty(assignTo) ? undefined : (_publishStart && _publishStart.toISOString()),
            publishEndTime: !isEmpty(assignTo) ? undefined : (_publishEnd && _publishEnd.toISOString()),
            event: {
                collectionId: assignTo,
                title: title,
                notes: description,
                feed_schema: feedSchema,
                feed_data: schemaData,
                time: {
                    timeZone: timeZone,
                    start: _start && _start.toISOString(),
                    end: _end && _end.toISOString(),
                },
                allDay: isAllDay,
                location: location,
                flair: {
                    path: imageRes.path,
                    mediaType: imageRes.mediaType,
                },
                tagIds: listTagData,
            },
        };
    }

    const handleSchema = useCallback(() => {
        var tempSchema = fixedCollectionMode ? eventAssignSchema : eventSchema;
        tempSchema
            .validate(schemaRef.current)
            .then(function (value) {
                if (!isEmpty(value)) {
                    setErrorMessSchema();
                    setItemFeed({ ...value, event: { ...value.event, uid: itemFeed?.event?.uid } });
                }
            })
            .catch((e) => {
                setAtDefault(false);
                setErrorMessSchema(e.message);
            });
    }, [schemaRef.current]);

    return (
        !initialLoadingDone ?
        <ScreenLoading loaderType={"line-scale"} sentences={[]} /> :
        <div className="display-col">
            <div className={classes.titleHeader}>{`${typeAction === "edit" ? "Edit" : "Create"} > Event`}</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        title={`${typeAction === "edit" ? `${title ?? ""}` : "NEW EVENT"}`}
                        onClickClose={() => history.goBack()}
                        onClickJson={() => {
                            setAtDefault(false);
                        }}
                        isDefault={atDefault}
                        onClickDefault={() => {
                            if (isEmpty(errorMessSchema)) {
                                schemaRef.current = undefined;
                                setAtDefault(true);
                            }
                        }}
                        type="event"
                    />
                    <div className={classes.containerDivider} />
                    {atDefault ? (
                        <div ref={scrollRef} className={clsx(classes.containerForm, appStyles.scrollbar)}>
                            <InputWithTitle
                                title="Title"
                                require
                                data={title}
                                placeHolder="Name your event"
                                onChange={(value) => {
                                    setTitle(value);
                                }}
                            />
                            <div className="display-row">
                                <SelectWithTitle
                                    data={creatorId}
                                    list={listPersona?.map((item) => ({ title: item.name, value: item.uid }))}
                                    style={{ flex: 1 }}
                                    title="Calendar Jockey Persona"
                                    placeHolder="Select a persona"
                                    disable={!isEmpty(assignTo)}
                                    loading={loadingCollectionInfo}
                                    showComment={false}
                                    onItemChange={(value) => {
                                        setCreatorId(value);
                                    }}
                                />
                                <div style={{ width: 20 }} />
                                <SelectWithTitle
                                    data={
                                        assignTo ?? UNASSIGNED_EVENT_COLLECTION_ID
                                    }
                                    list={[
                                        { title: "Unassigned Event", uid: UNASSIGNED_EVENT_COLLECTION_ID },
                                        ...listAssign,
                                    ].map((item) => ({
                                        title: item.title,
                                        value: item.uid,
                                    }))}
                                    style={{ flex: 1 }}
                                    title="Assign to:"
                                    placeHolder="Collection / calendar"
                                    disable={fixedCollectionMode}
                                    showComment={false}
                                    isAlignParent={true}
                                    onItemChange={(value) => {
                                        if (value !== UNASSIGNED_EVENT_COLLECTION_ID) {
                                            var temp = listAssign.find((el) => el.uid === value);
                                            setCategoryId(temp.categoryId);
                                            setListSubCatId(temp.subCategoriesId);
                                            setAssignTo(value);
                                            setPublishStartTime(null);
                                            setPublishEndTime(null);
                                        } else {
                                            setCategoryId();
                                            setListSubCatId();
                                            setAssignTo();
                                        }
                                    }}
                                />
                            </div>
                            <SelectWithTitle
                                data={categoryId}
                                list={listCat.map((item) => ({ title: item.name, value: item.uid }))}
                                title="Category"
                                require
                                placeHolder="Select a category"
                                disable={!isEmpty(assignTo)}
                                loading={loadingCollectionInfo || isEmpty(listCat)}
                                onItemChange={(value) => {
                                    setCategoryId(value);
                                    setListSubCatId([]);
                                    setSubCatShow(listSubCat.filter((el) => el.categoryId === value));
                                }}
                            />
                            <TagWithTitle
                                title="Subcategories"
                                require
                                placeHolder="Add subcategory"
                                disable={!isEmpty(assignTo)}
                                loading={loadingCollectionInfo || isEmpty(listSubCat)}
                                data={listSubCatId}
                                list={subCatShow.map((item) => ({ title: item.name, value: item.uid }))}
                                onItemChange={(value) => setListSubCatId(value)}
                            />
                            <TagWithTitle
                                title="Tags"
                                placeHolder="Add tags"
                                data={listTagData}
                                loading={isEmpty(listTag)}
                                list={listTag.map((item) => ({ title: item.name, value: item.uid }))}
                                onItemChange={(value) => {
                                    setListTagData(value);
                                }}
                            />
                            <SummerNote
                                data={description}
                                title="Description"
                                placeholder="Event description"
                                onChange={(content) => setDescription(content)}
                            />
                            <InputWithTitle
                                data={location}
                                title="Location"
                                placeHolder="Enter event location"
                                style={{ marginTop: 32 }}
                                comment="If virtual event, enter event url, e.g., https://us02web.zoom.us/j/776251111"
                                onChange={(value) => {
                                    setLocation(value);
                                }}
                            />
                            <SelectDate
                                showAllDay={true}
                                isAllDay={isAllDay}
                                titleStart="Event Start Time"
                                titleEnd="Event End Time"
                                onStartDateChange={(value) => {
                                    if (
                                        (!isChoosePublishStartDate &&
                                            isEmpty(startTime) &&
                                            isEmpty(publishStartTime)) ||
                                        (!isChoosePublishStartDate &&
                                            moment(publishStartTime).isSame(moment(startTime).subtract(7, "day")))
                                    ) {
                                        setPublishStartTime(formatNaiveTime(moment(value).subtract(7, "day")));
                                    }
                                    if (
                                        !isEmpty(startTime) &&
                                        !isEmpty(endTime) &&
                                        moment(value).isAfter(moment(endTime))
                                    ) {
                                        let duration = moment.duration(moment(endTime).diff(moment(startTime)));
                                        setEndTime(
                                            formatNaiveTime(moment(value).add(duration.asMilliseconds(), "milliseconds"))
                                        );
                                    }
                                    if (
                                        !isEmpty(publishStartTime) &&
                                        !isEmpty(publishEndTime) &&
                                        moment(value).subtract(7, "day").isAfter(moment(publishEndTime))
                                    ) {
                                        let duration = moment.duration(
                                            moment(publishEndTime).diff(moment(publishStartTime))
                                        );
                                        setPublishEndTime(
                                            formatNaiveTime(
                                              moment(value)
                                                .subtract(7, "day")
                                                .add(duration.asMilliseconds(), "milliseconds")
                                            )
                                        );
                                    }
                                    setStartTime(value);
                                }}
                                onEndDateChange={(value) => {
                                    if (
                                        (!isChoosePublishEndDate && isEmpty(endTime) && isEmpty(publishEndTime)) ||
                                        (!isChoosePublishEndDate && moment(publishEndTime).isSame(moment(endTime)))
                                    ) {
                                        setPublishEndTime(value);
                                    }
                                    setEndTime(value);
                                }}
                                onAllDayChange={setAllDay}
                                initStartDate={startTime}
                                initEndDate={endTime}
                                require={true}
                            />
                            <CustomSelectTimeZone
                                timeZone={timeZone}
                                onTimeZoneChange={setTimeZone}
                            />
                            {isEmpty(assignTo) && (
                                <SelectDate
                                    initStartDate={publishStartTime}
                                    initEndDate={publishEndTime}
                                    onStartDateChange={(value) => {
                                        setChoosePublishStartDate(true);
                                        if (
                                            !isEmpty(publishStartTime) &&
                                            !isEmpty(publishEndTime) &&
                                            moment(value).isAfter(moment(publishEndTime))
                                        ) {
                                            var duration = moment.duration(
                                                moment(publishEndTime).diff(moment(publishStartTime))
                                            );
                                            setPublishEndTime(
                                                formatNaiveTime(
                                                  moment(value)
                                                    .add(duration.asMilliseconds(), "milliseconds")
                                                )
                                            );
                                        }
                                        setPublishStartTime(value);
                                    }}
                                    onEndDateChange={(value) => {
                                        setChoosePublishEndDate(true);
                                        setPublishEndTime(value);
                                    }}
                                />
                            )}
                            <ImagePicker
                                data={imageRes}
                                title="Image"
                                placeHolder="Image URL"
                                style={{ marginTop: isEmpty(assignTo) ? 22 : 10 }}
                                imageSource={(value) => setImageRes(value)}
                            />
                            <SelectWithTitle
                                data={feedSchema}
                                list={listSchema.map((item) => ({ title: item, value: item }))}
                                showComment={false}
                                title="Metadata"
                                placeHolder="Select a schema"
                                style={{ width: "calc(50% - 10px)" }}
                                onItemChange={(value) => {
                                    setFeedSchema(value);
                                    dispatch(getSchemaByName({ type: "event", name: value }));
                                }}
                            />
                            {!isEmpty(schema) && !isEmpty(schemaValue) && schemaValue.object && (
                                <SchemaObject
                                    onValueChange={(value) => setSchemaData(value)}
                                    schema={schemaValue.object}
                                    initialValue={itemFeed?.event?.feed_data}
                                />
                            )}
                            {!isEmpty(schema) && !isEmpty(schemaValue) && schemaValue.union && (
                                <SchemaUnion
                                    visibleName={schema.name}
                                    onValueChange={(value) => setSchemaData(value)}
                                    schema={schemaValue}
                                    initialValue={itemFeed?.event?.feed_data}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={classes.containerJsonForm}>
                            <div className={classes.containerJsonError}>
                                {!isEmpty(errorMessSchema) && (
                                    <span className={classes.textError}>{errorMessSchema}</span>
                                )}
                            </div>
                            <JsonComponent
                                data={eventSchema.cast(getItemSchema())}
                                isAssignEvent={fixedCollectionMode}
                                onChange={(value) => {
                                    schemaRef.current = value;
                                    handleSchema();
                                }}
                            />
                        </div>
                    )}
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {showError && <span className={classes.textError}>{messageError}</span>}
                    </div>
                    <ButtonBottom
                        showDraft={isEmpty(assignTo)}
                        showDelete={typeAction === "edit"}
                        onClickDelete={() =>
                            dispatch(
                                openMessageDialog({
                                    open: true,
                                    message: "Are you sure you want to delete this event?",
                                    title: "DELETE EVENT",
                                })
                            )
                        }
                        onClickOk={() => {
                            if (isEmpty(errorMessSchema)) {
                                createEventItem(false);
                            }
                        }}
                        onClickCancel={() => {
                            if (isEmpty(errorMessSchema)) {
                                createEventItem(true);
                            }
                        }}
                    />
                </div>
                <div className="display-center-col designPreview" style={{ flex: 1, padding: "0 20px" }}>
                    <div style={{ width: 300, height: 500, background: "#282828" }}></div>
                </div>
            </div>
        </div>
    );
}

export default EventView;
