import React, { useState, Fragment, useCallback, useMemo } from "react";
import { SchemaKeyValueType, schemaStyles } from "./Schema";
import { Input, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Row, Col } from "reactstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "../../../styles/react_datetime_overrides.css";
import * as moment from "moment";
import { capitalize } from "lodash";
import ImagePicker from "../../ImagePicker";
import SummerNote from "../../SummerNote";
import InputWithTitle from "../../InputWithTitle";
import SelectWithTitle from "../../SelectWithTitle";
import TimeZonePicker from "../../TimeZonePicker";
import { getSchemaByName } from "../../../redux/reducers/SchemaReducer";

const SchemaKeyValue = ({ visibleName, valueType, enumEntries, onValueChange, initialValue, required }) => {
    const setEmptyValue = () => {
        switch (valueType) {
            case SchemaKeyValueType.TEXT:
                return required ? "" : undefined;
            case SchemaKeyValueType.NUMBER:
                return undefined;
            case SchemaKeyValueType.BOOL:
                return false;
            default:
                return undefined;
        }
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [enumValue, setEnumValue] = useState(
        enumEntries
            ? initialValue
                ? enumEntries.find((entry) => entry.key === initialValue)
                    ? enumEntries.find((entry) => entry.key === initialValue).value
                    : enumEntries[0].value
                : enumEntries[0].value
            : ""
    );
    const [value, setValue] = useState(setEmptyValue());

    useMemo(() => {
        if (valueType === SchemaKeyValueType.ENUM) {
            if (initialValue) setEnumValue(initialValue);
        } else {
            if (initialValue !== undefined && initialValue !== null) setValue(initialValue);
        }
        onValueChange(initialValue || enumValue || value || setEmptyValue());
    }, [initialValue]);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const changeEnumValue = (entry) => {
        setEnumValue(entry.value);
        onValueChange(entry.key);
    };

    const onInputChange = useCallback(
        (event) => {
            const newValue =
                valueType === SchemaKeyValueType.NUMBER
                    ? +event
                    : valueType === SchemaKeyValueType.IMAGE_PICKER ||
                      valueType === SchemaKeyValueType.RICH_TEXT ||
                      valueType === SchemaKeyValueType.TEXT ||
                      valueType === SchemaKeyValueType.TIME_ZONE ||
                      valueType === SchemaKeyValueType.BOOL
                    ? event
                    : event.target.value;
            setValue(newValue);
            onValueChange(
                SchemaKeyValueType.TEXT ? (newValue === "" ? (required ? "" : undefined) : newValue) : newValue
            );
        },
        [setValue, valueType, onValueChange]
    );

    const onDatetimeChange = (event) => {
        let iso;
        switch (valueType) {
            case SchemaKeyValueType.DATETIME:
                iso = event.toISOString();
                break;
            case SchemaKeyValueType.DATE:
                iso = event.format("YYYY-MM-DD");
                break;
            case SchemaKeyValueType.TIME:
                iso = event.toISOString().split("T")[1].substring(0, 12);
                break;
            default:
                iso = event.format();
        }
        setValue(iso);
        onValueChange(iso);
    };

    const datetimeValue = () => {
        if (value) {
            switch (valueType) {
                case SchemaKeyValueType.DATETIME:
                    return moment(value, "YYYY-MM-DDThh:mm:ss.sss").format("MM/DD/YYYY h:mm a");
                case SchemaKeyValueType.DATE:
                    return moment(value, "YYYY-MM-DD").format("MM/DD/YYYY");
                case SchemaKeyValueType.TIME:
                    return moment(value, "hh:mm:ss.sss").format("h:mm a");
                default:
                    return moment(value).format();
            }
        } else {
            return "";
        }
        return value;
    };

    // const valueField = () => {
    //     switch (valueType) {
    //         case SchemaKeyValueType.TEXT:
    //             return (
    //                 <Input
    //                     className={required && value === "" ? "schema-missing-requirement" : ""}
    //                     type="text"
    //                     key={visibleName}
    //                     name={visibleName}
    //                     id={visibleName}
    //                     onChange={onInputChange}
    //                     value={value}
    //                     placeholder="Insert Text"
    //                 />
    //             );
    //         case SchemaKeyValueType.NUMBER:
    //             return (
    //                 <Input
    //                     className={required && value === undefined ? "schema-missing-requirement" : ""}
    //                     type="number"
    //                     key={visibleName}
    //                     name={visibleName}
    //                     id={visibleName}
    //                     onChange={onInputChange}
    //                     value={value}
    //                     placeholder="Insert Number"
    //                 />
    //             );
    //         case SchemaKeyValueType.BOOL:
    //             return (
    //                 <Input
    //                     type="checkbox"
    //                     key={visibleName}
    //                     name={visibleName}
    //                     id={visibleName}
    //                     onChange={onInputChange}
    //                     value={value}
    //                 />
    //             );
    //         case SchemaKeyValueType.ENUM:
    //             return (
    //                 <Dropdown isOpen={dropdownOpen} toggle={toggle} key={visibleName}>
    //                     <DropdownToggle caret>{enumValue}</DropdownToggle>
    //                     <DropdownMenu color="primary" className="w-100 p-1">
    //                         {enumEntries.map((entry) => (
    //                             <DropdownItem key={entry.key} onClick={() => changeEnumValue(entry)}>
    //                                 {entry.value}
    //                             </DropdownItem>
    //                         ))}
    //                     </DropdownMenu>
    //                 </Dropdown>
    //             );
    //         case SchemaKeyValueType.DATETIME:
    //         case SchemaKeyValueType.DATE:
    //         case SchemaKeyValueType.TIME:
    //             return (
    //                 <Datetime
    //                     key={visibleName}
    //                     onChange={onDatetimeChange}
    //                     utc={true}
    //                     value={datetimeValue()}
    //                     dateFormat={
    //                         valueType === SchemaKeyValueType.DATETIME || valueType === SchemaKeyValueType.DATE
    //                             ? true
    //                             : false
    //                     }
    //                     timeFormat={
    //                         valueType === SchemaKeyValueType.DATETIME || valueType === SchemaKeyValueType.TIME
    //                             ? true
    //                             : false
    //                     }
    //                 />
    //             );
    //         case SchemaKeyValueType.IMAGE_PICKER:
    //             return <ImagePicker showComment={false} comment="" data={value} imageSource={onInputChange} />;
    //         case SchemaKeyValueType.RICH_TEXT:
    //             return <SummerNote data={value} onChange={onInputChange} />;
    //         default:
    //             return undefined;
    //     }
    // };

    const valueField = () => {
        switch (valueType) {
            case SchemaKeyValueType.TEXT:
                return (
                    <InputWithTitle
                        title={visibleName}
                        onChange={onInputChange}
                        data={value}
                        placeholder="Insert Text"
                        required={required}
                        hideComment={true}
                    />
                );
            case SchemaKeyValueType.NUMBER:
                return (
                    <InputWithTitle
                        title={visibleName}
                        type="number"
                        onChange={onInputChange}
                        data={value}
                        placeholder="Insert Number"
                        required={required}
                        hideComment={true}
                    />
                );
            case SchemaKeyValueType.BOOL:
                return (
                  <SelectWithTitle
                    list={[{title: "true", value: true}, {title: "false", value: false}]}
                    data={value}
                    showComment={false}
                    title={visibleName}
                    placeHolder={value ? "true": "false"}
                    onItemChange={value => {
                        onInputChange(value);
                    }}
                  />
                )
            case SchemaKeyValueType.ENUM:
                return (
                  <SelectWithTitle
                    data={enumValue}
                    list={enumEntries.map(entry => ({ title: capitalize(entry.key), value: entry.key }))}
                    showComment={false}
                    title={visibleName}
                    placeHolder="Select a value"
                    onItemChange={value => {
                        // console.log(value);
                        changeEnumValue({value, key: value});
                    }}
                  />
                )
                return (
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} key={visibleName}>
                        <DropdownToggle caret>{enumValue}</DropdownToggle>
                        <DropdownMenu color="primary" className="w-100 p-1">
                            {enumEntries.map((entry) => (
                                <DropdownItem key={entry.key} onClick={() => changeEnumValue(entry)}>
                                    {entry.value}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                );
            case SchemaKeyValueType.DATETIME:
            case SchemaKeyValueType.DATE:
            case SchemaKeyValueType.TIME:
                return (
                    <div>
                        <div
                            style={{
                                fontSize: 12,
                                lineHeight: "14px",
                                color: "white",
                                fontFamily: "SFProTextSemiBold",
                                display: "flex",
                                margin: "10px 0 4px",
                            }}
                        >
                            {visibleName}
                            {required && <div style={{ color: "red", whiteSpace: "pre-wrap" }}> *</div>}
                        </div>
                        <Datetime
                            key={visibleName}
                            onChange={onDatetimeChange}
                            utc={true}
                            initialValue={datetimeValue()}
                            inputProps={{readOnly: true}}
                            dateFormat={
                                valueType === SchemaKeyValueType.DATETIME || valueType === SchemaKeyValueType.DATE
                                    ? true
                                    : false
                            }
                            timeFormat={
                                valueType === SchemaKeyValueType.DATETIME || valueType === SchemaKeyValueType.TIME
                                    ? true
                                    : false
                            }
                        />
                    </div>
                );
            case SchemaKeyValueType.IMAGE_PICKER:
                return (
                    <ImagePicker
                        title={visibleName}
                        showComment={false}
                        comment=""
                        data={value}
                        imageSource={onInputChange}
                    />
                );
            case SchemaKeyValueType.RICH_TEXT:
                return <SummerNote title={visibleName} data={value} onChange={onInputChange} />;
            case SchemaKeyValueType.TIME_ZONE:
                return <TimeZonePicker timeZone={value} onTimeZoneChange={onInputChange} />;
            default:
                return undefined;
        }
    };

    return (
        // <Fragment>
        //     <Row style={schemaStyles.topMargin}>
        //         {visibleName && (
        //             <Col sm={{ size: "auto" }}>
        //                 <span style={schemaStyles.schemaLine}>{`${visibleName}:`}</span>
        //             </Col>
        //         )}
        //         <Col
        //             sm={{
        //                 size:
        //                     valueType === SchemaKeyValueType.TEXT ||
        //                     valueType === SchemaKeyValueType.IMAGE_PICKER ||
        //                     valueType === SchemaKeyValueType.RICH_TEXT
        //                         ? "12"
        //                         : "4",
        //             }}
        //         >
        //             {valueField()}
        //         </Col>
        //     </Row>
        // </Fragment>
        valueField()
    );
};

export default SchemaKeyValue;
