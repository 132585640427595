import React, {  Fragment } from 'react';
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import SchemaKeyValue from './SchemaKeyValue';
import { SchemaElementType, SchemaComponent, schemaStyles } from './Schema';
import SchemaObject from './SchemaObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const SchemaUnion = ({ visibleName, schema, onValueChange, initialValue }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [
    internalInitialValue,
    setInternalInitialValue
  ] = useState(
      (initialValue  && '__type' in initialValue) ?
          initialValue
          : { __type: schema.union[0].visibleName, value: undefined }
  );
  const [selectedType, setSelectedType] = useState(() => {
    let initial_type = internalInitialValue.__type;
    let possible_names = schema.union.map(t => t.visibleName);
    // Reset type if the schema changed
    if (!possible_names.includes(initial_type)) {
      initial_type = possible_names[0];
    }
    return initial_type;
  });
  const [value, setValue] = useState(internalInitialValue);
  const [expanded, setExpanded] = useState(true);
  const onValueChangeRef = useRef(onValueChange);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const openCharacter = '{';
  const closeCharacter = '}';

  useEffect(() => {
    onValueChangeRef.current = onValueChange;
  }, [onValueChange]);

  useEffect(() => {
    setInternalInitialValue(value);
  }, [selectedType]);

  const onSchemaValueChange = useCallback((value) => {
    const newValue = {
      __type: selectedType,
      value,
    };
    setValue(newValue);
    onValueChangeRef.current(newValue);
  }, [selectedType])

  const element = useCallback((schemaEntry, initialValue) => {
    const {
      type,
      keyvalue,
      object,
      array,
      required
    } = schemaEntry;
    const visibleName = 'value';
    if (type === SchemaElementType.KEY_VALUE) {
      return <SchemaKeyValue
        visibleName={visibleName}
        valueType={keyvalue.valueType}
        enumEntries={keyvalue.enumEntries}
        initialValue={initialValue}
        required={required}
        onValueChange={onSchemaValueChange}
      />
    }
    if (type === SchemaElementType.OBJECT) {
      return <SchemaObject
        visibleName={visibleName}
        schema={object}
        initialValue={initialValue}
        onValueChange={onSchemaValueChange}
      />
    }
    if (type === SchemaElementType.ARRAY) {
      return <SchemaComponent
        schemaComponent="SchemaArray"
        visibleName={visibleName}
        schema={array}
        initialValue={initialValue}
        onValueChange={onSchemaValueChange}
      />
    }
    if (type === SchemaElementType.DYNAMIC_LENGTH) {
      return <SchemaComponent
        schemaComponent="SchemaArrayDynamicLength"
        visibleName={visibleName}
        schema={schemaEntry}
        initialValue={initialValue}
        required={required}
        onValueChange={onSchemaValueChange}
      />
    }
    if (type === SchemaElementType.UNION) {
      return <SchemaComponent
        schemaComponent="SchemaUnion"
        visibleName={visibleName}
        schema={schemaEntry}
        initialValue={initialValue}
        onValueChange={onSchemaValueChange}
      />
    }
  }, [onSchemaValueChange]);

  const selectedElement = useMemo(() => {
    return element(
        schema.union.find(s => s.visibleName === selectedType),
        internalInitialValue.value
    );
  }, [schema, selectedType]);

  const changeSelectedType = (type) => {
    setValue({ __type: type, value: undefined });
    setSelectedType(type);
  }

  const onClickCaret = () => {
    setExpanded(!expanded);
  }

  const openLine = () => {
    const bracket = expanded
      ? `${openCharacter}`
      : `${openCharacter} ... ${closeCharacter}`;
    if (visibleName) {
      return `${visibleName}: ${bracket}`;
    }
    return bracket;
  }

  return (
    <Fragment>
      <div style={{ ...schemaStyles.topMargin }}>
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faPlay} size="xs" rotation={expanded ? 270 : 90} onClick={onClickCaret} />
        <span style={{ ...schemaStyles.schemaLine, paddingLeft: '1em' }}>{openLine()}</span>
      </div>
      <div
        style={{ paddingLeft: "20px", display: expanded ? 'block' : 'none' }}
      >
        <Row style={schemaStyles.topMargin}>
          <Col sm={{ size: 'auto' }}>
            <span style={schemaStyles.schemaLine}>
              __type
            </span>
          </Col>
          <Col sm="4">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>
                {selectedType}
              </DropdownToggle>
              <DropdownMenu  color="primary" className="w-100 p-1">
                {schema.union.map(s => <DropdownItem
                  key={s.visibleName}
                onClick={() => changeSelectedType(s.visibleName)}>{s.visibleName}</DropdownItem>)}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        {selectedElement}
      </div>
      <p
        style={{ ...schemaStyles.schemaLine, display: expanded ? 'block' : 'none' }}
      >{closeCharacter}</p>
    </Fragment>
  );
};

export default SchemaUnion
