import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { createStanza, createStanzaFail } from "../reducers/StanzaReducer";

function* youTubeSagaWatcher() {
    yield takeLatest(createStanza, withCallback(createSta));
}

function* createSta(action) {
    console.log(action.payload.dataST, "action.payload.id");
    try {
        const response = yield call(handleApi, `dashboard/stanza/${action.payload.id}`, "POST", action.payload.dataST);
        return true;
    } catch (error) {
        yield put(createStanzaFail({ isError: true, message: error.message }));
        return false;
    }
}

export default youTubeSagaWatcher;
