import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Popover } from "@material-ui/core";
import { isEmpty } from "lodash";
import sortStyle from "../styles/SortStyle";
import PopupMenu from "./PopupMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { upperCaseFirstLetter } from "../utils/Utils";

function FilterComponent(props) {
    const { onClickFilter, filterBy } = props;
    const [anchorEl, setAnchorEl] = useState();
    const sortClasses = sortStyle();
    const [title, setTitle] = useState();

    useEffect(() => {
        setTitle(upperCaseFirstLetter(filterBy) ?? "All");
    }, [filterBy]);

    return (
        <div>
            <div
                className={clsx("display-center-row")}
                style={{ cursor: "pointer" }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <div className={clsx(sortClasses.conIconSort, "display-center")}>
                    <FontAwesomeIcon icon={faFilter} />
                </div>
                <div className={sortClasses.txtSort}>{title}</div>
            </div>
            <Popover
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <PopupMenu
                    listMenu={["All", "Live", "Pending", "Draft"]}
                    onClick={(value) => {
                        setAnchorEl();
                        setTitle(value);
                        onClickFilter(value.toLowerCase());
                    }}
                />
            </Popover>
        </div>
    );
}

export default FilterComponent;
