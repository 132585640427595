import { makeStyles } from "@material-ui/core";

const createFormStyle = makeStyles((theme) => ({
    titleHeader: {
        fontSize: 40,
        lineHeight: "48px",
        letterSpacing: "0.7px",
        color: "white",
        margin: "13px 0 12px 17.5px",
        whiteSpace: "nowrap",
    },
    titleCalendar: {
        // flex: 1,
        fontSize: 16,
        lineHeight: "19px",
        color: "white",
        fontFamily: "SFProTextBold",
    },
    containerChild: {
        height: "calc(100vh - 122px)",
    },
    containerContent: {
        background: "#282828",
        borderRadius: 8,
        marginLeft: 16,
        width: 600,
        height: "100%",
        [theme.breakpoints.up("xl")]: {
            width: "60%",
            maxWidth: 750,
        },
    },
    containerForm: {
        flex: 1,
        overflowY: "auto",
        padding: "20px 30px",
    },
    containerToggle: {
        height: 20,
        width: 126,
        borderRadius: 4,
        border: "0.5px solid #979797",
        fontSize: 12,
        // lineHeight: "24px",
    },
    containerDivider: {
        margin: "0 41px 0 32px",
        height: 1,
        background: "#D5D6D9",
    },
    containerBottom: {
        margin: "0 30px 30px",
    },
    containerError: {
        height: 20,
        margin: "6px 30px",
        display: "flex",
        justifyContent: "flex-end",
    },
    textError: {
        fontSize: 13,
        lineHeight: "20px",
        color: "#DA1414",
        fontFamily: "SourceSansPro",
    },
    containerBtn: {
        color: "white",
        fontSize: 13,
        lineHeight: "15px",
        borderRadius: 4,
        width: 69,
        height: 25,
        cursor: "pointer",
    },
    containerJsonForm: {
        flex: 1,
        padding: "0px 41px 30px 32px",
        height: 300,
    },
    containerJsonError: {
        height: 30,
        padding: "5px 0",
    },
    txtEditExisting: {
        fontFamily: "SFProText",
        fontSize: 15,
        color: "#FF6961",
        marginLeft: 24,
        cursor: "pointer",
    },
}));
export default createFormStyle;
