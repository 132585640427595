import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ItemCarousel from "./ItemCarousel";
import IconLeftActive from "../assets/icons/arrow-left-active.svg";
import IconRightActive from "../assets/icons/arrow-right-active.svg";
import IconLeft from "../assets/icons/arrow-left.svg";
import IconRight from "../assets/icons/arrow-right.svg";
import clsx from "clsx";
import ItemEventCarousel from "./ItemEventCarousel";
import { useDispatch, useSelector } from "react-redux";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import { deleteCalendar, deleteCalendarFail } from "../redux/reducers/CalendarReducer";
import { deleteCollection, deleteCollectionFail } from "../redux/reducers/CollectionReducer";
import { deleteEventUnassign } from "../redux/reducers/EventReducer";
import { isEmpty } from "lodash";
import AddIcon from "../assets/icons/add_circle_outline_rounded.svg";
import EmptyCalendar from "../assets/icons/empty_calendar.svg";
import EmptyCollection from "../assets/icons/empty_collection.svg";
import EmptyEvent from "../assets/icons/empty_event.svg";
import appStyle from "../styles/AppStyle";
import carouselStyle from "../styles/CarouselStyle";
import { useHistory, useLocation } from "react-router-dom";

const CarouselComponent = (props) => {
    const { title, data, style, total, onNextPage, isLoading, responsive, setResponsive } = props;
    const caRef = useRef();
    const [isMoving, setMoving] = useState();
    const [currentItem, setCurrentItem] = useState();
    const dispatch = useDispatch();
    const { onClickDelete, isDeleteCalendar, isDeleteCollection } = useSelector((state) => ({
        onClickDelete: state.app.onClickDelete,
        isDeleteCalendar: state.calendar.isDelete,
        isDeleteCollection: state.collection.isDelete,
    }));
    const appClasses = appStyle();
    const classes = carouselStyle();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let currC = caRef.current?.containerRef.current
        
        if (currC && data?.length > 0) {
            let n ;
            n = Math.floor(currC.offsetWidth/240);
            const widthWindow = window.innerWidth;
            if(1410 <= widthWindow && widthWindow < 1650) {
                setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        desktop: {
                            breakpoint: {
                                max: 1650,
                                min: 1410,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(1650 <= widthWindow && widthWindow < 3000) {
                setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        fullhd: {
                            breakpoint: {
                                max: 3000,
                                min: 1650,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(1190 <= widthWindow && widthWindow < 1410) {
                setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        tablet1: {
                            breakpoint: {
                                max: 1410,
                                min: 1190,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(950 <= widthWindow && widthWindow < 1190) {
                setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        tablet: {
                            breakpoint: {
                                max: 1190,
                                min: 950,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(710 <= widthWindow && widthWindow < 950) {
                setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        mobile: {
                            breakpoint: {
                                max: 950,
                                min: 710,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(0 <= widthWindow && widthWindow < 710) {
                setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        miniMobile: {
                            breakpoint: {
                                max: 710,
                                min: 0,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            }
        }
        
    }, [])

    useEffect(() => {
        if (onClickDelete && !isEmpty(currentItem)) {
            if (currentItem.isSubscribable === undefined) {
                dispatch(deleteEventUnassign({ uid: encodeURIComponent(currentItem.uid) }));
            } else if (currentItem.isSubscribable) {
                dispatch(
                    deleteCalendar({
                        uid: `${currentItem.provider}:${currentItem.type}:${encodeURIComponent(currentItem.uid)}`,
                    })
                );
            } else if (!currentItem.isSubscribable) {
                dispatch(
                    deleteCollection({
                        uid: `${currentItem.provider}:${currentItem.type}:${encodeURIComponent(currentItem.uid)}`,
                    })
                );
            }
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    useEffect(() => {
        if (isDeleteCalendar || isDeleteCollection) {
            dispatch(deleteCollectionFail({ isError: false, message: "" }));
            dispatch(deleteCalendarFail({ isError: false, message: "" }));
        }
    }, [isDeleteCalendar, isDeleteCollection]);

    const onClickCreateNew = () => {
        switch (title) {
            case "Calendar":
                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                    history.replace("/create/calendar");
                } else {
                    history.push("/create/calendar");
                }
                break;
            case "Collection":
                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                    history.replace("/create/collection");
                } else {
                    history.push("/create/collection");
                }
                break;
            default:
                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                    history.replace(`/create/event`);
                } else {
                    history.push(`/create/event`);
                }
                break;
        }
    };

    return (
        <div style={style}>
            <div className="display-center-row">
                <div className={classes.txtTitle}>{title}</div>
                {data?.length > 0 && (
                    <div className={clsx(classes.conNav, "display-center-row")}>
                        <div
                            className={clsx("display-center", "container-icon-navigation")}
                            onClick={() => {
                                caRef.current.previous();
                            }}
                        >
                            <img
                                className="icon-navigation"
                                src={caRef.current?.state?.currentSlide === 0 ? IconLeft : IconLeftActive}
                                alt=""
                            />
                        </div>
                        <div
                            className={clsx("display-center", "container-icon-navigation")}
                            onClick={() => {
                                if (!isLoading) {
                                    caRef.current.next();
                                }
                            }}
                        >
                            <img
                                className="icon-navigation"
                                src={
                                    caRef.current?.state?.currentSlide + caRef.current?.state?.slidesToShow >= total
                                        ? IconRight
                                        : IconRightActive
                                }
                                alt=""
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.conDivider} />
            <div className={classes.conCarousel}>
                {data?.length === 0 && (
                    <div className={clsx(appClasses.displayCenter, classes.conEmpty)}>
                        <div className={appClasses.colCenterDirection}>
                            <img
                                alt=""
                                src={
                                    title === "Collection"
                                        ? EmptyCollection
                                        : title === "Calendar"
                                        ? EmptyCalendar
                                        : EmptyEvent
                                }
                            />
                            <div
                                className={clsx(classes.conAdd, appClasses.rowCenterDirection)}
                                onClick={onClickCreateNew}
                            >
                                <img alt="" src={AddIcon} />
                                <div className={classes.txtCreateNew}>{`CREATE NEW ${title.toUpperCase()}`}</div>
                            </div>
                        </div>
                    </div>
                )}
                {data?.length > 0 && (
                    <Carousel
                        ref={(el) => (caRef.current = el)}
                        additionalTransfrom={0}
                        arrows={false}
                        autoPlay={false}
                        centerMode={false}
                        transitionDuration={700}
                        focusOnSelect={true}
                        keyBoardControl={false}
                        containerClass="container-carousel"
                        itemClass="container-item-carousel"
                        minimumTouchDrag={50}
                        renderButtonGroupOutside={true}
                        renderDotsOutside={false}
                        responsive={responsive}
                        showDots={false}
                        slidesToSlide={5}
                        swipeable={true}
                        beforeChange={() => setMoving(true)}
                        afterChange={() => {
                            setTimeout(() => {
                                setMoving(false);
                            }, 500);
                            if (
                                data.length < total &&
                                data.length - (caRef.current.state.currentSlide + caRef.current.state.slidesToShow) <=
                                    caRef.current.state.slidesToShow
                            ) {
                                onNextPage();
                            }
                        }}
                    >
                        {data.map((item, index) =>
                            item.event ? (
                                <ItemEventCarousel
                                    key={index}
                                    isMoving={isMoving}
                                    itemChild={item.event}
                                    itemParent={item}
                                    onClickDeleteFeed={(item) => {
                                        setCurrentItem(item);
                                        dispatch(
                                            openMessageDialog({
                                                open: true,
                                                message: "Are you sure you want to delete this event?",
                                                title: "DELETE EVENT",
                                            })
                                        );
                                    }}
                                />
                            ) : (
                                <ItemCarousel
                                    key={index}
                                    isMoving={isMoving}
                                    itemChild={item}
                                    onClickDeleteFeed={(item) => {
                                        setCurrentItem(item);
                                        dispatch(
                                            openMessageDialog({
                                                open: true,
                                                message: `Are you sure you want to delete this ${
                                                    item.isSubscribable ? "calendar" : "collection"
                                                }?`,
                                                title: `DELETE ${item.isSubscribable ? "CALENDAR" : "COLLECTION"}`,
                                            })
                                        );
                                    }}
                                />
                            )
                        )}
                    </Carousel>
                )}
            </div>
        </div>
    );
};

CarouselComponent.defaultProps = {
    type: "",
    isLoading: false,
};

export default CarouselComponent;
