import { isEmpty } from "lodash";
import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put, select } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { getItemFeedByIdSuccess, openMessageDialog } from "../reducers/AppReducer";
import {
    getCollections,
    getCollectionsFail,
    getCollectionsSuccess,
    createCollection,
    createCollectionSuccess,
    createCollectionFail,
    deleteCollection,
    deleteCollectionFail,
    deleteCollectionSuccess,
    updateCollection,
    updateCollectionSuccess,
    updateCollectionFail,
    getCollectionById,
    getCollectionByIdFail,
} from "../reducers/CollectionReducer";
import { getAllFeedByPreFilterSuccess, getFeedsByPreFilterSuccess } from "../reducers/PreFilterReducer";
import { getStatistics } from "../reducers/StatisticsReducer";

function* collectionSagaWatcher() {
    yield takeLatest(getCollections, getListCollection);
    yield takeLatest(createCollection, withCallback(create));
    yield takeLatest(deleteCollection, withCallback(deleteItem));
    yield takeLatest(updateCollection, withCallback(updateItem));
    yield takeLatest(getCollectionById, withCallback(getItemById));
}

function* create(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, "dashboard/collections", "POST", action.payload.data);
        yield put(createCollectionSuccess(response.data));
        yield put(getStatistics());
        if (state.collection.data.count === state.collection.data.items.length) {
            yield put(
                getCollectionsSuccess({
                    count: state.collection.data.count + 1,
                    items: state.collection.data.items.concat(response.data),
                    nextPageToken: null,
                })
            );
        }
        return true;
    } catch (error) {
        yield put(createCollectionFail({ isError: true, message: error.message }));
        return false;
    }
}

function* deleteItem(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/collections/${action.payload.uid}`, "DELETE");
        yield put(deleteCollectionSuccess());
        yield put(getStatistics());
        yield put(
            getFeedsByPreFilterSuccess({
                ...state.preFilter.data,
                items: state?.preFilter?.data?.items?.filter((el) => {
                    if (el.event) {
                        return true;
                    } else {
                        return encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2];
                    }
                }),
            })
        );
        yield put(
            getAllFeedByPreFilterSuccess({
                calendar: { ...state.preFilter.calendar },
                collection: {
                    ...state.preFilter.collection,
                    items: state?.preFilter?.collection?.items?.filter(
                        (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                    ),
                },
                event: { ...state.preFilter.event },
            })
        );
        yield put(
            getCollectionsSuccess({
                ...state.collection.data,
                count: state.collection.data.count - 1,
                items: state?.collection?.data?.items?.filter(
                    (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                ),
            })
        );
        return true;
    } catch (error) {
        yield put(deleteCollectionFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* getListCollection(action) {
    try {
        const state = yield select();
        var url = `dashboard/all/collections?limit=20&sort=title${
            !isEmpty(action.payload) ? `&page_token=${encodeURIComponent(action.payload)}` : ""
        }`;
        const response = yield call(handleApi, url);
        yield put(
            getCollectionsSuccess({ ...response.data, items: state.collection.data.items.concat(response.data.items) })
        );
    } catch (error) {
        yield put(getCollectionsFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

function* updateItem(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/collections/${action.payload.uid}`,
            "PUT",
            action.payload.data
        );
        yield put(updateCollectionSuccess(response.data));
        yield put(getStatistics());
        yield put(getItemFeedByIdSuccess(response.data));
        yield put(
            getFeedsByPreFilterSuccess({
                ...state.preFilter.data,
                items: state?.preFilter?.data?.items?.filter(
                    (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                ),
            })
        );
        yield put(
            getAllFeedByPreFilterSuccess({
                calendar: { ...state.preFilter.calendar },
                collection: {
                    ...state.preFilter.collection,
                    items: state?.preFilter?.collection?.items?.filter(
                        (el) => encodeURIComponent(el.uid) !== action.payload.uid.split(":")[2]
                    ),
                },
                event: { ...state.preFilter.event },
            })
        );
        // yield put(
        //     getCollectionsSuccess({
        //         ...state.collection.data,
        //         count: state.collection.data.count - 1,
        //         items: state.collection.data.items.filter((el) => el.uid !== action.payload.uid.split(":")[2]),
        //     })
        // );
        return true;
    } catch (error) {
        yield put(updateCollectionFail({ isError: true, message: error.message }));
        if (action.payload.isShowErrorDialog !== false) {
            yield put(
                openMessageDialog({
                    open: true,
                    message: error.message,
                    title: "ERROR",
                })
            );
        }
        return false;
    }
}

function* getItemById(action) {
    try {
        var url = `/dashboard/collections/${action.payload}`;
        const response = yield call(handleApi, url);
        return response.data;
    } catch (error) {
        yield put(getCollectionByIdFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
        return {};
    }
}

// function* getListCollection(action) {
//     try {
//         if (isEmpty(action.payload)) {
//             const response = yield call(handleApi, "dashboard/collections");
//             var data = response.data;
//             var collections = response.data
//                 .filter((el) => !el.isSubscribable)
//                 .sort((a, b) => {
//                     var date1 = new Date(a.upcomingEventTime);
//                     var date2 = new Date(b.upcomingEventTime);
//                     if (date1 < date2) return 1;
//                     else if (date1 > date2) return -1;
//                     else return 0;
//                 });
//             var calendars = response.data
//                 .filter((el) => el.isSubscribable)
//                 .sort((a, b) => {
//                     var date1 = new Date(a.upcomingEventTime);
//                     var date2 = new Date(b.upcomingEventTime);
//                     if (date1 < date2) return 1;
//                     else if (date1 > date2) return -1;
//                     else return 0;
//                 });
//             yield put(getCollectionsSuccess({ data: data, collections: collections, calendars: calendars }));
//         } else {
//             const itemResponse = yield call(handleApi, `dashboard/collections/${action.payload}`);
//             yield put(getCollectionsByIdSuccess(itemResponse.data));
//         }
//     } catch (error) {
//         console.log(error.message);
//         yield put(getCollectionsFail());
//     }
// }

export default collectionSagaWatcher;
