import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import IndexSagas from "../sagas/index";

const initializeSagaMiddleware = createSagaMiddleware();

// const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

const store = createStore(rootReducer, storeEnhancers(applyMiddleware(initializeSagaMiddleware)));

initializeSagaMiddleware.run(IndexSagas);

export default store;
