import { createSlice } from "@reduxjs/toolkit";

const collectionReducer = createSlice({
    name: "collections",
    initialState: {
        isLoading: false,
        data: { items: [] },
        item: {},
        isError: false,
        messageError: "",
        isDelete: false,
    },
    reducers: {
        createCollection: (state, action) => {
            state.isLoading = true;
            state.isError = false;
            state.messageError = "";
        },
        createCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.item = action.payload;
        },
        createCollectionFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        getCollections: (state, action) => {
            state.isLoading = true;
        },
        getCollectionsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getCollectionsFail: (state, action) => {
            state.isLoading = false;
        },
        deleteCollection: (state, action) => {
            state.isLoading = true;
            state.isError = false;
            state.messageError = "";
            state.isDelete = false;
        },
        deleteCollectionSuccess: (state, action) => {
            state.isLoading = false;
            state.isDelete = true;
        },
        deleteCollectionFail: (state, action) => {
            state.isLoading = false;
            state.isDelete = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        updateCollection: (state, action) => {
            state.isLoading = true;
        },
        updateCollectionSuccess: (state, action) => {
            state.isLoading = false;
        },
        updateCollectionFail: (state, action) => {
            state.isLoading = false;
        },
        getCollectionById: (state, action) => {
            state.isLoading = true;
        },
        getCollectionByIdFail: (state, action) => {
            state.isLoading = false;
        },
    },
});

const { reducer, actions } = collectionReducer;
export const {
    getCollections,
    getCollectionsSuccess,
    getCollectionsFail,
    createCollection,
    createCollectionSuccess,
    createCollectionFail,
    deleteCollection,
    deleteCollectionSuccess,
    deleteCollectionFail,
    updateCollection,
    updateCollectionSuccess,
    updateCollectionFail,
    getCollectionById,
    getCollectionByIdFail,
} = actions;
export default reducer;
