import { makeStyles } from "@material-ui/core";

const sortStyle = makeStyles((theme) => ({
    txtSort: {
        marginLeft: 8,
        fontFamily: "Mulish",
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "20px",
        color: "#ADAFB2",
        letterSpacing: 0.2,
    },
    conIconSort: {
        cursor: "pointer",
        width: 20,
        height: 20,
    },
}));

export default sortStyle;
