import React, { useEffect, useState } from "react";
import createFormStyle from "../styles/CreateFormStyle";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ButtonBottom from "../components/ButtonBottom";
import SectionHeader from "../components/SectionHeader";
import SelectWithTitle from "../components/SelectWithTitle";
import { useHistory } from "react-router-dom";
import { getSchema, getSchemaByName, getSchemaByNameSuccess } from "../redux/reducers/SchemaReducer";
import gqlASTParser, {
    ParserError,
    AWS_SCALARS,
    compileSchemaComponentsFromParsedAST,
    MEDIA_RESOURCE_TYPE,
} from "../utils/gqlASTParser";
import MonacoEditor from "react-monaco-editor";
import * as gqlConvert from "graphql-to-json-converter";
import InputWithTitle from "../components/InputWithTitle";
import { createItemSchema, deleteItemSchema } from "../redux/actions";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";

const ErrorMessage = {
    SCHEMANAME_FIELD_EMPTY: "SCHEMANAME_EMPTY",
    SCHEMANAME_FIELD_SHORT: "SCHEMANAME_FIELD_SHORT",
    SCHEMANAME_FIELD_LONG: "SCHEMANAME_FIELD_LONG",
    SCHEMANAME_FIELD_INVALID: "SCHEMANAME_FIELD_INVALID",

    SCHEMANAME_TYPE_NOT_PRESENT: "SCHEMANAME_TYPE_NOT_PRESENT",
    SCHEMANAME_TYPE_NOT_IMPLEMENTING_FEEDITEM: "SCHEMANAME_TYPE_NOT_IMPLEMENTING_FEEDITEM",
    SCHEMANAME_TYPE_EMPTY: "SCHEMANAME_TYPE_EMPTY",

    SCHEMA_TYPE_ONLY: "SCHEMA_TYPE_ONLY",
    SCHEMA_SCALAR_PRESENT: "SCHEMA_SCALAR_PRESENT",
    SCHEMA_INVALID: "SCHEMA_INVALID",
};

const SCHEMANAME_FIELD_MIN = 3;
const SCHEMANAME_FIELD_MAX = 16;

function CreateSchemaView() {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const { isError, messageError, listSchema, schema, onClickDelete } = useSelector((state) => ({
        isError: state.schema.isError,
        messageError: state.schema.messageError,
        listSchema: state.schema.data,
        schema: state.schema.schema,
        onClickDelete: state.app.onClickDelete,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [actionType, setActionType] = useState("create");
    const [parsedAST, setParsedAST] = useState(undefined);
    const [typeFeed, setTypeFeed] = useState("");
    const [schemaName, setSchemaName] = useState("");
    const [fieldErrorMsg, setFieldErrorMsg] = useState(ErrorMessage.SCHEMANAME_FIELD_EMPTY);
    const [schemaErrorMsg, setSchemaErrorMsg] = useState(ErrorMessage.SCHEMANAME_TYPE_NOT_PRESENT);
    const [code, setCode] = useState("# Replace blank line with intended name of Schema\ntype ____ {\n\t\n}");
    const options = {
        minimap: {
            enabled: false,
        },
        scrollbar: {
            horizontalScrollbarSize: 3,
            verticalScrollbarSize: 3,
        },
        links: true,
        lineNumbers: {
            LineNumbersType: "off",
        },
        padding: {
            top: 18,
        },
        overviewRulerLanes: 8,
        overviewRulerBorder: true,
        renderValidationDecorations: "on",
    };

    useEffect(() => {
        if (!isEmpty(schema)) {
            setCode(schema.code.replace(/\\n/gm, "\n"));
        }
    }, [schema]);

    const onMonacoChange = (newValue) => {
        const value = newValue + AWS_SCALARS + MEDIA_RESOURCE_TYPE;
        const jsonSchema = gqlConvert(value);
        setCode(newValue);
        if (!jsonSchema.type && !jsonSchema.union) {
            setSchemaErrorMsg(ErrorMessage.SCHEMA_INVALID);
            return;
        }
        try {
            if (
                jsonSchema.type &&
                !(schemaName in jsonSchema.type) &&
                jsonSchema.union &&
                !(schemaName in jsonSchema.union)
            ) {
                setSchemaErrorMsg(ErrorMessage.SCHEMANAME_TYPE_NOT_PRESENT);
                return;
            }
        } catch (e) {
            setSchemaErrorMsg(ErrorMessage.SCHEMA_INVALID);
            return;
        }
        if ("input" in jsonSchema) {
            setSchemaErrorMsg(ErrorMessage.SCHEMA_TYPE_ONLY);
            return;
        }

        try {
            const parsedTypes = gqlASTParser(value);
            // console.log(parsedTypes);
            const schema = compileSchemaComponentsFromParsedAST(schemaName, parsedTypes);
            // console.log(schema);
            setParsedAST(parsedTypes);
        } catch (e) {
            if (e === ParserError.QUERY_PRESENT || e === ParserError.MUTATION_PRESENT) {
                setSchemaErrorMsg(ErrorMessage.SCHEMA_TYPE_ONLY);
            } else if (e === ParserError.SCALAR_PRESENT) {
                setSchemaErrorMsg(ErrorMessage.SCHEMA_SCALAR_PRESENT);
            } else {
                setSchemaErrorMsg(ErrorMessage.SCHEMA_INVALID);
            }

            setCode(newValue);
            return;
        }

        setSchemaErrorMsg(undefined);
        setCode(newValue);
    };

    const onInputChange = (value) => {
        setSchemaName(value);
        validateSchemaName(value);
        const jsonSchema = gqlConvert(code + AWS_SCALARS + MEDIA_RESOURCE_TYPE);
        try {
            if (
                (jsonSchema.type && !(value in jsonSchema.type)) ||
                (jsonSchema.union && !(value in jsonSchema.union))
            ) {
                setSchemaErrorMsg(ErrorMessage.SCHEMANAME_TYPE_NOT_PRESENT);
                return;
            }
        } catch (e) {
            setSchemaErrorMsg(ErrorMessage.SCHEMA_INVALID);
            return;
        }
        setSchemaErrorMsg(undefined);
    };

    const validateSchemaName = (value) => {
        if (value === "") {
            setFieldErrorMsg(ErrorMessage.SCHEMANAME_FIELD_EMPTY);
            return;
        }

        if (value.length < SCHEMANAME_FIELD_MIN) {
            setFieldErrorMsg(ErrorMessage.SCHEMANAME_FIELD_SHORT);
            return;
        }
        if (value.length > SCHEMANAME_FIELD_MAX) {
            setFieldErrorMsg(ErrorMessage.SCHEMANAME_FIELD_LONG);
            return;
        }

        if (!value.match(/^[a-zA-Z]\w+$/)) {
            setFieldErrorMsg(ErrorMessage.SCHEMANAME_FIELD_INVALID);
            return;
        }

        setFieldErrorMsg(undefined);
    };

    const errorMessage = () => {
        switch (fieldErrorMsg || schemaErrorMsg) {
            // case ErrorMessage.SCHEMANAME_FIELD_EMPTY:
            //     return `${typeFeed} Type Name is empty`;
            case ErrorMessage.SCHEMANAME_FIELD_SHORT:
                return `${typeFeed} Type Name must be at least ${SCHEMANAME_FIELD_MIN} characters long`;
            case ErrorMessage.SCHEMANAME_FIELD_LONG:
                return `${typeFeed} Type Name must be at most ${SCHEMANAME_FIELD_MAX} characters long`;
            case ErrorMessage.SCHEMANAME_FIELD_INVALID:
                return `${typeFeed} Type Name can only be alphanumeric and must start with a letter`;
            case ErrorMessage.SCHEMANAME_TYPE_NOT_PRESENT:
                return `'${schemaName}' is not present in the schema.`;
            case ErrorMessage.SCHEMANAME_TYPE_NOT_IMPLEMENTING_FEEDITEM:
                return `'${schemaName}' needs to implement 'FeedItem'`;
            case ErrorMessage.SCHEMANAME_TYPE_EMPTY:
                return `'${schemaName}' has no properties`;
            case ErrorMessage.SCHEMA_TYPE_ONLY:
                return "Only types can be defined";
            case ErrorMessage.SCHEMA_SCALAR_PRESENT:
                return "Scalars are not allowed to be defined";
            case ErrorMessage.SCHEMA_INVALID:
                return "Schema is invalid";
            default:
                return "";
        }
    };

    useEffect(() => {
        if (onClickDelete) {
            dispatch(
                deleteItemSchema({
                    type: typeFeed,
                    name: schemaName,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            dispatch(getSchemaByNameSuccess());
                            history.goBack();
                        }
                    },
                })
            );
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    return (
        <div className="display-col">
            <div className={classes.titleHeader}>{`${actionType === "create" ? "Create" : "Edit"} > Schema`}</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        showSwitch={false}
                        onClickClose={() => history.goBack()}
                        title={`${actionType === "create" ? "NEW" : "EDIT"} SCHEMA`}
                        showTextEditInTitle={true}
                        txtAction={`${actionType === "create" ? "Edit an existing Schema" : "Create New Schema"}`}
                        onClickEdit={() => {
                            setFieldErrorMsg();
                            setSchemaErrorMsg();
                            setTypeFeed();
                            setSchemaName();
                            setCode("# Replace blank line with intended name of Schema\ntype ____ {\n\t\n}");
                            if (actionType === "create") {
                                setActionType("edit");
                            } else {
                                setActionType("create");
                            }
                        }}
                        type="schema"
                    />
                    <div className={classes.containerDivider} />
                    <div className={clsx(classes.containerForm, appStyles.scrollbar)}>
                        <SelectWithTitle
                            data={typeFeed}
                            list={["Calendar", "Collection", "Event", "Persona"].map((item) => ({
                                title: item,
                                value: item.toLowerCase(),
                            }))}
                            showComment={false}
                            title="Metadata"
                            placeHolder="Select a schema"
                            style={{ width: "calc(50% - 10px)" }}
                            onItemChange={(value) => {
                                setTypeFeed(value);
                                if (actionType === "edit") {
                                    setSchemaName();
                                    setCode("# Replace blank line with intended name of Schema\ntype ____ {\n\t\n}");
                                    dispatch(getSchema(value));
                                }
                            }}
                        />
                        {actionType === "create" && (
                            <InputWithTitle
                                data={schemaName}
                                title="Schema Name"
                                require
                                placeHolder="Schema name"
                                onChange={(value) => {
                                    onInputChange(value);
                                }}
                            />
                        )}
                        {actionType === "edit" && (
                            <SelectWithTitle
                                data={schemaName}
                                list={listSchema.map((item) => ({ title: item, value: item }))}
                                showComment={false}
                                title="Metadata"
                                placeHolder="Select a schema"
                                onItemChange={(value) => {
                                    setSchemaName(value);
                                    dispatch(getSchemaByName({ type: typeFeed, name: value }));
                                }}
                            />
                        )}
                        <div style={{ marginTop: 28 }}>
                            <MonacoEditor
                                height={400}
                                language="graphql"
                                theme="vs-dark"
                                value={code}
                                onChange={onMonacoChange}
                                options={options}
                            />
                        </div>
                    </div>
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {isError ? (
                            <span className={classes.textError}>{messageError}</span>
                        ) : (
                            <span className={classes.textError}>{errorMessage()}</span>
                        )}
                    </div>
                    <ButtonBottom
                        txtOk="SAVE"
                        showDelete={actionType === "edit" && !isEmpty(schemaName)}
                        showDraft={actionType === "edit" && !isEmpty(schemaName)}
                        txtCancel="Duplicate"
                        onClickCancel={() => setActionType("create")}
                        onClickDelete={() => {
                            dispatch(
                                openMessageDialog({
                                    open: true,
                                    message: "Are you sure you want to delete this schema?",
                                    title: "DELETE SCHEMA",
                                })
                            );
                        }}
                        onClickOk={() => {
                            if (isEmpty(fieldErrorMsg) && isEmpty(schemaErrorMsg)) {
                                dispatch(
                                    createItemSchema({
                                        type: typeFeed,
                                        name: schemaName,
                                        data: { code: code },
                                        onComplete: ({ error, cancelled, data }) => {
                                            if (data) {
                                                dispatch(getSchemaByNameSuccess());
                                                history.goBack();
                                            }
                                        },
                                    })
                                );
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreateSchemaView;
