import { createSlice } from "@reduxjs/toolkit";

const youTubeReducer = createSlice({
    name: "youtube",
    initialState: {
        isLoading: false,
        isError: false,
        messageError: "",
    },
    reducers: {
        createYoutube: (state, action) => {
            state.isLoading = true;
        },
        createYoutubeFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
    },
});

const { reducer, actions } = youTubeReducer;
export const { createYoutube, createYoutubeFail } = actions;
export default reducer;
