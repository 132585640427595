import React, { useState, useEffect } from "react";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import StickyStyle from "../styles/HeaderPreFilterStyle";
import Logo from "../assets/icons/logo.svg";

function HeaderHomeSticky({ title }) {
    const [isActiveSticky, setActiveSticky] = useState(null);
    const classes = appStyle();
    const classesSticky = StickyStyle();
    useEffect(() => {
        const itemScroll = document.getElementById("detect-scroll");
        const handleScroll = () => {
            // const height = document.getElementById("container-HeaderDetail").clientHeight;
            const heightHeaderPrefilter = document.getElementById("headerPrefilter").clientHeight;
            const heightHeaderPrefilterSticky = document.getElementById("container-prefilter-sticky").clientHeight;

            if (itemScroll.scrollTop > heightHeaderPrefilter - heightHeaderPrefilterSticky - 20) {
                setActiveSticky(true);
            }
            if (
                itemScroll.scrollTop < heightHeaderPrefilter - heightHeaderPrefilterSticky - 20 &&
                isActiveSticky !== null
            ) {
                setActiveSticky(false);
            }
        };
        itemScroll.addEventListener("scroll", handleScroll, { passive: false });
        return () => itemScroll.removeEventListener("scroll", handleScroll);
    }, [isActiveSticky]);

    return (
        <div
            className={clsx(
                classes.containerHeadSticky,
                "display-row",
                classesSticky.stickyHeaderStyle,
                isActiveSticky === true
                    ? [classes.activeSticky]
                    : isActiveSticky === false
                    ? [classes.headSticky, "fadeOutUp"]
                    : ""
            )}
            style={{ height: 68 }}
            id="container-prefilter-sticky"
        >
            <div
                style={{
                    fontSize: 35,
                    fontWeight: "bold",
                    letterSpacing: "0.7px",
                    color: "white",
                    flex: 1,
                }}
                className={clsx("defaultMoveUp", isActiveSticky === true ? "moveUp1" : false)}
            >
                {title}
            </div>
            <img
                className={clsx("defaultMoveUp", isActiveSticky === true ? "moveUp2" : false)}
                src={Logo}
                alt=""
                style={{ width: 50, height: 50 }}
            />
        </div>
    );
}

export default HeaderHomeSticky;
