import React, { useRef, useState, useEffect, useCallback } from "react";
import createFormStyle from "../styles/CreateFormStyle";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import SectionHeader from "../components/SectionHeader";
import { useSelector, useDispatch } from "react-redux";
import ButtonBottom from "../components/ButtonBottom";
import InputWithTitle from "../components/InputWithTitle";
import SelectWithTitle from "../components/SelectWithTitle";
import { useHistory } from "react-router-dom";
import { createItemStanza, resetItemStanza } from "../redux/actions";
import { isEmpty, capitalize } from "lodash";

import { getSchema, getSchemaByName } from "../redux/reducers/SchemaReducer";
import { getCategory } from "../redux/reducers/CategoryReducer";
import { getSubCategory } from "../redux/reducers/SubCategoryReducer";




function CreateStanzaView() {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const { isError, messageError, listCat, listSchema, listSubCat} = useSelector((state) => ({
        isError: state.stanza.isError,
        messageError: state.stanza.messageError,
        listCat: state.category.data,
        listSchema: state.schema.data,
        listSubCat: state.subCategory.data,
    }));
    const history = useHistory();
    const [id, setId] = useState(null);
    
    const [stanzaCategory, setStanzaCategory] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [feedSchema, setFeedSchema] = useState(null);
    const [itemFeed, setItemFeed] = useState(history?.location?.state?.data);
    const [listSubCatId, setListSubCatId] = useState(null);
    const [subCatShow, setSubCatShow] = useState([]);
    
    const dispatch = useDispatch();
    const [atDefault, setAtDefault] = useState(true);
    const [errorMessSchema, setErrorMessSchema] = useState("");
    const schemaRef = useRef();

    useEffect(() => {
        dispatch(getCategory());
        dispatch(getSubCategory());
        dispatch(getSchema("event"));
        dispatch(resetItemStanza())
    }, []);

    useEffect(() => {
        setCategoryId(itemFeed?.categoryId ?? null);
        setFeedSchema(itemFeed?.event?.feed_schema ?? null);
        setListSubCatId(itemFeed?.subCategoriesId ?? null);
    }, [itemFeed]);

    function createStanza() {
        
        dispatch(
            createItemStanza({
                id,
                dataST: {
                    stanzaCategory: stanzaCategory,
                    eventSchema: feedSchema,
                    subcategoryId : listSubCatId,
                    categoryId: categoryId,
                },
                onComplete: ({ error, cancelled, data }) => {
                    if (data) {
                        history.goBack();
                    }
                },
            })
        );
    }

    return (
        <div className="display-col">
            <div className={classes.titleHeader}>Create > Stanza</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        title="NEW STANZA"
                        onClickClose={() => history.goBack()}
                        onClickJson={() => setAtDefault(false)}
                        isDefault={true}
                        onClickDefault={() => {
                            if (isEmpty(errorMessSchema)) {
                                schemaRef.current = undefined;
                            }
                        }}
                        type="stanza"
                    />
                    <div className={classes.containerDivider} />
                        <div className={clsx(classes.containerForm, appStyles.scrollbar)}>
                            <div>
                                <InputWithTitle
                                    data={id}
                                    require
                                    style={{ width: 295 }}
                                    showComment={false}
                                    placeHolder={'Stanza ID'}
                                    onChange={(value) => setId(value)}
                                    title="Stanza ID"
                                />
                                <InputWithTitle
                                    data={stanzaCategory}
                                    style={{ width: 295 }}
                                    showComment={false}
                                    placeHolder={'Stanza Category (Optional)'}
                                    onChange={(value) => setStanzaCategory(value)}
                                    title="Stanza Category"
                                />
                                <SelectWithTitle
                                    data={categoryId}
                                    list={listCat.map((item) => ({ title: item.name, value: item.uid }))}
                                    title="Category"
                                    require
                                    placeHolder="Select a category"
                                    onItemChange={(value) => {
                                        setCategoryId(value);
                                        setListSubCatId(null);
                                        setSubCatShow(listSubCat.filter((el) => el.categoryId === value));
                                    }}
                                />
                                <SelectWithTitle
                                    data={listSubCatId}
                                    list={subCatShow.map((item) => ({ title: item.name, value: item.uid }))}
                                    title="Subcategories"
                                    require
                                    placeHolder="Add subcategory"
                                    onItemChange={(value) => {
                                        setListSubCatId(value)
                                    }}
                                />
                                <SelectWithTitle
                                    data={feedSchema}
                                    require
                                    list={listSchema.map((item) => ({ title: item, value: item }))}
                                    showComment={false}
                                    title="Event Schema"
                                    placeHolder="Select a schema"
                                    style={{ width: "calc(50% - 10px)" }}
                                    onItemChange={(value) => {
                                        setFeedSchema(value);
                                        dispatch(getSchemaByName({ type: "event", name: value }));
                                    }}
                                />
                            </div>
                        </div>                 
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {isError && <span className={classes.textError}>{messageError}</span>}
                    </div>
                    <ButtonBottom
                        showDraft={false}
                        txtOk="SAVE"
                        onClickOk={() => {
                            if (isEmpty(errorMessSchema)) {
                                createStanza();
                            }
                        }}
                    />
                </div>
                <div className="display-center-col designPreview" style={{ flex: 1, padding: "0 20px" }}>
                    <div style={{ width: 300, height: 500, background: "#282828" }}></div>
                </div>
            </div>
        </div>
    );
}

export default CreateStanzaView;
