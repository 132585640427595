import React from "react";
import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { openMessageDialog, onClickConfirmDialog } from "../redux/reducers/AppReducer";
import appStyle from "../styles/AppStyle";

function MessageDialog(props) {
    const {} = props;
    const appStyles = appStyle();
    const { openConfirm, messageDialog, titleDialog } = useSelector((state) => ({
        openConfirm: state.app.openConfirmDialog,
        messageDialog: state.app.messageDialog,
        titleDialog: state.app.titleDialog,
    }));
    const dispatch = useDispatch();

    const isInfoDialog = titleDialog === 'ERROR' || titleDialog === 'INFO'

    return (
        <Dialog open={openConfirm}>
            <div className={appStyles.containerDeleteDialog}>
                <div className="display-row">
                    <div className={appStyles.titleDeleteDialog}>{titleDialog}</div>
                    <CloseIcon
                        style={{ color: "white", width: 20, height: 20, cursor: "pointer" }}
                        onClick={() => dispatch(openMessageDialog({ open: false, message: "", title: "" }))}
                    />
                </div>
                <div className={appStyles.contentDeleteDialog}>{messageDialog}</div>
                <div style={{ height: 1, background: " rgba(133, 140, 148, 0.6)" }} />
                <div className="display-row" style={{ marginTop: 22, position: "absolute", width: 132, right: 33 }}>
                    <div style={{ width: 61 }}>
                        {!isInfoDialog && (
                            <div
                                className={appStyles.btnCancelDeleteDialog}
                                onClick={() => dispatch(openMessageDialog({ open: false, message: "", title: "" }))}
                            >
                                Cancel
                            </div>
                        )}
                    </div>
                    <div
                        className={appStyles.btnOkDeleteDialog}
                        onClick={() => {
                            dispatch(openMessageDialog({ open: false, message: "", title: "" }));
                            if (!isInfoDialog) {
                                dispatch(onClickConfirmDialog(true));
                            }
                        }}
                    >
                        {isInfoDialog ? "Close" : "Delete"}
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default MessageDialog;
