import { faBorderNone } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core";
const detailViewStyle = makeStyles((theme) => ({
    containerHead: {
        // height: 222,
        width: "100%",
        padding: "0 20px 20px 20px",
    },
    containerHeaderDetail: {
        minHeight: 222,
    },
    containerEvents: {
        height: 115,
        width: "100%",
        padding: "0px 20px 20px 20px",
        display: "flex",
        justifyContent: "space-between",
    },
    containerHeadSticky: {
        width: "100%",
        padding: "0px 20px 20px 20px",
    },
    txtMulish: {
        fontFamily: "MulishBold",
        [theme.breakpoints.up("768")]: {
            fontSize: "12px",
            lineHeight: "18px",
        },
        [theme.breakpoints.down("769")]: {
            fontSize: "12px",
            lineHeight: "20px",
        },
        color: "#8CB2F8",
    },
    txtMulishSidebarOpen: {
        fontFamily: "MulishBold",
        fontSize: "8px",
        lineHeight: "10px",
        color: "#8CB2F8",
    },
    containerIconHeader: {
        width: 119,
        height: 36,
        background: "rgba(140, 178, 248,0.1)",
        borderRadius: 8,
    },
    containerIconHeaderSidebarOpen: {
        width: 80,
        height: 24,
        background: "rgba(140, 178, 248,0.1)",
        borderRadius: 8,
    },
    txtTitle: {
        fontFamily: "SFProDisplayHeavy",
        [theme.breakpoints.up("979")]: {
            lineHeight: "84px",
            fontSize: "70px",
            height: "84px",
            maxWidth: "calc(100% - 190px)",
        },
        [theme.breakpoints.down("980")]: {
            lineHeight: "84px",
            fontSize: "46px",
            height: "84px",
            maxWidth: "calc(100% - 143px)",
        },
        [theme.breakpoints.down("769")]: {
            lineHeight: "45px",
            fontSize: "38px",
            height: "45px",
            maxWidth: "calc(100% - 120px)",
        },
        color: "white",
        letterSpacing: 0.7,
        flexShrink: 0,
        display: "inline-block",
    },
    txtTitleSidebarOpen: {
        fontFamily: "SFProDisplayHeavy",
        lineHeight: "36px",
        height: "36px",
        fontSize: "30px",
        color: "white",
        letterSpacing: 0.7,
        flexShrink: 0,
        display: "inline-block",
        width: "calc(100% - 104px)",
    },
    txtTitleStickyHeader: {
        fontFamily: "SFProDisplayHeavy",
        fontSize: 35,
        color: "white",
        letterSpacing: 0.7,
        flexShrink: 0,
        display: "inline-block",
        marginLeft: "15px",
    },
    squareImage: {
        [theme.breakpoints.up("768")]: {
            width: "201px",
            height: "201px",
        },
        [theme.breakpoints.down("769")]: {
            width: "131px",
            height: "131px",
        },
        flexShrink: 0,
        objectFit: "cover",
    },
    squareImageSidebarOpen: {
        width: "96px",
        height: "96px",
        flexShrink: 0,
        objectFit: "cover",
    },
    typeAndStatusGroup: {
        [theme.breakpoints.up("768")]: {
            marginLeft: "35px",
        },
        [theme.breakpoints.down("769")]: {
            marginLeft: "18px",
        },
    },
    typeAndStatusGroupSidebarOpen: {
        marginLeft: "9px",
    },
    titleGroup: {
        [theme.breakpoints.up("768")]: {
            marginLeft: "35px",
            height: 84,
        },
        [theme.breakpoints.down("769")]: {
            marginLeft: "18px",
            height: 84,
        },
    },
    titleGroupSidebarOpen: {
        marginLeft: "9px",
        height: 84,
    },
    circleImage: {
        display: "inline-flex",
        objectFit: "cover",
        [theme.breakpoints.up("768")]: {
            width: "70px",
            height: "70px",
            marginRight: "16px",
        },
        [theme.breakpoints.down("769")]: {
            width: "50px",
            height: "50px",
            marginRight: "10px",
        },
        marginRight: 16,
        borderRadius: "50%",
        background: "#282828",
        flexShrink: 0,
    },
    circleImageSidebarOpen: {
        display: "inline-flex",
        width: "37px",
        height: "37px",
        marginRight: "7px",
        borderRadius: "50%",
        background: "#282828",
        flexShrink: 0,
        objectFit: "cover",
    },
    txtBottomHeader: {
        color: "#ADAFB2",
        opacity: 0.7,
        fontSize: 12,
        lineHeight: "20px",
        [theme.breakpoints.up("768")]: {
            marginLeft: "121px",
        },
        [theme.breakpoints.down("769")]: {
            marginLeft: "78px",
        },
        height: 20,
        overflow: "hidden",
        maxWidth: 600,
    },
    txtBottomHeaderSidebarOpen: {
        color: "#ADAFB2",
        opacity: 0.7,
        fontSize: 8,
        lineHeight: "20px",
        marginLeft: "56px",
        height: 20,
        overflow: "hidden",
        maxWidth: 600,
    },
    txtInHeader: {
        fontSize: 10,
        lineHeight: "12px",
        letterSpacing: 1.4,
        height: 33,
        alignItems: "flex-end",
        [theme.breakpoints.up("768")]: {
            marginLeft: 121,
        },
        [theme.breakpoints.down("769")]: {
            margin: "8px 0 0 78px",
        },
    },
    txtInHeaderSidebarOpen: {
        fontSize: 8,
        lineHeight: "10px",
        letterSpacing: 1.4,
        margin: "8px 0 0 56px",
    },
    txtAddTo: {
        fontFamily: "Roboto",
        marginLeft: 20,
        height: 24,
        fontSize: 14,
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        color: "#8CB2F8",
    },
    txtHeaderCol: {
        color: "#9FA2B4",
        letterSpacing: 0.2,
        fontSize: 14,
        lineHeight: "18px",
        fontFamily: "MulishBold",
    },
    containerList: {
        background: "#282828",
        // borderRadius: 8,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        margin: "0px 20px",
        padding: "0px 20px",
        // minHeight: "calc(100% - 221px)",
    },
    txtUid: {
        color: "#C5C7CD",
        opacity: 0.4,
        [theme.breakpoints.up("768")]: {
            fontSize: 12,
        },
        [theme.breakpoints.down("769")]: {
            fontSize: 10,
        },
        fontSize: 12,
        lineHeight: "16px",
        fontFamily: "Mulish",
        letterSpacing: 0.1,
    },
    txtItemLocation: {
        fontFamily: "Mulish",
        fontSize: 14,
        lineHeight: "20px",
        color: "white",
        letterSpacing: 0.2,
        margin: "16px 0 4px",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    txtSubItemLocation: {
        fontFamily: "Mulish",
        fontSize: 12,
        lineHeight: "16px",
        color: "#C5C7CD",
        letterSpacing: 0.1,
    },
    txtDetails: {
        fontFamily: "MulishSemiBold",
        fontSize: 14,
        lineHeight: "20px",
        color: "white",
        letterSpacing: 0.2,
    },
    txtSubDetails: {
        fontFamily: "Mulish",
        fontSize: 12,
        lineHeight: "16px",
        color: "#C5C7CD",
        letterSpacing: 0.1,
    },
    imageItem: {
        width: 71,
        height: 74,
        margin: "10px 12px 8px 54px",
        borderRadius: 10,
        background: "#181818",
        objectFit: "cover",
    },
    txtRowPerPage: {
        fontFamily: "Mulish",
        [theme.breakpoints.up("768")]: {
            fontSize: 14,
        },
        [theme.breakpoints.down("769")]: {
            fontSize: 12,
        },
        lineHeight: "20px",
        color: "#9FA2B4",
        letterSpacing: 0.3,
    },
    txtCountPag: {
        fontFamily: "Mulish",
        width: 22,
        height: 18,
        [theme.breakpoints.up("768")]: {
            fontSize: 14,
        },
        [theme.breakpoints.down("769")]: {
            fontSize: 12,
        },
        lineHeight: "20px",
        color: "#4B506D",
        letterSpacing: 0.3,
        margin: "0 3px 0 6px",
    },
    txtPagePag: {
        //minWidth: 50,
        height: 18,
        margin: "0 24px 0 48px",
        fontFamily: "Mulish",
        [theme.breakpoints.up("768")]: {
            fontSize: 14,
            margin: "0 24px 0 48px",
        },
        [theme.breakpoints.down("769")]: {
            fontSize: 12,
            margin: "0 10px 0 10px",
        },
        lineHeight: "20px",
        color: "#9FA2B4",
        letterSpacing: 0.3,
        whiteSpace: "nowrap",
    },
    containerOver: {
        position: "absolute",
        right: 0,
        left: 0,
        bottom: 0,
        top: 0,
    },
    txtEvents: {
        // position: "absolute",
        fontWeight: 800,
        fontSize: 70,
        letterSpacing: "0.7px",
        color: "white",
        fontWeight: "bold",
    },
    activeSticky: {
        opacity: "1 !important",
        visibility: "visible !important",
        display: "flex",
        position: "fixed",
        width: "calc(100vw - 210px)",
        zIndex: 2,
        backgroundColor: "#181818",
        animationDuration: "0.5s",
    },
    headSticky: {
        opacity: 0,
    },
    stickyHeaderStyle: {
        visibility: "hidden",
        opacity: 0,
        width: "calc(100vw - 210px)",
        zIndex: 2,
        backgroundColor: "#181818",
        animationDuration: "0.5s",
        // borderBottom: "1px solid #3b3b3c",
        overflow: "hidden",
        paddingRight: 20,
        position: "absolute",
        top: 48,
        // width: "100%",
    },
    containerHeadStickyEvent: {
        marginTop: -70,
    },
    containerHeadStickyDetail: {
        // marginTop: -87,
    },
    headerTable: {
        height: "35px",
        borderBottom: "1px solid rgba(223, 224, 235, 0.1)",
        marginTop: "14px",
        // minWidth: "500px",
        position: "sticky",
        top: 79,
        background: "#282828",
        zIndex: "2",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
    },
    headerTableEvent: {
        top: "68px",
    },
    refilterButtonAddStick: {
        visibility: "hidden",
        animationDuration: "0.3s",
    },
    activeButtonCreated: {
        visibility: "visible !important",
    },
}));
export default detailViewStyle;
