import React, { useEffect, useState, useRef, useCallback } from "react";
import createFormStyle from "../styles/CreateFormStyle";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import InputWithTitle from "../components/InputWithTitle";
import SummerNote from "../components/SummerNote";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ButtonBottom from "../components/ButtonBottom";
import SelectWithTitle from "../components/SelectWithTitle";
import SectionHeader from "../components/SectionHeader";
import { getSchema, getSchemaByName } from "../redux/reducers/SchemaReducer";
import gqlASTParser, {
    AWS_SCALARS,
    compileSchemaComponentsFromParsedAST,
    MEDIA_RESOURCE_TYPE,
} from "../utils/gqlASTParser";
import SchemaObject from "../components/Feed/schema/SchemaObject";
import SchemaUnion from "../components/Feed/schema/SchemaUnion";
import ImagePicker from "../components/ImagePicker";
import JsonComponent from "../components/JsonComponent";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { getPersonas } from "../redux/reducers/PersonaReducer";
import { createItemPersona, deleteItemPersona, updateItemPersona } from "../redux/actions";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import SearchSelectTitle from "../components/SearchSelectTitle";

function CreatePersonaView() {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const { schema, listSchema, isError, messageError, listPersona, onClickDelete } = useSelector((state) => ({
        schema: state.schema.schema,
        listSchema: state.schema.data,
        isError: state.persona.isError,
        messageError: state.persona.messageError,
        listPersona: state.persona.data,
        onClickDelete: state.app.onClickDelete,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [imageRes, setImageRes] = useState();
    const [feedSchema, setFeedSchema] = useState();
    const [schemaData, setSchemaData] = useState();
    const [schemaValue, setSchemaValue] = useState(undefined);

    const [itemPersona, setItemPersona] = useState();
    const [actionType, setActionType] = useState("create");
    const [atDefault, setAtDefault] = useState(true);
    const [errorMessSchema, setErrorMessSchema] = useState("");
    const schemaRef = useRef();

    const personaSchema = Yup.object()
        .shape({
            uid: Yup.string().nullable(),
            name: Yup.string().nullable(),
            description: Yup.string().nullable(),
            image: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            schema_name: Yup.string().nullable(),
            feed_data: Yup.object().nullable(),
        })
        .noUnknown()
        .strict(true);

    const editPersonaSchema = Yup.object()
        .shape({
            name: Yup.string().nullable(),
            description: Yup.string().nullable(),
            image: Yup.object({
                path: Yup.string().nullable(),
                mediaType: Yup.string().nullable(),
            }),
            schema_name: Yup.string().nullable(),
            feed_data: Yup.object().nullable(),
        })
        .noUnknown()
        .strict(true);

    useEffect(() => {
        dispatch(getSchema("persona"));
    }, []);

    useEffect(() => {
        setName(itemPersona?.name ?? null);
        setId(itemPersona?.uid ?? null);
        setDescription(itemPersona?.description ?? null);
        setImageRes(itemPersona?.image ?? null);
        setFeedSchema(itemPersona?.schema_name ?? null);
        setSchemaData(itemPersona?.feed_data ?? null);
    }, [itemPersona]);

    useEffect(() => {
        if (!isEmpty(listSchema) && !isEmpty(feedSchema) && listSchema.some((el) => el === feedSchema)) {
            dispatch(getSchemaByName({ type: "persona", name: feedSchema }));
        }
    }, [listSchema, feedSchema]);

    useEffect(() => {
        if (!isEmpty(schema)) {
            const parsedAST = gqlASTParser(
                schema.code.replace(/\\n/gm, "\n").replace(/\\t/gm, "\t") + AWS_SCALARS + MEDIA_RESOURCE_TYPE
            );
            const schemaCompile = compileSchemaComponentsFromParsedAST(schema.name, parsedAST);
            setSchemaValue(schemaCompile);
        }
    }, [schema]);

    function createPersona() {
        var data = {
            name: name,
            description: description,
            image: imageRes,
            schema_name: feedSchema,
            feed_data: schemaData,
        };

        if (actionType === "create") {
            dispatch(
                createItemPersona({
                    id,
                    data,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
        } else {
            dispatch(
                updateItemPersona({
                    id,
                    data,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
        }
    }

    useEffect(() => {
        if (onClickDelete) {
            dispatch(
                deleteItemPersona({
                    payload: itemPersona.uid,
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.goBack();
                        }
                    },
                })
            );
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    const handleSchema = useCallback(
        (itemPersona) => {
            var tempSchema = actionType === "create" ? personaSchema : editPersonaSchema;
            tempSchema
                .validate(schemaRef.current)
                .then(function (value) {
                    // console.log(value);
                    if (!isEmpty(value)) {
                        setErrorMessSchema();
                        setItemPersona({ ...itemPersona, ...value });
                    }
                })
                .catch((e) => {
                    // console.log(e);
                    setAtDefault(false);
                    setErrorMessSchema(e.message);
                });
        },
        [schemaRef.current]
    );

    return (
        <div className="display-col">
            <div className={classes.titleHeader}>{`${actionType === "create" ? "Create" : "Edit"} > Persona`}</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        title={`${actionType === "create" ? "NEW PERSONA" : "EDIT PERSONA"}`}
                        txtAction={`${actionType === "create" ? "Edit an existing Persona" : "Create New Persona"}`}
                        onClickClose={() => history.goBack()}
                        onClickJson={() => setAtDefault(false)}
                        showTextEdit={true}
                        onClickEdit={() => {
                            if (actionType === "create") {
                                setActionType("edit");
                                if (isEmpty(listPersona)) {
                                    dispatch(getPersonas());
                                }
                            } else {
                                setItemPersona();
                                setActionType("create");
                            }
                        }}
                        isDefault={atDefault}
                        onClickDefault={() => {
                            if (isEmpty(errorMessSchema)) {
                                schemaRef.current = undefined;
                                setAtDefault(true);
                            }
                        }}
                        type="persona"
                    />
                    <div className={classes.containerDivider} />
                    {atDefault ? (
                        <div className={clsx(classes.containerForm, appStyles.scrollbar)}>
                            {actionType === "create" && (
                                <div>
                                    <InputWithTitle
                                        data={id}
                                        title="Unique ID"
                                        require
                                        placeHolder="Custom Persona ID"
                                        onChange={(value) => {
                                            setId(value);
                                        }}
                                    />
                                    <InputWithTitle
                                        data={name}
                                        title="Name"
                                        require
                                        placeHolder="Name your calendar jockey persona"
                                        onChange={(value) => {
                                            setName(value);
                                        }}
                                    />
                                </div>
                            )}
                            {actionType === "edit" && (
                                <SearchSelectTitle
                                    data={id}
                                    list={listPersona.map((item) => ({ title: item.name, value: item.uid }))}
                                    title="Name"
                                    require
                                    placeHolder="Select a Persona"
                                    onItemChange={(value) => {
                                        setItemPersona(listPersona.find((el) => el.uid === value));
                                    }}
                                />
                            )}
                            <ImagePicker
                                data={imageRes}
                                title="Image"
                                placeHolder="Image URL"
                                imageSource={(value) => setImageRes(value)}
                            />
                            <SummerNote
                                data={description}
                                title="Description"
                                placeholder="Persona description"
                                onChange={(content) => setDescription(content)}
                            />
                            <SelectWithTitle
                                data={feedSchema}
                                list={listSchema.map((item) => ({ title: item, value: item }))}
                                showComment={false}
                                title="Metadata"
                                placeHolder="Select a schema"
                                style={{ marginTop: 32, width: "calc(50% - 10px)" }}
                                onItemChange={(value) => {
                                    setFeedSchema(value);
                                    dispatch(getSchemaByName({ type: "persona", name: value }));
                                }}
                            />
                            {!isEmpty(schema) && !isEmpty(schemaValue) && schemaValue.object && (
                                <SchemaObject
                                    onValueChange={(value) => setSchemaData(value)}
                                    schema={schemaValue.object}
                                    initialValue={itemPersona?.feed_data}
                                />
                            )}
                            {!isEmpty(schema) && !isEmpty(schemaValue) && schemaValue.union && (
                                <SchemaUnion
                                    visibleName={schema.name}
                                    onValueChange={(value) => setSchemaData(value)}
                                    schema={schemaValue}
                                    initialValue={itemPersona?.feed_data}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={classes.containerJsonForm}>
                            <div className={classes.containerJsonError}>
                                {!isEmpty(errorMessSchema) && (
                                    <span className={classes.textError}>{errorMessSchema}</span>
                                )}
                            </div>
                            <JsonComponent
                                data={personaSchema.cast({
                                    uid: actionType === "create" ? id : undefined,
                                    name: name,
                                    description: description,
                                    image: {
                                        path: imageRes?.path ?? null,
                                        mediaType: imageRes?.mediaType ?? null,
                                    },
                                    schema_name: feedSchema,
                                    feed_data: schemaData,
                                })}
                                onChange={(value) => {
                                    schemaRef.current = value;
                                    handleSchema(itemPersona);
                                    // setItemPersona({ ...value, uid: itemPersona.uid });
                                }}
                            />
                        </div>
                    )}
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {isError && <span className={classes.textError}>{messageError}</span>}
                    </div>
                    <ButtonBottom
                        showDelete={actionType === "edit" && !isEmpty(itemPersona)}
                        onClickDelete={() =>
                            dispatch(
                                openMessageDialog({
                                    open: true,
                                    message: "Are you sure you want to delete this persona?",
                                    title: "DELETE PERSONA",
                                })
                            )
                        }
                        onClickCancel={() => setActionType("create")}
                        txtCancel="Duplicate"
                        showDraft={actionType === "edit" && !isEmpty(itemPersona)}
                        txtOk="SAVE"
                        onClickOk={() => {
                            if (isEmpty(errorMessSchema)) {
                                createPersona();
                            }
                        }}
                    />
                </div>
                <div className="display-center-col designPreview" style={{ flex: 1, padding: "0 20px" }}>
                    <div style={{ width: 300, height: 500, background: "#282828" }}></div>
                </div>
            </div>
        </div>
    );
}

export default CreatePersonaView;
