import React, { useEffect, useState } from "react";
import ItemSidebar from "./ItemSidebar";
import IconHome from "../assets/icons/home.svg";
import IconHomeActive from "../assets/icons/home_active.svg";
import IconSearch from "../assets/icons/search.svg";
import IconSearchActive from "../assets/icons/search_active.svg";
import IconLive from "../assets/icons/radio.svg";
import IconLiveActive from "../assets/icons/radio_active.svg";
import IconPending from "../assets/icons/pause-circle_filled.svg";
import IconPendingActive from "../assets/icons/pause-circle_filled_active.svg";
import IconDraft from "../assets/icons/paste.svg";
import IconDraftActive from "../assets/icons/paste_active.svg";
import IconArchive from "../assets/icons/save-alt.svg";
import IconArchiveActive from "../assets/icons/save-alt-active.svg";
import IconEvent from "../assets/icons/directions-boat.svg";
import IconDown from "../assets/icons/arrow_drop_down.svg";
import IconCollapse from "../assets/icons/arrow_collapse.svg";
import IconPlus from "../assets/icons/plus-icon.svg";
import { Collapse, Popover } from "@material-ui/core";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import _, { isEmpty, unset } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ItemSubSidebar from "./ItemSubSidebar";
import { useHistory, useLocation } from "react-router-dom";
import { getCalendars } from "../redux/reducers/CalendarReducer";
import { getCollections } from "../redux/reducers/CollectionReducer";
import PopupMenu from "./PopupMenu";
import styled from "styled-components";
import { onChangeOpenSidebar } from "../redux/reducers/AppReducer";
import { useDrop } from "react-dnd";
import { updateItemFeedEvent } from "../redux/actions";
import ItemFeedSidebar from "./ItemFeedSidebar";
import { Resizable } from "re-resizable";

const Ul = styled.div`
    @media (max-width: 768px) {
        flex-flow: column nowrap;
        position: relative;
        top: 0;
        left: 0;
        height: 100vh;
        // min-width: ${({ openSidebar }) => (openSidebar ? "210px" : "50px")};
        // max-width: ${({ openSidebar }) => (openSidebar ? "210px" : "50px")};
        padding-left: 0px;
        overflow: hidden;
    }
`;

const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "solid 1px #ddd",
    // background: "#f0f0f0"
  };

const Sidebar = (props) => {
    const appStyles = appStyle();
    const [anchorEl, setAnchorEl] = useState();

    const [ widthBox, setWidthBox ] = useState(200);
    const [heightBox, setHeightBox ] = useState(200);

    const dispatch = useDispatch();
    const { collections, calendars, statistics, isOpenSidebar, tags } = useSelector((state) => ({
        collections: state.collection.data,
        calendars: state.calendar.data,
        statistics: state.statistics.data,
        isOpenSidebar: state.app.isOpenSidebar,
        tags : state.tag.data,
    }));
    const [openSidebar, setOpenSidebar] = useState();
    const { innerWidth: width, innerHeight: height } = window;

    useEffect(() => {
        const sideBar = document.getElementById('sidebar').style;
        sideBar.width = 'unset';
        sideBar.minWidth = 'unset';
        sideBar.display = 'block';
        setOpenSidebar(isOpenSidebar);
    }, [isOpenSidebar]);

    const history = useHistory();
    const location = useLocation();
    const [{ isOverEventDrop }, eventDropRef] = useDrop({
        accept: "item-assign",
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOverEventDrop: monitor.isOver(),
        }),
        drop: (item, monitor) => {
            dispatch(
                updateItemFeedEvent({
                    uid: encodeURIComponent(item.data.uid),
                    data: { collection_id: null },
                    onComplete: ({ error, cancelled, data }) => {
                        if (data) {
                            history.push("/events");
                        }
                    },
                })
            );
        },
    });

    const handleResize = () => {
        if (isOpenSidebar === true) {
            const stickyCalendar = document?.getElementById('ctn-header-sticky');
            const sideBar = document.getElementById('sidebar').style.width;
            const CRS = document.querySelector('.container-carousel')
            let height
            
            const widthWindow = window.innerWidth;

            if (!CRS) {
                height = widthWindow - parseInt(sideBar) - 57
            } else {
                height = CRS.offsetWidth
            }
            let n ;
            n = Math.floor(height/240);
            
            
            if(1410 <= widthWindow && widthWindow < 1650) {
                props.setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        desktop: {
                            breakpoint: {
                                max: 1650,
                                min: 1410,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(1650 <= widthWindow && widthWindow < 3000) {
                props.setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        fullhd: {
                            breakpoint: {
                                max: 3000,
                                min: 1650,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(1190 <= widthWindow && widthWindow < 1410) {
                props.setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        tablet1: {
                            breakpoint: {
                                max: 1410,
                                min: 1190,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(950 <= widthWindow && widthWindow < 1190) {
                props.setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        tablet: {
                            breakpoint: {
                                max: 1190,
                                min: 950,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(710 <= widthWindow && widthWindow < 950) {
                props.setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        mobile: {
                            breakpoint: {
                                max: 950,
                                min: 710,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            } else if(0 <= widthWindow && widthWindow < 710) {
                props.setResponsive(prevRes => {
                    return { 
                        ...prevRes, 
                        miniMobile: {
                            breakpoint: {
                                max: 710,
                                min: 0,
                            },
                            items: n,
                            slidesToSlide: n
                        },
                    }
                })
            }
            
            if( stickyCalendar ) {
                stickyCalendar.style.width =  `calc(100vw - ${sideBar})`
            }
        }
    }

    return (
        <Resizable
            id="sidebar"
            style={style}
            defaultSize={{
                width: "210px",
                height: "100%"
            }}
            minWidth="210px"
            maxWidth="35%"
            onResize={handleResize}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
        >
            <Ul  ref={props.scrollRef} className={clsx("sidebar-animation", openSidebar ? "sidebar-open" : false)} openSidebar={openSidebar}>
                <div
                    className={clsx("container-sidebar", appStyles.scrollbar)}
                    style={{ color: "#ADAFB2", fontSize: 14, lineHeight: "17px" }}
                >
                    <div
                        style={{
                            fontSize: 12,
                            lineHeight: "14px",
                            letterSpacing: "2.01px",
                            margin: "10px 15px 5px",
                            height: "40px",
                        }}
                    >
                        <div
                            className={clsx({
                                [appStyles.sidebarCloseItem]: !openSidebar,
                                [appStyles.sidebarOpenItem]: openSidebar,
                            })}
                        >
                            {"MAIN"}
                        </div>
                    </div>
                    <ItemSidebar
                        icon={IconHome}
                        openSidebar={openSidebar}
                        iconActive={IconHomeActive}
                        title="Home"
                        active={isEmpty(location.pathname.split("/")[1])}
                        onItemClick={() => history.push("/")}
                    />
                    <ItemSidebar
                        icon={IconLive}
                        openSidebar={openSidebar}
                        iconActive={IconLiveActive}
                        title="Live"
                        active={location.pathname.includes("live")}
                        showBadge={true}
                        numberBadge={statistics?.live}
                        onItemClick={() => history.push("/live")}
                    />
                    <ItemSidebar
                        icon={IconPending}
                        openSidebar={openSidebar}
                        iconActive={IconPendingActive}
                        title="Pending"
                        active={location.pathname.includes("pending")}
                        showBadge={true}
                        numberBadge={statistics?.pending}
                        onItemClick={() => history.push("/pending")}
                    />
                    <ItemSidebar
                        icon={IconDraft}
                        openSidebar={openSidebar}
                        iconActive={IconDraftActive}
                        title="Draft"
                        active={location.pathname.includes("draft")}
                        showBadge={true}
                        numberBadge={statistics?.draft}
                        onItemClick={() => history.push("/draft")}
                    />
                    <ItemSidebar
                        icon={IconArchive}
                        openSidebar={openSidebar}
                        iconActive={IconArchiveActive}
                        title="Archived"
                        active={location.pathname.includes("archived")}
                        onItemClick={() => history.push("/archived")}
                    />
                    <div className="display-center-row" style={{ margin: "51px 0 25px" }}>
                        <div
                            style={{ marginLeft: 15, whiteSpace: "nowrap" }}
                            className={clsx({
                                [appStyles.sidebarCloseItem]: !openSidebar,
                                [appStyles.sidebarOpenItem]: openSidebar,
                            })}
                        >
                            FEED TYPES
                        </div>
                        <div style={{ flex: 1, marginRight: 25, height: 1, marginLeft: 13, background: "#585758" }} />
                    </div>

                    <div
                        ref={eventDropRef}
                        style={{ background: isOverEventDrop ? "rgba(255, 255, 255, 0.2)" : "initial" }}
                    >
                        <ItemSidebar
                            icon={IconEvent}
                            title="EVENTS"
                            showOver={false}
                            styleTitle={{ fontSize: 12, lineHeight: "14px", letterSpacing: "1.31px" }}
                            showAddIcon={true}
                            onItemClick={() => history.push("/events")}
                            onClickAdd={() => {
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(`/create/event`);
                                } else {
                                    history.push(`/create/event`);
                                }
                            }}
                            openSidebar={openSidebar}
                        />
                    </div>

                    <ItemFeedSidebar title="CALENDARS" data={calendars} />

                    <ItemFeedSidebar title="COLLECTIONS" data={collections} />

                    {/* <ItemFeedSidebar title="TAGS" data={tags} /> */}

                    <div style={{ marginRight: 25, height: 2, background: "#181818", marginTop: 15 }} />
                    <div
                        style={{ height: 46, cursor: "pointer" }}
                        className="display-center-row"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                        <img src={IconPlus} width={28} height={28} style={{ margin: "0 10px" }} alt="" />
                        {openSidebar && <div>Create New</div>}
                    </div>
                    <div style={{ marginRight: 25, height: 2, background: "#181818" }} />
                    <Popover
                        open={!_.isEmpty(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        onClose={() => setAnchorEl()}
                    >
                        <PopupMenu
                            listMenu={[
                                "Calendar",
                                "Collection",
                                "Event",
                                "Category",
                                "Subcategory",
                                "Schema",
                                "Persona",
                                "Tag",
                                "Youtube",
                                "Stanza"
                            ]}
                            onClick={(item) => {
                                setAnchorEl();
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(`/create/${item.toLowerCase()}`);
                                } else {
                                    history.push(`/create/${item.toLowerCase()}`);
                                }
                            }}
                        />
                    </Popover>
                </div>
            </Ul>
        </Resizable>
    );
};

export default Sidebar;
