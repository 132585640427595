import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put, select } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { openMessageDialog } from "../reducers/AppReducer";
import {
    getSchema,
    getSchemaFail,
    getSchemaSuccess,
    getSchemaByName,
    getSchemaByNameSuccess,
    getSchemaByNameFail,
    updateSchema,
    updateSchemaFail,
    createSchema,
    createSchemaFail,
    deleteSchema,
    deleteSchemaFail,
} from "../reducers/SchemaReducer";

function* schemaSagaWatcher() {
    yield takeLatest(getSchema, getListSchema);
    yield takeLatest(getSchemaByName, getSchemaBy);
    yield takeLatest(updateSchema, withCallback(updateItem));
    yield takeLatest(createSchema, withCallback(createItem));
    yield takeLatest(deleteSchema, withCallback(deleteItem));
}

function* getListSchema(action) {
    try {
        const response = yield call(handleApi, `dashboard/schema/${action.payload}`);
        yield put(getSchemaSuccess(response.data));
    } catch (error) {
        yield put(getSchemaFail({ isError: true, message: error.message }));
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

function* getSchemaBy(action) {
    try {
        const response = yield call(handleApi, `dashboard/schema/${action.payload.type}/${action.payload.name}`);
        yield put(getSchemaByNameSuccess(response.data));
    } catch (error) {
        yield put(getSchemaByNameFail({ isError: true, message: error.message }));
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

function* createItem(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/schema/${action.payload.type}/${encodeURIComponent(action.payload.name)}`,
            "POST",
            action.payload.data
        );
        // yield put(getSchemaSuccess(state.schema.data.concat()));
        return true;
    } catch (error) {
        yield put(createSchemaFail({ isError: true, message: error.message }));
        return false;
    }
}

function* updateItem(action) {
    try {
        const response = yield call(
            handleApi,
            `dashboard/schema/${action.payload.type}/${encodeURIComponent(action.payload.name)}`,
            "POST",
            action.payload.data
        );
        return true;
    } catch (error) {
        yield put(updateSchemaFail({ isError: true, message: error.message }));
        return false;
    }
}

function* deleteItem(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/schema/${action.payload.type}/${encodeURIComponent(action.payload.name)}`,
            "DELETE"
        );
        // yield put(getSchemaSuccess(state.schema.data.concat()));
        return true;
    } catch (error) {
        yield put(deleteSchemaFail({ isError: true, message: error.message }));
        return false;
    }
}

export default schemaSagaWatcher;
