import { makeStyles } from "@material-ui/core";

const itemCarouselStyle = makeStyles((theme) => ({
    conIconMore: {
        position: "absolute",
        right: 0,
        top: 0,
        padding: 3,
    },
    imgStyle: {
        objectFit: "cover",
        pointerEvents: "none",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    conImg: {
        width: 230,
        height: 230,
        position: "relative",
    },
    conBlur: {
        width: 230,
        height: 230,
        position: "absolute",
        background: "rgba(0, 0, 0, 0.2)",
        borderRadius: 5,
    },
    iconMore: {
        color: "white",
        cursor: "pointer",
        width: 24,
        height: 24,
    },
    conContent: {
        background: "#282828",
        width: 230,
        padding: 10,
        color: "white",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    txtTitle: {
        fontWeight: "bold",
        fontSize: 16,
        lineHeight: "19px",
    },
    txtDescription: {
        fontSize: 12,
        height: 32,
        lineHeight: "16px",
        width: "100%",
        color: "#ADAFB2",
        marginTop: 10,
        overflow: "hidden",
    },
    txtEvent: {
        fontSize: 10,
        lineHeight: "12px",
        color: "#ADAFB2",
        marginTop: 10,
        letterSpacing: "1.4px",
    },
    txtSub: {
        fontSize: 10,
        lineHeight: "12px",
        color: "#ADAFB2",
        letterSpacing: "1.4px",
    },
    txtDescriptionEvent: {
        fontSize: 12,
        height: 32,
        lineHeight: "16px",
        width: "100%",
        color: "#ADAFB2",
        marginTop: 10,
        overflow: "hidden",
    },
}));
export default itemCarouselStyle;
