import clsx from "clsx";
import React, { useState } from "react";
import AddIcon from "../assets/icons/add_rounded.svg";
import appStyle from "../styles/AppStyle";
import { useSelector, useDispatch } from "react-redux";

const ItemSidebar = (props) => {
    const classes = appStyle();
    const {
        icon,
        iconActive,
        title,
        numberBadge,
        showBadge,
        showAddIcon,
        active,
        styleTitle,
        style,
        onClickAdd,
        onItemClick,
        showOver,
    } = props;
    const [isHover, setHover] = useState(false);
    const { isOpenSidebar } = useSelector((state) => ({
        isOpenSidebar: state.app.isOpenSidebar,
    }));

    return (
        <div
            style={{ height: 32, ...style }}
            className="display-center-row"
            onMouseOver={(e) => setHover(true)}
            onMouseEnter={(e) => setHover(true)}
            onMouseLeave={(e) => setHover(false)}
        >
            <div
                style={{
                    width: 4,
                    height: 32,
                    background: active || (isHover && showOver) ? "#8CB2F8" : "transparent",
                    marginLeft: 1,
                }}
            ></div>
            <div className="display-center-row" style={{ cursor: "pointer", flex: 1 }} onClick={onItemClick}>
                <img
                    style={{ margin: "0 15px 0 10px" }}
                    width={17}
                    height={17}
                    src={active || (isHover && showOver) ? iconActive : icon}
                    alt=""
                />
                <div
                    style={{
                        flex: 1,
                        color: active || (isHover && showOver) ? "#8CB2F8" : "#ADAFB2",
                        userSelect: "none",
                        ...styleTitle,
                    }}
                >
                    <div className={isOpenSidebar ? classes.sidebarOpenItem : classes.sidebarCloseItem}>{title}</div>
                </div>
            </div>
            {showBadge && (
                <div
                    style={{
                        width: 25,
                        height: 20,
                        background: "#FF6961",
                        borderRadius: 40,
                        color: "white",
                        fontSize: 13,
                        lineHeight: "16px",
                        marginRight: 18,
                    }}
                    className="display-center"
                >
                    <div className={isOpenSidebar ? classes.sidebarOpenItem : classes.sidebarCloseItem}>
                        {numberBadge}
                    </div>
                </div>
            )}
            {showAddIcon && (
                <img
                    src={AddIcon}
                    width={24}
                    height={24}
                    style={{ cursor: "pointer", marginRight: 18 }}
                    alt=""
                    onClick={onClickAdd}
                />
            )}
        </div>
    );
};
ItemSidebar.defaultProps = {
    numberBadge: 0,
    showBadge: false,
    showAddIcon: false,
    isDropdown: false,
    showOver: true,
    style: {},
    styleTitle: { fontSize: 14, lineHeight: "17px" },
};
export default ItemSidebar;
