import React, { useState } from "react";
import { ClickAwayListener, Popover, Popper } from "@material-ui/core";
import { isEmpty } from "lodash";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory, useLocation } from "react-router-dom";
import { getImageUrlBig, formatEventTime, copyFeedItem } from "../utils/Utils";
import parse from "html-react-parser";
import PopupMenu from "./PopupMenu";
import copy from "copy-to-clipboard";
import itemCarouselStyle from "../styles/ItemCarouselStyle";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { updateItemFeedCalendar, updateItemFeedCollection } from "../redux/actions";
import { usePopper } from "react-popper";

const ItemCarousel = (props) => {
    const { itemChild, isMoving, onClickDeleteFeed } = props;
    const [anchor, setAnchor] = useState();
    const history = useHistory();
    const listMenu = isEmpty(itemChild.nextEvent)
        ? ["Pause", "Delete", "Duplicate", "Archive", "Preview", "Add Event", "Copy JSON"]
        : ["Pause", "Delete", "Duplicate", "Edit Upcoming Event", "Archive", "Preview", "Add Event", "Copy JSON"];
    const location = useLocation();
    const classes = itemCarouselStyle();
    const dispatch = useDispatch();
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement);

    return (
        <div className="display-center-col">
            <div
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                    if (isMoving !== true) {
                        history.push(
                            `/${itemChild.isSubscribable ? "calendar" : "collection"}/${itemChild.provider}:${
                                itemChild.type
                            }:${encodeURIComponent(itemChild.uid)}`
                        );
                    }
                }}
            >
                <div className={clsx("display-center", classes.conImg)}>
                    <img
                        src={getImageUrlBig(itemChild.image)}
                        width={230}
                        height={230}
                        className={classes.imgStyle}
                        alt=""
                    />
                    <div className={classes.conBlur} />

                    <div
                        className={clsx("display-center", classes.conIconMore)}
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchor(e.currentTarget);
                        }}
                    >
                        <MoreVertIcon className={classes.iconMore} />
                    </div>

                    {!isEmpty(anchor) && (
                        <ClickAwayListener onClickAway={() => setAnchor()}>
                            <div
                                ref={setPopperElement}
                                style={{ ...styles.popper, zIndex: 99, top: 5 }}
                                {...attributes.popper}
                            >
                                <PopupMenu
                                    listMenu={listMenu}
                                    onClick={(itemMenu) => {
                                        setAnchor();
                                        switch (itemMenu) {
                                            case "Pause":
                                                if (itemChild.isSubscribable) {
                                                    dispatch(
                                                        updateItemFeedCalendar({
                                                            uid: `${itemChild.provider}:${
                                                                itemChild.type
                                                            }:${encodeURIComponent(itemChild.uid)}`,
                                                            data: { status: "draft" },
                                                        })
                                                    );
                                                } else {
                                                    dispatch(
                                                        updateItemFeedCollection({
                                                            uid: `${itemChild.provider}:${
                                                                itemChild.type
                                                            }:${encodeURIComponent(itemChild.uid)}`,
                                                            data: { status: "draft" },
                                                        })
                                                    );
                                                }
                                                break;
                                            case "Delete":
                                                onClickDeleteFeed(itemChild);
                                                break;
                                            case "Duplicate":
                                                if (
                                                    location.pathname.includes("create") ||
                                                    location.pathname.includes("edit")
                                                ) {
                                                    history.replace(
                                                        `/duplicate/${
                                                            itemChild.isSubscribable ? "calendar" : "collection"
                                                        }/${itemChild.provider}:${itemChild.type}:${encodeURIComponent(
                                                            itemChild.uid
                                                        )}`
                                                    );
                                                } else {
                                                    history.push(
                                                        `/duplicate/${
                                                            itemChild.isSubscribable ? "calendar" : "collection"
                                                        }/${itemChild.provider}:${itemChild.type}:${encodeURIComponent(
                                                            itemChild.uid
                                                        )}`
                                                    );
                                                }
                                                break;
                                            case "Edit Upcoming Event":
                                                if (
                                                    location.pathname.includes("create") ||
                                                    location.pathname.includes("edit")
                                                ) {
                                                    history.replace(
                                                        `/feed/${itemChild.provider}:${
                                                            itemChild.type
                                                        }:${encodeURIComponent(
                                                            itemChild.uid
                                                        )}/edit/${encodeURIComponent(itemChild.nextEvent.uid)}`
                                                    );
                                                } else {
                                                    history.push(
                                                        `/feed/${itemChild.provider}:${
                                                            itemChild.type
                                                        }:${encodeURIComponent(
                                                            itemChild.uid
                                                        )}/edit/${encodeURIComponent(itemChild.nextEvent.uid)}`
                                                    );
                                                }
                                                break;
                                            case "Archive":
                                                if (itemChild.isSubscribable) {
                                                    dispatch(
                                                        updateItemFeedCalendar({
                                                            uid: `${itemChild.provider}:${
                                                                itemChild.type
                                                            }:${encodeURIComponent(itemChild.uid)}`,
                                                            data: { status: "archived" },
                                                        })
                                                    );
                                                } else {
                                                    dispatch(
                                                        updateItemFeedCollection({
                                                            uid: `${itemChild.provider}:${
                                                                itemChild.type
                                                            }:${encodeURIComponent(itemChild.uid)}`,
                                                            data: { status: "archived" },
                                                        })
                                                    );
                                                }
                                                break;
                                            case "Preview":
                                                if (
                                                    location.pathname.includes("create") ||
                                                    location.pathname.includes("edit")
                                                ) {
                                                    history.replace(
                                                        `/edit/${
                                                            itemChild.isSubscribable ? "calendar" : "collection"
                                                        }/${itemChild.provider}:${itemChild.type}:${encodeURIComponent(
                                                            itemChild.uid
                                                        )}`
                                                    );
                                                } else {
                                                    history.push(
                                                        `/edit/${
                                                            itemChild.isSubscribable ? "calendar" : "collection"
                                                        }/${itemChild.provider}:${itemChild.type}:${encodeURIComponent(
                                                            itemChild.uid
                                                        )}`
                                                    );
                                                }
                                                break;
                                            case "Add Event":
                                                if (
                                                    location.pathname.includes("create") ||
                                                    location.pathname.includes("edit")
                                                ) {
                                                    history.replace(
                                                        `/feed/${itemChild.provider}:${
                                                            itemChild.type
                                                        }:${encodeURIComponent(itemChild.uid)}/create`
                                                    );
                                                } else {
                                                    history.push(
                                                        `/feed/${itemChild.provider}:${
                                                            itemChild.type
                                                        }:${encodeURIComponent(itemChild.uid)}/create`
                                                    );
                                                }
                                                break;
                                            case "Copy JSON":
                                                copy(`${JSON.stringify(copyFeedItem(itemChild))}`);
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            </div>
                        </ClickAwayListener>
                    )}
                </div>
                <div className={clsx("display-col", classes.conContent)}>
                    <div className={clsx("text-ellipsis", classes.txtTitle)}>{itemChild?.title ?? ""}</div>
                    <div style={{ height: 32, marginTop: 10 }}>
                        {!isEmpty(itemChild.nextEvent) && (
                            <div>
                                <div className="text-ellipsis" style={{ fontSize: 13, lineHeight: "16px" }}>
                                    {`Next up: ${itemChild?.nextEvent?.title ?? ""}`}
                                </div>
                                <div style={{ fontSize: 13, lineHeight: "16px" }}>
                                    {formatEventTime(itemChild?.nextEvent)}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.txtDescription}>{parse(itemChild?.description ?? "")}</div>
                    <div className={classes.txtEvent}>{`${itemChild.eventsCount} EVENTS`}</div>
                    <div style={{ height: "12px" }}>
                        {itemChild.isSubscribable && (
                            <span className={classes.txtSub}>{`${itemChild.subscribersCount} SUBSCRIBERS`}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemCarousel;
