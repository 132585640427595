import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put, select } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { sortSubCategory } from "../../utils/Utils";
import { openMessageDialog } from "../reducers/AppReducer";
import {
    createSubCategory,
    createSubCategoryFail,
    deleteSubCategory,
    deleteSubCategoryFail,
    getSubCategory,
    getSubCategoryFail,
    getSubCategorySuccess,
    updateSubCategory,
    updateSubCategoryFail,
} from "../reducers/SubCategoryReducer";

function* subCategorySagaWatcher() {
    yield takeLatest(getSubCategory, getListSubTag);
    yield takeLatest(createSubCategory, withCallback(createSubTag));
    yield takeLatest(updateSubCategory, withCallback(updateSubTag));
    yield takeLatest(deleteSubCategory, withCallback(deleteSubTag));
}

function* getListSubTag(action) {
    try {
        const response = yield call(handleApi, "dashboard/subcategory");
        sortSubCategory(response.data)
        yield put(getSubCategorySuccess(response.data));
    } catch (error) {
        yield put(getSubCategoryFail());
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

function* createSubTag(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/subcategory/${encodeURIComponent(action.payload.catId)}/${encodeURIComponent(
                action.payload.id
            )}`,
            "POST",
            action.payload.data
        );
        yield put(getSubCategorySuccess(state.subCategory.data.concat(response.data)));
        return true;
    } catch (error) {
        yield put(createSubCategoryFail({ isError: true, messageError: error.message }));
        return false;
    }
}

function* updateSubTag(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/subcategory/${encodeURIComponent(action.payload.catId)}/${encodeURIComponent(
                action.payload.id
            )}`,
            "POST",
            action.payload.data
        );
        var result = state.subCategory.data.map((item) => {
            if (item.uid === action.payload.id) {
                item = response.data;
            }
            return item;
        });
        yield put(getSubCategorySuccess(result));
        return true;
    } catch (error) {
        yield put(updateSubCategoryFail({ isError: true, messageError: error.message }));
        return false;
    }
}

function* deleteSubTag(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/subcategory/${encodeURIComponent(action.payload.catId)}/${encodeURIComponent(
                action.payload.id
            )}`,
            "DELETE"
        );
        yield put(getSubCategorySuccess(state.subCategory.data.filter((el) => el.uid !== action.payload.id)));
        return true;
    } catch (error) {
        yield put(deleteSubCategoryFail({ isError: true, messageError: error.message }));
        return false;
    }
}

export default subCategorySagaWatcher;
