import React, { useEffect, useRef, useState } from 'react'

import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

import '../styles/JSONEditorReact.css';

const JSONEditorReact = ({ json, text, onChange, ...props }) => {
  const [editor, setEditor] = useState();
  const containerRef = useRef();

  useEffect(() => {
    if (!containerRef.current) return;
    const _editor = new JSONEditor(containerRef.current, {
      onChange: () => {
        if (onChange) {
          try {
            // const text = _editor.getText();
            // if (text === '') {
            //   onChange(null);
            // }
            const currentJson = _editor.get();
            onChange(currentJson);
          } catch (err) {
            // parser error - happen in text/code/preview mode
            // this.err = err;
          }
        }
      },
      ...props
    });
    if (json !== undefined) {
      _editor.set(json);
    }
    if (text !== undefined) {
      _editor.setText(text);
    }
    setEditor(_editor);

    return function cleanup() {
      if (_editor) _editor.destroy();
    }
  }, [containerRef.current]);

  return (
    <div className="jsoneditor-react-container" ref={containerRef} />
  );
}

export default JSONEditorReact;
