import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onChangeOpenSidebar } from "../redux/reducers/AppReducer";
import styled from "styled-components";
import Sidebar from "./Sidebar";

const StyledBurger = styled.div`
    width: 15px;
    height: 19px;
    position: fixed;
    margin-top: 6px;
    left: ${({ openSidebar }) => (openSidebar ? "180px" : "15px")};
    z-index: 3;
    display: none;

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }

    div {
        width: 2rem;
        height: 0.25rem;
        background-color: #ccc;
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.5s linear;

        &:nth-child(1) {
            transform: ${({ openSidebar }) => (openSidebar ? "rotate(45deg)" : "rotate(0)")};
        }

        &:nth-child(2) {
            transform: ${({ openSidebar }) => (openSidebar ? "translateX(100%)" : "translateX(0)")};
            opacity: ${({ openSidebar }) => (openSidebar ? 0 : 1)};
        }

        &:nth-child(3) {
            transform: ${({ openSidebar }) => (openSidebar ? "rotate(-45deg)" : "rotate(0)")};
        }
    }
`;

const Burger = (props) => {
    const { isOpenSidebar } = useSelector((state) => ({
        isOpenSidebar: state.app.isOpenSidebar,
    }));
    const dispatch = useDispatch();

    const [openSidebar, setOpenSidebar] = useState();

    useEffect(() => {
        setOpenSidebar(isOpenSidebar);
    }, [isOpenSidebar]);

    return (
        <>
            <StyledBurger
                openSidebar={openSidebar}
                onClick={() => {
                    dispatch(onChangeOpenSidebar(!openSidebar));
                }}
            >
                <div />
                <div />
                <div />
            </StyledBurger>
            <Sidebar scrollRef={props.scrollRef} setResponsive={props.setResponsive}/>
        </>
    );
};

export default Burger;
