import { createSlice } from "@reduxjs/toolkit";

const stanzaReducer = createSlice({
    name: "stanza",
    initialState: {
        isLoading: false,
        isError: false,
        messageError: "",
    },
    reducers: {
        createStanza: (state, action) => {
            state.isLoading = true;
        },
        createStanzaFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        resetStanza: (state, action) => {
            state.isError = false
            state.messageError = "";
        },
    },
});

const { reducer, actions } = stanzaReducer;
export const { createStanza, createStanzaFail, resetStanza} = actions;
export default reducer;