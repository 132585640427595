import { createSlice } from "@reduxjs/toolkit";

const calendarReducer = createSlice({
    name: "calendars",
    initialState: {
        isLoading: false,
        data: { items: [] },
        item: {},
        isError: false,
        messageError: "",
        isDelete: false,
    },
    reducers: {
        createCalendar: (state, action) => {
            state.isLoading = true;
            state.isError = false;
            state.messageError = "";
        },
        createCalendarSuccess: (state, action) => {
            state.isLoading = false;
            state.item = action.payload;
        },
        createCalendarFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        getCalendars: (state, action) => {
            state.isLoading = true;
        },
        getCalendarsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getCalendarsFail: (state, action) => {
            state.isLoading = false;
        },
        deleteCalendar: (state, action) => {
            state.isLoading = true;
            state.isError = false;
            state.messageError = "";
            state.isDelete = false;
        },
        deleteCalendarSuccess: (state, action) => {
            state.isLoading = false;
            state.isDelete = true;
        },
        deleteCalendarFail: (state, action) => {
            state.isLoading = false;
            state.isDelete = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.message;
        },
        updateCalendar: (state, action) => {
            state.isLoading = true;
        },
        updateCalendarSuccess: (state, action) => {
            state.isLoading = false;
        },
        updateCalendarFail: (state, action) => {
            state.isLoading = false;
        },
        getCalendarById: (state, action) => {
            state.isLoading = true;
        },
        getCalendarByIdFail: (state, action) => {
            state.isLoading = false;
        },
    },
});

const { reducer, actions } = calendarReducer;
export const {
    createCalendar,
    createCalendarSuccess,
    createCalendarFail,
    getCalendars,
    getCalendarsSuccess,
    getCalendarsFail,
    deleteCalendar,
    deleteCalendarSuccess,
    deleteCalendarFail,
    updateCalendar,
    updateCalendarSuccess,
    updateCalendarFail,
    getCalendarById,
    getCalendarByIdFail,
} = actions;
export default reducer;
