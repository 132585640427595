import { Collapse } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ItemSidebar from "./ItemSidebar";
import ItemSubSidebar from "./ItemSubSidebar";
import { useHistory, useLocation } from "react-router-dom";
import IconDown from "../assets/icons/arrow_drop_down.svg";
import IconCollapse from "../assets/icons/arrow_collapse.svg";
import { useSelector, useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import { onChangeOpenSidebar } from "../redux/reducers/AppReducer";
import { getCollections } from "../redux/reducers/CollectionReducer";
import { getCalendars } from "../redux/reducers/CalendarReducer";

function ItemFeedSidebar(props) {
    const { data, title } = props;
    const history = useHistory();
    const location = useLocation();
    const [isCollapse, setCollapse] = useState();
    const dispatch = useDispatch();
    const { isOpenSidebar } = useSelector((state) => ({
        isOpenSidebar: state.app.isOpenSidebar,
    }));

    const [{ isOver }, dropRef] = useDrop({
        accept: ["item-unassign", "item-assign"],
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
        drop: (item, monitor) => {
            // console.log("isOverCalendarDrop");
        },
    });

    const [{ isOverLoadMore }, loadMoreDropRef] = useDrop({
        accept: ["item-unassign", "item-assign"],
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOverLoadMore: monitor.isOver(),
        }),
        drop: (item, monitor) => {
            // console.log("isOverCalendarDrop");
        },
    });

    useEffect(() => {
        if (isOver) {
            if (!isOpenSidebar) {
                dispatch(onChangeOpenSidebar(true));
            }
            setCollapse(true);
        }
        if (!isOpenSidebar) {
            setCollapse(false);
        }
    }, [isOver, isOpenSidebar]);

    useEffect(() => {
        if (isOverLoadMore) {
            loadMore();
        }
    }, [isOverLoadMore]);

    const loadMore = () => {
        if (title === "COLLECTIONS") {
            dispatch(getCollections(data.nextPageToken));
        } else {
            dispatch(getCalendars(data.nextPageToken));
        }
    };

    return (
        <div>
            <div ref={dropRef} style={{ background: isOver ? "rgba(255, 255, 255, 0.2)" : "initial" }}>
                <ItemSidebar
                    icon={isCollapse ? IconDown : IconCollapse}
                    showOver={false}
                    title={title}
                    style={{ marginTop: 10 }}
                    styleTitle={{ fontSize: 12, lineHeight: "14px", letterSpacing: "1.31px" }}
                    showAddIcon={true}
                    onItemClick={() => {
                        if (!isOpenSidebar) {
                            dispatch(onChangeOpenSidebar(true));
                        }
                        setCollapse(!isCollapse);
                    }}
                    onClickAdd={() => {
                        if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                            history.replace(`/create/${title === "COLLECTIONS" ? "collection" : "calendar"}`);
                        } else {
                            history.push(`/create/${title === "COLLECTIONS" ? "collection" : "calendar"}`);
                        }
                    }}
                />
            </div>
            <Collapse in={isCollapse}>
                <div>
                    {data.items.map((item, index) => (
                        <ItemSubSidebar key={index} item={item} active={location.pathname.includes(item.uid)} />
                    ))}
                    {data.count > data.items.length && (
                        <div
                            ref={loadMoreDropRef}
                            style={{
                                paddingLeft: 78,
                                height: 32,
                                cursor: "pointer",
                                background: isOverLoadMore ? "rgba(255, 255, 255, 0.2)" : "initial",
                            }}
                            className="display-center-row"
                            onClick={loadMore}
                        >
                            Load more ...
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    );
}

export default ItemFeedSidebar;
