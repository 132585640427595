import React, { useState } from "react";
import { ClickAwayListener, Popover, Popper } from "@material-ui/core";
import _, { isEmpty } from "lodash";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory, useLocation } from "react-router-dom";
import { getImageUrlBig, formatEventTime, copyFeedEventItem } from "../utils/Utils";
import parse from "html-react-parser";
import PopupMenu from "./PopupMenu";
import copy from "copy-to-clipboard";
import itemCarouselStyle from "../styles/ItemCarouselStyle";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { updateItemEventUnassign } from "../redux/actions";
import { usePopper } from "react-popper";
import { getEventRsvpLink } from '../redux/reducers/EventReducer'

function ItemEventCarousel(props) {
    const { itemChild, onClickDeleteFeed, isMoving, itemParent } = props;
    const [anchor, setAnchor] = useState();
    const history = useHistory();
    const location = useLocation();
    const listMenu = ["Pause", "Delete", "Duplicate", "Archive", "Preview", "Copy JSON", "Create RSVP Link"];
    const classes = itemCarouselStyle();
    const dispatch = useDispatch();
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement);

    return (
        <div className="display-center-col">
            <div
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                    if (isMoving !== true) {
                        if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                            history.replace(`/edit/event/${encodeURIComponent(itemChild.uid)}`);
                        } else {
                            history.push(`/edit/event/${encodeURIComponent(itemChild.uid)}`);
                        }
                    }
                }}
            >
                <div className={clsx("display-center", classes.conImg)}>
                    <img
                        src={getImageUrlBig(itemChild.flair)}
                        width={230}
                        height={230}
                        className={classes.imgStyle}
                        alt=""
                    />
                    <div className={classes.conBlur} />
                    <div
                        className={clsx("display-center", classes.conIconMore)}
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchor(e.currentTarget);
                        }}
                    >
                        <MoreVertIcon className={classes.iconMore} />
                    </div>
                    {!isEmpty(anchor) && (
                        <ClickAwayListener onClickAway={() => setAnchor()}>
                            <div
                                ref={setPopperElement}
                                style={{ ...styles.popper, zIndex: 99, top: 5 }}
                                {...attributes.popper}
                            >
                                <PopupMenu
                                    listMenu={listMenu}
                                    onClick={(itemMenu) => {
                                        setAnchor();
                                        switch (itemMenu) {
                                            case "Pause":
                                                dispatch(
                                                    updateItemEventUnassign({
                                                        uid: encodeURIComponent(itemChild.uid),
                                                        data: { status: "draft" },
                                                    })
                                                );
                                                break;
                                            case "Delete":
                                                onClickDeleteFeed(itemChild);
                                                break;
                                            case "Duplicate":
                                                if (
                                                    location.pathname.includes("create") ||
                                                    location.pathname.includes("edit")
                                                ) {
                                                    history.replace(`/duplicate/${encodeURIComponent(itemChild.uid)}`);
                                                } else {
                                                    history.push(`/duplicate/${encodeURIComponent(itemChild.uid)}`);
                                                }
                                                break;
                                            case "Archive":
                                                dispatch(
                                                    updateItemEventUnassign({
                                                        uid: encodeURIComponent(itemChild.uid),
                                                        data: { status: "archived" },
                                                    })
                                                );
                                                break;
                                            case "Preview":
                                                if (
                                                    location.pathname.includes("create") ||
                                                    location.pathname.includes("edit")
                                                ) {
                                                    history.replace(`/edit/event/${encodeURIComponent(itemChild.uid)}`);
                                                } else {
                                                    history.push(`/edit/event/${encodeURIComponent(itemChild.uid)}`);
                                                }
                                                break;
                                            case "Copy JSON":
                                                copy(`${JSON.stringify(copyFeedEventItem(itemParent))}`);
                                                break;
                                            case "Create RSVP Link":
                                                dispatch(
                                                    getEventRsvpLink({
                                                        feedId: `${itemParent.provider}:${itemParent.type}:${itemParent.uid}`,
                                                        eventId: itemChild.uid
                                                    })
                                                );
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            </div>
                        </ClickAwayListener>
                    )}
                </div>
                <div className={clsx("display-col", classes.conContent)}>
                    <div className={clsx("text-ellipsis", classes.txtTitle)}>{itemChild?.title ?? ""}</div>
                    <div style={{ fontSize: 13, lineHeight: "16px", marginTop: 16 }}>
                        {formatEventTime(itemChild)}
                    </div>
                    <div className={classes.txtDescriptionEvent}>{parse(itemChild?.description ?? "")}</div>
                </div>
            </div>
        </div>
    );
}

export default ItemEventCarousel;
