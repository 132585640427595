import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put, select } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { sortTag } from "../../utils/Utils";
import { openMessageDialog } from "../reducers/AppReducer";
import {
    createTag,
    createTagFail,
    deleteTag,
    deleteTagFail,
    getTags,
    getTagsFail,
    getTagsSuccess,
    updateTag,
    updateTagFail,
} from "../reducers/TagReducer";

function* tagSagaWatcher() {
    yield takeLatest(getTags, getListTag);
    yield takeLatest(createTag, withCallback(createItem));
    yield takeLatest(updateTag, withCallback(updateItem));
    yield takeLatest(deleteTag, withCallback(deleteItem));
}

function* getListTag(action) {
    try {
        const response = yield call(handleApi, "dashboard/tag");
        sortTag(response.data)
        yield put(getTagsSuccess(response.data));
    } catch (error) {
        yield put(getTagsFail({ isError: true, message: error.message }));
        yield put(
            openMessageDialog({
                open: true,
                message: error.message,
                title: "ERROR",
            })
        );
    }
}

function* createItem(action) {
    try {
        const state = yield select();
        const response = yield call(handleApi, `dashboard/tag/db/${action.payload.id}`, "POST", action.payload.data);
        yield put(getTagsSuccess(state.tag.data.concat(response.data)));
        return true;
    } catch (error) {
        yield put(createTagFail({ isError: true, message: error.message }));
        return false;
    }
}

function* updateItem(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/tag/${action.payload.db}/${action.payload.id}`,
            "POST",
            action.payload.data
        );
        var result = state.tag.data.map((item) => {
            if (item.uid === action.payload.id) {
                item = response.data;
            }
            return item;
        });
        yield put(getTagsSuccess(result));
        return true;
    } catch (error) {
        yield put(updateTagFail({ isError: true, message: error.message }));
        return false;
    }
}

function* deleteItem(action) {
    try {
        const state = yield select();
        const response = yield call(
            handleApi,
            `dashboard/tag/${action.payload.dataSource}/${action.payload.uid}`,
            "DELETE"
        );
        yield put(getTagsSuccess(state.tag.data.filter((el) => el.uid !== action.payload.uid)));
        return true;
    } catch (error) {
        yield put(deleteTagFail({ isError: true, message: error.message }));
        return false;
    }
}

export default tagSagaWatcher;
