import { combineReducers } from "redux";
import appReducer from "./AppReducer";
import collectionReducer from "./CollectionReducer";
import categoryReducer from "./CategoryReducer";
import subCategoryReducer from "./SubCategoryReducer";
import schemaReducer from "./SchemaReducer";
import calendarReducer from "./CalendarReducer";
import tagReducer from "./TagReducer";
import eventReducer from "./EventReducer";
import statisticsReducer from "./StatisticsReducer";
import preFilterReducer from "./PreFilterReducer";
import personaReducer from "./PersonaReducer";
import youtubeReducer from "./YouTubeReducer";
import stanzaReducer from "./StanzaReducer";
import eventTagReducer from "./TagEventReducer"

const rootReducer = combineReducers({
    app: appReducer,
    collection: collectionReducer,
    category: categoryReducer,
    subCategory: subCategoryReducer,
    schema: schemaReducer,
    calendar: calendarReducer,
    tag: tagReducer,
    event: eventReducer,
    statistics: statisticsReducer,
    preFilter: preFilterReducer,
    persona: personaReducer,
    youtube: youtubeReducer,
    eventTag:eventTagReducer,
    stanza: stanzaReducer,
});

export default rootReducer;
