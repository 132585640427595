import { createSlice } from "@reduxjs/toolkit";

const subCategoryReducer = createSlice({
    name: "subCategory",
    initialState: {
        isLoading: false,
        data: [],
        isError: false,
        messageError: "",
    },
    reducers: {
        getSubCategory: (state, action) => {
            state.isLoading = true;
        },
        getSubCategorySuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getSubCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
        createSubCategory: (state, action) => {
            state.isLoading = true;
        },
        createSubCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
        updateSubCategory: (state, action) => {
            state.isLoading = true;
        },
        updateSubCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
        deleteSubCategory: (state, action) => {
            state.isLoading = true;
        },
        deleteSubCategoryFail: (state, action) => {
            state.isLoading = false;
            state.isError = action.payload.isError;
            state.messageError = action.payload.messageError;
        },
    },
});

const { reducer, actions } = subCategoryReducer;
export const {
    getSubCategory,
    getSubCategorySuccess,
    getSubCategoryFail,
    createSubCategory,
    createSubCategoryFail,
    updateSubCategory,
    updateSubCategoryFail,
    deleteSubCategory,
    deleteSubCategoryFail,
} = actions;
export default reducer;
