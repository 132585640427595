import { makeStyles } from "@material-ui/core";

const appStyle = makeStyles((theme) => ({
    rowDisplay: {
        display: "flex",
        flexDirection: "row",
    },
    rowDirection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    rowCenterDirection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    colDisplay: {
        display: "flex",
        flexDirection: "column",
    },
    colDirection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    colCenterDirection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    displayCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    scrollbar: {
        "&::-webkit-scrollbar": {
            width: 4,
            // height: 8,
            borderRadius: 2,
        },
        "&::-webkit-scrollbar-button": {
            // border: '.5px solid yellow'
        },
        "&::-webkit-scrollbar-track": {
            // '&::-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            // borderRadius: 10,
            // background: 'red',
        },
        "&::-webkit-scrollbar-track-piece": {
            // background: 'purple',
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0.8)",
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.5)",
            borderRadius: 2,
        },
        "&::-webkit-scrollbar-corner": {},
        "&::-webkit-scrollbar-resizer": {},
    },
    textEllipsis: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    containerPopup: {
        minWidth: 205,
        background: "#282828",
        borderRadius: 8,
        padding: "10px 0",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    },
    txtPopup: {
        fontFamily: "SFProText",
        fontSize: 15,
        lineHeight: "29px",
        padding: "5px 20px",
        color: "white",
        cursor: "pointer",
        whiteSpace: "nowrap",
    },
    containerPagination: {
        bottom: "0",
        height: 47,
        display: "flex",
        // padding: "15px 0px",
        position: "sticky",
        background: "#282828",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        borderTop: "1px solid #333333",
        fontSize: 12,
    },
    containerPaginationPreFilter: {
        bottom: "0",
        height: 47,
        display: "flex",
        alignItems: "center",
        position: "sticky",
        background: "#282828",
        justifyContent: "space-between",
        width: "100%",
        justifyContent: "flex-end",
        borderTop: "1px solid #333333",
    },
    containerChildTabPreFilter: {
        background: "#282828",
        margin: "0 19px 0 20px",
        padding: "0px 19px 0px 13px",
        // height: "calc(100% - 142px)",
    },
    containerListPreFilter: {
        minHeight: "calc(100vh - 250px)",
    },
    positionSortIcon: {
        height: 20,
        position: "absolute",
        right: 7,
        top: 25,
    },
    txtNoDataPrefilter: {
        color: "#9FA2B4",
        fontSize: "12px",
        minHeight: "calc(100vh - 237px)",
        fontFamily: "SFProText",
        paddingTop: "40px",
        display: "flex",
        alignItems: "flex-start",
    },
    TableContentDetail: {
        // minHeight: "100vh",
    },
    TableContentEvent: {
        minHeight: "calc(100vh - 304px)",
    },
    txtNoDataDetail: {
        paddingTop: 15,
        fontSize: 12,
        fontFamily: "SFProText",
        color: "#9FA2B4",
    },
    txtNoDataDetailEvent: {
        paddingTop: 15,
        fontSize: 12,
        fontFamily: "SFProText",
        color: "#9FA2B4",
    },
    containerDeleteDialog: {
        width: 470,
        height: 205,
        background: "#282828",
        borderRadius: 4,
        padding: "29px 33px 22px",
        position: "relative",
    },
    titleDeleteDialog: {
        flex: 1,
        fontFamily: "SFProTextBold",
        color: "white",
        fontSize: 16,
        lineHeight: "19px",
    },
    contentDeleteDialog: {
        color: "white",
        fontSize: 14,
        lineHeight: "26px",
        fontFamily: "SFProText",
        margin: "26px 0 36px",
    },
    btnCancelDeleteDialog: {
        width: 61,
        lineHeight: "25px",
        border: "1px solid #ADAFB2",
        color: "white",
        fontFamily: "SFProText",
        borderRadius: 4,
        textAlign: "center",
        cursor: "pointer",
    },
    btnOkDeleteDialog: {
        width: 61,
        lineHeight: "25px",
        background: "#FF6961",
        color: "white",
        fontSize: 13,
        fontFamily: "SFProText",
        borderRadius: 4,
        textAlign: "center",
        cursor: "pointer",
        marginLeft: 10,
    },
    containerHeadSticky: {
        width: "100%",
        padding: "1px 0 20px 20px",
        top: 49,
        paddingRight: 40,
        paddingLeft: 0,
    },
    containerHeadStickyPrefilter: {
        width: "100%",
        padding: "1px 0 20px 20px",
        top: 49,
        paddingRight: 40,
        paddingLeft: 0,
        marginLeft: 0,
    },
    activeSticky: {
        opacity: "1 !important",
        visibility: "visible !important",
        display: "flex",
        position: "fixed",
        width: "calc(100vw - 210px)",
        zIndex: 2,
        backgroundColor: "#181818",
        animationDuration: "0.5s",
    },
    headSticky: {
        opacity: 0,
    },
    stickyHeaderStyle: {
        visibility: "hidden",
        opacity: 0,
        zIndex: 2,
        backgroundColor: "#181818",
        animationDuration: "0.5s",
        padding: "0 20px",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        position: "sticky",
        top: -1,
        width: "100%",
        marginTop: -70,
    },
    containerPaginationEvent: {
        justifyContent: "flex-end !important",
    },
    containerHomeHeader: {
        padding: "0 20px",
    },
    sidebarCloseItem: {
        [theme.breakpoints.down("769")]: {
            display: "none",
        },
    },
    sidebarOpenItem: {
        [theme.breakpoints.down("769")]: {
            //display: "none",
        },
    },
    containerContent: {
        height: "100%",
        // display: "flex",
    },
    tagSelectBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: "25px"
    },
    txtTitle: {
        // marginRight: "10px",
        color: "#ADAFB2",
        fontSize: "14px",
        fontFamily: "Mulish",
        fontWeight: 600,
        lineHeight: "20px",
        /* margin-left: 8px; */
        letterSpacing: "0.2px",
        cursor: "pointer"
    },
    selectCustom: {
        height: "292px",
        fontSize: "15px",
        overflowY: "auto",
        // borderRadius: "10px"
    },
    // optionCustom: {
    //     // width: "25px"
    //     fontSize: "15px",
    //     color: "#ffffff"

    // },
    boxDisplay: {
        height: "25px",
        width: "200px",
        border: "1px solid #adafb2",
        borderRadius: "20px",
        padding: "2px 10px",
        position: "relative"
    },
    customDropdown: {
        position: "absolute",
        width: "18px",
        height: "18px",
        right: "10px"
    },
    selectBoxWrapper: {
        display: "flex",
        flexDirection: "column",
        padding: "25px 0px 15px 9px",
        // border: "1px solid #adafb2",
        background: "#282828",
        borderRadius: "8px",
        width: "270px",
        boxSizing: "border-box",
        boxShadow: "0px 4px 5px 1px rgba(0, 0, 0, 0.22)",
        zIndex: 1
        // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        // marginTop: "250px"
    },
    customFilterIcon: {
        marginRight: "10px"
    },
    optionCustom: {
        display: "flex",
        height: "39px",
        alignItems: "center",
        fontSize: "15px",
        color: "#ffffff",
        cursor: "pointer"
    },
    notFoundTagEvent: {
        textAlign: "center",
        marginTop: "25px",
    },
    tabSearchWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },

    iconContentWrapper: {
        // border: "2px solid #ccc",
        marginRight: "19px",
        height: "35px",
        background: "#282828",
        borderRadius: "90px"
    },

    searchImage: {
        marginTop: "-2px",
        marginRight: "11px",
        // marginLeft: "10px",
        width: "21px",
        height: "21px",
    },
    iconContentWrapperDetail: {
        marginRight: "19px",
        height: "25px",
        background: "#202022",
        borderRadius: "90px"
    }
}));

export default appStyle;
