import { makeStyles } from "@material-ui/core";

const itemPreFilterStyle = makeStyles((theme) => ({
    containerItem: {
        height: 136,
        // minWidth: 600,
        padding: "10px 0 9px 0",
        position: "relative",
    },
    containerOver: {
        position: "absolute",
        right: 0,
        top: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "flex-end",
    },
    iconMore: {
        width: 30,
        height: 30,
        margin: "58px 19px 54px 0",
        cursor: "pointer",
    },
    containerImage: {
        width: 117,
        height: 117,
        margin: "0px 22px 0px 15px",
        // background: "#181818",
        // borderRadius: 8,
    },
    containerName: {
        background: "#333333",
        borderRadius: 8,
        fontSize: 14,
        lineHeight: "18px",
        padding: "8px 15px 9px",
        height: 35,
        width: "fit-content",
        maxWidth: "100%",
        minWidth: 120,
    },
    txtName: {
        color: "white",
        // minWidth: 143,
        // textAlign: "center",
        fontFamily: "MulishBold",
    },
    txtNextEvent: {
        fontFamily: "SFProTextMedium",
        fontSize: 20,
        lineHeight: "26px",
        color: "white",
        margin: "4px 0",
        maxWidth: "80%",
    },
    txtDate: {
        fontFamily: "SFProText",
        fontSize: 16,
        lineHeight: "20px",
        margin: "2px 0",
        color: "#999FAA",
    },
    txtTime: {
        fontFamily: "SFProText",
        fontSize: 16,
        lineHeight: "20px",
        margin: "2px 0",
        color: "#999FAA",
    },
    txtLocation: {
        fontFamily: "SFProText",
        fontSize: 16,
        lineHeight: "20px",
        color: "#999FAA",
        marginTop: 4,
    },
}));

export default itemPreFilterStyle;
