import React, { useRef, useState, useEffect, useCallback } from "react";
import createFormStyle from "../styles/CreateFormStyle";
import clsx from "clsx";
import appStyle from "../styles/AppStyle";
import SectionHeader from "../components/SectionHeader";
import { useSelector, useDispatch } from "react-redux";
import ButtonBottom from "../components/ButtonBottom";
import InputWithTitle from "../components/InputWithTitle";
import SelectWithTitle from "../components/SelectWithTitle";
import { useHistory } from "react-router-dom";
import { createItemYoutube } from "../redux/actions";
import JsonComponent from "../components/JsonComponent";
import * as Yup from "yup";
import { isEmpty, capitalize } from "lodash";
import SchemaObject from '../components/Feed/schema/SchemaObject'
import gqlASTParser, {
    AWS_SCALARS, compileSchemaComponentsFromParsedAST,
    MEDIA_RESOURCE_TYPE,
} from '../utils/gqlASTParser'
import TagWithTitle from '../components/TagWithTitle';
import moment from "moment-timezone";


const commonFields = `
defaultStartTime: DefaultTime
defaultDuration: Int
`
const schema = `${AWS_SCALARS}
type DefaultTime {
  time: AWSTime!
  timeZone: TimeZone
}
type Channel {
  ${commonFields}
}
type Playlist {
  startDate: AWSDate
  ${commonFields}
}
type Video {
  startDate: AWSDate
  ${commonFields}
}`;
const parsedAST = gqlASTParser(schema);


function CreateYouTubeView() {
    const classes = createFormStyle();
    const appStyles = appStyle();
    const { isError, messageError } = useSelector((state) => ({
        isError: state.youtube.isError,
        messageError: state.youtube.messageError,
    }));
    const localTz = moment.tz.guess();
    const tomorrow = moment().add(1, 'day').format('Y-MM-DD');
    const [ytType, setYTType] = useState("channel");
    const [id, setId] = useState(null);
    const [feedType, setFeedType] = useState('calendar');
    const [collectionRule, setCollectionRule] = useState('day');
    const [weekDays, setWeekDays] = useState([]);
    const [calendarSettings, setCalendarSettings] = useState({
        startDate: tomorrow,
        defaultStartTime: {
            time: '10:00:00.000',
            timeZone: localTz
        },
        defaultDuration: 30
    });
    const [calendarSchema, setCalendarSchema] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [itemYT, setItemYT] = useState();
    const [atDefault, setAtDefault] = useState(true);
    const [errorMessSchema, setErrorMessSchema] = useState("");
    const schemaRef = useRef();

    const ytSchema = Yup.object()
        .shape({
            type: Yup.string().default("channel"),
            id: Yup.string().nullable(),
            settings: Yup.object().nullable()
        })
        .noUnknown()
        .strict(true);

    useEffect(() => {
        setYTType(itemYT?.type ?? "channel");
        setId(itemYT?.id ?? null);
    }, [itemYT]);

    useEffect(() => {
        let schemaName = capitalize(ytType);
        if (ytType === 'playlist' && feedType === 'calendar') {
            schemaName = 'Channel'
        }
        if (schemaName) {
            const schemaCompile = compileSchemaComponentsFromParsedAST(schemaName, parsedAST);
            setCalendarSchema(schemaCompile.object);
        } else {
            setCalendarSchema(null);
        }
    }, [ytType, feedType])

    function createYT() {
        let settings = {...calendarSettings};
        if (ytType === 'playlist') {
            settings.feedType = feedType;
            if (feedType === 'collection') {
                let rule = {recurringInterval: collectionRule}
                if (collectionRule === 'week') {
                    rule.rule = weekDays;
                }
                settings.collectionRule = rule;
            }
        }
        dispatch(
            createItemYoutube({
                type: ytType,
                id,
                settings: settings,
                onComplete: ({ error, cancelled, data }) => {
                    if (data) {
                        history.goBack();
                    }
                },
            })
        );
    }

    const handleSchema = useCallback(() => {
        ytSchema
            .validate(schemaRef.current)
            .then(function (value) {
                if (!isEmpty(value)) {
                    setErrorMessSchema();
                    setYTType(value.type);
                    setId(value.id);
                    setCalendarSettings(value.settings);
                }
            })
            .catch((e) => {
                // console.log(e);
                setAtDefault(false);
                setErrorMessSchema(e.message);
            });
    }, [schemaRef.current]);

    return (
        <div className="display-col">
            <div className={classes.titleHeader}>Create > Youtube</div>
            <div className={clsx("display-row", classes.containerChild)}>
                <div className={clsx("display-col", classes.containerContent)}>
                    <SectionHeader
                        title="NEW YOUTUBE"
                        onClickClose={() => history.goBack()}
                        onClickJson={() => setAtDefault(false)}
                        isDefault={atDefault}
                        onClickDefault={() => {
                            if (isEmpty(errorMessSchema)) {
                                schemaRef.current = undefined;
                                setAtDefault(true);
                            }
                        }}
                        type="youtube"
                    />
                    <div className={classes.containerDivider} />
                    {atDefault ? (
                        <div className={clsx(classes.containerForm, appStyles.scrollbar)}>
                            <div className="display-row">
                                <SelectWithTitle
                                    list={["Channel", "Playlist", "Video"].map((item) => ({
                                        title: item,
                                        value: item.toLowerCase(),
                                    }))}
                                    onItemChange={(value) => setYTType(value)}
                                    showComment={false}
                                    data={ytType}
                                    title="Channel or Playlist"
                                />
                                <InputWithTitle
                                    data={id}
                                    style={{ width: 295, marginLeft: 7 }}
                                    showComment={false}
                                    placeHolder={
                                        ytType === "channel"
                                            ? "Channel ID"
                                            : ytType === "playlist"
                                            ? "Playlist ID"
                                            : "Video ID"
                                    }
                                    onChange={(value) => setId(value)}
                                />
                            </div>

                            {ytType === 'playlist' &&
                            <div className="display-row">
                                <SelectWithTitle
                                    list={["Calendar", "Collection"].map((item) => ({
                                        title: item,
                                        value: item.toLowerCase(),
                                    }))}
                                    onItemChange={(value) => setFeedType(value)}
                                    showComment={false}
                                    data={feedType}
                                    title="Feed Type"
                                />
                                {feedType === 'collection' &&
                                <div style={{ width: 20 }}/>
                                }
                                {feedType === 'collection' &&
                                <SelectWithTitle
                                    list={[{
                                        title: 'Daily',
                                        value: 'day'
                                    }, {
                                        title: 'Weekly',
                                        value: 'week'
                                    }]}
                                    onItemChange={(value) => setCollectionRule(value)}
                                    showComment={false}
                                    data={collectionRule}
                                    title="Collection Rule"
                                />
                                }
                            </div>
                            }
                            {ytType === 'playlist' &&
                            feedType === 'collection' &&
                            collectionRule === 'week' &&
                            <div className="display-row">
                                <TagWithTitle
                                    title="Collection Rule Week days"
                                    require
                                    placeHolder="Select week days"
                                    list={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(item => {
                                        return {title: item, value: item}
                                    })}
                                    data={weekDays}
                                    onItemChange={setWeekDays}
                                    showComment={false}
                                />
                            </div>
                            }
                            {calendarSchema &&
                            <SchemaObject
                              collapsable={false}
                              onValueChange={(value) => setCalendarSettings(value)}
                              schema={calendarSchema}
                              initialValue={calendarSettings}
                            />
                            }
                        </div>
                    ) : (
                        <div className={classes.containerJsonForm}>
                            <div className={classes.containerJsonError}>
                                {!isEmpty(errorMessSchema) && (
                                    <span className={classes.textError}>{errorMessSchema}</span>
                                )}
                            </div>
                            <JsonComponent
                                data={ytSchema.cast({
                                    id: id,
                                    type: ytType,
                                    settings: calendarSettings
                                })}
                                onChange={(value) => {
                                    schemaRef.current = value;
                                    handleSchema();
                                    // setItemYT(value);
                                }}
                            />
                        </div>
                    )}
                    <div className={classes.containerDivider} />
                    <div className={classes.containerError}>
                        {isError && <span className={classes.textError}>{messageError}</span>}
                    </div>
                    <ButtonBottom
                        showDraft={false}
                        txtOk="SAVE"
                        onClickOk={() => {
                            if (isEmpty(errorMessSchema)) {
                                createYT();
                            }
                        }}
                    />
                </div>
                <div className="display-center-col designPreview" style={{ flex: 1, padding: "0 20px" }}>
                    <div style={{ width: 300, height: 500, background: "#282828" }}></div>
                </div>
            </div>
        </div>
    );
}

export default CreateYouTubeView;
