import React, { useState, useEffect, useCallback } from 'react'
import JSONEditorReact from "./JSONEditorReact";
import '../styles/JSONEditorReact.css';

const JsonEditorComponent = (props) => {
    const { data, onChange, validate, onError } = props;

    return (
        <JSONEditorReact
            json={data}
            mode="code"
            // modes={['code', 'tree']}
            statusBar={false}
            mainMenuBar={false}
            onChange={(value) => {
                // If there are custom validator, update data in onValidate instead
                if (!validate) onChange(value);
            }}
            onValidate={(json) => {
                if (!validate) return null;
                return validate(json).then(({errors, data}) => {
                    if (!errors || !errors.length) onChange(data);
                    else onError(errors[0].message);
                    return errors;
                })
            }}
            onValidationError={(errors) => {
                if (errors.length === 0) {
                    onError("");
                } else {
                    let error = errors[0];
                    switch (error.type) {
                        case "validation": // schema validation error
                            break;
                        case "customValidation": // custom validation error
                            // as onValidationError doesn't fire regularly
                            // we have to keep track of custom validation error in onValidate
                            // onError(error.message);
                            break;
                        case "error": // json parse error
                            onError('JSON parse error');
                            break;
                    }
                }
            }}
        />
    );
};

export default JsonEditorComponent;
