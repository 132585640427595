import { Popover } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import MoreIcon from "../assets/icons/more.svg";
import itemPreFilterStyle from "../styles/ItemPreFilterStyle";
import { formatEventTime, getImageUrlSmall, copyFeedItem } from "../utils/Utils";
import { useHistory, useLocation } from "react-router-dom";
import PopupMenu from "./PopupMenu";
import { useDispatch, useSelector } from "react-redux";
import { updateItemFeedCalendar, updateItemFeedCollection } from "../redux/actions";
import copy from "copy-to-clipboard";
import "../styles/ItemPreFilter.scss";

function ItemPreFilter(props) {
    const { item, status, onClickDeleteFeed, tag } = props;
    const classes = itemPreFilterStyle();
    const [isOver, setOver] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [listMenu, setListMenu] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        switch (status) {
            case "live":
                if (isEmpty(item.nextEvent)) {
                    setListMenu(["Pause", "Delete", "Duplicate", "Archive", "Preview", "Add Event", "Copy JSON"]);
                } else {
                    setListMenu([
                        "Pause",
                        "Delete",
                        "Duplicate",
                        "Edit Upcoming Event",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                }

                break;
            case "draft":
                if (isEmpty(item.nextEvent)) {
                    setListMenu(["Submit", "Delete", "Duplicate", "Archive", "Preview", "Add Event", "Copy JSON"]);
                } else {
                    setListMenu([
                        "Submit",
                        "Delete",
                        "Duplicate",
                        "Edit Upcoming Event",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                }

                break;
            case "pending":
                if (isEmpty(item.nextEvent)) {
                    setListMenu([
                        "Approve - Live",
                        "Go To Draft",
                        "Delete",
                        "Duplicate",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                } else {
                    setListMenu([
                        "Approve - Live",
                        "Go To Draft",
                        "Delete",
                        "Duplicate",
                        "Edit Upcoming Event",
                        "Archive",
                        "Preview",
                        "Add Event",
                        "Copy JSON",
                    ]);
                }

                break;
            case "archived":
                setListMenu(["Unarchive", "Delete", "Duplicate", "Copy JSON"]);
                break;
            default:
                break;
        }
    }, [status, item]);

    return (
        <div
            className={clsx("display-row", classes.containerItem, "container-item-pre-filter")}
            style={{
                background: `${isOver ? "rgba(0, 0, 0, 0.3)" : ""}`,
                borderRadius: `${isOver ? "10px" : 0}`,
                cursor: "pointer",
            }}
            // onMouseOver={(e) => {
            //     setOver(true);
            // }}
            onMouseEnter={(e) => {
                setOver(true);
            }}
            onMouseLeave={(e) => {
                setAnchorEl();
                setOver(false);
            }}
            onClick={() =>
                history.push(
                    `/${item.isSubscribable ? "calendar" : "collection"}/${item.provider}:${
                        item.type
                    }:${encodeURIComponent(item.uid)}`
                )
            }
        >
            {isOver && (
                <div className={classes.containerOver}>
                    <div
                        className={clsx("display-center", classes.iconMore)}
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        <img src={MoreIcon} alt="" />
                    </div>
                </div>
            )}
            <div className={classes.containerImage}>
                <img
                    alt=""
                    src={getImageUrlSmall(item.image)}
                    style={{ width: 117, height: 117, objectFit: "cover", borderRadius: 8 }}
                />
            </div>
            <div style={{ height: 117, flex: 1, width: "calc(100% - 157px)" }}>
                <div style={{ height: 35 }}>
                    {item.isSubscribable !== undefined ? (
                        <div className={classes.containerName}>
                            <div className={clsx(classes.txtName, "text-ellipsis")}>{item.title}</div>
                        </div>
                    ) : (tag === true) && (
                        <div className={classes.containerName}>
                            <div className={clsx(classes.txtName, "text-ellipsis")}>{item.title}</div>
                        </div>
                    )}
                </div>
                {!isEmpty(item.nextEvent) && (
                    <div>
                        <div className={clsx(classes.txtNextEvent, "text-ellipsis ")}>{`Next event: ${
                            item?.nextEvent?.title ?? ""
                        }`}</div>
                        {formatEventTime(item?.nextEvent, true, classes)}
                        <div className={clsx(classes.txtLocation, "txtLocationItemPreFilter")}>
                            {item?.nextEvent?.location ?? ""}
                        </div>
                    </div>
                )}
            </div>
            <Popover
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl();
                    setOver(false);
                }}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
            >
                <PopupMenu
                    listMenu={listMenu}
                    onClick={(itemMenu) => {
                        setAnchorEl();
                        switch (itemMenu) {
                            case "Delete":
                                onClickDeleteFeed(item);
                                break;
                            case "Duplicate":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/duplicate/${item.isSubscribable ? "calendar" : "collection"}/${
                                            item.provider
                                        }:${item.type}:${encodeURIComponent(item.uid)}`
                                    );
                                } else {
                                    history.push(
                                        `/duplicate/${item.isSubscribable ? "calendar" : "collection"}/${
                                            item.provider
                                        }:${item.type}:${encodeURIComponent(item.uid)}`
                                    );
                                }
                                break;
                            case "Edit Upcoming Event":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/feed/${item.provider}:${item.type}:${encodeURIComponent(
                                            item.uid
                                        )}/edit/${encodeURIComponent(item.nextEvent.uid)}`
                                    );
                                } else {
                                    history.push(
                                        `/feed/${item.provider}:${item.type}:${encodeURIComponent(
                                            item.uid
                                        )}/edit/${encodeURIComponent(item.nextEvent.uid)}`
                                    );
                                }
                                break;
                            case "Archive":
                                if (item.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "archived" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "archived" },
                                        })
                                    );
                                }
                                break;
                            case "Preview":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/edit/${item.isSubscribable ? "calendar" : "collection"}/${item.provider}:${
                                            item.type
                                        }:${encodeURIComponent(item.uid)}`
                                    );
                                } else {
                                    history.push(
                                        `/edit/${item.isSubscribable ? "calendar" : "collection"}/${item.provider}:${
                                            item.type
                                        }:${encodeURIComponent(item.uid)}`
                                    );
                                }
                                break;
                            case "Add Event":
                                if (location.pathname.includes("create") || location.pathname.includes("edit")) {
                                    history.replace(
                                        `/feed/${item.provider}:${item.type}:${encodeURIComponent(item.uid)}/create`
                                    );
                                } else {
                                    history.push(
                                        `/feed/${item.provider}:${item.type}:${encodeURIComponent(item.uid)}/create`
                                    );
                                }
                                break;
                            case "Unarchive":
                            case "Submit":
                                if (item.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "pending" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "pending" },
                                        })
                                    );
                                }
                                break;
                            case "Approve - Live":
                                if (item.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "live" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "live" },
                                        })
                                    );
                                }
                                break;
                            case "Pause":
                            case "Go To Draft":
                                if (item.isSubscribable) {
                                    dispatch(
                                        updateItemFeedCalendar({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "draft" },
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateItemFeedCollection({
                                            uid: `${item.provider}:${item.type}:${encodeURIComponent(item.uid)}`,
                                            data: { status: "draft" },
                                        })
                                    );
                                }
                                break;
                            case "Copy JSON":
                                copy(`${JSON.stringify(copyFeedItem(item))}`);
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </Popover>
        </div>
    );
}

export default ItemPreFilter;
