import { buildSchema } from "graphql";
import { SchemaElementType, SchemaKeyValueType } from "../components/Feed/schema/Schema";

export const AWS_SCALARS =
    "\nscalar AWSDate\nscalar AWSTime\nscalar AWSDateTime\nscalar AWSTimestamp\nscalar AWSEmail\nscalar AWSJSON\nscalar AWSURL\nscalar AWSPhone\nscalar AWSIPAddress\nscalar RichText\nscalar TimeZone";

export const MEDIA_RESOURCE_TYPE = "\ntype MediaResource {\npath: String\nmediaType: String\n}";

export const ParserError = {
    QUERY_PRESENT: "QUERY_PRESENT",
    MUTATION_PRESENT: "MUTATION_PRESENT",
    SCALAR_PRESENT: "SCALAR_PRESENT",
};

function extractUserDefinedTypes(typeMap) {
    const userDefinedTypeMap = {};

    Object.keys(typeMap).forEach((key) => {
        if (!key.startsWith("__")) {
            if (
                ![
                    "Int",
                    "Float",
                    "String",
                    "Boolean",
                    "Query",
                    "Mutation",
                    "AWSDate",
                    "AWSTime",
                    "AWSDateTime",
                    "AWSTimestamp",
                    "AWSEmail",
                    "AWSJSON",
                    "AWSURL",
                    "AWSPhone",
                    "AWSIPAddress",
                    "RichText",
                    "TimeZone"
                ].includes(key)
            ) {
                userDefinedTypeMap[key] = typeMap[key];
            }
        }
    });

    return userDefinedTypeMap;
}

function getMergedFields(type) {
    const fields = type.getFields();
    if ("getInterfaces" in type) {
        const gqlInterfaces = type.getInterfaces();
        if (gqlInterfaces.length > 0) {
            gqlInterfaces.forEach((gqlInterface) => {
                const interfaceMergedFields = getMergedFields(gqlInterface);
                Object.keys(interfaceMergedFields).forEach((key) => {
                    if (!fields[key]) {
                        fields[key] = interfaceMergedFields[key];
                    }
                });
            });
        }
    }
    return fields;
}

function parseField(field) {
    const parsedField = {
        type: "",
        required: false,
        array: false,
    };

    let fieldType = field.astNode.type;
    if (fieldType.kind === "NonNullType") {
        parsedField.required = true;
        fieldType = fieldType.type;
    }
    if (fieldType.kind === "ListType") {
        parsedField.array = true;
        fieldType = fieldType.type;
    }
    parsedField.type = fieldType.name.value;
    return parsedField;
}

export default function gqlASTParser(schemaText) {
    const ast = buildSchema(schemaText);
    const typeMap = ast.getTypeMap();

    if (ast.getQueryType()) {
        throw ParserError.QUERY_PRESENT;
    }
    if (ast.getMutationType()) {
        throw ParserError.MUTATION_PRESENT;
    }

    const userDefinedTypeMap = extractUserDefinedTypes(typeMap);

    const parsedTypes = {};
    Object.keys(userDefinedTypeMap).forEach((key) => {
        const type = userDefinedTypeMap[key];
        if (type.astNode.kind === "ScalarTypeDefinition") {
            throw ParserError.SCALAR_PRESENT;
        }
        const parsedType = {};
        if ("getTypes" in type) {
            parsedType.type = "union";
            parsedType.value = type.getTypes().map((type) => type.name);
        } else if ("getValues" in type) {
            parsedType.type = "enum";
            parsedType.value = type.getValues().map((type) => type.name);
        } else {
            parsedType.type = "type";
            parsedType.value = {};

            const fields = getMergedFields(type);
            Object.keys(fields).forEach((fieldKey) => {
                const field = fields[fieldKey];
                parsedType.value[fieldKey] = parseField(field);
            });
        }
        parsedTypes[key] = parsedType;
    });

    return parsedTypes;
}

const ObjectSchema = () => ({
    type: SchemaElementType.OBJECT,
    required: false,
    object: {},
});

const StringSchema = () => ({
    type: SchemaElementType.KEY_VALUE,
    required: false,
    keyvalue: {
        valueType: SchemaKeyValueType.TEXT,
    },
});

const FloatSchema = () => ({
    type: SchemaElementType.KEY_VALUE,
    required: false,
    keyvalue: {
        valueType: SchemaKeyValueType.NUMBER,
    },
});

const EnumSchema = () => ({
    type: SchemaElementType.KEY_VALUE,
    required: false,
    keyvalue: {
        valueType: SchemaKeyValueType.ENUM,
        enumEntries: [],
    },
});

const MediaResourceSchema = () => ({
    type: SchemaElementType.KEY_VALUE,
    required: false,
    keyvalue: {
        valueType: SchemaKeyValueType.IMAGE_PICKER,
    },
});

const RichTextSchema = () => ({
    type: SchemaElementType.KEY_VALUE,
    required: false,
    keyvalue: {
        valueType: SchemaKeyValueType.RICH_TEXT,
    },
});

const TimeZoneSchema = () => ({
    type: SchemaElementType.KEY_VALUE,
    required: false,
    keyvalue: {
        valueType: SchemaKeyValueType.TIME_ZONE,
    },
});

const ArraySchema = () => ({
    type: SchemaElementType.DYNAMIC_LENGTH,
    required: false,
    array: [],
    dynamicLength: {
        isUnion: false,
    },
});

const UnionSchema = () => ({
    type: SchemaElementType.UNION,
    required: false,
    union: [],
});

const BoolSchema = () => ({
    type: SchemaElementType.KEY_VALUE,
    required: false,
    keyvalue: {
        valueType: SchemaKeyValueType.BOOL,
    },
});

function schemaFromType(type, parsedAST) {
    let schema;
    if (["String", "AWSTimestamp", "AWSEmail", "AWSJSON", "AWSURL", "AWSPhone", "AWSIPAddress"].includes(type)) {
        schema = StringSchema();
    } else if (type === "AWSDate") {
        schema = StringSchema();
        schema.keyvalue.valueType = SchemaKeyValueType.DATE;
    } else if (type === "AWSTime") {
        schema = StringSchema();
        schema.keyvalue.valueType = SchemaKeyValueType.TIME;
    } else if (type === "AWSDateTime") {
        schema = StringSchema();
        schema.keyvalue.valueType = SchemaKeyValueType.DATETIME;
    } else if (type === "Float") {
        schema = FloatSchema();
    } else if (type === "Int") {
        schema = FloatSchema();
    } else if (type === "MediaResource") {
        schema = MediaResourceSchema();
    } else if (type === "RichText") {
        schema = RichTextSchema();
    } else if (type === "TimeZone") {
        schema = TimeZoneSchema();
    } else if (type === "Boolean") {
        schema = BoolSchema();
    }else {
        schema = compileSchemaComponent(parsedAST[type], parsedAST);
    }
    return schema;
}

function compileSchemaComponent(target, parsedAST) {
    let schema;
    const type = target.type;
    if (type === "type") {
        schema = ObjectSchema();
        Object.keys(target.value).forEach((key) => {
            schema.object[key] = compileSchemaComponent(target.value[key], parsedAST);
            schema.object[key].visibleName = key;
        });
    } else if (type === "enum") {
        schema = EnumSchema();
        schema.keyvalue.enumEntries = target.value.map((val) => ({ key: val, value: val }));
    } else if (type === "union") {
        schema = UnionSchema();
        target.value.forEach((val) => {
            const unionSchema = schemaFromType(val, parsedAST);
            unionSchema.visibleName = val;
            schema.union.push(unionSchema);
        });
    } else {
        if (target.array) {
            schema = ArraySchema();
            target.array = false;
            if (target.type in parsedAST) {
                const targetType = parsedAST[target.type];
                if (targetType.type === "") {
                }
            }
            schema.array.push(compileSchemaComponent(target, parsedAST));
        } else {
            schema = schemaFromType(type, parsedAST);
        }
    }
    schema.required = target.required;
    return schema;
}

export function compileSchemaComponentsFromParsedAST(schemaName, parsedAST) {
    const targetAST = parsedAST[schemaName];
    return compileSchemaComponent(targetAST, parsedAST);
}
