import { createSlice } from "@reduxjs/toolkit";

const eventTagReducer = createSlice({
    name: "tagEvent",
    initialState: {
        isLoading: false,
        data: { events: [], items: [] },
        tag: {}
    },
    reducers: {
        getEventsByTag: (state, action) => {
            state.isLoading = true;
        },
        getEventsByTagSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getEventsByTagFail: (state, action) => {
            state.isLoading = false;
        },
        changeTagTab: (state, action) => {
            state.data = { events: [] };
        },
        setTagType: (state, action) => {
            state.tag = action.payload.tagType
        }
    },
});

const { reducer, actions } = eventTagReducer;
export const {
    getEventsByTag,
    getEventsByTagSuccess,
    getEventsByTagFail,
    changeTagTab,
    setTagType
} = actions;
export default reducer;