import { withCallback } from "redux-saga-callback";
import { takeLatest, call, put } from "redux-saga/effects";
import handleApi from "../../utils/HandleApi";
import { createYoutube, createYoutubeFail } from "../reducers/YouTubeReducer";

function* youTubeSagaWatcher() {
    yield takeLatest(createYoutube, withCallback(createYt));
}

function* createYt(action) {
    try {
        const response = yield call(handleApi, `dashboard/youtube/${action.payload.type}/${action.payload.id}`, "POST", action.payload.settings);
        return true;
    } catch (error) {
        yield put(createYoutubeFail({ isError: true, message: error.message }));
        return false;
    }
}

export default youTubeSagaWatcher;
