import { makeStyles } from "@material-ui/core";
const imagePickerStyle = makeStyles((theme) => ({
    txtTitle: {
        fontSize: 12,
        lineHeight: "14px",
        height: "14px",
        color: "white",
        fontFamily: "SFProTextSemiBold",
    },
}));

export default imagePickerStyle;
