import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import ScreenLoading from "react-fullpage-custom-loader";
import HeaderPreFilter from "../components/HeaderPreFilter";
import SortIcon from "../assets/icons/sort.svg";
import SearchIcon from "../assets/icons/search.svg"
import appStyle from "../styles/AppStyle";
import ItemPreFilter from "../components/ItemPreFilter";
import Pagination from "../components/Pagination";
import SelectWithTitle from "../components/SelectWithTitle";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../redux/reducers/PreFilterReducer";
import { changeTagTab } from "../redux/reducers/TagEventReducer";
import ItemEventPreFilter from "../components/ItemEventPreFilter";
import { getParameterByName, upperCaseFirstLetter, getImageUrl } from "../utils/Utils";
// formatTime, copyFeedEventItem
import { getFeedsByPreFilter, getListEventByTag } from "../redux/actions"; //import action chỗ này
import { getTags } from "../redux/reducers/TagReducer";
import { divide, isEmpty } from "lodash";
import { onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import { deleteCalendar, deleteCalendarFail } from "../redux/reducers/CalendarReducer";
import { deleteCollection, deleteCollectionFail } from "../redux/reducers/CollectionReducer";
import { deleteEventUnassign } from "../redux/reducers/EventReducer";
import SortComponent from "../components/SortComponent";
import { useHistory } from "react-router-dom";
import headerPreFilterStyle from "../styles/HeaderPreFilterStyle";
import HeaderPreFilterSticky from "../components/HeaderPreFilterSticky";
import TabsComponent from "../components/TabsComponent";
import DropDownImg from "../assets/icons/arrow_drop_down.svg"
import FilterImg from "../assets/icons/filter_icon.svg"
import CaretIcon from "../assets/icons/Caret.svg"
import { TagFacesTwoTone } from "@material-ui/icons";
import config from "../config";

function PreFilterView() {
    const status = useParams().status;
    const appStyles = appStyle();
    const dispatch = useDispatch();
    const { listFeed, onClickDelete, isDeleteCalendar, isDeleteCollection, tagList, tagEventList, tagStore, processLog, isLoadingTagEvent, isLoadingPrefilter } = useSelector((state) => ({
        listFeed: state.preFilter.data,
        onClickDelete: state.app.onClickDelete,
        isDeleteCalendar: state.calendar.isDelete,
        isDeleteCollection: state.collection.isDelete,
        tagList: state.tag.data,
        tagEventList: state.eventTag.data,
        tagStore: state.eventTag.tag,
        processLog: state.app.process,
        isLoadingTagEvent: state.eventTag.isLoading,
        isLoadingPrefilter: state.preFilter.isLoading,
    }));

    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);
    const [type, setType] = useState("all");
    const [lisFeedShow, setListFeedShow] = useState([]);
    const [noData, setNoData] = useState();
    const [currentItem, setCurrentItem] = useState();
    const [isAscending, setAscending] = useState(false);
    const [sortBy, setSortBy] = useState();
    const [tagValue, setTagValue] = useState('');
    const [tagQuery, setTagQuery] = useState('');
    const [tagFisrt, setTagFirst] = useState();
    
    const [openSelect, setOpenSelect] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [keyCode, setKeyCode] = useState(0);

    const history = useHistory();
    const classes = headerPreFilterStyle();

    useEffect(() => {
        resetData();
        setType(isEmpty(getParameterByName("type")) ? "all" : getParameterByName("type"));
        setSortBy(isEmpty(getParameterByName("sortBy")) ? "-created" : getParameterByName("sortBy"));
        setAscending(isEmpty(getParameterByName("sortBy")) ? false : getParameterByName("sortBy")[0] !== "-");
        if(getParameterByName("type") === 'tags') {
            if (processLog === 'goBack' && Object.keys(tagStore).length !== 0) {
                setTagQuery(tagStore.tagQuery)
                setTagValue(tagStore.tagValue)
                dispatch( getListEventByTag({
                    tag_id: tagStore.tagQuery,
                    limit: 10,
                    sortBy: sortBy,
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        if (isEmpty(data?.events)) {
                            setNoData(true);
                        } else {
                            setNoData(false);
                        }
                    },
                }))
            } else {
                setNoData(false)
            }
        } else {
            dispatch(
                getFeedsByPreFilter({
                    status: status,
                    type: isEmpty(getParameterByName("type")) ? "all" : getParameterByName("type"),
                    limit: 10,
                    sortBy: getParameterByName("sortBy"),
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        if (isEmpty(data?.items)) {
                            setNoData(true);
                        } else {
                            setNoData(false);
                        }
                    },
                })
            );
        }
    }, [status, processLog]);

    useEffect(() => {
        setListFeedShow(listFeed ?? []);
    }, [listFeed]);

    useEffect(() => {
        if (onClickDelete) {
            if (currentItem.isSubscribable === undefined) {
                dispatch(deleteEventUnassign({ uid: encodeURIComponent(currentItem.uid) }));
            } else if (currentItem.isSubscribable) {
                dispatch(
                    deleteCalendar({
                        uid: `${currentItem.provider}:${currentItem.type}:${encodeURIComponent(currentItem.uid)}`,
                    })
                );
            } else if (!currentItem.isSubscribable) {
                dispatch(
                    deleteCollection({
                        uid: `${currentItem.provider}:${currentItem.type}:${encodeURIComponent(currentItem.uid)}`,
                    })
                );
            }
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    useEffect(() => {
        if (isDeleteCalendar || isDeleteCollection) {
            dispatch(deleteCollectionFail({ isError: false, message: "" }));
            dispatch(deleteCalendarFail({ isError: false, message: "" }));
        }
    }, [isDeleteCalendar, isDeleteCollection]);

    useEffect(()=>{
        dispatch(getTags())
    },[])

    useEffect(() => {
        document.getElementById('search').addEventListener('keyup', (e)=>{
            if(e.key === 'Enter') {
                setKeyCode(13);
            }
        });
    }, []);

    useEffect(() => {
        if(keyCode === 13) {
            submitSearch()
        }
    }, [keyCode]);

    const onChangeTab = (value, tag='') => {
        setType(value);
        setTagFirst(false)
        history.replace({
            pathname: history.location.pathname,
            search: "?" + new URLSearchParams({ type: value, sortBy: "-created" }).toString(),
        });
        resetData();
        if(value === 'tags') {
            if (tag === 'pseudo') {
                setTagFirst(true)
                setTagValue();
                return;
            }
            const tag_id = encodeURIComponent(`${tag.dataSource}:${tag.value}`)
            setTagQuery(tag_id)

            dispatch( getListEventByTag({
                tag_id,
                limit: 10,
                sortBy: sortBy,
                onComplete: ({ error, cancelled, data }) => {
                    if (error || cancelled) return;
                    if (isEmpty(data?.events)) {
                        setNoData(true);
                    } else {
                        setNoData(false);
                    }
                },
            }))
            return;
        }

        dispatch(
            getFeedsByPreFilter({
                status: status,
                type: `${value}`,
                limit: 10,
                onComplete: ({ error, cancelled, data }) => {
                    if (error || cancelled) return;
                    if (isEmpty(data?.items)) {
                        setNoData(true);
                    } else {
                        setNoData(false);

                    }
                },
            })
        );
    };

    function submitSearch() {
        setKeyCode(0);
        setPage(1);
        if(type === 'tags') {
            dispatch( getListEventByTag({
                tag_id: tagQuery,
                limit: count,
                query: searchValue,
                sortBy: sortBy,
                onComplete: ({ error, cancelled, data }) => {
                    if (error || cancelled) return;
                    if (isEmpty(data?.events)) {
                        setNoData(true);
                    } else {
                        setNoData(false);
                    }
                },
            }))
        } else {
            dispatch(
                getFeedsByPreFilter({
                    status: status,
                    type: `${type}`,
                    query: searchValue,
                    sortBy: getParameterByName("sortBy"),
                    limit: count,
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        if (isEmpty(data?.items)) {
                            setNoData(true);
                        } else {
                            setNoData(false);
                        }
                    },
                })
            )
        }
    };

    function resetData() {
        setNoData();
        setAscending(false);
        setSortBy("-created");
        setPage(1);
        setCount(10);
        setSearchValue('')
        setListFeedShow([]);
        dispatch(changeTab());
        dispatch(changeTagTab());
    }

    return (
        <div>
            <HeaderPreFilter title={upperCaseFirstLetter(status === "pending" ? "Pending approval" : status)} />
            <HeaderPreFilterSticky title={upperCaseFirstLetter(status === "pending" ? "Pending approval" : status)} />
            <div className={appStyles.tabSearchWrapper}>
                <TabsComponent status={type} onChangeTab={onChangeTab} />
                <div className={appStyles.iconContentWrapper} style={{pointerEvents: `${(type==='tags' && !tagValue) ? 'none' : ''}`, opacity: `${(type==='tags' && !tagValue) ? "0.5" : ''}`}}>
                    <input 
                        id="search"
                        type="text" 
                        className="search-form" 
                        name="search" 
                        placeholder="Search here"
                        value={searchValue}
                        onChange={(e)=>{
                            setSearchValue(e.target.value);
                        }}
                        autoComplete="off"
                        disabled={type === 'tags' && !tagValue}
                    />
                    <img className={appStyles.searchImage} src={SearchIcon} alt="" onClick={submitSearch}/>
                </div>
            </div>
            <div className={appStyles.containerChildTabPreFilter}>
                {(noData && type !== 'tags') ? (
                    <div className={clsx("display-center", appStyles.txtNoDataPrefilter)}>
                        {!searchValue ? 'There is no data to display': 'No Results'}
                    </div>
                ) : (
                    <div className={appStyles.containerContent}>
                        <div style={{ position: "relative", height: `${type==='tags' ? 'unset' : '60px'}`, display: "flex" }}>
                            {
                                type === 'tags' && (
                                    <SelectWithTitle
                                        tag={true}
                                        data={tagValue}
                                        list={tagList.map((item) => ({ title: item.name, value: item.uid, dataSource: item.dataSource }))}
                                        title="Select Tag"
                                        require
                                        placeHolder="Select a tag"
                                        onChangeTab={onChangeTab}
                                        onItemChange={(item) => {
                                            setTagValue(item.value);
                                            onChangeTab("tags", item)
                                        }}
                                    />
                                )
                            }
                            { (noData === false && tagFisrt !== true)  && (
                                <div className={appStyles.positionSortIcon}>
                                    <SortComponent
                                        tag={type === 'tags'}
                                        isAscending={isAscending}
                                        isEvent={type === "single-events"}
                                        sortBy={sortBy}
                                        onClickSort={(value, isInvert) => {
                                            setPage(1);
                                            setSortBy(value);
                                            if(isInvert) {
                                                setAscending(!isAscending);
                                            }
                                            history.replace({
                                                pathname: history.location.pathname,
                                                search: "?" + new URLSearchParams({ type, sortBy: value }).toString(),
                                            });
                                            if (type!=='tags') {
                                                dispatch(changeTab());
                                                dispatch(
                                                    getFeedsByPreFilter({
                                                        status: status,
                                                        type: type,
                                                        query: searchValue,
                                                        limit: count,
                                                        sortBy: value,
                                                    })
                                                );
                                            } else {
                                                dispatch(changeTagTab());
                                                dispatch(
                                                    getListEventByTag({
                                                        tag_id: tagQuery,
                                                        limit: count,
                                                        query: searchValue,
                                                        sortBy: sortBy,
                                                    }
                                                ));
                                            }
                                            
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {
                            noData === true && (
                                <div className={appStyles.notFoundTagEvent}> No events found</div>
                            )
                        }
                        
                        <div className={clsx(appStyles.containerListPreFilter, appStyles.scrollbar)}>
                            { type !=='tags' ? (
                                isLoadingPrefilter ? (
                                    <ScreenLoading loaderType={"line-scale"} sentences={[]} />
                                ) : (lisFeedShow?.items?.slice((page - 1) * count, page * count).map((item, index) =>
                                item.event ? (
                                    <ItemEventPreFilter
                                        key={index}
                                        status={status}
                                        item={item.event}
                                        itemParent={item}
                                        onClickDeleteFeed={(item) => {
                                            setCurrentItem(item);
                                            dispatch(
                                                openMessageDialog({
                                                    open: true,
                                                    message: "Are you sure you want to delete this event?",
                                                    title: "DELETE EVENT",
                                                })
                                            );
                                        }}
                                    />
                                ) : (
                                    <ItemPreFilter
                                        tag={type === 'tags'}
                                        key={index}
                                        status={status}
                                        item={item}
                                        onClickDeleteFeed={(item) => {
                                            setCurrentItem(item);
                                            dispatch(
                                                openMessageDialog({
                                                    open: true,
                                                    message: `Are you sure you want to delete this ${
                                                        item.isSubscribable ? "calendar" : "collection"
                                                    }?`,
                                                    title: `DELETE ${item.isSubscribable ? "CALENDAR" : "COLLECTION"}`,
                                                })
                                            );
                                        }}
                                    />
                                ))
                            )) : (
                                isLoadingTagEvent ? (
                                    <ScreenLoading loaderType={"line-scale"} sentences={[]} />
                                ) : (
                                    tagEventList?.events?.slice((page - 1) * count, page * count).map((item, index) => (
                                        <ItemEventPreFilter
                                            tag={true}
                                            tagType={{tagQuery,tagValue}}
                                            key={index}
                                            status={status}
                                            item={item}
                                            itemParent={item}
                                            onClickDeleteFeed={(item) => {
                                                setCurrentItem(item);
                                                dispatch(
                                                    openMessageDialog({
                                                        open: true,
                                                        message: "Are you sure you want to delete this event?",
                                                        title: "DELETE EVENT",
                                                    })
                                                );
                                            }}
                                        />
                                    ))
                                )
                            )}
                        </div>
                    </div>
                )}
                <div className={appStyles.containerPaginationPreFilter}>
                    <div className={appStyles.containerPaginationPreFilter} style={{ border: "none", height: 46 }}>
                        <Pagination
                            onCountChange={(value) => {
                                setCount(value);
                                setPage(1);
                                if(type !== 'tags') {
                                    dispatch(changeTab());
                                    dispatch(getFeedsByPreFilter({ status: status, type: type, limit: value }));
                                } else {
                                    dispatch(changeTagTab());
                                    dispatch(getListEventByTag({tag_id: tagQuery, limit: value}));
                                }
                                
                            }}
                            onPreviousPage={(value) => {
                                setPage(value);
                            }}
                            onNextPage={(value) => {
                                if(type !== 'tags') {
                                    if (value * count > listFeed.items.length && listFeed.items.length < listFeed.count) {
                                        dispatch(
                                            getFeedsByPreFilter({
                                                status: status,
                                                query: searchValue,
                                                type: type,
                                                limit: count,
                                                sortBy: sortBy,
                                                pageToken: listFeed.nextPageToken,
                                            })
                                        );
                                    }
                                } else {
                                    if (value * count > tagEventList.events.length && tagEventList.events.length < tagEventList.count) {
                                        dispatch(
                                            getListEventByTag({
                                                tag_id: tagQuery,
                                                limit: count,
                                                query: searchValue,
                                                sortBy: sortBy,
                                                page_token: tagEventList.nextPageToken,
                                                
                                            }
                                        ));
                                    }
                                }
                                setPage(value);
                            }}
                            total={type!=='tags' ? listFeed.count : tagEventList.count}
                            currentPage={page}
                            currentCount={count}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreFilterView;
