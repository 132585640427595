import clsx from "clsx";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import createFormStyle from "../styles/CreateFormStyle";

function SectionHeader(props) {
    const {
        title,
        showSwitch,
        onClickClose,
        onClickDefault,
        onClickJson,
        isDefault,
        showTextEdit,
        onClickEdit,
        showTextEditInTitle,
        txtAction,
        type,
    } = props;
    const classes = createFormStyle();
    // const [isDefault, setDefault] = useState(true);

    function generateText() {
        switch (type) {
            case "collection":
                return "An event with numerous time availability, such as a movie showtimes or multiple nights for a concert.";
            case "calendar":
                return "A steady flow or a high-volume of new events added to the calendar (e.g., YouTube Channel or NBA season).";
            case "event":
                return "An ad-hoc event such as a live concert or presidential election.";
            case "category":
                return "Pick a category to help the user quickly find & understand events.";
            case "subcategory":
                return "Pick subcategories to help the user filter & refine events.";
            case "schema":
                return "A set of data types can be created to extend extra metadata, such as multiple images, locations, and maybe even a group of personas.";
            case "persona":
                return "A persona should've notoriety and eminence that are worthy of emulating.";
            case "tag":
                return "A list of related keywords that help users quickly browse similar events.";
            case "youtube":
                return "Popular channels or playlists with serializable videos such as a learning a language or episodic videos (e.g. Season 1 TED).";
            case "stanza":
                return "Hello, Welcome to stanza!";
            default:
                return "";
        }
    }

    return (
        <div>
            <div className="display-center-row" style={{ margin: "32px 0 0 30px", height: 20, position: "relative" }}>
                <CloseIcon
                    style={{
                        color: "white",
                        width: 18,
                        height: 18,
                        position: "absolute",
                        right: 30,
                        cursor: "pointer",
                    }}
                    onClick={onClickClose}
                />
                {showSwitch && (
                    <div className={clsx("display-center-row", classes.containerToggle)} style={{display: type === 'stanza' && 'none'}}>
                        <div
                            style={{
                                flex: 1,
                                textAlign: "center",
                                cursor: "pointer",
                                color: isDefault ? "white" : "unset",
                                background: isDefault ? "rgba(151, 151, 151, 0.2)" : "unset",
                            }}
                            onClick={() => {
                                if (!isDefault) {
                                    // setDefault(true);
                                    onClickDefault();
                                }
                            }}
                        >
                            DEFAULT
                        </div>
                        <div style={{ height: 20, width: 0.5, background: "#979797" }} />
                        <div
                            style={{
                                flex: 1,
                                textAlign: "center",
                                cursor: "pointer",
                                color: !isDefault ? "white" : "unset",
                                background: !isDefault ? "rgba(151, 151, 151, 0.2)" : "unset",
                            }}
                            onClick={() => {
                                if (isDefault) {
                                    // setDefault(false);
                                    onClickJson();
                                }
                            }}
                        >
                            JSON
                        </div>
                    </div>
                )}
                {showTextEdit && (
                    <div className={classes.txtEditExisting} onClick={onClickEdit}>
                        {txtAction}
                    </div>
                )}
            </div>
            <div className="display-col" style={{ margin: "25px 0 5px 30px" }}>
                <div className="display-center-row">
                    <div className={classes.titleCalendar}>{title}</div>
                    {showTextEditInTitle && (
                        <div className={classes.txtEditExisting} onClick={onClickEdit}>
                            {txtAction}
                        </div>
                    )}
                </div>
                <div style={{ fontFamily: "SourceSansPro", fontSize: 10 }}>{generateText()}</div>
            </div>
        </div>
    );
}

SectionHeader.defaultProps = {
    title: "",
    txtAction: "",
    showSwitch: true,
    isDefault: true,
    showTextEdit: false,
    showTextEditInTitle: false,
    onClickClose: () => {},
    onClickEdit: () => {},
};

export default SectionHeader;
