import React, { useEffect, useState } from "react";
import Dropdown from "../assets/icons/dropdown.svg";
import IconLeftActive from "../assets/icons/arrow-left-active.svg";
import IconRightActive from "../assets/icons/arrow-right-active.svg";
import IconLeft from "../assets/icons/arrow-left.svg";
import IconRight from "../assets/icons/arrow-right.svg";
import detailViewStyle from "../styles/DetailViewStyle";
import { Popover } from "@material-ui/core";
import { isEmpty } from "lodash";
import clsx from "clsx";

function Pagination(props) {
    const { style, onCountChange, total, onNextPage, onPreviousPage, currentPage, currentCount } = props;
    const classes = detailViewStyle();
    const [anchorEl, setAnchorEl] = useState();
    const [page, setPage] = useState();
    const [count, setCount] = useState();

    useEffect(() => {
        setPage(currentPage);
        setCount(currentCount);
    }, [currentPage, currentCount]);

    return (
        <div style={{ height: 24, ...style }} className="display-center-row">
            <div className={clsx(classes.txtRowPerPage, "text-ellipsis")}>Rows per page:</div>
            <div
                className="display-center-row"
                style={{ cursor: total > 0 ? "pointer" : "initial" }}
                onClick={(e) => {
                    if (total > 0) setAnchorEl(e.currentTarget);
                }}
            >
                <div className={classes.txtCountPag}>{total > 0 ? count : 0}</div>
                <img alt="" src={Dropdown} />
            </div>
            <div className={classes.txtPagePag}>{`${total === 0 ? 0 : (page - 1) * count + 1}-${
                page * count > total ? total : page * count
            } of ${total}`}</div>
            <div
                style={{ width: 20, height: 24, cursor: total > 0 ? "pointer" : "initial", marginRight: 20 }}
                className="display-center"
                onClick={() => {
                    if (page > 1) {
                        setPage(page - 1);
                        onPreviousPage(page - 1);
                    }
                }}
            >
                <img src={page === 1 ? IconLeft : IconLeftActive} alt="" />
            </div>
            <div
                style={{ width: 20, height: 24, cursor: total > 0 ? "pointer" : "initial" }}
                className="display-center"
                onClick={() => {
                    if (page * count < total) {
                        setPage(page + 1);
                        onNextPage(page + 1);
                    }
                }}
            >
                <img src={page * count < total ? IconRightActive : IconRight} alt="" />
            </div>
            <Popover
                open={!isEmpty(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                style={{ borderRadius: 0 }}
            >
                <div style={{ background: "#282828" }}>
                    {[5, 10, 20].map((item, index) => (
                        <div
                            key={index}
                            style={{ height: 25, width: 30, cursor: "pointer", color: "white", textAlign: "center" }}
                            onClick={() => {
                                setAnchorEl();
                                setCount(item);
                                setPage(1);
                                onCountChange(item);
                            }}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </Popover>
        </div>
    );
}

Pagination.defaultProps = {
    onCountChange: () => {},
    total: 0,
    currentPage: 1,
    currentCount: 10,
    onNextPage: () => {},
    onPreviousPage: () => {},
};

export default Pagination;
