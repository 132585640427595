import React from "react";
import SchemaArrayDynamicLength from "./SchemaArrayDynamicLength";
import SchemaObject from "./SchemaObject";
import SchemaArray from "./SchemaArray";
import SchemaUnion from "./SchemaUnion";

export const SchemaKeyValueType = {
    TEXT: "text",
    NUMBER: "number",
    BOOL: "bool",
    ENUM: "enum",
    DATE: "date",
    TIME: "time",
    DATETIME: "datetime",
    IMAGE_PICKER: "image_picker",
    RICH_TEXT: "rich_text",
    TIME_ZONE: "time_zone"
};

export const schemaStyles = {
    schemaLine: {
        marginBottom: "0",
        fontSize: "18px",
    },
    topMargin: {
        // marginTop: "1em",
    },
};

export const SchemaElementType = {
    KEY_VALUE: "keyvalue",
    OBJECT: "object",
    ARRAY: "array",
    DYNAMIC_LENGTH: "dynamicLength",
    UNION: "union",
};

const schemaComponents = {};

export function registerSchemaComponent(name, component) {
    schemaComponents[name] = component;
    return function (props) {
        return SchemaComponent({ name, props });
    };
}

export function SchemaComponent({ schemaComponent, ...props }) {
    const C = schemaComponents[schemaComponent];
    return <C {...props} />;
}

registerSchemaComponent("SchemaArrayDynamicLength", SchemaArrayDynamicLength);
registerSchemaComponent("SchemaObject", SchemaObject);
registerSchemaComponent("SchemaArray", SchemaArray);
registerSchemaComponent("SchemaUnion", SchemaUnion);
