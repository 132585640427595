import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./styles/AppStyle.scss";
import HomeView from "./views/HomeView";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { saveToken, onChangeOpenSidebar } from "./redux/reducers/AppReducer";
import Header from "./components/Header";
import Burger from "./components/Burger";
import { getCollections } from "./redux/reducers/CollectionReducer";
import FeedView from "./views/FeedView";
import EventView from "./views/EventView";
import CreateCategoryView from "./views/CreateCategoryView";
import CreateSubCategoryView from "./views/CreateSubCategoryView";
import CreatePersonaView from "./views/CreatePersonaView";
import CreateTagView from "./views/CreateTagView";
import CreateYouTubeView from "./views/CreateYouTubeView";
import CreateStanzaView from "./views/CreateStanzaView";
import FeedDetailView from "./views/FeedDetailView";
import PreFilterView from "./views/PreFilterView";
import CreateSchemaView from "./views/CreateSchemaView";
import { getStatistics } from "./redux/reducers/StatisticsReducer";
import { getCalendars } from "./redux/reducers/CalendarReducer";
import { disableBodyScroll } from "body-scroll-lock";
import appStyle from "./styles/AppStyle";
import { getCategory } from "./redux/reducers/CategoryReducer";
import { updateItemEventUnassign, updateItemFeedEvent } from "./redux/actions";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MessageDialog from "./components/MessageDialog";

const App = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const appStyles = appStyle();
    const targetRef = useRef();
    const sidebarRef = useRef();
    const history = useHistory();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(769));

    const [responsive, setResponsive] = useState({
        desktop: {
            breakpoint: {
                max: 1650,
                min: 1410,
            },
            items: 5,
            slidesToSlide: 5,
        },
        fullhd: {
            breakpoint: {
                max: 3000,
                min: 1650,
            },
            items: 6,
            slidesToSlide: 6,
        },
        tablet1: {
            breakpoint: {
                max: 1410,
                min: 1190,
            },
            items: 4,
            slidesToSlide: 4,
        },
        tablet: {
            breakpoint: {
                max: 1190,
                min: 950,
            },
            items: 3,
            slidesToSlide: 3,
        },
        mobile: {
            breakpoint: {
                max: 950,
                min: 710,
            },
            items: 2,
            slidesToSlide: 2,
        },
        miniMobile: {
            breakpoint: {
                max: 710,
                min: 0,
            },
            items: 1,
            slidesToSlide: 1,
        }
    });

    

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                dispatch(saveToken(token));
                dispatch(getCollections());
                dispatch(getCalendars());
                dispatch(getStatistics());
                dispatch(getCategory());
            } catch (e) {
                console.error(e);
            }
        })();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        dispatch(onChangeOpenSidebar(!isTablet));
    }, [isTablet]);

    useEffect(() => {
        disableBodyScroll(targetRef.current);
        disableBodyScroll(sidebarRef.current);
    }, [targetRef.current, sidebarRef.current]);

    
    window.onresize = ()=>{
        const CRS = document.querySelector('.container-carousel')
        const sideBar = document.getElementById('sidebar').style.width;
        let height
        const widthWindow = window.innerWidth;

        if (!CRS) {
            height = widthWindow - parseInt(sideBar) - 57
        } else {
            height = CRS.offsetWidth
        }
        let n ;
        n = Math.floor(height/240);
        if(1410 <= widthWindow && widthWindow < 1650) {
            setResponsive(prevRes => {
                return { 
                    ...prevRes, 
                    desktop: {
                        breakpoint: {
                            max: 1650,
                            min: 1410,
                        },
                        items: n,
                        slidesToSlide: n
                    },
                }
            })
        } else if(1650 <= widthWindow && widthWindow < 3000) {
            setResponsive(prevRes => {
                return { 
                    ...prevRes, 
                    fullhd: {
                        breakpoint: {
                            max: 3000,
                            min: 1650,
                        },
                        items: n,
                        slidesToSlide: n
                    },
                }
            })
        } else if(1190 <= widthWindow && widthWindow < 1410) {
            setResponsive(prevRes => {
                return { 
                    ...prevRes, 
                    tablet1: {
                        breakpoint: {
                            max: 1410,
                            min: 1190,
                        },
                        items: n,
                        slidesToSlide: n
                    },
                }
            })
        } else if(950 <= widthWindow && widthWindow < 1190) {
            setResponsive(prevRes => {
                return { 
                    ...prevRes, 
                    tablet: {
                        breakpoint: {
                            max: 1190,
                            min: 950,
                        },
                        items: n,
                        slidesToSlide: n
                    },
                }
            })
        } else if(710 <= widthWindow && widthWindow < 950) {
            setResponsive(prevRes => {
                return { 
                    ...prevRes, 
                    mobile: {
                        breakpoint: {
                            max: 950,
                            min: 710,
                        },
                        items: n,
                        slidesToSlide: n
                    },
                }
            })
        } else if(0 <= widthWindow && widthWindow < 710) {
            setResponsive(prevRes => {
                return { 
                    ...prevRes, 
                    miniMobile: {
                        breakpoint: {
                            max: 710,
                            min: 0,
                        },
                        items: n,
                        slidesToSlide: n
                    },
                }
            })
        }
    }

    return (
        <div id="app" style={{ display: "flex", flexDirection: "column" }}>
            <MessageDialog />
            <Header />
            <div style={{ display: "flex", flexDirection: "col", height: "calc(100vh - 49px)" }}>
                <Burger scrollRef={sidebarRef} setResponsive={setResponsive}/>
                <div ref={targetRef} className="container-content" id="detect-scroll">
                    <Switch>
                        <Route path="/" exact>
                            <HomeView carouselInfo={{ responsive: responsive, setResponsive:setResponsive}}/>
                        </Route>
                        <Route path="/create/calendar" exact component={FeedView} />
                        <Route path="/edit/calendar/:id" exact component={FeedView} />
                        <Route path="/duplicate/calendar/:id" exact component={FeedView} />
                        <Route path="/calendar/:id" exact component={FeedDetailView} />
                        <Route path="/collection/:id" exact component={FeedDetailView} />
                        <Route path="/create/collection" exact component={FeedView} />
                        <Route path="/edit/collection/:id" exact component={FeedView} />
                        <Route path="/duplicate/collection/:id" exact component={FeedView} />
                        <Route path="/feed/:id/duplicate/:eventId" exact component={EventView} />
                        <Route path="/feed/:id/create" exact component={EventView} />
                        <Route path="/feed/:id/edit/:eventId" exact component={EventView} />
                        <Route path="/duplicate/:eventId" exact component={EventView} />
                        <Route path="/events" exact component={FeedDetailView} />
                        <Route path="/create/event" exact component={EventView} />
                        <Route path="/edit/event/:eventId" exact component={EventView} />
                        <Route path="/create/category" exact component={CreateCategoryView} />
                        <Route path="/create/subcategory" exact component={CreateSubCategoryView} />
                        <Route path="/create/schema" exact component={CreateSchemaView} />
                        <Route path="/create/persona" exact component={CreatePersonaView} />
                        <Route path="/create/tag" exact component={CreateTagView} />
                        <Route path="/create/youtube" exact component={CreateYouTubeView} />
                        <Route path="/create/stanza" exact component={CreateStanzaView} />
                        <Route path="/:status" exact component={PreFilterView} />
                        <Redirect to="/" />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default withAuthenticationRequired(App);
