import { makeStyles } from "@material-ui/core";
const headerPreFilterStyle = makeStyles((theme) => ({
    container: {
        padding: "0px 20px",
    },
    txtPreFilter: {
        flex: 1,
        fontSize: 70,
        letterSpacing: 0.7,
        color: "white",
        padding: "5px 0 6px",
        fontWeight: "bold",
        "maxWidth": "536px",
        "whiteSpace": "nowrap",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
    },
    txtTab: {
        fontSize: 12,
        lineHeight: "14px",
        letterSpacing: 2.1,
        margin: "1px 0 13px",
    },
    containerTab1: {
        width: 86,
    },
    containerTab2: {
        width: 136,
    },
    containerTab3: {
        width: 151,
    },
    containerTab4: {
        width: 105,
    },
    containerTab5: {
        // width: 26
    },
    containerHeadSticky: {
        width: "100%",
        padding: "0 0 20px 20px",
        top: 49,
        paddingRight: 40,
        paddingLeft: 0,
    },
    activeSticky: {
        opacity: "1 !important",
        visibility: "visible !important",
        position: "fixed",
        width: "calc(100vw - 210px)",
        zIndex: 2,
        backgroundColor: "#181818",
        animationDuration: "0.5s",
    },
    headSticky: {
        opacity: 0,
    },
    stickyHeaderStyle: {
        visibility: "hidden",
        opacity: 0,
        width: "calc(100vw - 210px)",
        zIndex: 2,
        backgroundColor: "#181818",
        animationDuration: "0.5s",
        marginLeft: 0,
        paddingLeft: 20,
        paddingRight: 20,
        position: "absolute",
        // top: -1,
        width: "100%",
        // marginTop: -87
    },
    StickyFilterMenu: {
        position: "sticky",
        top: -1,
        padding: "0 20px",
        backgroundColor: "#181818",
        top: 67,
        zIndex: 2
    },
    imageItem: {
        width: "71px",
        height: "74px",
        margin: "10px 12px 8px 0px",
        background: "#181818",
        objectFit: "cover",
        borderRadius: "10px",
    }
}));

export default headerPreFilterStyle;
