import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ScreenLoading from "react-fullpage-custom-loader";
import detailViewStyle from "../styles/DetailViewStyle";
import appStyle from "../styles/AppStyle";
import SortIcon from "../assets/icons/sort.svg";
import FilterIcon from "../assets/icons/filter.svg";
import AddIcon from "../assets/icons/add_circle_outline_rounded.svg";
import ItemDetail from "../components/ItemDetail";
import Pagination from "../components/Pagination";
import HeaderDetail from "../components/HeaderDetail";
import HeaderDetailSticky from "../components/HeaderDetailSticky";
import HeaderPreFilterSticky from "../components/HeaderPreFilterSticky";
import HeaderEventSticky from "../components/HeaderEventSticky";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { deleteEvent, deleteEventUnassign, resetListEvent, getEventsUnassign } from "../redux/reducers/EventReducer";
import { deleteItemFeedEvent } from "../redux/actions"
import { getItemFeedById, onClickConfirmDialog, openMessageDialog } from "../redux/reducers/AppReducer";
import { useHistory, useLocation } from "react-router-dom";
import ItemEventDetail from "../components/ItemEventDetail";
import clsx from "clsx";
import { getEventsById, getListEventUnassign } from "../redux/actions";
import SortComponent from "../components/SortComponent";
import { getParameterByName } from "../utils/Utils";
import FilterComponent from "../components/FilterComponent";
import Logo from "../assets/icons/logo.svg";
import SearchIcon from "../assets/icons/search.svg"
import "../styles/FeedDetailView.scss";

function FeedDetailView() {
    const feedId = useParams().id;
    const classes = detailViewStyle();
    const appStyles = appStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [currentItem, setCurrentItem] = useState();
    const { itemFeed, events, onClickDelete, isOpenSidebar, isLoadingEvent } = useSelector((state) => ({
        events: state.event.data,
        itemFeed: state.app.itemFeed,
        onClickDelete: state.app.onClickDelete,
        isOpenSidebar: state.app.isOpenSidebar,
        isLoadingEvent: state.event.isLoading,
    }));

    const [count, setCount] = useState(10);
    const [page, setPage] = useState(1);
    const [eventShow, setEventShow] = useState({});
    const [noData, setNoData] = useState();
    const [isAscending, setAscending] = useState(false);
    const [sortBy, setSortBy] = useState();
    const [filterBy, setFilterBy] = useState();
    const [isActiveSticky, setActiveSticky] = useState(null);
    const [openSidebar, setOpenSidebar] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [keyCode, setKeyCode] = useState();

    const searchDetailRef = useRef(null);

    useEffect(() => {
        setPage(1);
        setCount(10);
        dispatch(resetListEvent());
        setNoData();
        let _sortBy = getParameterByName("sortBy") || "-created";
        let _sortAscending = _sortBy[0] !== "-";
        let _filterBy = getParameterByName("filterBy") || "all";
        setSortBy(_sortBy);
        setAscending(_sortAscending);
        setFilterBy(_filterBy);
        if (!isEmpty(feedId)) {
            dispatch(getItemFeedById(`${feedId}`));
            dispatch(
                getEventsById({
                    id: `${feedId}`,
                    limit: 10,
                    sortBy: _sortBy,
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        if (isEmpty(data?.events)) {
                            setNoData(true);
                        } else {
                            setNoData(false);
                        }
                    },
                })
            );
        } else {
            dispatch(
                getListEventUnassign({
                    status: _filterBy,
                    limit: 10,
                    sortBy: _sortBy,
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        if (isEmpty(data?.items)) {
                            setNoData(true);
                        } else {
                            setNoData(false);
                        }
                    },
                })
            );
        }
    }, [feedId]);

    useEffect(() => {
        setEventShow(events);
    }, [events]);

    useEffect(() => {
        if (onClickDelete && !isEmpty(currentItem)) {
            if (!isEmpty(feedId)) {
                dispatch(deleteItemFeedEvent({ 
                    uid: encodeURIComponent(currentItem.uid),
                    onComplete: ({ error, cancelled, data }) => {
                        if(data === true) {
                            dispatch(
                                getEventsById({
                                    id: `${feedId}`,
                                    limit: 10,
                                    sortBy: sortBy,
                                    onComplete: ({ error, cancelled, data }) => {
                                        if (error || cancelled) return;
                                        if (isEmpty(data?.events)) {
                                            setNoData(true);
                                        } else {
                                            setNoData(false);
                                        }
                                    },
                                })
                            );
                        }
                    }
                }));
            } else {
                dispatch(deleteEventUnassign({ uid: encodeURIComponent(currentItem.event.uid) }));
            }
            dispatch(onClickConfirmDialog(false));
        }
    }, [onClickDelete]);

    useEffect(() => {
        const itemScroll = document.getElementById("detect-scroll");
        const handleScroll = () => {
            // console.log('itemScroll.scrollTop',itemScroll.scrollTop)
            const height = document.getElementById("container-HeaderDetail").clientHeight;
            const heightHeaderSticky = document.getElementById("ctn-header-sticky").clientHeight;
            const valueScroll = isEmpty(feedId) ? 80 : height - heightHeaderSticky + 45;
            if (itemScroll.scrollTop > valueScroll) {
                setActiveSticky(true);
            }
            if (itemScroll.scrollTop < valueScroll && isActiveSticky !== null) {
                setActiveSticky(false);
            }
        };
        itemScroll.addEventListener("scroll", handleScroll, { passive: false });
        return () => itemScroll.removeEventListener("scroll", handleScroll);
    }, [isActiveSticky]);

    useEffect(() => {
        setOpenSidebar(isOpenSidebar);
    }, [isOpenSidebar]);

    function onKeyPress(e) {
        if(e.key === 'Enter') {
            setKeyCode(13);
        }
    }

    useEffect(() => {
        if(keyCode === 13) {
            submitSearch()
        }
    }, [keyCode]);

    function submitSearch() {
        setKeyCode()
        setPage(1)
        if (!isEmpty(feedId)) {
            // dispatch(getItemFeedById(`${feedId}`));
            dispatch(
                getEventsById({
                    id: `${feedId}`,
                    limit: 10,
                    sortBy: sortBy,
                    query: searchValue,
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        if (isEmpty(data?.events)) {
                            setNoData(true);
                        } else {
                            setNoData(false);
                        }
                    },
                })
            );
        } else {
            dispatch(
                getListEventUnassign({
                    status: filterBy,
                    limit: 10,
                    query: searchValue,
                    sortBy: sortBy,
                    onComplete: ({ error, cancelled, data }) => {
                        if (error || cancelled) return;
                        if (isEmpty(data?.items)) {
                            setNoData(true);
                        } else {
                            setNoData(false);
                        }
                    },
                })
            );
        }
    };

    return (
        <div
            className={clsx(
                "display-col",
                "container-detail-view",
                openSidebar ? "container-open-bar-detail-view" : "container-close-bar-detail-view",
                isEmpty(feedId) && "container-events-detail-view"
            )}
        >
            <div id="container-HeaderDetail">
                <HeaderDetail isEvent={isEmpty(feedId)} data={!isEmpty(feedId) ? itemFeed : ""} />
            </div>
            {!isEmpty(feedId) ? (
                <HeaderDetailSticky isEvent={isEmpty(feedId)} data={!isEmpty(feedId) ? itemFeed : ""} />
            ) : (
                <HeaderEventSticky title={"Events"} logo={Logo} />
            )}
            
            <div className={classes.containerList}>
                {
                    isLoadingEvent ? (
                        <ScreenLoading loaderType={"line-scale"} sentences={[]} />
                    ) : (
                        <>
                            <div className="display-row" style={{ marginLeft: 12, marginTop: 20 }}>
                            <div
                                className="display-row"
                                style={{ cursor: "pointer", height :"24px" }}
                                onClick={() => {
                                    if (!isEmpty(feedId)) {
                                        history.push(`/feed/${feedId}/create`);
                                    } else {
                                        history.push(`/create/event`);
                                    }
                                }}
                            >
                                <img alt="" src={AddIcon} />
                                <div className={classes.txtAddTo}>{`${
                                    isEmpty(feedId)
                                        ? "ADD EVENT"
                                        : itemFeed.isSubscribable
                                        ? "ADD TO CALENDAR"
                                        : itemFeed.isSubscribable === false
                                        ? "ADD TO COLLECTION"
                                        : ""
                                }`}</div>
                            </div>
                            <div style={{ flex: 1 }} />
                            <div className={appStyles.iconContentWrapperDetail} >
                                <input 
                                    id="searchdetail"
                                    ref={searchDetailRef}
                                    type="text" 
                                    className="search-form-detail" 
                                    name="searchdetail" 
                                    placeholder="Search here"
                                    value={searchValue}
                                    onKeyPress={onKeyPress}
                                    onChange={(e)=>{
                                        e.preventDefault();
                                        setSearchValue(e.target.value);
                                    }}
                                    autoComplete="off"
                                    // disabled={type === 'tags' && !tagValue}
                                />
                                <img className={appStyles.searchImage} src={SearchIcon} alt="" onClick={submitSearch}/>
                            </div>
                            {noData === false && (
                                <div className="display-center-row">
                                    <div style={{ marginRight: 32 }}>
                                        <SortComponent
                                            isAssignEvent={!isEmpty(feedId)}
                                            isEvent={true}
                                            isAscending={isAscending}
                                            sortBy={sortBy}
                                            onClickSort={(value, isInvert) => {
                                                setPage(1);
                                                setSortBy(value);
                                                if(isInvert) {
                                                    setAscending(!isAscending);
                                                }
                                                history.replace({
                                                    pathname: history.location.pathname,
                                                    search: "?" + new URLSearchParams({ sortBy: value, filterBy }).toString(),
                                                });
                                                if (!isEmpty(feedId)) {
                                                    dispatch(resetListEvent());
                                                    dispatch(
                                                        getEventsById({
                                                            id: feedId,
                                                            limit: count,
                                                            sortBy: value,
                                                            query: searchValue
                                                        })
                                                    );
                                                } else {
                                                    dispatch(resetListEvent());
                                                    dispatch(
                                                        getEventsUnassign({ status: filterBy, limit: count, sortBy: value, query: searchValue })
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                    {isEmpty(feedId) && (
                                        <FilterComponent
                                            filterBy={filterBy}
                                            onClickFilter={(value) => {
                                                setPage(1);
                                                setFilterBy(value);
                                                history.replace({
                                                    pathname: history.location.pathname,
                                                    search: "?" + new URLSearchParams({ sortBy, filterBy: value }).toString(),
                                                });
                                                dispatch(resetListEvent());
                                                dispatch(
                                                    getListEventUnassign({
                                                        status: value,
                                                        limit: count,
                                                        sortBy: sortBy,
                                                        onComplete: ({ error, cancelled, data }) => {
                                                            if (error || cancelled) return;
                                                            if (isEmpty(data?.items)) {
                                                                setNoData(true);
                                                            } else {
                                                                setNoData(false);
                                                            }
                                                        },
                                                    })
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className={clsx(
                                "display-row container-table-detail",
                                classes.headerTable,
                                isEmpty(feedId) ? classes.headerTableEvent : false
                            )}
                        >
                            <div className="col-detail">
                                <div className="display-row" style={{ marginLeft: 12, alignItems: "center" }}>
                                    <div
                                        className={clsx(
                                            "display-row",
                                            classes.refilterButtonAddStick,
                                            "defaultMoveUp",
                                            isActiveSticky === true ? [classes.activeButtonCreated, "moveUp"] : false
                                        )}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            if (!isEmpty(feedId)) {
                                                history.push(`/feed/${feedId}/create`);
                                            } else {
                                                history.push(`/create/event`);
                                            }
                                        }}
                                    >
                                        <img alt="" src={AddIcon} />
                                    </div>
                                    <span className={clsx(classes.txtHeaderCol, "txt-event-detail")}>Event Details</span>
                                </div>
                            </div>
                            <div className={clsx(classes.txtHeaderCol, "col-location")}>Location</div>
                            <div className={clsx(classes.txtHeaderCol, "col-time")}>Start time</div>
                            {isEmpty(feedId) && <div className="col-status"></div>}
                            <div className="col-dots"></div>
                        </div>
                        <div
                            className={clsx(
                                appStyles.scrollbar,
                                !isEmpty(feedId)
                                    ? [appStyles.TableContentDetail, "tableContentDetail"]
                                    : appStyles.TableContentEvent
                            )}
                        >
                            {noData ? (
                                <div
                                    className={clsx(
                                        "display-center",
                                        isEmpty(feedId) ? appStyles.txtNoDataDetail : appStyles.txtNoDataDetailEvent
                                    )}
                                >
                                    There is no data to display
                                </div>
                            ) : (
                                <>
                                    {eventShow?.events?.slice((page - 1) * count, page * count).map((item, index) => (
                                        <ItemDetail
                                            key={index}
                                            indexItem={index}
                                            item={item}
                                            itemFeed={itemFeed}
                                            onClickDeleteFeed={(item) => {
                                                setCurrentItem(item);
                                                dispatch(
                                                    openMessageDialog({
                                                        open: true,
                                                        message: `Are you sure you want to delete this ${
                                                            item.isSubscribable ? "calendar" : "collection"
                                                        }?`,
                                                        title: `DELETE ${item.isSubscribable ? "CALENDAR" : "COLLECTION"}`,
                                                    })
                                                );
                                            }}
                                        />
                                    ))}

                                    {eventShow?.items?.slice((page - 1) * count, page * count).map((item, index) => (
                                        <ItemEventDetail
                                            key={index}
                                            indexItem={index}
                                            item={item}
                                            onClickDeleteFeed={(item) => {
                                                setCurrentItem(item);
                                                dispatch(
                                                    openMessageDialog({
                                                        open: true,
                                                        message: "Are you sure you want to delete this event?",
                                                        title: "DELETE EVENT",
                                                    })
                                                );
                                            }}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                        <div
                            className={clsx(
                                appStyles.containerPagination,
                                !(!isEmpty(feedId) && !isEmpty(itemFeed)) ? appStyles.containerPaginationEvent : false
                            )}
                        >
                            {!isEmpty(feedId) && !isEmpty(itemFeed) && (
                                <div className={classes.txtUid}>{`${
                                    itemFeed.isSubscribable ? "Calendar" : itemFeed.isSubscribable === false ? "Collection" : ""
                                } uid: ${itemFeed.uid}`}</div>
                            )}
                            <div className="container-pagination">
                                <Pagination
                                    style={{ right: 2, top: 57 }}
                                    onCountChange={(value) => {
                                        setCount(value);
                                        setPage(1);
                                        dispatch(resetListEvent());
                                        if (!isEmpty(feedId)) {
                                            dispatch(
                                                getEventsById({
                                                    id: feedId,
                                                    limit: value,
                                                    sortBy: sortBy,
                                                })
                                            );
                                        } else {
                                            dispatch(getEventsUnassign({ status: filterBy, limit: value, sortBy: sortBy }));
                                        }
                                    }}
                                    onPreviousPage={(value) => {
                                        setPage(value);
                                    }}
                                    onNextPage={(value) => {
                                        if (!isEmpty(feedId)) {
                                            if (value * count > events.events.length && events.events.length < events.count) {
                                                dispatch(
                                                    getEventsById({
                                                        id: feedId,
                                                        limit: count,
                                                        sortBy: sortBy,
                                                        query: searchValue,
                                                        pageToken: events.nextPageToken,
                                                    })
                                                );
                                            }
                                        } else {
                                            if (value * count > events.items.length && events.items.length < events.count) {
                                                dispatch(
                                                    getEventsUnassign({
                                                        status: filterBy,
                                                        limit: count,
                                                        sortBy: sortBy,
                                                        query: searchValue,
                                                        pageToken: events.nextPageToken,
                                                    })
                                                );
                                            }
                                        }
                                        setPage(value);
                                    }}
                                    total={eventShow?.count}
                                    currentPage={page}
                                    currentCount={count}
                                />
                            </div>
                        </div>
                    </>
                    )
                }
            </div>
        </div>
    );
}

export default FeedDetailView;
