import React, { useState, useEffect, forwardRef, useCallback } from "react";
import { usePopper } from "react-popper";
import DatePicker from "react-datepicker";
import "../styles/react_datepicker.scss";
import "react-datepicker/dist/react-datepicker.css";
import { isEmpty } from "lodash";
import moment from "moment";
import { ClickAwayListener } from "@material-ui/core";

import { formatNaiveTime } from '../utils/Utils';

const is24 = window.navigator.language === "en-US" || window.navigator.userLanguage === "en-US";
const dateFmt = 'MMMM d, yyyy';
const timeFmt = is24 ? 'HH:mm' : 'h:mm a';
const dateTimeFmt = `${dateFmt} ${timeFmt}`;

const TimeList = (props) => {
    const { isOpen, value } = props;
    const [hours] = useState(() => {
        let hours;
        if (is24) {
            hours = [...Array(24).keys()];
        } else {
            // For AM/PM, the list will be:
            //  12, 1, 2, ..., 11
            hours = [...Array(12).keys()];
            hours[0] = 12;
        }
        return hours.map(i => formatSingleDigit(i));
    });
    const [minutes] = useState([...Array(60).keys()].map((i) => formatSingleDigit(i)));
    const [isAM, setAM] = useState();

    useEffect(() => {
        if (isOpen) {
            const elHourActive = document.querySelector(".hours__item.active");
            const elMinuteActive = document.querySelector(".minutes__item.active");
            if (elHourActive) elHourActive.scrollIntoView(true);
            if (elMinuteActive) elMinuteActive.scrollIntoView(true);
            setAM(moment(value).format("A") === "AM");
        }
    }, [isOpen]);

    function formatSingleDigit(num) {
        return num.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });
    }

    function handleChange(time, value) {
        props.onChange(time, value, isAM);
    }

    return (
        <div className="time-list">
            <div className="time-list__hours">
                {hours.map((hour) => (
                    <div
                        key={"hour-" + hour}
                        onClick={() => handleChange("hour", hour)}
                        className={`hours__item ${props.selectedHour === hour ? "active" : ""}`}
                    >
                        {hour}
                    </div>
                ))}
            </div>
            <div className="time-list__minutes">
                {minutes.map((minute) => (
                    <div
                        key={"minute-" + minute}
                        onClick={() => handleChange("minute", minute)}
                        className={`minutes__item ${props.selectedMinute === minute ? "active" : ""}`}
                    >
                        {minute}
                    </div>
                ))}
            </div>
            {!is24 && (
                <div className="time-list__format">
                    <div
                        onClick={() => {
                            setAM(true);
                            handleChange("formatTime", "AM");
                        }}
                        className={`minutes__item ${isAM ? "active" : ""}`}
                    >
                        AM
                    </div>
                    <div
                        onClick={() => {
                            setAM(false);
                            handleChange("formatTime", "PM");
                        }}
                        className={`minutes__item ${!isAM ? "active" : ""}`}
                    >
                        PM
                    </div>
                </div>
            )}
        </div>
    );
};

const PopperTime = ({ date, value, onChange }) => {
    let [hourInput, minuteInput] = value ? value.split(":") : [0, 0];
    if (hourInput !== null) {
        hourInput = Number(hourInput);
        minuteInput = Number(minuteInput);
    }
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    });
    const [isOpen, setIsOpen] = useState(false);
    const [hour, setHour] = useState(formatSingleDigit(hourInput));
    const [minute, setMinute] = useState(formatSingleDigit(minuteInput));
    const [formatTime, setFormat] = useState(hourInput < 12 ? 'AM' : 'PM');
    const [valueShow, setValueShow] = useState();
    const [hourShow, setHourShow] = useState(() => {
        if (!is24) {
            if (hourInput === 0) return formatSingleDigit(12);
            if (hourInput > 12) return formatSingleDigit(hourInput - 12);
        }
        return formatSingleDigit(hourInput);
    });
    function toggle(outside) {
        if (outside && isOpen) setIsOpen(false);
        else setIsOpen(!isOpen);
    }

    function formatSingleDigit(num) {
        // if (!num) return null;

        try {
            return Number(num).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            });
        } catch (error) {
            return null;
        }
    }

    function handleChange(type, value, isAM) {
        switch (type) {
            case 'minute':
                setMinute(formatSingleDigit(value));
                break;
            case 'hour':
                value = Number(value);
                setHourShow(formatSingleDigit(value));
                if (!is24) {
                    if (value === 12) value = 0;
                    if (!isAM) {
                        setHour(formatSingleDigit(value + 12));
                    } else {
                        setHour(formatSingleDigit(value));
                    }
                } else {
                    setHour(formatSingleDigit(value));
                }
                break;
            case 'formatTime':
                setFormat(value);
                setHour(_hour => {
                    _hour = Number(_hour) % 12;
                    if (value === 'PM') {
                        _hour += 12;
                    }
                    return formatSingleDigit(_hour);
                });
                break;
        }
    }

    useEffect(() => {
        if (hour && minute) {
            setValueShow(
                `${hourShow ? hourShow : "00"}:${minute ? minute : "00"} ${
                    !is24 && formatTime !== "Invalid date" ? formatTime : ""
                }`
            );
            onChange(`${hour ? hour : "00"}:${minute ? minute : "00"}`);
        }
    }, [hour, minute, formatTime]);

    return (
        <div>
            <div
                className="style-input"
                onClick={toggle}
                ref={setReferenceElement}
            >
                {valueShow}
            </div>

            {(isOpen && (
                <ClickAwayListener
                    onClickAway={() => {
                        setIsOpen(false);
                        onChange(`${hour ? hour : "00"}:${minute ? minute : "00"}`);
                    }}
                >
                    <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                        <TimeList
                            isOpen={isOpen}
                            // onBlur={() => onChange(`${hour}:${minute}`)}
                            onChange={handleChange}
                            toggle={toggle}
                            selectedHour={hourShow}
                            selectedMinute={minute}
                            value={date}
                        />
                        <div ref={setArrowElement} style={styles.arrow} />
                    </div>
                </ClickAwayListener>
            )) ||
                null}
        </div>
    );
};

const CustomInput = forwardRef(({ value, onClick, placeholderText }, ref) => (
    <div ref={ref} className="conInputTime" onClick={onClick}>
        <div style={{ opacity: isEmpty(value) ? 0.6 : 1 }}>{isEmpty(value) ? placeholderText : value}</div>
    </div>
));

const StringDatePicker = ({allDay, selected, range, selectsStart, placeholder, onChange, isoFormat}) => {
    const [time, setTime] = useState(null);
    const callback = useCallback(() => {
        if (!time) return onChange(null);
        let timeStr;
        if (isoFormat) timeStr = time.toISOString();
        else timeStr = formatNaiveTime(time)
        return onChange(timeStr);
    }, [time, onChange]);

    useEffect(() => {
        // convert selected time string to date, using local timezone
        if (selected) {
            //
            let momentTime = moment(selected);
            setTime(momentTime.toDate());
        } else {
            setTime(null);
        }
    }, [selected, setTime])

    return (
        <DatePicker
            dateFormat={allDay ? dateFmt : dateTimeFmt}
            selected={time}
            onChange={setTime}
            onCalendarClose={callback}
            // shouldCloseOnSelect={false}
            showTimeInput={!allDay}
            selectsStart={selectsStart}
            selectsEnd={!selectsStart}
            startDate={range[0] ? moment(range[0]).toDate() : null}
            endDate={range[1] ? moment(range[1]).toDate() : null}
            minDate={(!selectsStart && range[0]) ? moment(range[0]).toDate() : null}
            customInput={
                <CustomInput placeholderText={placeholder} />
            }
            placeholderText={placeholder}
            customTimeInput={<PopperTime />}
        />
    )
}

export default function SelectDate(props) {
    const {
        titleStart,
        titleEnd,
        showAllDay,
        style,
        initStartDate,
        initEndDate,
        onStartDateChange,
        onEndDateChange,
        require,
        isAllDay,
        onAllDayChange,
        isoFormat // for feed view - as we don't have a place to set timezone there
    } = props;

    function checkAllDay() {
        onAllDayChange(!isAllDay);
    }

    return (
        <div className="display-center-row" style={{ alignItems: "flex-end", marginTop: 10, ...style }}>
            <div className="display-column" style={{ flex: 1 }}>
                <div
                    style={{
                        display: "flex",
                        fontFamily: "SFProTextSemiBold",
                        fontSize: 12,
                        height: 18,
                        lineHeight: "14px",
                        color: "white",
                    }}
                >
                    {titleStart ? titleStart : "Publish Start Time"}
                    {require && <div style={{ color: "red", whiteSpace: "pre-wrap" }}> *</div>}
                </div>
                <StringDatePicker
                  allDay={isAllDay}
                  selected={initStartDate}
                  range={[initStartDate, initEndDate]}
                  selectsStart={true}
                  placeholder={titleStart ? "Event Start Time" : "Publish Start Time"}
                  onChange={onStartDateChange}
                  isoFormat={isoFormat}
                />
            </div>
            <div style={{ width: 30, height: 31 }} className="display-center">
                <div style={{ width: 14, height: 1, margin: "0 8px", background: "rgba(255, 255, 255, 0.6)" }}/>
            </div>
            <div className="display-column" style={{ flex: 1 }}>
                <div style={{ display: "flex", justifyContent: "space-between", height: 18 }}>
                    <div
                        style={{
                            display: "flex",
                            fontFamily: "SFProTextSemiBold",
                            fontSize: 12,
                            lineHeight: "14px",
                            color: "white",
                        }}
                    >
                        {titleEnd ? titleEnd : "Publish End Time"}
                        {require && <div style={{ color: "red", whiteSpace: "pre-wrap" }}> *</div>}
                    </div>
                    {showAllDay && (
                        <div className="display-row">
                            <input id="cb-all-day" checked={isAllDay} type="checkbox" onChange={checkAllDay} />
                            <div
                                style={{ marginLeft: 5, fontFamily: "SFProTextSemiBold", fontSize: 12, color: "white" }}
                            >
                                All day
                            </div>
                        </div>
                    )}
                </div>
                <StringDatePicker
                  allDay={isAllDay}
                  selected={initEndDate}
                  range={[initStartDate, initEndDate]}
                  selectsStart={false}
                  placeholder={titleStart ? "Event End Time" : "Publish End Time"}
                  onChange={onEndDateChange}
                  isoFormat={isoFormat}
                />
            </div>
        </div>
    );
}

SelectDate.defaultProps = {
    require: false,
    isAllDay: false,
};
