import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import CitiesAndTimeZones from '../assets/CitiesAndTimeZones';
import moment from 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconDown from "../assets/icons/icon-dropDown.svg";

import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const IconDropdown = () => (
    <svg
        width="12px"
        height="8px"
        viewBox="0 0 12 8"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ic/arrow</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.600000024">
        <g id="Field/Ic+Text+Arrow/Completed-Copy" transform="translate(-112.000000, -16.000000)" fill="#FFFFFF">
          <g id="ic/arrow" transform="translate(106.000000, 8.000000)">
            <path
                d="M12.7525767,8.86008765 L17.5488085,14.3414954 C17.9124905,14.757132 17.870373,15.3888946 17.4547364,15.7525767 C17.2724449,15.9120818 17.0384549,16 16.7962318,16 L7.20376823,16 C6.65148348,16 6.20376823,15.5522847 6.20376823,15 C6.20376823,14.7577769 6.29168647,14.5237869 6.45119153,14.3414954 L11.2474233,8.86008765 C11.6111054,8.44445102 12.242868,8.40233351 12.6585046,8.76601556 C12.6919154,8.79524999 12.7233423,8.82667688 12.7525767,8.86008765 Z"
                id="Triangle"
                transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
            />
          </g>
        </g>
      </g>
    </svg>
);

function getListTimeZones() {
  const localTz = moment.tz.guess();
  const lastTzPart = localTz.split('/').pop().replaceAll('_', ' ');

  const listTimezones = [{
    title: localTz,
    gmt: moment.tz(localTz).format('Z'),
    value: {
      city: lastTzPart,
      country: 'System Time Zone',
      timeZoneName: localTz,
    },
  }];

  CitiesAndTimeZones.forEach((item) => {
    listTimezones.push({
      title: item.timeZoneName,
      gmt: moment.tz(item.timeZoneName).format('Z'),
      value: item,
    });
  });

  return listTimezones;
}

const listDefaultTimeZones = getListTimeZones();

const CustomSelectTimeZone = ({ timeZone, onTimeZoneChange }) => {
  const [listOptions, setListOptions] = useState(listDefaultTimeZones);

  const [selected, _setSelected] = useState(null);
  const selectedRef = useRef(null);
  const setSelected = useCallback(val => {
    selectedRef.current = val;
    _setSelected(val);
  }, []);

  const timeZoneRef = useRef(null);

  useEffect(() => {
    if (timeZone && timeZone !== selectedRef.current?.title) {
      const matched = listDefaultTimeZones.find(tz => tz.title === timeZone);
      if (matched) {
        setSelected(matched);
      } else {
        let tzParts = timeZone.split('/');
        const firstTzPart = tzParts[0].replaceAll('_', ' ');
        const lastTzPart = tzParts.pop().replaceAll('_', ' ');
        const newList = getListTimeZones();
        const matched = {
          title: timeZone,
          gmt: moment.tz(timeZone).format('Z'),
          value: {
            city: lastTzPart,
            country: firstTzPart,
            timeZoneName: timeZone,
          },
        };
        newList.unshift(matched);
        setListOptions(newList);
        setSelected(matched);
      }
    }
  }, [timeZone, setSelected]);

  const formatTimeZone = (value) => {
    const localTime = moment.tz(new Date(), value);
    const res = localTime.format('h:mm A (z)');

    return res.replace(/([+-])0+/, '$1');
  };

  function closePopup() {
    if (timeZoneRef.current === null) return;
    const element = timeZoneRef.current.querySelector('.MuiAutocomplete-input');
    element.blur();
  }

  return (
      <div style={{ width: "100%", marginTop: 10 }} className="display-col custom-time-zone-picker">
        <div
            style={{
              fontSize: 12,
              lineHeight: "14px",
              color: "white",
              fontFamily: "SFProTextSemiBold",
              display: "flex",
            }}
        >Time Zone</div>
        <Autocomplete
          autoComplete
          disableClearable
          selectOnFocus={false}
          value={selected}
          ref={timeZoneRef}
          defaultValue={selected}
          onChange={(event, option) => {
            if (option !== null) {
              setSelected(option);
              onTimeZoneChange(option.value.timeZoneName);
            }
          }}
          className="display-center-row"
          style={{
            flex: 1,
            marginTop: 4,
          }}
          // style={{ width: "100%" }}
          onClose={closePopup}
          getOptionLabel={(option) => (option.value ? `${option.value.country} / ${option.value.city}` : '')}
          // getOptionLabel={(option) => (option.value ? option.value.timeZoneName : '')}
          options={listOptions}
          renderOption={(option) => {
              // <div className="item-timezone">
              //   <div>
              //     <p className="value">{option.value.city}</p>
              //     <p className="display">{option.value.country}</p>
              //   </div>
              //   <div className="time">{formatTimeZone(option.value.timeZoneName)}</div>
              // </div>
              return <div className="custom-time-zone-option"
                   style={{
                       height: 30,
                       background: "#181818",
                       color: "#ADAFB2",
                       cursor: "pointer",
                       zIndex: 100,
                       pointerEvents: "auto",
                       fontFamily: "SFProDisplay",
                       fontSize: 14
                   }}>{option.value.city} / {option.value.country}, {formatTimeZone(option.value.timeZoneName)}</div>
          }}
          popupIcon={<img src={IconDown} width={16} height={16} style={{ margin: "5px 0px" }} alt="" />}
          renderInput={(params) => <TextField {...params} inputProps={{
            ...params.inputProps,
            style: {
              fontFamily: "SFProDisplay",
              fontSize: 14,
            }
          }} variant="outlined" />}
        />
      </div>
  );
}

export default CustomSelectTimeZone;
